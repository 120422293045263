import React, { useState } from 'react';
import { Button, Form, message, Space } from 'antd';
import { IPerson, IPersonCourse } from '../../types';
import { BRDatePicker } from '../BRForm';
import { FormProps } from 'antd/lib/form';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS, DATE_OPTIONS } from '../../constants';
import { useBRStore } from '../../hooks/useBRStore';
import { BRDrawer, BRDrawerProps } from '../BRDrawer';
import { ROUTES } from '../../routes';
import { SessionContext } from '../App';
import { Link } from '../../hooks/useRoutes';
import { RemoveCourseFromPersonModal } from '../modals/RemoveCourseFromPersonModal';
import { ModalContext } from '../BRModal';

interface Props extends BRDrawerProps {
  person: IPerson;
  courseTakenUuid: string;
  onSuccess?: () => void;
}

export const PersonCourseDrawer: React.FunctionComponent<Props> =
  ({ person, courseTakenUuid, onSuccess, ...drawerProps }) => {

    const { session } = React.useContext(SessionContext);
    const today = new Date();
    const [scheduledDate, setScheduledDate] = useState(today);
    const [dirty, setDirty] = useState(false);

    const fetchDataParams = React.useMemo(() => ({ url: `/v0/people/${person.uuid}/courses/${courseTakenUuid}` }), [person.uuid]);
    const course = useBRStore<IPersonCourse>({ fetchDataParams });

    function dirtyWrap<T>(fn: React.Dispatch<T>) {
      return (arg: T) => {
        setDirty(true);
        fn(arg);
      };
    }

    const [modal, setModal] = React.useState<React.ReactNode>();

    const onModalSuccess = () => {
      message.success('Remove course completed successfully.');
      onCourseDrawerClose();
    };

    const removeModal = (
      <RemoveCourseFromPersonModal
        person={person}
        course={courseTakenUuid}
        onSuccess={onModalSuccess}
      />
    );

    const openRemoveModal = () => {
      setModal(removeModal);
    };

    const closeModal = () => { setModal(undefined); };

    React.useEffect(() => {
      const scheduled = course.data.scheduledDate && new Date(course.data.scheduledDate) || today;
      setScheduledDate(scheduled);
    },              [course.loaded],
    );

    React.useEffect(
      () => {
        course.fetch();
      },
      [course.fetch]);

    const completedDate = course.data.completedDate;

    const handleSubmit = async () => {
      const formData: {
        courseTaken: string | undefined,
        person: string | undefined,
        scheduledDate: number | undefined,
      } = {
        courseTaken: courseTakenUuid,
        person: person.uuid,
        scheduledDate: scheduledDate && scheduledDate.getTime(),
      };

      await sendCommand({
        formData,
        command: COMMANDS.UPDATE_PERSON_COURSE,
      });

      onSuccess && onSuccess();
    };

    const formProps: FormProps = {
      onFinish: handleSubmit,
    };

    const dateItem = completedDate ?
      { label: 'Completed Date', date: completedDate, editable: false } :
      { label: 'Scheduled Date', date: scheduledDate, editable: scheduledDate && course.data.transcript?.provider.name === 'Manual' };

    const onCourseDrawerClose = () => {
      setModal(undefined);
      session.router.go({ name: ROUTES.person }, { uuid: person.uuid, tab: 'courses' }, { query: session.router.query });
    };

    const buttons: React.ReactNode[] = [];

    if (course.data?.transcript?.provider?.name === 'Manual') {
      buttons.push((
        <Button key="remove" type="primary" danger={true} onClick={openRemoveModal}>Remove Course</Button>
      ));
    }

    return (
      <>
        <BRDrawer
          {...drawerProps}
          width={400}
          title={
            <><Link href={session.router.getUrl({ name: ROUTES.course }, { uuid: `${course.data.course}` })}>{course.data.name || 'Course'}</Link> Details</>
          }
          okText="Save"
          cancelText="Cancel"
          formProps={formProps}
          dirty={dirty}
          onClose={onCourseDrawerClose}
          buttons={buttons}
        >
          { (dateItem.editable) ?
            (
              <Form.Item label={dateItem.label}>
                <BRDatePicker
                  allowClear={true}
                  dateValue={scheduledDate}
                  setValue={dirtyWrap(setScheduledDate)}
                />
              </Form.Item>
            )
            :
            (
              <Form.Item label={dateItem.label}>
                <em className="ant-form-text">
                  {dateItem.date ? new Date(dateItem.date).toLocaleString('en-US', DATE_OPTIONS) : 'No Date Recorded'}
                </em>
              </Form.Item>
            )
          }
        </BRDrawer>
        {!!modal && (
          <ModalContext.Provider value={{ close: closeModal, onSuccess: closeModal, visible: !!modal }}>
            {modal}
          </ModalContext.Provider>
        )}
      </>
    );
  };
