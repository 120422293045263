import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { RuleEditorNodeProps } from '.';

export function RuleEditorEdit(props: RuleEditorNodeProps) {

  const onEdit = () => {
    props.openEditor(props.elt);
  };

  return (
    <Button onClick={onEdit} icon={<EditOutlined />}>Edit</Button>
  );
}
