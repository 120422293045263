
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { SessionContext } from './App';
import { BRStore } from '../hooks/useBRStore';
import { TenureDocument } from '../resources/TenureDocument';
import { ITenureApprovalStatuses, IApprovalStatus } from '../types';
import { IQueryStore } from '../types/storeTypes/IQueryStore';

export const TenureAttachmentsList: React.FunctionComponent<{
  approvalStore: BRStore<ITenureApprovalStatuses>,
  tenure: TenureDocument,
}> = ({ approvalStore, tenure }) => {
  const { fetch } = approvalStore;
  const { records: statuses, templates } = approvalStore.data;
  const { session } = React.useContext(SessionContext);
  const [attachments, setAttachments] = useState([] as any[]);

  useEffect(() => { fetch(); }, [fetch]);

  useEffect(
    () => {
      if (statuses) {
        const newAttachments: any[] = [];
        statuses.forEach((status: IApprovalStatus) => {
          const approval = status.approvalItem.name;
          const query: IQueryStore = {
            tab: 'approvals',
            selection: templates.find(template => template.approvalItems && template.approvalItems.find(item => item.uuid === status.approvalItem.uuid) != null)?.uuid,
          };
          const hash = status.approvalItem.uuid;
          if (status.attachments) {
            status.attachments.forEach((attachment) => {
              newAttachments.push({
                approval,
                query,
                hash,
                uuid: attachment.uuid,
                name: attachment.name || '',
                location: '',
              });
            });
          }
          if (status.physicalAttachments) {
            status.physicalAttachments.forEach((attachment) => {
              newAttachments.push({
                approval,
                query,
                hash,
                uuid: attachment.uuid,
                name: attachment.name || '',
                location: attachment.location || '',
              });
            });
          }
        });
        setAttachments(newAttachments);
      }
    },
    [statuses, templates, tenure]);

  const stringSorter = (column: string) => (a: any, b: any) => a[column].localeCompare(b[column]);

  const attachmentListTableColumns = [
    {
      title: 'Approval',
      dataIndex: 'approval',
      key: 'approval',
      sorter: stringSorter('approval'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: stringSorter('name'),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      sorter: stringSorter('location'),
    },
  ];

  const getAttachmentTableRowKey = (attachment: any) => attachment.uuid;

  const onClick = (record: any) => {
    session.router.go(
      { name: session.router.current.name },
      session.router.current.params,
      { query: record.query, hash: record.hash, replace: true },
    );
  };

  const onRow = (record: any) => {
    return {
      onClick: () => onClick(record),
    };
  };

  return (
    <Table dataSource={attachments} columns={attachmentListTableColumns} rowKey={getAttachmentTableRowKey} onRow={onRow} style={{ cursor: 'pointer' }}/>
  );
};
