import React, { useState } from 'react';
import Cascader, { CascaderOptionType, CascaderValueType, ShowSearchType } from 'antd/lib/cascader';

const UNCATEGORIZED = 'Uncategorized';

interface Props {
  resources: any[];
  disabled?: boolean;
  setResourceUuid: (resource: string) => void;
}

export function CategorizedCascader(props: Props) {
  const [selection, setSelection] = useState<CascaderValueType>(props.disabled && props.resources && props.resources.length > 0 ? ['', props.resources[0].uuid] : ['', '']);

  function buildCascaderOptions(resources: any[], disabled = false): CascaderOptionType[] {
    if (!resources || resources.length === 0) {
      return [];
    }

    if (disabled) {
      // If the field is disabled, just add the first resource, with no categories
      return [{
        label: '',
        value: '',
        children: [{
          label: resources[0].name,
          value: resources[0].uuid,
          key: resources[0].uuid,
        }],
      }];
    }
  
    // The option list starts with only uncategorized resources
    const resOptions: CascaderOptionType[] = [{
      label: UNCATEGORIZED,
      value: UNCATEGORIZED,
      key: UNCATEGORIZED,
      children: [],
    }];
  
    for (const res of resources) {
      if (res.categories?.length > 0) {
        // Add the resource to all of its categories
        for (const cat of res.categories) {
          const catName = cat.name ?? cat; // Allow categories to be strings or objects
          let catOption = resOptions.find(o => o.label === catName);
          if (!catOption) {
            catOption = {
              label: catName,
              value: catName,
              key: catName,
              children: [],
            }
            resOptions.push(catOption);
          }
          catOption.children?.push({
            label: res.name,
            value: res.uuid,
            key: res.uuid,
          });
        }
      } else {
        // Add the resource to the list of uncategorized resources
        resOptions[0].children?.push({
          label: res.name,
          value: res.uuid,
        });
      }
    }
  
    if (resOptions[0].children?.length === 0) {
      // If no resources are uncategorized, remove that as an option
      resOptions.shift();
    }

    // Sort category names alphabetically
    resOptions.sort((a: any, b: any) => a.label ? a.label.localeCompare(b.label) : 1);

    // Within each category, sort resource names alphabetically
    resOptions.forEach(cat => cat.children?.sort((a: any, b: any) => a.label ? a.label.localeCompare(b.label) : 1));
  
    return resOptions;
  }

  const options = buildCascaderOptions(props.resources, props.disabled);

  const handleSelection = (value: CascaderValueType) => {
    setSelection(value);
    props.setResourceUuid(`${value[1]}`);
  }

  const displayResource = (label: string[]) => {
    return label[1];
  }

  const filter = (inputValue: string, path: CascaderOptionType[]) => {
    return path.some(option => `${option?.label}`.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  return <Cascader
    options={options}
    onChange={handleSelection}
    disabled={props.disabled}
    displayRender={displayResource}
    showSearch={{ filter }}
    value={selection}
  />;
}