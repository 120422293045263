import React, { useContext } from 'react';
import { Button, Dropdown, Menu, message, Typography } from 'antd';
import { CLIENT_CONST } from '../constants';
import logoSvg from '../images/logo.svg';
import { NavMenu }  from './NavMenu';
import { SessionContext } from './App';
import { Link } from '../hooks/useRoutes';
import Title from 'antd/lib/typography/Title';
import { ROUTES } from '../routes';
import { LogoutOutlined, CaretDownFilled, UserOutlined, UserSwitchOutlined, EyeOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';
import { hasPermission, PERMISSION_TYPES } from '../helpers/hasPermission';
import { ModalContext } from './BRModal';
import { Command } from './CommandButtons';
import { ImpersonateModal } from './modals/ImpersonateModal';
import { authServices } from '../services/auth.services';

const { Text } = Typography;

const impersonateCommand: Command = {
  title: 'Impersonate',
  icon: <UserSwitchOutlined />,
  modal: (
    <ImpersonateModal />
  ),
};

export function NavBar() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const { session, dispatch } = useContext(SessionContext);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [current, setCurrent] = React.useState<Command>();

  const closeModal = () => { setModalVisible(false); };

  function makeOnSuccess(item: Command) {
    return () => {
      setCurrent(undefined);
      message.success(`${item.title} completed successfully.`);
    };
  }

  function handleResize() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case 'impersonate':
        setCurrent(impersonateCommand);
        setModalVisible(true);
        break;
      case 'deimpersonate':
        authServices.deimpersonate().then((result) => {
          if (result.person) {
            dispatch({
              type: 'userData',
              user: result.person,
              scope: result.scope.split(' '),
            });
            session.router.go(
              { name: session.router.current.name },
              session.router.current.params,
            );
          }
        });
        break;
      case 'logout':
        dispatch({ type: 'reset' });
        break;
      default:
        return;
    }
  };

  const { user, router } = session;
  const { RELEASE_NUMBER, TITLE } = CLIENT_CONST;

  const dropdownMenu = (
    <Menu onClick={handleMenuClick}>
      { width <= 450 && user && (
        <Menu.Item key="username" disabled={true}>
          <Text type="secondary" style={{ fontSize: '0.9em' }}>
            {user.firstName} {user.lastName}
          </Text>
        </Menu.Item>
      )}
      { width <= 450 && (
        <Menu.Divider />
      )}
      { hasPermission(PERMISSION_TYPES.impersonate, session) && (
        <Menu.Item key="impersonate">
          <UserSwitchOutlined />
          Impersonate
        </Menu.Item>
      )}
      { hasPermission(PERMISSION_TYPES.deimpersonate, session) && (
        <Menu.Item key="deimpersonate">
          <UserSwitchOutlined />
          Stop Impersonating
        </Menu.Item>
      )}
      <Menu.Item key="logout">
        <LogoutOutlined />
        Logout
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="release" disabled={true}>
        <Text type="secondary" style={{ fontSize: '0.8em' }}>Revision {RELEASE_NUMBER}</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="top">
        <div>
          <Link href={router.getUrl({ name: ROUTES.root })} style={{ color: 'white' }}>
            <img
              src={logoSvg}
              alt="[Logo]"
            />
            <Title
              level={4}
              style={{ color: 'white', display: 'inline', position: 'relative', top: '0.1em' }}
            >
              {TITLE}
            </Title>
          </Link>
        </div>
        <div>
          <Dropdown overlay={dropdownMenu}>
            <Button
              icon={hasPermission(PERMISSION_TYPES.deimpersonate, session) ? <ExclamationCircleTwoTone twoToneColor="red" /> : <UserOutlined />}
            >
              {width > 450 && user && [user.firstName, user.lastName].join(' ')}
              <CaretDownFilled />
            </Button>
          </Dropdown>
        </div>
      </div>
      <div>
        <NavMenu/>
      </div>
      { current && (
        <ModalContext.Provider value={{ close: closeModal, onSuccess: makeOnSuccess(current), visible: modalVisible }}>
          {current.modal}
        </ModalContext.Provider>
      )}
    </>
  );
}
