import React from 'react';
import { Card } from 'antd';
import { statusTagRenderer } from '../helpers/dataRenderers';
import { IPerson } from '../types/IPerson';

interface Props {
  person: IPerson;
  descriptionItems?: { [index: string]: string };
}

export function PersonCard(props: Props) {
  const { person } = props;
  return (
    <Card>
      <p>Name: {`${person.firstName} ${person.lastName}`}</p>
      <p>Status: {statusTagRenderer(person.status || 'Inactive', person)}</p>
      {props.descriptionItems &&
        Object.entries(props.descriptionItems).map(([key, value]) => {
          return <p key={key}>{key}: {value}</p>;
        })
      }
    </Card>
  );
}
