import React, { useContext } from 'react';
import { BRModal, BRModalProps } from '../BRModal';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { SessionContext } from '../App';
import { IMail } from '../../types';

interface Props extends BRModalProps {
  mail: IMail;
}

export const CancelNotificationModal: React.FunctionComponent<Props> =
({ mail, ...modalProps }) => {

  const { session } = useContext(SessionContext);

  const onFinish = async () => {
    const formData = { uuid: mail.uuid };
    const response = await sendCommand({
      formData,
      command: COMMANDS.CANCEL_EMAIL_NOTIFICATION,
    });
  };

  const title = 'Cancel Notification';

  return (
    <BRModal
      {...modalProps}
      title={title}
      cancelText="No"
      okText="Yes"
      formProps={{ onFinish }}
    >
      <p><strong>Are you sure you want to cancel this email?</strong></p>
      <p><strong>Subject:</strong> {mail.subject}</p>
    </BRModal>
  );
};
