import * as React from 'react';
import { TABLE_IDS } from '../constants';
import { BRLayout } from '../components/BRLayout';
import { buildReportDetailTitle } from '../helpers/buildDetailPageTitle';
import BRTable from '../components/BRTable';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { IReport } from '../types';

interface IProps {
  uuid: string;
  type: string;
}

export function ReportDetailPage(props: IProps) {

  const report = useBRTableStore<IReport>({
    fetchDataParams: React.useMemo(() => ({ url: `/v0/reports/${props.uuid}` }), [props.uuid]),
  });

  const { type } = props;

  const reportTypeToTableId = {
    requirementReport: TABLE_IDS.REPORT_REQUIREMENT_TABLE,
    courseReport: TABLE_IDS.REPORT_COURSE_TABLE,
    categoryReport: TABLE_IDS.REPORT_CATEGORY_TABLE,
    rcrReport: TABLE_IDS.REPORT_RCR_TABLE,
  };

  const tableId = reportTypeToTableId[type];

  return (
    <BRLayout
      title={buildReportDetailTitle(report)}
      subTitle={report.criteria ?
                report.criteria.map((c: any) => c.name).join(', ') :
                undefined}
    >
      <BRTable
        tableId={tableId}
        tableData={report}
      />
    </BRLayout>
  );
}
