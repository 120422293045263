import React, { useState } from 'react';
import { Form } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments } from '../BRForm';
import { ICourse } from '../../types';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';

interface Props extends BRModalProps {
  course: ICourse;
}

export const ActivateCourseModal: React.FunctionComponent<Props> =
({ course, ...modalProps }) => {

  const [comments, setComments] = useState('');

  const onFinish = async () => {
    const { uuid } = course;
    const formData = { uuid, comments };

    await sendCommand({
      formData,
      command: COMMANDS.ACTIVATE_COURSE,
    });
  };

  const title = `Activate ${course.name}`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Activate Course"
      formProps={{ onFinish }}
    >
      <Form.Item label="Course Name">
        <span className="ant-form-text">
          {course.name}
        </span>
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
