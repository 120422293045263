import { Alert, Button, Form, Input, Radio } from 'antd';
import React, { useState } from 'react';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { IPerson } from '../../types';
import { BRModal, BRModalProps } from '../BRModal';

interface Props extends BRModalProps {
  person: IPerson;
}

export const UpdatePersonModal: React.FunctionComponent<Props> =
({ person, ...modalProps }) => {
  const [firstName, setFirstName] = useState(person.firstName ?? '');
  const [lastName, setLastName] = useState(person.lastName ?? '');
  const [emails, setEmails] = useState(person.keys?.email ?? []);
  // The "primaryEmail" key is used for storing a custom primary email address for people imported from external sources
  // If it doesn't exist, the first email in the "email" key becomes the primary email
  const [primaryEmail, setPrimaryEmail] = useState(person.primaryEmail ?? '');
  const [customPrimaryEmail, setCustomPrimaryEmail] = useState(person.keys?.email?.indexOf(primaryEmail) ?? -1 < 0 ? primaryEmail : '');
  const isImported = person.createdBy != null && person.createdBy !== 'Application';

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name.startsWith('email-')) {
      const emailIndex = parseInt(name.split('-')[1], 10);
      if (emailIndex < emails.length - 1) {
        setEmails([...emails.slice(0, emailIndex), value, ...emails.slice(emailIndex + 1)]);
      } else {
        setEmails([...emails.slice(0, emailIndex), value]);
      }
    } else {
      switch (name) {
        case 'firstName':
          setFirstName(value);
          break;
        case 'lastName':
          setLastName(value);
          break;
        case 'primaryEmail':
          setPrimaryEmail(value);
          if (primaryEmail !== customPrimaryEmail) {
            // Reset the custom primary email if it's not being used
            setCustomPrimaryEmail('');
          }
          break;
        case 'customPrimaryEmail':
          setCustomPrimaryEmail(value);
          setPrimaryEmail(value);
      }
    }
  };

  const addEmail = () => {
    setEmails([...emails, '']);
  };

  const removeEmail = (index: number) => () => {
    if (index < emails.length - 1) {
      setEmails([...emails.slice(0, index), ...emails.slice(index + 1)]);
    } else {
      setEmails(emails.slice(0, index));
    }
  };

  const onFinish = async () => {
    if (isImported) {
      const formData = {
        primaryEmail,
        uuid: person.uuid,
      };
      await sendCommand({
        formData,
        command: COMMANDS.UPDATE_PERSON_PRIMARY_EMAIL,
      });
    } else {
      const formData = {
        firstName,
        lastName,
        emails,
        primaryEmail,
        uuid: person.uuid,
      };
      await sendCommand({
        formData,
        command: COMMANDS.UPDATE_PERSON,
      });
    }
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <BRModal
      {...modalProps}
      title={'Update Person'}
      formProps={{ onFinish }}
    >
      {isImported ? (
        <p>
          <Alert message={`Since this person was imported from ${person.createdBy}, you will only be able to set the user's primary email address here. Please update other information at its source if necessary.`} type="warning" />
        </p>
      ) : <></>}
      <Form.Item label="First Name">
        <Input
          type="text"
          name="firstName"
          value={firstName}
          onChange={handleChange}
          disabled={isImported}
        />
      </Form.Item>
      <Form.Item label="Last Name">
        <Input
          type="text"
          name="lastName"
          value={lastName}
          onChange={handleChange}
          disabled={isImported}
        />
      </Form.Item>
      <Form.Item label="Emails">
        {emails?.map((email, index) => {
          return (
            <span key={`email-span-${index}`}>
              <Input
                type="text"
                name={`email-${index}`}
                key={`email-${index}`}
                value={emails[index]}
                onChange={handleChange}
                addonAfter={ !isImported ? (
                  <Button key={`email-remove-${index}`} onClick={removeEmail(index)} type="link" block={true} size="small">Remove</Button>
                ) : undefined}
                disabled={isImported}
              />
            </span>
          );
        })}
        {!isImported ? (
          <Button onClick={addEmail}>Add</Button>
        ) : <></>}
      </Form.Item>
      <Form.Item label="Primary Email">
        <Radio.Group
          name="primaryEmail"
          value={primaryEmail}
          onChange={handleChange}
        >
          {emails?.map((email, index) => {
            return (
              <Radio
                style={radioStyle}
                key={`radio-${index}`}
                value={email}
              >
                {email}
              </Radio>
            );
          })}
          <Radio
            style={radioStyle}
            key={'radio-custom'}
            value={customPrimaryEmail}
          >
            Other:&nbsp;
            <Input
              type="text"
              name="customPrimaryEmail"
              value={customPrimaryEmail}
              onChange={handleChange}
            />
          </Radio>
        </Radio.Group>
      </Form.Item>
    </BRModal>
  );
};
/* <Input
type="text"
name="primaryEmail"
value={primaryEmail}
onChange={handleChange}
required={isImported}
/>
 */
