import * as React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface HelpProps {
  text: string;
}

export function Help(props: HelpProps) {
  return (
    <span title={props.text} style={{ color: 'darkgrey' }}><QuestionCircleOutlined /></span>
  );
}
