import * as React from 'react';
import { Button, Menu, Dropdown, message } from 'antd';
import { ToolFilled, EllipsisOutlined } from '@ant-design/icons';
import { ModalContext } from './BRModal';

export type ClickHandler = React.EventHandler<React.MouseEvent<HTMLButtonElement>>;

export interface Command {
  modal?: React.ReactNode;
  // command type is used for permissions with checkCommands function
  // an absent type will default to show the command button at all times
  type?: string;
  title: string;
  icon: JSX.Element;
  enabled?: boolean;
  onClick?: () => void;
}

interface Props {
  commands: Command[];
  vertical?: boolean;
}

export function CommandButtons({ commands, vertical }: Props) {
  const [count, setCount] = React.useState(commands.length);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [current, setCurrent] = React.useState<Command>();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [div, setDiv] = React.useState<HTMLDivElement>();

  function makeOnClick(item: Command) {
    return item.modal ? () => { setCurrent(item); setModalVisible(true); } : item.onClick;
  }

  function makeOnSuccess(item: Command) {
    return () => {
      setCurrent(undefined);
      message.success(`${item.title} completed successfully.`);
    };
  }

  const close = () => { setModalVisible(false); };

  React.useEffect(
    () => {
      setCount(commands.length);
    },
    [commands],
  );

  React.useEffect(
    () => {
      const handleResize = () => {
        if (width !== window.innerWidth) {
          setWidth(window.innerWidth);
          setCount(commands.length);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    },
    [commands],
  );

  React.useEffect(
    () => {
      if (!vertical && div && div.clientHeight > 40 && count > 0) {
        // setCount causes another render
        // adjusting count until header height doesn't overflow
        setCount(count - 1);
      }
    },
    [width, count, div]);

  const updateCount = (el: HTMLDivElement) => {
    // runs every time <div> is rendered
    setDiv(el);
  };

  const out = [];

  commands.slice(0, count).forEach((item: Command, i: number) => {
    out.push((
      <Button
        size="small"
        key={item.title}
        type="primary"
        disabled={item.enabled === false}
        onClick={makeOnClick(item)}
        icon={item.icon}
        block={vertical}
      >
        {item.title}
      </Button>
    ));
  });

  const rest = commands.slice(count);

  if (rest.length > 0) {
    const clickHandlers = {};
    const handleMenuClick = (e: any) => {
      clickHandlers[e.key](e);
    };
    const items = rest.map((item: Command, i: number) => {
      const onClick = makeOnClick(item);
      clickHandlers[item.title] = onClick;
      return (
        <Menu.Item key={item.title} disabled={item.enabled === false} onClick={onClick}>{item.enabled === false ? 'loading' : item.icon} {item.title}</Menu.Item>
      );
    });
    out.push((
      <Dropdown
        key={'dropdown'}
        overlay={<Menu onClick={handleMenuClick}>{items}</Menu>}
        trigger={['click']}
      >
        <Button
          size="small"
          type="primary"
          icon={count === 0 ? <ToolFilled /> : <EllipsisOutlined />}
        />
      </Dropdown>
    ));
  }

  const className = vertical ? 'br-command-buttons-vertical' : 'br-command-buttons';

  return (
    <div ref={updateCount} className={className}>
      {out}
      { current && (
        <ModalContext.Provider value={{ close, onSuccess: makeOnSuccess(current), visible: modalVisible }}>
          {current.modal}
        </ModalContext.Provider>
      )}
    </div>
  );
}
