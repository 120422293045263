import * as React from 'react';
import { Form, Alert } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { IReport } from '../../types/IReport';
import { reportsTypeNames, COMMANDS } from '../../constants';
import { ButtonProps } from 'antd/lib/button';
import { sendCommand } from '../../services/commands.services';
import { ROUTES } from '../../routes';
import { SessionContext } from '../App';

interface Props extends BRModalProps {
  report: IReport | undefined;
}

export const RerunReportModal: React.FunctionComponent<Props> =
({ report = {}, ...modalProps }) => {

  const { session } = React.useContext(SessionContext);

  const onFinish = async () => {
    const formData = {
      uuid: report.uuid,
    };

    const response = await sendCommand({
      formData,
      command: COMMANDS.RERUN_REPORT,
    });

    session.router.go({ name: ROUTES.report }, response);
  };

  const okButtonProps: ButtonProps = {
    disabled: !report.uuid,
  };

  const formattedType = (report.type) ? reportsTypeNames[report.type] : '';

  const formattedCriteria = (report.criteria && report.criteria.length)
    ? report.criteria.map(c => c.name).join(', ')
    : 'No Specific Criteria';

  return (
    <BRModal
      {...modalProps}
      title="Run Report Again"
      okText="Run"
      formProps={{ onFinish }}
      okButtonProps={okButtonProps}
      onOk={onFinish}
    >
      {!report.uuid &&
        <Alert message="No Report Selected" type="warning"/>}
      <Form.Item label="Report type">
        <span className="ant-form-text">
          {formattedType}
        </span>
      </Form.Item>
      <Form.Item label="With criteria">
        <span className="ant-form-text">
          {formattedCriteria}
        </span>
      </Form.Item>

    </BRModal>
  );
};
