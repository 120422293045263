import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tag, Table, List, Empty, Select, Menu, Dropdown } from 'antd';
import { IPersonRequirement, IPerson, RESOURCE_STATUS } from '../../types';
import { ListItem } from './ListItem';
import { BRListData } from '../../hooks/useBRListData';
import { PlusOutlined } from '@ant-design/icons';
import { BRStore } from '../../hooks/useBRStore';

export const DetailsTable: React.FunctionComponent<{
  person: BRStore<IPerson>,
  requirements: BRListData<IPersonRequirement>,
  mandatory?: boolean,
  applies?: boolean,
  admin: boolean
  initialStatusFilter?: string[],
}> = ({ person, requirements, mandatory, applies = true, admin, initialStatusFilter }) => {
  const { fetch, records, loading } = requirements;
  const allStatuses = [RESOURCE_STATUS.actionRequired, RESOURCE_STATUS.complete, RESOURCE_STATUS.expired, RESOURCE_STATUS.expiring, RESOURCE_STATUS.incomplete]
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter || allStatuses);

  useEffect(() => { fetch(); }, [fetch]);

  const pageFetch = useCallback(
    () => {
      person.fetch();
      requirements.fetch();
    },
    [person, requirements],
  );

  const listItem = (pr: IPersonRequirement) =>  <ListItem pr={pr} person={person} requirements={requirements} admin={admin} />;

  const filteredRecords = useMemo(
    () => {
      let out = records ? records.filter(pr => pr.applies === applies && pr.status && statusFilter.includes(pr.status)) : [];

      if (mandatory !== undefined) {
        out = out.filter(pr => pr.mandatory === mandatory);
      }

      return out;
    },
    [records, mandatory, applies, statusFilter],
  );

  const statusTags = React.useMemo(
    () => {
      const tags = [];
      const menuItems = [];
      for (const c of allStatuses) {
        if (statusFilter.includes(c)) {
          const handleClose = () => {
            setStatusFilter(statusFilter.filter(s => s !== c));
          };
          tags.push((
            <Tag
              key={c}
              closable={true}
              onClose={handleClose}
            >
              {c}
            </Tag>
          ));
        } else {
          menuItems.push((
            <Menu.Item
              key={c}
            >
              {c}
            </Menu.Item>
          ));
        }

      }
      const handleAdd = ({ key }: any) => {
        setStatusFilter(allStatuses.filter(s => statusFilter.includes(s) || s === key));
      };
      const menu = (
        <Menu
          onClick={handleAdd}
        >
          {menuItems}
        </Menu>

      );
      if (menuItems.length > 0) {
        tags.push((
          <Dropdown
            key="drop"
            trigger={['click']}
            overlay={menu}
          >
            <Tag><PlusOutlined /> Add Status</Tag>
          </Dropdown>
        ));
      }
      return tags;
    },
    [statusFilter]);

  return (
    <>
      <p style={{ lineHeight: '2em' }}><strong>Show: </strong>
        {statusTags}
      </p>
      <List
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Requirements"
            />
          ),
        }}
        dataSource={filteredRecords}
        renderItem={listItem}
        loading={loading}
      />
    </>
  );
};
