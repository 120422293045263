import React, { useState } from 'react';
import { Checkbox, Input, Form, Switch } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments } from '../BRForm';
import { IRequirement } from '../../types';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

interface Props extends BRModalProps {
  requirement: IRequirement;
}

const { TextArea } = Input;

export const UpdateRequirementModal: React.FunctionComponent<Props> =
({ requirement, ...modalProps }) => {
  // form initialized with props, but operates on state
  const [name, setName] = useState(requirement.name || '');
  const [active, setActive] = useState(requirement.active);
  const [activeEdited, setActiveEdited] = useState(false);
  const [mandatory, setMandatory] = useState(requirement.mandatory);
  const [mandatoryEdited, setMandatoryEdited] = useState(false);
  const [comments, setComments] = useState('');
  const [nameEdited, setNameEdited] = useState(false);
  const [sendNotifications, setSendNotifications] = useState(requirement.sendNotifications);
  const [sendNotificationsEdited, setSendNotificationsEdited] = useState(false);
  const [sendInitialNotifications, setSendInitialNotifications] = useState(requirement.sendInitialNotifications);
  const [sendInitialNotificationsEdited, setSendInitialNotificationsEdited] = useState(false);

  const onFinish = async () => {
    const { uuid } = requirement;
    const formData = {
      uuid,
      comments,
      name: nameEdited ? name : undefined,
      active: activeEdited ? active : undefined,
      mandatory: mandatoryEdited ? mandatory : undefined,
      sendNotifications: sendNotificationsEdited ? sendNotifications : undefined,
      sendInitialNotifications: sendInitialNotificationsEdited ? sendInitialNotifications : undefined,
    };

    await sendCommand({
      formData,
      command: COMMANDS.UPDATE_REQUIREMENT,
    });
  };

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'sendNotifications':
        setSendNotifications(checked);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (e: any) => {
    const { name, checked } = e.target;
    switch (name) {
      case 'nameEdited':
        setNameEdited(checked);
        break;
      case 'activeEdited':
        setActiveEdited(checked);
        break;
      case 'mandatoryEdited':
        setMandatoryEdited(checked);
        break;
      case 'sendNotificationsEdited':
        setSendNotificationsEdited(checked);
        break;
      case 'sendInitialNotificationsEdited':
        setSendInitialNotificationsEdited(checked);
        break;
      default:
        break;
    }
  };

  const title = `Update ${requirement.name}`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Update Requirement"
      formProps={{ onFinish }}
    >
      <Form.Item
        label={(
          <Checkbox
            onChange={handleCheckboxChange}
            name="nameEdited"
            checked={nameEdited}
            id="nameEdited"
          >
            Name
          </Checkbox>
        )}
      >
        <Input
          onChange={handleChange}
          value={name}
          type="text"
          name="name"
          className="form-control"
          disabled={!nameEdited}
          required={nameEdited}
        />
      </Form.Item>

      <Form.Item
        label={(
          <Checkbox
            onChange={handleCheckboxChange}
            name="activeEdited"
            checked={activeEdited}
            id="activeEdited"
          >
            Active
          </Checkbox>
        )}
      >
        <Switch
          onChange={setActive}
          checked={active}
          disabled={!activeEdited}
          checkedChildren={<CheckCircleFilled />}
          unCheckedChildren={<CloseCircleFilled />}
        />
      </Form.Item>

      <Form.Item
        label={(
          <Checkbox
            onChange={handleCheckboxChange}
            name="mandatoryEdited"
            checked={mandatoryEdited}
            id="mandatoryEdited"
          >
            Mandatory
          </Checkbox>
        )}
      >
        <Switch
          onChange={setMandatory}
          checked={mandatory}
          disabled={!mandatoryEdited}
          checkedChildren={<CheckCircleFilled />}
          unCheckedChildren={<CloseCircleFilled />}
        />
      </Form.Item>

      <Form.Item
        label={(
          <Checkbox
            onChange={handleCheckboxChange}
            name="sendNotificationsEdited"
            checked={sendNotificationsEdited}
            id="sendNotificationsEdited"
          >
            Send Reminder Notifications
          </Checkbox>
        )}
      >
        <Switch
          onChange={setSendNotifications}
          checked={sendNotifications}
          disabled={!sendNotificationsEdited}
          checkedChildren={<CheckCircleFilled />}
          unCheckedChildren={<CloseCircleFilled />}
        />
      </Form.Item>

      <Form.Item
        label={(
          <Checkbox
            onChange={handleCheckboxChange}
            name="sendInitialNotificationsEdited"
            checked={sendInitialNotificationsEdited}
            id="sendInitialNotificationsEdited"
          >
            Send Initial Notifications
          </Checkbox>
        )}
      >
        <Switch
          onChange={setSendInitialNotifications}
          checked={sendInitialNotifications}
          disabled={!sendInitialNotificationsEdited}
          checkedChildren={<CheckCircleFilled />}
          unCheckedChildren={<CloseCircleFilled />}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments}/>
    </BRModal>
  );
};
