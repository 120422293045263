import { IProvider, IPersonRequirement, IRole, ICommand, ITranscript } from '.';
import { IRequirement } from './IRequirement';
import { ICourse } from './ICourse';
import { IBasicPerson } from './IBasicPerson';
import { IPersonApproval } from './IPersonApproval';
import { IGroup } from './IGroup';

export enum RESOURCE_STATUS {
  expired = 'Expired',
  expiring = 'Expiring',
  actionRequired = 'Action Required',
  complete = 'Complete',
  incomplete = 'Incomplete',
  notApplicable = 'Not Applicable',
  draft = 'Draft',
  inactive = 'Inactive',
}

export interface IPerson {
  uuid: string;
  firstName?: string;
  lastName?: string;
  clickId?: string;
  department?: string;
  division?: string;
  requirements?: IPersonRequirement[];
  courses?: IPersonCourse[];
  roles?: string[];
  userRoles?: IRole[];
  events?: ICommand[];
  exemptions?: IExemption[];
  aalasId?: string;
  citiId?: string;
  startDate?: number;
  employeeId?: string;
  kAwardAttainedDate?: number;
  active?: boolean;
  emails?: string[];
  era?: boolean;
  secondaryEmail?: string;
  tertiaryEmail?: string;
  groups?: IGroup[];
  shadows?: IBasicPerson[];
  status?: RESOURCE_STATUS;
  uri?: string;
  username?: string;
  fellowshipCredentials?: string;
  fellowshipStatus?: string;
  fellowshipProgram?: string;
  fellowshipStartDate?: number;
  fellowshipEndDate?: number;
  hasIrb?: boolean;
  hasIacuc?: boolean;
  keys?: { [ index: string ]: string[] };
  profiles?: { [ index: string ]: string[] };
  approvals?: IPersonApproval[];
  hasActiveTenure?: boolean;
  descriptionItems?: { [ index: string]: string };
  createdBy?: string;
  primaryEmail?: string;
  manuallyAdded?: boolean;
}

export interface IAward {
  id?: string;
  eSpaId?: string;
  startDate?: Date;
  endDate?: Date;
  description?: string;
  state?: string;
  link?: string;
  type?: AwardType;
  attributes?: Set<string>;
}

export enum AwardType {
  KAward = 'K Award',
  TAward = 'T Award',
  FAward = 'F Award',
  Other = 'Other',
}

export interface IPersonCourse {
  active?: boolean;
  completedDate?: number;
  scheduledDate?: number;
  uuid?: string;
  course?: string; // uuid
  link?: string;
  name?: string;
  provider?: IProvider;
  teaching?: boolean;
  url?: string;
  transcript?: ITranscript;
}

export interface IExemption {
  requirement: IRequirement;
  course: ICourse;
  exemptionDate: number;
}
