import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect, BRLargeDataSelect } from '../BRForm';
import { IPerson } from '../../types';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { IGroup, GROUP_TYPE } from '../../types/IGroup';

interface Props extends BRModalProps {
  person?: IPerson;
  group?: IGroup;
}
const groupDataParams = { url: '/v0/groups', queryParams: { all: true } };
const peopleDataParams = { url: '/v0/people', queryParams: { basic: true } };

export const AddPersonToGroupModal: React.FunctionComponent<Props> =
({ person: importedPerson, group: importedGroup, ...modalProps }) => {

  const groups = useBRListData<IGroup>({
    fetchDataParams: groupDataParams,
  });

  const people = useBRListData<IPerson>({
    fetchDataParams: peopleDataParams,
  });

  const [group, setGroup] = useState('');
  const [person, setPerson] = useState('');
  const [comments, setComments] = useState('');

  useEffect(
    () => {
      if (!importedGroup) {
        groups.fetch();
      } else {
        setGroup(importedGroup.uuid);
      }
    },
    []);

  useEffect(
    () => {
      if (!importedPerson) {
        people.fetch();
      } else {
        setPerson(importedPerson.uuid);
      }
    },
    []);

  const onFinish = async () => {
    const formData: {
      group: string,
      person: string,
      comments: string,
    } = {
      group,
      person,
      comments,
    };

    await sendCommand({
      formData,
      command: COMMANDS.ADD_PERSON_TO_GROUP,
    });
  };

  const groupOptions = (importedGroup ? [importedGroup] : groups.records).filter(g => (g.createdBy === 'Application' || g.createdBy === 'Manual') &&
      g.type !== GROUP_TYPE.protocol && !importedPerson?.groups?.some(pg => pg.uuid === g.uuid)).map(
        g => ({
          key: g.uuid,
          value: g.uuid || '',
          label: g.name,
        }),
      );

  const personOptions = (importedPerson ? [importedPerson] : people.records).filter(p => !importedGroup?.members?.some(pg => pg.uuid === p.uuid)).map(
    p => ({
      key: p.uuid,
      value: p.uuid || '',
      label: `${p.firstName} ${p.lastName} [${p.keys?.username?.[0] ?? p.keys?.email?.[0]}]`,
    }),
  );

  const title = `Add ${importedPerson ? `${importedPerson.firstName} ${importedPerson.lastName}` : 'Person' } to ${importedGroup ? importedGroup.name : 'Group' }`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Add to Group"
      formProps={{ onFinish }}
    >
      <Form.Item label="Add Person">
        <BRLargeDataSelect
          options={personOptions}
          setValue={setPerson}
          loading={people.loading}
          disabled={!!importedPerson}
          value={importedPerson && personOptions[0]}
        />
      </Form.Item>

      <Form.Item label="To Group">
        <BRSelect
          options={groupOptions}
          setValue={setGroup}
          disabled={!!importedGroup}
          value={importedGroup && groupOptions[0]}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
