import { Button, DatePicker, Form, Upload } from 'antd';
import { FormProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

import React, { ChangeEvent, useEffect, useState } from 'react';
import { COMMANDS } from '../../constants';
import { BRModal, BRModalProps } from '../BRModal';
import { sendCommand } from '../../services/commands.services';
import { IEquivalency } from '../../types/IEquivalency';
import { BRSelect, BRSelectValue } from '../BRForm';
import { useBRListData } from '../../hooks/useBRListData';
import { IAttachment, ICourse } from '../../types';

interface Props extends BRModalProps {
  equivalency: IEquivalency;
}

const fetchDataParams = { url: '/v0/courses', queryParams: { all: true } };

export const EditEquivalencyModal: React.FunctionComponent<Props> =
({ equivalency, ...modalProps }) => {
  const courses = useBRListData<ICourse>({ fetchDataParams });

  useEffect(
    () => {
      courses.fetch();
    },
    [courses.fetch]);

  const [course, setCourse] = useState(equivalency.course.uuid);
  const [completionDate, setCompletionDate] = useState<moment.Moment | undefined>(moment(equivalency.completionDate));
  const [learnerComment, setLearnerComment] = useState(equivalency.learnerComment || '');

  const isDateDisabled = (date: moment.Moment) => date.isAfter(moment());
  const setNewCompletionDate = (date: moment.Moment | null, dateString: string) => date == null ? setCompletionDate(undefined) : setCompletionDate(date);

  const courseOptions: BRSelectValue[] = courses.records ? courses.records.map(
    c => ({
      key: `edit-${c.uuid}`,
      value: c.uuid || '',
      label: c.name || '',
    })) : [];

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setLearnerComment(value);
  };

  const onFinish = async () => {
    const formData: any = {};
    formData.uuid = equivalency.uuid;
    formData.course = course;
    formData.completionDate = completionDate?.valueOf();
    formData.learnerComment = learnerComment;

    await sendCommand({
      formData,
      command: COMMANDS.UPDATE_EQUIVALENCY,
    });

    return false;
  };

  const onFileAdd = async (file: UploadFile) => {
    const formData = new FormData();
    formData.append('uuid', equivalency.uuid);
    formData.append('files[]', file as any, file.name);

    await sendCommand({
      formData,
      command: COMMANDS.ADD_ATTACHMENTS_TO_EQUIVALENCY,
    });
  };

  const onFileRemove = async (file: UploadFile) => {
    try {
      await sendCommand({
        formData: {
          uuid: equivalency.uuid,
          deletedAttachmentUUIDs: [file.uid],
        },
        command: COMMANDS.REMOVE_ATTACHMENTS_FROM_EQUIVALENCY,
      });

      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <BRModal
      {...modalProps}
      title={'Respond to Equivalency Request'}
      formProps={{ onFinish }}
    >
      <Form.Item label="Course" required={true}>
        <BRSelect
          value={courseOptions.find(option => option.value === course)}
          options={courseOptions}
          setValue={setCourse}
        />
      </Form.Item>
      <Form.Item label="Completion Date" required={true}>
        <DatePicker
          value={completionDate}
          disabledDate={isDateDisabled}
          onChange={setNewCompletionDate}
          name="completionDate"
        />
      </Form.Item>
      <Form.Item label="Certificate" required={true}>
        <Upload
          multiple={true}
          disabled={false}
          fileList={equivalency.attachments?.map((attachment: IAttachment): UploadFile => {
            return {
              uid: attachment.uuid,
              name: attachment.displayname,
              size: attachment.size,
              type: attachment.mimetype,
              url: attachment.url,
            };
          }) || []}
          beforeUpload={onFileAdd}
          onRemove={onFileRemove}
        >
          <Button><UploadOutlined />Upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item label="Learner Comment">
        <TextArea
          value={learnerComment}
          rows={4}
          onChange={handleCommentChange}
        />
      </Form.Item>
    </BRModal>
  );
};
