import * as React from 'react';
import { TabMenu } from '../../components/TabMenu';
import { BRLayout } from '../../components/BRLayout';
import { ICommand } from '../../types';
import { DATE_TIME_OPTIONS, TABLE_IDS } from '../../constants';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import { Tag, List } from 'antd';
import { useBRTableStore } from '../../hooks/useBRTableStore';
import { useBRStore } from '../../hooks/useBRStore';
import { IMail, IMailAddress } from '../../types/IMail';
import { Command } from '../../components/CommandButtons';
import BRTable from '../../components/BRTable';
import { ROUTES } from '../../routes';
import { CancelNotificationModal } from '../../components/modals/CancelNotificationModal';
import { ResendMailModal } from '../../components/modals/ResendMailModal';
import { MailOutlined, StopOutlined } from '@ant-design/icons';
import { checkCommands } from '../../helpers/checkCommands';
import { API_ROUTES } from '../../constants/apiRoutes';
import { PERMISSION_TYPES } from '../../helpers/hasPermission';

interface Props {
  uuid: string;
}

export function OutboxDetailPage(props: Props) {

  const mailUuid = props.uuid;
  const urlRoot = `/v0/outbox/${mailUuid}`;

  const mailStore = useBRStore<IMail>({
    fetchDataParams: React.useMemo(() => ({ url: urlRoot }), [props.uuid]),
  });
  const mail = mailStore.data;

  React.useEffect(
    () => { mailStore.fetch(); },
    [],
  );

  const events = useBRTableStore<ICommand>({
    fetchDataParams: React.useMemo(() => ({ url: API_ROUTES.commands, queryParams: { resource: mailUuid } }), [props.uuid]),
  });

  const previewHTML = () => ({ __html: mail.body || '' });

  const recipientItem = (item: IMailAddress) => (
    <List.Item>
      { typeof item === 'string' ? (
        <List.Item.Meta
          title={item}
        />
      ) : (
        <List.Item.Meta
          title={item.name}
          description={item.address}
        />
      )}
    </List.Item>
  );

  const attachmentItem = (item: string) => (
    <List.Item>
      <List.Item.Meta
        title={item}
      />
    </List.Item>
  );

  const commandRowKey = (event: ICommand) => `recipient-row-${event.uuid}`;

  const menuTabs = [
    {
      name: 'Preview',
      key: 'preview',
      component: (
        <div
          dangerouslySetInnerHTML={previewHTML()}
        />
      ),
    },
    {
      name: 'Recipients',
      key: 'to',
      component: (
        <>
          <List
            header={<h3>To:</h3>}
            itemLayout="horizontal"
            dataSource={mail.to}
            renderItem={recipientItem}
          />
          {mail.cc?.length > 0 &&
            <List
              header={<h3>CC:</h3>}
              itemLayout="horizontal"
              dataSource={mail.cc}
              renderItem={recipientItem}
            />
          }
        </>
      ),
    },
    {
      name: 'Attachments',
      key: 'attachments',
      component: (
        <List
          itemLayout="horizontal"
          dataSource={mail.attachments}
          renderItem={attachmentItem}
        />
      ),
    },
    {
      name: 'Audit Log',
      key: 'audit',
      permissions: PERMISSION_TYPES.audit,
      component: (
        <BRTable
          tableData={events}
          tableId={TABLE_IDS.OUTBOX_AUDIT_LOG}
          linkPath={ROUTES.command}
          rowKey={commandRowKey}
        />
      ),
    },
  ];

  const allOutboxCommands: Command[] = [];

  allOutboxCommands.push({
    title: 'Resend Mail',
    icon: <MailOutlined />,
    type: 'resendMail',
    // TODO this should use an enum
    enabled: mail.state !== 'Pending' && mail.state !== 'Cancelled' && mail.state !== 'Scheduled',
    modal: (
      <ResendMailModal
        mail={mail}
        onSuccess={mailStore.fetch}
      />
    ),
  });

  allOutboxCommands.push({
    title: 'Cancel Mail',
    icon: <StopOutlined />,
    type: 'cancelEmailNotification',
    // TODO this should use an enum
    enabled: mail.state === 'Pending' || mail.state === 'Scheduled',
    modal: (
      <CancelNotificationModal
        mail={mail}
        onSuccess={mailStore.fetch}
      />
    ),
  });

  const commands = checkCommands(allOutboxCommands, mailStore);

  const dateRenderer = (dateValue: number) => {
    if (dateValue) {
      const date = new Date(dateValue);
      return date.toLocaleString('en-US', DATE_TIME_OPTIONS);
    }
    return 'No Date Recorded';
  };

  const descriptionItems = {
    Created: dateRenderer(mail.createdDate),
    Scheduled: mail.scheduledDate ? dateRenderer(mail.scheduledDate) : 'Immediate',
    From: mail.from ? (typeof mail.from === 'string') ? `${mail.from}` : `${mail.from.name} <${mail.from.address}>` : 'System Default',
  };

  return (
    <BRLayout
      loading={mailStore.loading}
      tags={(
        <Tag>
          {mail.state}
        </Tag>
      )}
      title={mail.subject}
      commands={commands}
      descriptionItems={descriptionItems}
    >
      <LoadingAnimation loading={mailStore.loading} />
      <TabMenu tabs={menuTabs} />
    </BRLayout>
  );
}
