export const API_ROUTES = {
  commands: '/v0/commands',
  courses: '/v0/courses',
  courseCategories: '/v0/courses/categories',
  courseProviders: '/v0/courses/providers',
  requirements: '/v0/requirements',
  requirementCategories: '/v0/requirements/categories',
  transcripts: '/v0/transcripts',
  tenures: '/v0/tenures/',
  tenureRequests: '/v0/tenurerequests/',
  settings: '/v0/settings',
  properties: '/v0/properties',
};
