import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { BRLayout } from '../components/BRLayout';

export function UnauthorizedPage() {
  return (
    <BRLayout>
      <Content>
        <h1 style={{ marginTop: '200px', textAlign: 'center' }}>Sorry, you're not authorized to view this page.</h1>
      </Content>
    </BRLayout>
  );
}
