import * as React from 'react';
import { Table } from 'antd';

interface RCTProps {
  xml: string;
}

export function RequirementConditionsTable(props: RCTProps) {

  const columns = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
    },
    {
      title: 'Input',
      dataIndex: 'input',
      key: 'input',
      width: 20,
    },
    {
      title: 'Condition',
      dataIndex: 'result',
      key: 'result',
      size: 30,
      render: (text: string, record: any) => {
        let out: string = '';
        if (text === 'any') {
          out = 'if any child condition matches';
        } else if (text === 'all') {
          out = 'if all child conditions match';
        }
        if (record.title === 'Loop' && record.match === 'any') {
          out += ' any item from input';
        } else if (record.title === 'Loop') {
          out += ' all items from input';
        }
        return out || `if ${record.condition}` || undefined;
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  let key = 0;

  function nodeTitle(node: Element) {
    switch (node.tagName) {
      case 'needAll':
        return <em>If <strong>all</strong>of these conditions:</em>;
      case 'needOne':
        return <em>If <strong>any</strong> of these conditions:</em>;
      case 'group':
        const type = node.getAttribute('type');
        if (type === 'protocol') {
          return 'Protocol';
        } if (type === 'award') {
          return 'Award';
        }
        return 'Group';
      case 'person':
        return 'Person Role';
    }
    return node.tagName;
  }

  function parseConditionNode(node: HTMLElement) {
    const row: any = {};
    row.title = node.getAttribute('title');
    row.input = node.getAttribute('input');
    row.condition = node.getAttribute('condition');
    row.result = node.getAttribute('result');
    row.match = node.getAttribute('match');
    row.value = node.getAttribute('value');

    return {
      ...row,
      title: nodeTitle(node),
      key: `${key += 1}`,
      children: node.children.length > 0 ? Array.prototype.map.call(node.children, parseConditionNode) : undefined,
    };
  }

  const domParser = new DOMParser();
  const xml = domParser.parseFromString(props.xml, 'text/xml');
  const data = parseConditionNode(xml.documentElement);

  return (
    <Table
      size="small"
      columns={columns}
      className={'clickable-table'}
      dataSource={[data]}
      expandable={{
        expandRowByClick: true,
        defaultExpandAllRows: true,
        indentSize: 25,
      }}
    />
  );
}
