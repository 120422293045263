import React, { useContext } from 'react';
import { Checkbox } from 'antd';
import { FieldProps, Field } from '../Field';
import { StepContext } from '../Step';
// tslint:disable-next-line:import-name
import { DocumentEditorContext } from '../../../types/DocumentEditor';

export const CheckboxField = (props: FieldProps) => {
  const { documentEditor } = useContext(DocumentEditorContext);
  const { value, setValue } = useContext(StepContext);

  if (!documentEditor.document) {
    return null;
  }

  return (
    <Field {...props}>
      <Checkbox
        checked={value && value !== 'false'}
        disabled={!documentEditor.document.editable || !!props.readOnly}
        onChange={setValue}
      />
    </Field>
  );
};
