import React, { useState, useEffect, useMemo } from 'react';
import { Alert, Form } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { IPerson, IPersonCourse } from '../../types';
import { DATE_OPTIONS, COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';
import { useBRListData } from '../../hooks/useBRListData';

interface Props extends BRModalProps {
  person: IPerson;
  course?: string;
}

export const RemoveCourseFromPersonModal: React.FunctionComponent<Props> =
({ person, ...modalProps }) => {

  const [courses, setCourses] = useState<string[]>(modalProps.course ? [modalProps.course] : []);
  const [courseError, setCourseError] = useState(false);
  const [comments, setComments] = useState('');

  const fetchDataParams = useMemo(() => ({ url: `/v0/people/${person.uuid}/courses`, queryParams: { manual: true, all: true } }), [person.uuid]);
  const coursesData = useBRListData<IPersonCourse>({ fetchDataParams });

  useEffect(
    () => {
      if (modalProps.course) {
        setCourses([modalProps.course]);
      }
    },
    [modalProps.course]);

  useEffect(
    () => {
      coursesData.fetch();
    },
    [coursesData.fetch]);

  const onFinish = async () => {
    setCourseError(false);
    if (!courses) {
      setCourseError(true);
      return;
    }

    const formData = {
      comments,
      courses,
      person: person.uuid,
    };

    await sendCommand({
      formData,
      command: COMMANDS.REMOVE_COURSE_FROM_PERSON,
    });
  };

  const formattedCourses = coursesData.records && coursesData.records.map((t: IPersonCourse) => {
    const date = t.completedDate || t.scheduledDate;
    const dateString = date && new Date(date).toLocaleString('en-US', DATE_OPTIONS);

    return ({
      key: t.uuid || '',
      label: `${dateString} - ${t.name}`,
      value: t.uuid || '',
    });
  });

  const title = `Remove Course from ${person.firstName} ${person.lastName}`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Remove Course"
      formProps={{ onFinish }}
    >
      { !modalProps.course && (
        <>
          <Form.Item label="Course:">
            <BRSelect
              setValue={setCourses}
              options={formattedCourses}
              mode="multiple"
            />
            {courseError && <Alert message="Please select at least one course" type="warning"  />}
          </Form.Item>

          <Form.Item label="From Person:">
            <span className="ant-form-text">
              {`${person.firstName} ${person.lastName}`}
            </span>
          </Form.Item>
        </>
      )}

      <BRFormComments comments={comments} setComments={setComments}/>

    </BRModal>
  );
};
