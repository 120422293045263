import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Tabs, Collapse } from 'antd';
import { SessionContext } from './App';
import { ROUTES } from '../routes';
import { hasPermission, PERMISSION_TYPES } from '../helpers/hasPermission';

export interface TabMenuTab {
  name: string;
  key?: string;
  component?: PropTypes.ReactNodeLike;
  permissions?: PERMISSION_TYPES[] | PERMISSION_TYPES;
}
interface Props {
  tabPosition?: 'left' | 'top';
  route?: ROUTES;
  tabs: TabMenuTab[];
  selected?: string;
}

export const TabMenu = (props: Props) => {

  const { session } = React.useContext(SessionContext);

  const [width, setWidth] = React.useState(window.innerWidth);

  const allowedTabs = props.tabs.filter((tab) => tab.permissions ? hasPermission(tab.permissions, session) : true);

  function handleResize() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const onChange = (key: string | string[]) => {
    const query = {
      tab: typeof key === 'object' ? key.filter(k => k !== session.router.current.params.tab)[0] : key,
    };
    session.router.go(
      { name: props.route || session.router.current.name },
      {
        ...session.router.current.params,
        tab: typeof key === 'object' ? key.filter(k => k !== session.router.current.params.tab)[0] : key,
      },
    );
  };

  if (width > 720) {
    const tabPosition = props.tabPosition || 'left';
    const tabPanes = allowedTabs.map((t, i) => (
      <Tabs.TabPane tab={t.name} key={t.key || t.name}>
        {(t.component) ? t.component : <span>No related data found</span>}
      </Tabs.TabPane>
    ));

    return (
      <Tabs
        tabBarGutter={tabPosition === 'top' ? 20 : 0}
        activeKey={props.selected || session.router.current.params.tab || allowedTabs[0].key || allowedTabs[0].name}
        defaultActiveKey={props.selected || allowedTabs[0].key || allowedTabs[0].name}
        tabPosition={tabPosition}
        destroyInactiveTabPane={true}
        onChange={onChange}
      >
        {tabPanes}
      </Tabs>
    );
  }

  const panels = allowedTabs.map((t, i) => (
    <Collapse.Panel header={t.name} key={t.key || t.name}>
      {(t.component) ? t.component : <span>No related data found</span>}
    </Collapse.Panel>
  ));

  return (
    <Collapse
      onChange={onChange}
      expandIconPosition="right"
      destroyInactivePanel={true}
      activeKey={props.selected || session.router.current.params.tab}
    >
      {panels}
    </Collapse>
  );
};
