import React from "react";
import { Select } from "antd";
import { CascaderOptionType } from "antd/lib/cascader";
import { SelectProps } from "antd/lib/select";

interface Props extends SelectProps<any> {
  selectedOption: CascaderOptionType | undefined;
  onChange: (value: string) => void;
}

export function ValueSelect({ selectedOption, onChange, ...props }: Props) {
  const [customText, setCustomText] = React.useState<string | undefined>();

  React.useEffect(() => {
    const option = selectedOption?.options?.find((option: string) => option === props.value);
    if (!option) {
      // if option is not found, set customText to props.value. If defined, it will be added to option list
      setCustomText(props.value);
    }
  }, [props.value])

  const valueOptions = selectedOption?.options?.map((option: string) => ({
    value: option,
  })) || [];
  if (customText && !valueOptions.find((option: any) => option.value === customText)) {
    valueOptions.unshift({
      value: customText,
    });
  }

  const onValueChange = (value: string) => {
    onChange(value);
    setCustomText('');
  }

  const onValueSearch = (value: any) => {
    setCustomText(value);
  }

  return (
    <Select
      // value options will be preselected options (if any) with an added option for "custom" input that doesn't match any options
      {...props}
      showSearch={true}
      options={valueOptions}
      onChange={onValueChange}
      onSearch={onValueSearch}
    />
  );
}