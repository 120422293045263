import * as React from 'react';
import { TabMenu, TabMenuTab } from '../components/TabMenu';
import { BRLayout } from '../components/BRLayout';
import { IRequirementCategory, IRequirement, IPerson, ICommand } from '../types';
import { COMMANDS, TABLE_IDS } from '../constants';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { Tag, Empty, message, Button } from 'antd';
import { useBRTableStore } from '../hooks/useBRTableStore';
import BRTable from '../components/BRTable';
import { useBRStore } from '../hooks/useBRStore';
import { UpdateRequirementModal } from '../components/modals/UpdateRequirementModal';
import { ROUTES } from '../routes';
import { RequirementCoursesTable } from '../components/RequirementCoursesTable';
import { RequirementConditionsTable } from '../components/RequirementConditionsTable';
import { EditOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import { checkCommands } from '../helpers/checkCommands';
import { ITemplate } from '../types/ITemplate';
import { NewTemplateModal } from '../components/modals/NewTemplateModal';
import { SessionContext } from '../components/App';
import { FEATURES } from '../constants/features';
import { Command } from '../components/CommandButtons';
import { RuleEditor } from '../components/RuleEditor';
import { API_ROUTES } from '../constants/apiRoutes';
import { RichTextEditor } from '../components/RichTextEditor';
import { sendCommand } from '../services/commands.services';
import { IRule } from '../types/IRule';
import { AddRequirementToCategoryModal } from '../components/modals/AddRequirementToCategoryModal';
import { RemoveRequirementFromCategoryModal } from '../components/modals/RemoveRequirementFromCategoryModal';
import { PERMISSION_TYPES } from '../helpers/hasPermission';

const COURSES_TAB = 'courses';
const TEMPLATES_TAB = 'templates';

interface Props {
  uuid: string;
}

export function RequirementDetailPage(props: Props) {
  const { session } = React.useContext(SessionContext);

  const requirementUuid = props.uuid;
  const urlRoot = `/v0/requirements/${requirementUuid}`;
  const fetchDataParams = React.useMemo(() => ({ url: urlRoot }), [props.uuid]);

  const requirementStore = useBRStore<IRequirement>({ fetchDataParams });
  const requirement = requirementStore.data;

  const getDescription = () => requirement.description || '';

  const [tab, setTab] = React.useState(COURSES_TAB);
  const [description, setDescription] = React.useState(getDescription());

  React.useEffect(
    () => {
      requirementStore.fetch();
    },
    [requirementStore.fetch]);

  const courseRuleFetchParams = React.useMemo(() => ({ url: `/v0/rules/${requirement?.coursesRule?.uuid}` }), [requirement.coursesRule?.uuid]);
  const courseRuleStore = useBRStore<IRule>({ fetchDataParams: courseRuleFetchParams });

  React.useEffect(
    () => {
      if (requirement.coursesRule?.uuid) {
        courseRuleStore.fetch();
      }
    },
    [courseRuleStore.fetch],
  );

  const conditionRuleFetchParams = React.useMemo(() => ({ url: `/v0/rules/${requirement?.conditionsRule?.uuid}` }), [requirement.conditionsRule?.uuid]);
  const conditionRuleStore = useBRStore<IRule>({ fetchDataParams: conditionRuleFetchParams });

  React.useEffect(
    () => {
      if (requirement.conditionsRule?.uuid) {
        conditionRuleStore.fetch();
      }
    },
    [conditionRuleStore.fetch],
  );

  React.useEffect(
    () => {
      setTab(session.router.current.params.tab);
    },
    [props]);

  const people = useBRTableStore<IPerson>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/people` }), [props.uuid]),
  });

  const events = useBRTableStore<ICommand>({
    fetchDataParams: React.useMemo(() => ({ url: API_ROUTES.commands, queryParams: { resource: requirementUuid } }), [props.uuid]),
  });

  const templates = useBRTableStore<ITemplate>({
    fetchDataParams: { url: '/v0/templates', queryParams: { resource: requirementUuid } },
  });

  const [courseMap, setCourseMap] = React.useState({});

  React.useEffect(
    () => {
      if (requirementStore.loaded) {
        const newMap = {};
        for (const c of requirementStore.data.courses ?? []) {
          newMap[c.uuid] = c;
        }
        setCourseMap(newMap);
        setDescription(getDescription());
      }
    },
    [requirementStore.loaded],
  );

  const EmptyRule = (props: { loaded: boolean, isCourseEditor?: boolean }) => {

    async function createRule() {
      await sendCommand({
        formData: {
          xml: props.isCourseEditor ? '<CourseGroup match="any" />' : '<Block result="any" />',
          type: props.isCourseEditor ? 'requirementCourses' : 'requirementConditions',
          resource: requirement.uuid,
        },
        command: COMMANDS.CREATE_RULE,
      });
      message.success(`Rule successfully generated.`);
      await requirementStore?.fetch();
    }

    return (
      <Empty
        description={'No current rule for this requirement'}
      >
        {props.loaded && <Button type="primary" onClick={createRule}>Create Rule</Button>}
      </Empty>
    );
  };

  const updateCurrent = () => {
    requirementStore.fetch();
    switch (session.router.current.params.tab) {
      case 'events':
        events.fetch();
        break;
      case 'people':
        people.fetch();
        break;
      case 'courses':
      case 'conditions':
      default:
        // courses and conditions will update when requirementStore refreshes
        break;
    }
  };

  const menuTabs: TabMenuTab[] = [];

  if (FEATURES.COURSE_EDITOR) {
    menuTabs.push({
      name: 'Courses',
      key: COURSES_TAB,
      component: requirement.coursesRule
        ? <RuleEditor ruleStore={courseRuleStore} />
        : <EmptyRule loaded={requirementStore.loaded} isCourseEditor={true} />,
    });
  } else {
    menuTabs.push({
      name: 'Courses',
      key: COURSES_TAB,
      component: (requirement.coursesRule && requirement.courses) ? (
        <RequirementCoursesTable
          xml={requirement.coursesRule.versions[0]?.xml}
          courseMap={courseMap}
        />
      ) : (<Empty />),
    });
  }

  if (FEATURES.RULE_EDITOR) {
    menuTabs.push({
      name: 'Conditions',
      key: 'conditions',
      component: requirement.conditionsRule
        ? <RuleEditor ruleStore={conditionRuleStore} />
        : <EmptyRule loaded={requirementStore.loaded} />,
    });
  } else {
    menuTabs.push({
      name: 'Conditions',
      key: 'conditions',
      component: requirement.conditionsRule ? (
        <RequirementConditionsTable
          xml={requirement.conditionsRule.versions[0]?.xml}
        />
      ) : (<Empty />),
    });
  }

  async function saveDescription() {
    await sendCommand({
      formData: {
        description,
        uuid: requirement.uuid,
      },
      command: COMMANDS.UPDATE_REQUIREMENT_DESCRIPTION,
    });
    message.success('Description saved.');
    await requirementStore.fetch();
  };

  const descriptionEdited = description !== getDescription() && description !== `<p>${getDescription()}</p>`;

  const descriptionCommands = checkCommands(
    [
      {
        title: 'Save Description',
        icon: <SaveOutlined />,
        type: COMMANDS.UPDATE_REQUIREMENT_DESCRIPTION,
        onClick: saveDescription,
        enabled: descriptionEdited,
      },
    ],
    requirementStore,
  );

  const descriptionReadOnly = descriptionCommands.length === 0;

  if (!descriptionReadOnly) {
    descriptionCommands.unshift({
      title: 'Revert',
      icon: <RollbackOutlined/>,
      onClick: () => {
        setDescription(getDescription());
      },
      enabled: descriptionEdited,
    });
  }

  menuTabs.push({
    name: 'Description',
    key: 'description',
    component: (
      <RichTextEditor
        initialText={description}
        commands={descriptionCommands}
        onChange={setDescription}
        readonly={descriptionReadOnly}
      />
    ),
  });

  menuTabs.push(
    {
      name: 'People',
      key: 'people',
      component: (
        <BRTable
          tableData={people}
          linkPath={ROUTES.person}
          tableId={TABLE_IDS.REQ_PEOPLE_TABLE}
        />
      ),
    },
    {
      name: 'Audit Log',
      key: 'events',
      permissions: PERMISSION_TYPES.audit,
      component: (
        <BRTable
          tableData={events}
          linkPath={ROUTES.command}
          tableId={TABLE_IDS.REQ_AUDIT_LOG}
        />
      ),
    });

  if (FEATURES.TEMPLATE_EDITOR) {
    menuTabs.push({
      name: 'Templates',
      key: TEMPLATES_TAB,
      permissions: PERMISSION_TYPES.templates,
      component: (
        <BRTable
          tableId={TABLE_IDS.TEMPLATES_TABLE}
          linkPath={ROUTES.template}
          tableData={templates}
        />
      ),
    });
  }

  const allRequirementCommands: Command[] = [
    {
      title: 'Update Requirement',
      icon: <EditOutlined />,
      type: 'updateRequirement',
      modal: (
        <UpdateRequirementModal
          requirement={requirement}
          onSuccess={updateCurrent}
        />
      ),
    },
    {
      title: 'Add To Category',
      icon: <PlusCircleOutlined />,
      type: COMMANDS.ADD_REQUIREMENT_TO_CATEGORY,
      modal: (
        <AddRequirementToCategoryModal
          requirement={requirement}
          onSuccess={requirementStore.fetch}
        />
      ),
    },
    {
      title: 'Remove From Category',
      icon: <MinusCircleOutlined />,
      type: COMMANDS.REMOVE_REQUIREMENT_FROM_CATEGORY,
      modal: (
        <RemoveRequirementFromCategoryModal
          requirement={requirement}
          onSuccess={requirementStore.fetch}
        />
      ),
    },
  ];

  if (FEATURES.TEMPLATE_EDITOR) {
    allRequirementCommands.push({
      title: 'New Template',
      icon: <PlusOutlined />,
      type: 'newTemplate',
      modal: (
        <NewTemplateModal
          resource={requirementUuid}
          resourceLabel={requirement.name || requirementUuid}
          onSuccess={templates.fetch}
        />
      ),
      enabled: tab === TEMPLATES_TAB,
    });
  }

  const commands = checkCommands(allRequirementCommands, requirementStore);

  const descriptionItems = {
    Categories: requirement.categories
      ? requirement.categories.map((c: IRequirementCategory) => c.name).join(', ') : 'N/A',
    Mandatory: requirement.mandatory ? 'Yes' : 'No',
    'Initial Notifications': requirement.sendInitialNotifications ? 'Enabled' : 'Disabled',
    'Reminder Notifications': requirement.sendNotifications ? 'Enabled' : 'Disabled',
  };

  return (
    <BRLayout
      loading={requirementStore.loading}
      title={requirement.name || 'Unnamed Requirement'}
      commands={commands}
      tags={(
        <Tag color={requirement.active ? 'green' : 'red'}>
          {requirement.active ? 'Active' : 'Inactive'}
        </Tag>
      )}
      descriptionItems={descriptionItems}
    >
      <LoadingAnimation loading={requirementStore.loading} />
      <TabMenu tabs={menuTabs} />
    </BRLayout>
  );
}
