import React, { useState } from 'react';
import { Form } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments } from '../BRForm';
import { ICourse } from '../../types';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';

interface Props extends BRModalProps {
  course: ICourse;
}

export const DeactivateCourseModal: React.FunctionComponent<Props> =
({ course, ...modalProps }) => {

  const [comments, setComments] = useState('');

  const onFinish = async () => {
    await sendCommand({
      command: COMMANDS.DEACTIVATE_COURSE,
      formData: { comments, uuid: course },
    });
  };

  const title = `Deactivate ${course.name}`;

  return(
      <BRModal
        {...modalProps}
        title={title}
        okText="Deactivate Course"
        formProps={{ onFinish }}
      >
        <Form.Item label="Course Name">
          <span className="ant-form-text">
            {course.name}
          </span>
        </Form.Item>

        <BRFormComments comments={comments} setComments={setComments} />

      </BRModal>
  );
};
