import React, { useState, useEffect } from 'react';
import { Form, message } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRSelect } from '../BRForm';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { ITemplateType } from '../../types/ITemplateType';
import { SessionContext } from '../App';
import { ROUTES } from '../../routes';

interface Props extends BRModalProps {
  resource?: string;
  resourceLabel?: string;
}

export const NewTemplateModal: React.FunctionComponent<Props> =
  ({ resource, resourceLabel, ...modalProps }) => {
    const { session } = React.useContext(SessionContext);

    const templateTypes = useBRListData<ITemplateType>({
      fetchDataParams: { url: '/v0/templatetypes' },
    });

    useEffect(
      () => {
        templateTypes.fetch();
      },
      [],
    );

    const [templateType, setTemplateType] = useState<string|undefined>();

    const onFinish = async () => {
      const formData = {
        resource,
        type: templateType,
      };

      const commandResponse = await sendCommand({
        formData,
        command: COMMANDS.NEW_TEMPLATE,
      });

      if (commandResponse && commandResponse.length > 0) {
        session.router.go({ name: ROUTES.template }, { uuid: commandResponse[0].uuid });
        if (commandResponse[0].exists) {
          message.warn('Notification template already exists. Opening for edit.');
        }
      }
    };

    const templateOptions = templateTypes.records && templateTypes.records.map(
      t => ({
        key: t.type,
        label: t.label,
        value: t.type,
      }),
    );

    return (
      <BRModal
        {...modalProps}
        title="Create New Template"
        okText="Create"
        formProps={{ onFinish }}
      >
        <Form.Item
          label="Template Type:"
          rules={[
            {
              required: true,
              message: 'Please select one template type',
            },
          ]}
        >
          <BRSelect
            setValue={setTemplateType}
            options={templateOptions}
          />
        </Form.Item>
        <Form.Item label="Resource:">
        <span className="ant-form-text">
          {`${resourceLabel}`}
        </span>
        </Form.Item>
      </BRModal>
    );
  };
