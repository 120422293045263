import { Document } from './Document';
import { ITenureRequest, TENURE_REQUEST_STATUS } from '../types';
import { statusTagRenderer } from '../helpers/dataRenderers';
import { FormType } from '../types/Form';
import { DocumentSpec } from '../types/DocumentSpec';
import { buildWizardFromData } from '../components/Wizard/Wizard';
import { ROUTES } from '../routes';
import { CLIENT_CONST, COMMANDS } from '../constants';
import { API_ROUTES } from '../constants/apiRoutes';

export class TenureRequestDocument extends Document {
  constructor(spec: DocumentSpec) {
    super(spec);
  }

  get tenureRequestStatus(): TENURE_REQUEST_STATUS {
    return this.fields['status'];
  }

  get statusTag() {
    return statusTagRenderer(this.tenureRequestStatus);
  }

  asITenureRequest(allFields = false): ITenureRequest {
    const tenureRequest: ITenureRequest = { uuid: this.id, createdDate: this.created?.valueOf() || Date.now(), lastModified: Date.now() };

    const fields = allFields ? this.fields : this.changedFields;

    if (this.id === 'new') {
      tenureRequest.owner = this.fields.owner;
    }

    for (const field in fields) {
      switch (field) {
        case 'owner':
        case 'status':
        case 'personUuid':
        case 'newPerson':
        case 'newPersonFirstName':
        case 'newPersonLastName':
        case 'newPersonDepartment':
        case 'newPersonDivision':
        case 'newPersonPrimaryEmail':
        case 'newPersonSecondaryEmail':
        case 'sponsor':
        case 'location':
        case 'locationOtherAddressOne':
        case 'locationOtherAddressTwo':
        case 'locationOtherCity':
        case 'locationOtherState':
        case 'locationOtherZip':
          tenureRequest[field] = this.fields[field];
          break;
        case 'dateRange':
          tenureRequest.startDate = this.fields['dateRange'][0] !== undefined ? this.fields['dateRange'][0]?.valueOf() || null : undefined;
          tenureRequest.endDate = this.fields['dateRange'][1] !== undefined ? this.fields['dateRange'][1]?.valueOf() || null : undefined;
          break;
          // transient/required fields
        case 'editable':
        case 'wizardProps':
        case 'status':
          break;
        default:
          if (!tenureRequest.siteData) {
            tenureRequest.siteData = {};
          }
          tenureRequest.siteData[field] = this.fields[field];
      }
    }

    return tenureRequest;
  }

  asInterface = this.asITenureRequest;
}

export function getDocumentSpecFromITenureRequestAndWizard(tenureRequest: ITenureRequest, wizard: any): DocumentSpec {
  const { uuid: id, owner, createdDate: created, siteData, startDate, endDate, ...fields } = tenureRequest;
  const spec = {
    id,
    created,
    type: FormType.tenureRequest,
    displayName: `${CLIENT_CONST.TENURE_TERM} Request`,
    createCommand: COMMANDS.CREATE_TENURE_REQUEST,
    updateCommand: COMMANDS.UPDATE_TENURE_REQUEST,
    apiGetRoot: API_ROUTES.tenureRequests,
    fields: {
      owner,
      editable: tenureRequest.status !== TENURE_REQUEST_STATUS.approved,
      wizardProps: buildWizardFromData(id, ROUTES.tenureRequestWizard, { uuid: owner, tenureRequestUuid: id }, getDocumentSpecFromITenureRequestAndWizard, wizard),
      dateRange: [startDate, endDate],
      ...siteData,
      ...fields,
    },
    changedFields: new Set<string>(),
  };

  return spec;
}
