export function handleResponse(response: any) {
  return response.text().then((text: string) => {
    let data: any;
    const error: { text: string } = {
      text: '',
    };

    try {
      data = JSON.parse(text);
    } catch (err) {
      error.text = 'Unexpected or No Response. Check your connection and try again.';
      return Promise.reject(error);
    }

    if (!response.ok) {
      // if (response.status === 401) {
      //   authActions.logout()(store.dispatch);
      // }

      error.text = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
