import { Select } from 'antd';
import { useBRListData } from '../../../hooks/useBRListData';
import React from 'react';

interface Props {
  onChange: (e: any) => void;
  attributes: { [index: string]: any };
}

export function CourseSelect(props: Props) {

  const selectParams = React.useMemo(
    () => ({ fetchDataParams: { url: '/v0/rules/data/course' } }),
    [],
  );

  const optionStore = useBRListData<{ label: string, value: string }>(selectParams);

  React.useEffect(() => {
    optionStore.fetch();
  }, []);

  const currentOption = optionStore.records?.find(record => props.attributes.uuid === record.value);

  return (
    <Select
      style={{ width: '100%' }}
      loading={optionStore.loading}
      showSearch={true}
      optionFilterProp={'label'}
      options={optionStore.records}
      value={currentOption?.value}
      onChange={props.onChange}
      placeholder={'Select course'}
    />
  );
}
