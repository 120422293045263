import React, { useState, useEffect, useMemo } from 'react';
import { Alert, Form } from 'antd';

import { BRModal, BRModalProps } from '../BRModal';
import { BRDatePicker, BRFormComments, BRSelect } from '../BRForm';
import { IPerson, IPersonRequirement, IRequirement } from '../../types';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';
import { useBRListData } from '../../hooks/useBRListData';
import { useBRStore } from '../../hooks/useBRStore';

interface Props extends BRModalProps {
  person: IPerson;
  requirement?: string;
  course?: string;
}

export const AddExemptionToPersonModal: React.FunctionComponent<Props> =
({ person, ...modalProps }) => {

  const fetchDataParams = useMemo(() => ({ url: `/v0/people/${person.uuid}/requirements` }), [person.uuid]);

  const requirements = useBRListData<IPersonRequirement>({ fetchDataParams });

  useEffect(() => {
    requirements.fetch();
  },        []);

  const [requirementId, setRequirementId] = useState(modalProps.requirement ?? '');
  const [course, setCourse] = useState(modalProps.course ?? '');
  const [exemptionDate, setExemptionDate] = useState(new Date());
  const [courseIdError, setCourseIdError] = useState('');
  const [reqIdError, setReqIdError] = useState('');
  const [comments, setComments] = useState('');

  const reqDataParams = useMemo(() => ({ url: `/v0/requirements/${requirementId}` }), [requirementId]);

  const requirementStore = useBRStore<IRequirement>({ fetchDataParams: reqDataParams });
  const requirement = requirementStore.data;

  useEffect(() => {
    if (!modalProps.requirement) {
      requirementStore.fetch();
      setCourse('');
    }
  },        [requirementId, modalProps.course]);

  const onFinish = async () => {
    setCourseIdError('');
    setReqIdError('');
    if (!requirementId) {
      setReqIdError('A requirement must be selected');
      return;
    }
    if (!course) {
      setCourseIdError('A course must be selected');
      return;
    }

    const formData = {
      course,
      exemptionDate,
      comments,
      requirement: requirementId,
      person: person.uuid,
    };

    await sendCommand({
      formData,
      command: COMMANDS.ADD_EXEMPTION_TO_PERSON,
    });
  };

  const requirementOptions = requirements.records ? requirements.records.map(r => ({
    key: r.uuid,
    value: r.uuid || '',
    label: r.name,
  })) : [];

  const courseOptions = requirement?.courses?.map(c => ({
    key: c.uuid,
    value: c.uuid || '',
    label: c.name,
  })) || [];

  const selectedCourse = {
    key: course,
    value: course,
    label: requirement?.courses?.find(c => c.uuid === course)?.name || course,
  };

  const title = `Add Exemption to ${person.firstName} ${person.lastName}`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Add Exemption"
      formProps={{ onFinish }}
    >
      { !(modalProps.requirement && modalProps.course) && (
        <>
          <Form.Item label={'For Requirement:'}>
            {reqIdError && <Alert message={reqIdError} />}
            <BRSelect
              setValue={setRequirementId}
              options={requirementOptions}
            />
          </Form.Item>

          <Form.Item label={'And Course:'}>
            {courseIdError && <Alert message={courseIdError} />}
            <BRSelect
              setValue={setCourse}
              value={selectedCourse}
              options={courseOptions}
              disabled={requirementStore.loading}
            />
          </Form.Item>
        </>
      )}
      <Form.Item label={'To Person:'}>
        <span className="ant-form-text">
          {`${person.firstName} ${person.lastName}`}
        </span>
      </Form.Item>

      <Form.Item label="Date Exempted:">
        <BRDatePicker
          dateValue={exemptionDate}
          setValue={setExemptionDate}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
