import React, { useContext } from 'react';
import { FieldProps, Field } from '../Field';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { StepContext } from '../Step';
import { DocumentEditorContext } from '../../../types/DocumentEditor';

interface YesNoFieldProps extends FieldProps {

}

export const YesNoField = (props: YesNoFieldProps) => {
  const { documentEditor } = useContext(DocumentEditorContext);
  const { value, setValue } = useContext(StepContext);

  if (!documentEditor.document) {
    return null;
  }

  let defaultValue;

  if (value === true) {
    defaultValue = 'Yes';
  }

  if (value === false) {
    defaultValue = 'No';
  }

  const onChange = (e: RadioChangeEvent) => {
    if (!documentEditor.document) {
      return;
    }

    let newValue;

    switch (e.target.value) {
      case 'Yes':
        newValue = true;
        break;
      case 'No':
        newValue = false;
        break;
    }

    setValue(newValue);
  };

  return (
    <Field {...props}>
      <Radio.Group defaultValue={defaultValue} onChange={onChange} disabled={!documentEditor.document.editable || !!props.readOnly}>
        <Radio value="Yes">Yes</Radio>
        <Radio value="No">No</Radio>
      </Radio.Group>
    </Field>
  );
};
