import { buildWizardFromData } from '../components/Wizard/Wizard';
import { COMMANDS } from '../constants';
import { API_ROUTES } from '../constants/apiRoutes';
import { ROUTES } from '../routes';
import { ISettings } from '../types';
import { DocumentSpec } from '../types/DocumentSpec';
import { FormType } from '../types/Form';
import { Document } from './Document';

export class SettingsDocument extends Document {
  constructor(spec: DocumentSpec) {
    super(spec);
  }

  asISettings(allFields = false): ISettings {
    const settings: ISettings = {};

    const fields = allFields ? this.fields : this.changedFields;

    for (const field in fields) {
      settings[field] = this.fields[field];
    }

    return settings;
  }

  asInterface = this.asISettings;
}

export function getDocumentSpecFromISettingsAndWizard(settings: ISettings, wizard: any): DocumentSpec {
  const { ...fields } = settings;
  const spec = {
    created: Date.now(),
    id: '',
    type: FormType.settings,
    displayName: 'Settings',
    createCommand: COMMANDS.UPDATE_SETTINGS,
    updateCommand: COMMANDS.UPDATE_SETTINGS,
    apiGetRoot: API_ROUTES.settings,
    fields: {
      editable: true, // Always editable
      wizardProps: buildWizardFromData('', ROUTES.settingsWizard, {}, getDocumentSpecFromISettingsAndWizard, wizard),
      ...fields,
    },
    changedFields: new Set<string>(),
  };

  return spec;
}
