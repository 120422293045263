import { Session } from '../types/Session';

export enum PERMISSION_TYPES {
  // pages & tabs
  people = 'people',
  groups = 'groups',
  reports = 'reports',
  requirements = 'requirements',
  courses = 'courses',
  transcripts = 'transcripts',
  outbox = 'outbox',
  templates = 'templates',
  tenures = 'tenures',
  audit = 'audit',
  // roles/modules
  onboarding = 'onboarding',
  training = 'training',
  // navbar commands
  impersonate = 'impersonate',
  deimpersonate = 'deimpersonate',
}

export function hasPermission(requiredPermissions: PERMISSION_TYPES | PERMISSION_TYPES[], session: Session<any, any>): boolean {
  const permissions = Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions];
  const hasPermission = permissions.some(p => session?.scope?.includes(p));
  return hasPermission;
}
