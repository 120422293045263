import { useBRFetch, IRequestParams } from './useBRFetch';
import * as React from 'react';

export interface BRListData<T> {
  records: T[];
  loading: boolean;
  loaded: boolean;
  fetch: () => Promise<{ records: T[] }>;
}

type FetchDataParams = {
  url: string,
  resourceId?: string | number,
  queryParams?: IRequestParams,
};

type Props = {
  initialData?: any,
  fetchDataParams: FetchDataParams,
};

// useBRListData is meant for the management of data for the DetailsTable component

export const useBRListData = <T>(props: Props): BRListData<T> => {
  const [data, setData] = React.useState(props.initialData || { records: [] });
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  const [makeRequest] = useBRFetch();

  const fetch = React.useCallback(
    dataFetch,
    [props.fetchDataParams],
  );

  const { url, resourceId, queryParams } = props.fetchDataParams;

  async function dataFetch() {
    setLoading(true);
    const fetchUrl = resourceId ? `${url}/${resourceId}` : url;
    const response = await makeRequest(
      fetchUrl,
      queryParams,
    );
    setLoading(false);
    setData(response);
    setLoaded(true);
    console.log(`returned BRListData for ${fetchUrl}:`, response);
    return response;
  }

  return { ...data, loading, loaded, fetch };
};
