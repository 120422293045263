import { Document } from '../resources/Document';
import { sendCommand } from './commands.services';

export function postWizardDocument(document: Document, values: any) {
  let command;
  const finalValues = document.asInterface();

  if (document.id === 'new') {
    command = document.createCommand;
  } else {
    command = document.updateCommand;
  }

  return sendCommand({
    command,
    formData: {
      ...finalValues,
    },
  });
}
