export * from './IPersonRequirement';
export * from './ICourseTaken';
export * from './INotification';
export * from './ICommand';
export * from './IBreadcrumbs';
export * from './ICourseCategory';
export * from './IProvider';
export * from './IRole';
export * from './IUser';
export * from './IPerson';
export * from './IRequirement';
export * from './IProtocol';
export * from './ICourse';
export * from './IRequirementCategory';
export * from './IBasicPerson';
export * from './IReport';
export * from './IRoutes';
export * from './IResourceSchema';
export * from './IPagination';
export * from './ITenure';
export * from './ITenureRequest';
export * from './IApprovalStatuses';
export * from './ITranscript';
export * from './ITranscriptProvider';
export * from './IApprovalItem';
export * from './IApprovalTemplate';
export * from './IApprovalStatus';
export * from './IAttachment';
export * from './IPhysicalAttachment';
export * from './IMail';
export * from './ISettings';
