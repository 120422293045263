import React, { useState, useEffect } from 'react';
import { Form } from 'antd';

import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRLargeDataSelect } from '../BRForm';
import { IPerson, ITranscript } from '../../types';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { API_ROUTES } from '../../constants/apiRoutes';

interface Props extends BRModalProps {
  person?: IPerson;
  transcript?: ITranscript;
}

const transcriptDataParams = { url: API_ROUTES.transcripts, queryParams: { all: true } };
const peopleDataParams = { url: '/v0/people', queryParams: { basic: true } };

export const LinkTranscriptModal: React.FunctionComponent<Props> =
(props) => {

  const [person, setPerson] = useState('');
  const [transcript, setTranscript] = useState('');
  const [comments, setComments] = useState('');

  const transcripts = useBRListData<ITranscript>({
    fetchDataParams: transcriptDataParams,
  });

  const people = useBRListData<IPerson>({
    fetchDataParams: peopleDataParams,
  });

  useEffect(
    () => {
      if (!props.transcript) {
        transcripts.fetch();
      } else {
        setTranscript(props.transcript.uuid);
      }
    },
    [transcripts.fetch]);

  useEffect(
    () => {
      if (!props.person) {
        people.fetch();
      } else {
        setPerson(props.person.uuid);
      }
    },
    [people.fetch]);

  const onFinish = async () => {
    const formData = {
      person,
      transcript,
      comments,
    };

    await sendCommand({
      formData,
      command: COMMANDS.LINK_TRANSCRIPT,
    });
  };

  const transcriptOptions = (props.transcript ? [props.transcript] : transcripts.records).map(
      (g: ITranscript) => {
        const label = `${g.firstName} ${g.lastName}${g.keys.email ? ` (${g.keys.email[0]})` : ''} ${g.provider.name} ${g.providerId}`;
        return {
          label,
          key: g.uuid,
          value: g.uuid || '',
        };
      },
    );

  const personOptions = (props.person ? [props.person] : people.records).map(
    (p: IPerson) => {
      const label = `${p.firstName} ${p.lastName} (${p.keys?.username?.[0]})`;
      return {
        label,
        key: p.uuid,
        value: p.uuid || '',
      };
    },
  );

  return (
    <BRModal
      {...props}
      title="Link Transcript"
      okText="Update Link"
      formProps={{ onFinish }}
    >
      <Form.Item label="Link Transcript">
        <BRLargeDataSelect
          options={transcriptOptions}
          setValue={setTranscript}
          loading={transcripts.loading}
          disabled={!!props.transcript}
          value={props.transcript && transcriptOptions[0]}
        />
      </Form.Item>
      <Form.Item label="To Person">
        <BRLargeDataSelect
          options={personOptions}
          setValue={setPerson}
          loading={people.loading}
          disabled={!!props.person}
          value={props.person && personOptions[0]}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments}/>
    </BRModal>
  );
};
