import * as React from 'react';
import { BRLayout } from '../components/BRLayout';
import { IRequirement } from '../types';
import { TABLE_IDS } from '../constants';
import BRTable from '../components/BRTable';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { NewRequirementModal } from '../components/modals/NewRequirementModal';
import { UploadCSVModal } from '../components/modals/UploadCSVModal';
import { ROUTES } from '../routes';
import { PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { checkCommands } from '../helpers/checkCommands';
import { API_ROUTES } from '../constants/apiRoutes';
import { Command } from '../components/CommandButtons';

interface Props {

}

export function RequirementListPage(props: Props) {

  const requirements = useBRTableStore<IRequirement>({
    fetchDataParams: { url: API_ROUTES.requirements },
  });

  const allRequirementListCommands: Command[] = [
    {
      title: 'Create Requirement',
      icon: <PlusCircleOutlined />,
      type: 'newRequirement',
      modal: (
        <NewRequirementModal
          onSuccess={requirements.fetch}
        />
      ),
    },
    // bulkImport: {
    //   title: 'Bulk Import',
    //   icon: <UploadOutlined />,
    //   modal: <UploadCSVModal />,
    // },
  ];

  const commands = checkCommands(allRequirementListCommands, requirements);

  return (
    <BRLayout
      commands={commands}
      loading={requirements.loading}
      title="Requirements"
    >
      <BRTable
        linkPath={ROUTES.requirement}
        tableId={TABLE_IDS.REQ_PAGE_TABLE}
        tableData={requirements}
      />
    </BRLayout>
  );
}
