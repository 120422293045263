import * as React from 'react';
import { BranchesOutlined } from '@ant-design/icons';
import { Avatar, List } from 'antd';
import { getElementAttributes } from '../../helpers/getNodeAttributes';
import { RuleEditorDelete } from './RuleEditorDelete';
import { RuleEditorEdit } from './RuleEditorEdit';
import { RuleEditorAdd } from './RuleEditorAdd';
import { RuleEditorNodeProps, RULE_NODE_COLORS } from '.';

export function RuleEditorBlock(props: RuleEditorNodeProps) {
  const attributes = getElementAttributes(props.elt);
  const description = <><strong>True if:</strong> {attributes.result === 'any' ? 'any child is true' : 'all children are true'}</>;

  return (
    <List.Item
      style={{ padding: '0.25em' }}
      actions={props.editable ? [
        <RuleEditorAdd {...props} key="Add" />,
        <RuleEditorEdit {...props} key="Edit" />,
        <RuleEditorDelete {...props} key="Delete" />,
      ]: undefined}
    >
      <List.Item.Meta avatar={<Avatar icon={<BranchesOutlined />} style={{ backgroundColor: RULE_NODE_COLORS.Block }} />} title={props.elt.nodeName} description={description} />
    </List.Item>
  );
}
