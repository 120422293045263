import {
  BookOutlined,
  CaretDownFilled,
  ReadOutlined,
  SaveOutlined,
  UndoOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Tree, message, Empty, Button, Spin } from 'antd';
import { DataNode } from 'antd/lib/tree';
import * as React from 'react';
import { COMMANDS, UI_COLORS } from '../../constants';
import { checkCommands } from '../../helpers/checkCommands';
import { getElementAttributes } from '../../helpers/getNodeAttributes';
import { BRStore, useBRStore } from '../../hooks/useBRStore';
import { sendCommand } from '../../services/commands.services';
import { DictOf } from '../../types/DictOf';
import { IRule, IRuleVersion } from '../../types/IRule';
import { Command, CommandButtons } from '../CommandButtons';
import { VersionTimeline } from '../VersionsTimeline';
import { RuleEditorBlock } from './RuleEditorBlock';
import { RuleEditorCourse } from './RuleEditorCourse';
import { RuleEditorCourseGroup } from './RuleEditorCourseGroup';
import { RuleEditorDrawer } from './RuleEditorDrawer';
import { RuleEditorLoop } from './RuleEditorLoop';
import { RuleEditorRenewalGroup } from './RuleEditorRenewalGroup';
import { RuleEditorTest } from './RuleEditorTest';
import { useDom } from './useDom';
import { v4 } from 'uuid';
import { Key } from 'antd/lib/table/interface';

interface RuleComparator {
  key: string;
  title: string;
}

interface RuleObject {
  title: string;
  key: string;
  schema: RuleObjectSchema;
}

interface RuleObjectSchema {
  comparators?: RuleComparator[];
  children?: RuleObject[];
  collection?: boolean;
}

const comparators: DictOf<RuleComparator> = {
  true: {
    key: 'true',
    title: 'Is true?',
  },
  false: {
    key: 'false',
    title: 'Is false?',
  },
  isCurrentUser: {
    key: 'isCurrentUser',
    title: 'Is Current User?',
  },
  equals: {
    key: 'equals',
    title: 'Equals',
  },
};

const objectSchemas: DictOf<RuleObjectSchema> = {};

objectSchemas.string = {
  comparators: [
    comparators.equals,
  ],
};

objectSchemas.stringArray = {
  collection: true,
  comparators: [
    comparators.equals,
  ],
};

objectSchemas.groupMemberships = {
  collection: true,
  children: [
    {
      title: 'Name',
      key: 'name',
      schema: objectSchemas.string,
    },
    {
      title: 'Type',
      key: 'type',
      schema: objectSchemas.string,
    },
    {
      title: 'Person',
      key: 'person',
      schema: objectSchemas.person,
    },
    {
      title: 'Roles',
      key: 'roles',
      schema: objectSchemas.stringArray,
    },
    {
      title: 'Other Members',
      key: 'members',
      schema: objectSchemas.groupMemberships,
    },
  ],
};

objectSchemas.person = {
  comparators: [
    comparators.isCurrentUser,
  ],
  children: [
    {
      title: 'Group Memberships',
      key: 'groups',
      schema: objectSchemas.groupMemberships,
    },

  ],
};

export const RULE_NODE_COLORS = {
  Block: UI_COLORS.deepRed,
  Loop: UI_COLORS.deepPurple,
  Test: UI_COLORS.deepGreen,
  Course: UI_COLORS.deepGreen,
  RetiredCourse: UI_COLORS.gray,
  CourseGroup: UI_COLORS.deepBlue,
  RenewalGroup: UI_COLORS.deepOrange,
};

interface RuleEditorProps {
  ruleStore?: BRStore<IRule>;
}

export interface RuleEditorVersion extends Partial<IRuleVersion> {
  draft?: boolean;
}

export interface RuleEditorNodeProps {
  elt: Element;
  openEditor: (elt: Element, addToParent?: Element) => void;
  schema: any | undefined;
  isCourseEditor?: boolean;
  editable: boolean;
}

export function RuleEditor(props: RuleEditorProps) {
  const [editorParentNode, setEditorParentNode] = React.useState<Element>();
  const [selectedElt, setSelectedElt] = React.useState<Element>();

  const [selectedVersion, setSelectedVersion] = React.useState<number>(0);

  const [expandedNodes, setExpandedNodes] = React.useState<Key[]>([]);

  const type = props.ruleStore?.data?.type;

  const ruleSchemaParams = React.useMemo(() => ({ url: `/v0/rules/schema/${type}` }), [type]);

  const ruleSchemaStore = useBRStore<{ [index: string]: any }>({ fetchDataParams: ruleSchemaParams });

  React.useEffect(() => {
    ruleSchemaStore.fetch();
  }, [type]);

  const rule = props.ruleStore?.data;
  const draft = rule?.draft;

  const isCourseEditor = rule?.type === 'requirementCourses';

  const versions: RuleEditorVersion[] = [];
  const workingVersions = versions.concat(rule?.versions || []);

  const draftXml = (draft ?? rule?.versions?.[0])?.xml;

  const { dom, isDirty, getDomXmlString } = useDom(draftXml);

  // dummy version for draft/edited dom
  workingVersions.unshift({
    draft: true,
  });

  const createFormData = (index: number) => {
    return {
      uuid: rule?.uuid,
      xml: getDomXmlString(),
    };
  };

  async function saveDraft() {
    await sendCommand({
      formData: createFormData(0),
      command: COMMANDS.UPDATE_RULE_DRAFT,
    });
    message.success('Draft saved.');
    setSelectedVersion(0);
    await props.ruleStore?.fetch();
  }

  async function publish() {
    await sendCommand({
      formData: createFormData(0),
      command: COMMANDS.PUBLISH_RULE,
    });
    message.success('New version published.');
    setSelectedVersion(0);
    await props.ruleStore?.fetch();
  }

  async function revert() {
    await sendCommand({
      formData: createFormData(selectedVersion),
      command: COMMANDS.PUBLISH_RULE,
    });
    message.success(`Reverted Version ${selectedVersion} to Current Version.`);
    setSelectedVersion(0);
    await props.ruleStore?.fetch();
  }

  const allRuleCommands: Command[] = [];

  allRuleCommands.push({
    title: 'Save Draft',
    icon: <SaveOutlined />,
    type: COMMANDS.UPDATE_RULE_DRAFT,
    onClick: saveDraft,
    enabled: isDirty && selectedVersion === 0,
  });

  allRuleCommands.push({
    title: 'Revert to Version',
    icon: <UndoOutlined />,
    type: COMMANDS.PUBLISH_RULE,
    onClick: revert,
    enabled: !!selectedVersion,
  });

  allRuleCommands.push({
    title: 'Publish Draft',
    icon: <UploadOutlined />,
    type: COMMANDS.PUBLISH_RULE,
    onClick: publish,
    enabled: (isDirty || !!draft) && selectedVersion === 0,
  });

  const commands = checkCommands(allRuleCommands, props.ruleStore);

  const openEditor = (elt: Element, addToParent?: Element) => {
    setSelectedElt(elt);
    if (addToParent) {
      setEditorParentNode(addToParent);
    } else {
      setEditorParentNode(undefined);
    }
  };

  const allNodeKeys: Set<string> = new Set;

  const makeDataNode = (elt: Element, parent: any) => {
    let title: any;
    const attributes: any = getElementAttributes(elt);

    title = <></>;

    const children: DataNode[] = [];
    for (let i = 0; i < elt.children.length; i += 1) {
      if (elt.children[i].nodeName !== '#text') {
        children.push(makeDataNode(elt.children[i], elt));
      }
    }

    const nodeProps: RuleEditorNodeProps = {
      elt,
      openEditor,
      isCourseEditor,
      schema: ruleSchemaStore.data?.schema,
      editable: commands.length > 0 && selectedVersion === 0,
    };

    switch (elt.nodeName) {
      case 'Block':
        title = <RuleEditorBlock {...nodeProps} />;
        break;
      case 'Loop':
        title = <RuleEditorLoop {...nodeProps} />;
        break;
      case 'Test':
        title = <RuleEditorTest {...nodeProps} />;
        break;
      case 'CourseGroup':
        title = <RuleEditorCourseGroup {...nodeProps} />;
        break;
      case 'RenewalGroup':
        title = <RuleEditorRenewalGroup {...nodeProps} />;
        break;
      case 'Course':
        title = <RuleEditorCourse {...nodeProps} />;
        break;
    }

    const key = attributes.unique ?? v4();
    allNodeKeys.add(key);

    return {
      elt,
      attributes,
      parent,
      children,
      title,
      key: attributes.unique,
    };
  };

  const selectedDom = useDom(workingVersions[selectedVersion]?.xml).dom;

  const displayedDom = selectedVersion === 0 ? dom : selectedDom;

  const treeData = [makeDataNode(displayedDom.documentElement, undefined)];

  React.useEffect(() => {
    setExpandedNodes(Array.from(allNodeKeys));
  }, [displayedDom]);

  const onExpand = (expandedKeys: Key[]) => {
    setExpandedNodes(expandedKeys);
  }

  const onClose = () => {
    setSelectedElt(undefined);
    setEditorParentNode(undefined);
  };

  const newnewTimelineItems = workingVersions.map(version => ({
    label: version.draft ? 'Draft' : `Version ${version.versionNumber}`,
    date: version.createdOn ? new Date(version.createdOn) : new Date(),
  }));

  return (
    <div className="ruleEditor">
      <CommandButtons commands={commands} />
      <Spin size="large" tip={'Loading...'} spinning={props.ruleStore?.loading}>
      {props.ruleStore?.data?.uuid ? ( // what's the best way to determine the rule doesn't exist?
        <div className="ruleEditorBody">
          <div className="ruleEditorMain">
            <h3>Editing Current Draft</h3>
            {treeData && (
              <Tree
                className="ruleEditorTree"
                treeData={treeData}
                defaultExpandAll={true}
                showIcon={true}
                blockNode={true}
                draggable={false}
                selectable={false}
                // onSelect={onSelect}
                switcherIcon={<CaretDownFilled style={{ fontSize: '1.5em', color: '#888' }} />}
                // selectedKeys={selected ? [selected.key] : []}
                // allowDrop={allowDrop}
                // onDragEnter={onDragEnter}
                // onDrop={onDrop}
                showLine={{ showLeafIcon: false }}
                expandedKeys={expandedNodes}
                onExpand={onExpand}
              />
            )}
          </div>
          <div className="ruleEditorHistory">
            <VersionTimeline versions={newnewTimelineItems} selected={selectedVersion} onSelection={setSelectedVersion} dirty={isDirty} />
          </div>
        </div>
      ) : (
        <Empty
          description={'No current rule for this requirement'}
        />
      )}
      </Spin>
      <RuleEditorDrawer
        element={selectedElt}
        parentElement={editorParentNode || selectedElt?.parentElement}
        schema={ruleSchemaStore.data?.schema}
        onClose={onClose}
      />
    </div>
  );
}
