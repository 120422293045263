import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React from 'react';
import { RuleEditorNodeProps } from '.';

export function RuleEditorDelete(props: RuleEditorNodeProps) {

  const onDelete = () => {
    props.elt.remove();
  };

  const title = props.elt?.children?.length
    ? `Delete this node and its ${props.elt?.children?.length > 1 ? `${props.elt?.children?.length} children` : 'child'}?`
    : 'Delete this node?';

  return (
    <Popconfirm
      title={title}
      onConfirm={onDelete}
      icon={<ExclamationCircleFilled style={{ color: 'red' }} />}
    >
      <Button danger={true} icon={<DeleteOutlined />}>Delete</Button>
    </Popconfirm>
  );
}
