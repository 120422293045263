import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Upload } from 'antd';
import { FormProps } from 'antd/lib/form';
import moment from 'moment';
import { COMMANDS } from '../../constants';
import { BRModal, BRModalProps } from '../BRModal';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { ICourse } from '../../types';
import { BRSelect, BRSelectValue } from '../BRForm';
import TextArea from 'antd/lib/input/TextArea';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';

interface Props extends BRModalProps {
  person: string;
}

const fetchDataParams = { url: '/v0/courses', queryParams: { all: true } };

export const RequestEquivalencyModal: React.FunctionComponent<Props> =
({ person, ...modalProps }) => {
  const courses = useBRListData<ICourse>({ fetchDataParams });

  useEffect(
    () => {
      courses.fetch().then(() => setCourse(courses[0]?.uuid));
    },
    [courses.fetch]);

  const courseOptions: BRSelectValue[] = courses.records ? courses.records.map(
    c => ({
      key: c.uuid,
      value: c.uuid || '',
      label: c.name || '',
    })) : [];

  const [course, setCourse] = useState('');
  const [completionDate, setCompletionDate] = useState(undefined as (moment.Moment | undefined));
  const [attachments, setAttachments] = useState([] as UploadFile[]);
  const [learnerComment, setLearnerComment] = useState('');

  const isDateDisabled = (date: moment.Moment) => date.isAfter(moment());
  const setNewCompletionDate = (date: moment.Moment | null, dateString: string) => date == null ? setCompletionDate(undefined) : setCompletionDate(date);

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setLearnerComment(value);
  };

  const onFileAdd = (file: UploadFile) => {
    setAttachments([...attachments, file]);
    return false;
  };

  const onFileRemove = (file: UploadFile) => {
    setAttachments(attachments.filter(attachment => attachment !== file));
  };

  const onFinish = async () => {
    const formData = new FormData();
    formData.append('person', person);
    formData.append('course', course);
    formData.append('completionDate', `${completionDate?.valueOf()}`);
    attachments.forEach((attachment) => {
      formData.append('files[]', attachment as any, attachment.name);
    });
    if (learnerComment) {
      formData.append('learnerComment', learnerComment);
    }

    await sendCommand({
      formData,
      command: COMMANDS.CREATE_EQUIVALENCY,
    });

    return false;
  };

  return (
    <BRModal
      {...modalProps}
      title="Request a Course Equivalency"
      formProps={{ onFinish }}
    >
      <Form.Item label="Course" required={true}>
        <BRSelect
          value={courseOptions.find(option => option.value === course)}
          options={courseOptions}
          setValue={setCourse}
        />
      </Form.Item>
      <Form.Item label="Completion Date" required={true}>
        <DatePicker
          value={completionDate}
          disabledDate={isDateDisabled}
          onChange={setNewCompletionDate}
          name="completionDate"
        />
      </Form.Item>
      <Form.Item label="Certificate" required={true}>
        <Upload
          multiple={true}
          disabled={false}
          fileList={attachments}
          beforeUpload={onFileAdd}
          onRemove={onFileRemove}
        >
          <Button><UploadOutlined />Upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item label="Learner Comment">
        <TextArea
          value={learnerComment}
          rows={4}
          onChange={handleCommentChange}
        />
      </Form.Item>
    </BRModal>
  );
};
