import * as React from 'react';

export const LoadingAnimation: React.FunctionComponent<{loading?: boolean}>
  = ({ loading }) => {

    return(
      <>
        {
          loading && (
            <div
              className="w-100 h-100 d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                position: 'absolute',
              }}
            >
              <div className="progress-circle-indeterminate" />
            </div>
          )
        }
      </>
    );
  };

export const LoadingText: React.FunctionComponent<{loading?: boolean, text?: string}>
  = ({ loading, text }) => {

    return(
      <>
        {
          loading && (
            <div
              style={{
                height: '100%',
                left: 0,
                pointerEvents: 'none',
                position: 'absolute',
                top: 0,
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'table',
                  height: '100%',
                  pointerEvents: 'none',
                  width: '100%',
                }}
                role="presentation"
              >
                <div
                  style={{
                    display: 'table-cell',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <span
                    style={{
                      background: 'white',
                      borderRadius: 0,
                      boxShadow: 'none',
                      backgroundColor: 'white',
                      border: '1px solid #BDC3C7',
                      color: '#000',
                      padding: '16px',
                    }}
                  >
                    {text || 'Loading...'}
                  </span>
                </div>
              </div>
            </div>
          )
        }
      </>
    );
  };
