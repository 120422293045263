import { Document } from './Document';
import { ITenure } from '../types/ITenure';
import { RESOURCE_STATUS } from '../types';
import { statusTagRenderer } from '../helpers/dataRenderers';
import { DocumentSpec } from '../types/DocumentSpec';
import { FormType } from '../types/Form';
import { ROUTES } from '../routes';
import { buildWizardFromData } from '../components/Wizard/Wizard';
import { CLIENT_CONST, COMMANDS } from '../constants';
import { API_ROUTES } from '../constants/apiRoutes';

export class TenureDocument extends Document {
  constructor(spec: DocumentSpec) {
    super(spec);
  }

  get tenureStatus(): RESOURCE_STATUS {
    return this.fields['status'];
  }

  get statusTag() {
    return statusTagRenderer(this.tenureStatus);
  }

  asITenure(allFields = false): ITenure {
    const tenure: ITenure = { uuid: this.id, createdDate: this.created?.valueOf() || Date.now() };

    const fields = allFields ? this.fields : this.changedFields;

    if (this.id === 'new') {
      tenure.person = this.fields.person;
    }

    for (const field in fields) {
      switch (field) {
        case 'person':
        case 'status':
        case 'sponsor':
        case 'location':
        case 'locationOtherAddressOne':
        case 'locationOtherAddressTwo':
        case 'locationOtherCity':
        case 'locationOtherState':
        case 'locationOtherZip':
        case 'tenureNumber':
          tenure[field] = this.fields[field];
          break;
        case 'dateRange':
          tenure.startDate = this.fields['dateRange'][0] !== undefined ? this.fields['dateRange'][0]?.valueOf() || null : undefined;
          tenure.endDate = this.fields['dateRange'][1] !== undefined ? this.fields['dateRange'][1]?.valueOf() || null : undefined;
          break;
        // transient/required fields
        case 'editable':
        case 'wizardProps':
        case 'tenureNumber':
        case 'status':
          break;
        default:
          if (!tenure.siteData) {
            tenure.siteData = {};
          }
          tenure.siteData[field] = this.fields[field];
      }
    }

    return tenure;
  }

  asInterface = this.asITenure;
}

export function getDocumentSpecFromITenureAndWizard(tenure: ITenure, wizard: any): DocumentSpec {
  const { uuid: id, person, createdDate: created, siteData, startDate, endDate, ...fields } = tenure;
  const spec = {
    id,
    created,
    type: FormType.tenure,
    displayName: CLIENT_CONST.TENURE_TERM,
    createCommand: COMMANDS.CREATE_TENURE,
    updateCommand: COMMANDS.UPDATE_TENURE,
    apiGetRoot: API_ROUTES.tenures,
    fields: {
      person,
      editable: true, // Always editable
      wizardProps: buildWizardFromData(id, ROUTES.tenureWizard, { uuid: person, tenureUuid: id }, getDocumentSpecFromITenureAndWizard, wizard),
      dateRange: [startDate, endDate],
      ...siteData,
      ...fields,
    },
    changedFields: new Set<string>(),
  };

  return spec;
}
