import React, { useState, useMemo, useEffect } from 'react';
import { Form, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';

import { BRModal, BRModalProps } from '../BRModal';
import { BRSelect, BRSelectValue } from '../BRForm';
import { IExemption, IPerson, IPersonCourse, IPersonRequirement, IUser, RESOURCE_STATUS } from '../../types';
import { DATE_OPTIONS } from '../../constants';
import { useBRListData } from '../../hooks/useBRListData';
import { requestPDF } from '../../helpers/requestPDF';
import { requestFile } from '../../helpers/requestFile';
import { FEATURES } from '../../constants/features';

interface Props extends BRModalProps {
  person: IUser | IPerson
  homePage?: boolean
}

interface CourseOption extends BRSelectValue {
  key: string;
  value: string;
  name: string;
  completedDate: number;
}

export const GenerateCertificateModal: React.FunctionComponent<Props> =
({ person, homePage, ...modalProps }) => {

  const fetchCoursesParams = useMemo(() => ({ url: `/v0/people/${person.uuid}/courses`, queryParams: { all: true } }), [person]);
  const taken = useBRListData<IPersonCourse>({ fetchDataParams: fetchCoursesParams });
  const fetchRequirementsParams = useMemo(() => ({ url: `/v0/people/${person.uuid}/requirements`, queryParams: { all: true } }), [person]);
  const personRequirements = useBRListData<IPersonRequirement>({ fetchDataParams: fetchRequirementsParams });
  const exemptions = useBRListData<IExemption>({ fetchDataParams: useMemo(() => ({ url: `/v0/people/${person.uuid}/exemptions` }), [person]) });

  const [courses, setCourses] = useState<string[]>([]);
  const [requirements, setRequirements] = useState([] as string[]);
  const [useCourses, setUseCourses] = useState(false);
  const [useRequirements, setUseRequirements] = useState(true);
  const [pdf, setPdf] = useState(true);

  useEffect(
    () => {
      taken.fetch();
    },
    [taken.fetch]);

  useEffect(
    () => {
      personRequirements.fetch();
    },
    [personRequirements.fetch]);

  useEffect(
    () => {
      exemptions.fetch();
    },
    [exemptions.fetch]);

  const onFinish = async () => {
    const suffix = pdf ? 'pdf' : 'docx';
    const certificateTitle = `Certificates for ${person.firstName} ${person.lastName}.${suffix}`;

    if (useCourses) {
      if (pdf) {
        await requestPDF(`/v0/people/${person.uuid}/certificate.pdf`, certificateTitle, { courses });
      } else {
        await requestFile(`/api/v0/people/${person.uuid}/certificate.doc`, certificateTitle, 'application/msword', { courses });
      }
    } else {
      if (pdf) {
        await requestPDF(`/v0/people/${person.uuid}/requirementcertificate.pdf`, certificateTitle, { requirements });
      } else {
        await requestFile(`/api/v0/people/${person.uuid}/requirementcertificate.doc`, certificateTitle, 'application/msword', { requirements });
      }
    }
  };

  const handleRadio = (e: RadioChangeEvent) => {
    const { value } = e.target;
    if (value === 'useRequirements') {
      setUseCourses(false);
      setUseRequirements(true);
      setCourses(courses);
    } else {
      setUseCourses(true);
      setUseRequirements(false);
      setRequirements(requirements);
    }
  };

  const handleFormatRadio = (e: RadioChangeEvent) => {
    setPdf(e.target.value === 'pdf');
  };

  const courseMap: { [uuid: string]: CourseOption} = {};

  for (const course of taken.records) {
    if (!course.uuid || !course.name) { continue; }
    if (!courseMap[course.uuid] || (course.completedDate && course.completedDate > courseMap[course.uuid].completedDate)) {
      const name = course.name;
      courseMap[course.uuid] = {
        name,
        key: course.uuid,
        value: course.uuid,
        label: course.name,
        completedDate: course.completedDate || 0,
      };
    }
  }

  for (const exemption of exemptions.records) {
    const courseUuid = exemption.course.uuid;
    if (!courseMap[courseUuid]) {
      const name = exemption.course.name || '';
      courseMap[courseUuid] = {
        name,
        key: courseUuid,
        value: courseUuid,
        label: name,
        completedDate: exemption.exemptionDate,
      };
    }
  }

  const courseOptions: BRSelectValue[] = Object.values(courseMap).sort((a, b) => {
    const comparison = a.name.localeCompare(b.name);
    return comparison !== 0 ? comparison : b.completedDate - a.completedDate;
  }).map((c) => {
    const dateString = new Date(c.completedDate).toLocaleString('en-US', DATE_OPTIONS);
    return {
      key: c.key,
      value: c.value,
      label: `${dateString} - ${c.name}`,
    };
  });

  const completedRequirements: IPersonRequirement[] =
    personRequirements.records.filter(r => r.status === RESOURCE_STATUS.complete || r.status === RESOURCE_STATUS.expiring || r.status === RESOURCE_STATUS.expired);

  const requirementMap: {[uuid: string]: BRSelectValue} = {};
  const requirementOptions = completedRequirements.map((r: IPersonRequirement) => {
    const option: BRSelectValue = {
      key: r.uuid,
      value: r.uuid || '',
      label: r.name ? r.name : '',
    };
    if (r.uuid) {
      requirementMap[r.uuid] = option;
    }
    return option;
  });

  const title = `Generate Certificate for ${person.firstName} ${person.lastName}`;

  const selectedCourses = courses.map(uuid => courseMap[uuid]);
  const selectedRequirements = requirements.map(uuid => requirementMap[uuid]);

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Generate Certificate"
      formProps={{ onFinish }}
    >
      <Form.Item label="Select Requirements:">
        <Radio.Group onChange={handleRadio} defaultValue={useCourses ? 'useCourses' : 'useRequirements'}>
          <Radio
            value="useRequirements"
            name="useRequirements"
            id="useRequirements"
          >
            Use Requirements
          </Radio>
          <Radio
            value="useCourses"
            name="useCourses"
            id="useCourses"
          >
            Use Courses
          </Radio>
        </Radio.Group>
      </Form.Item>

      {useRequirements && (
        <Form.Item label="Select Requirements:">
          <BRSelect
              options={requirementOptions}
              setValue={setRequirements}
              value={selectedRequirements}
              mode="multiple"
              disabled={useCourses}
          />
        </Form.Item>
      )}

      {useCourses && (
        <Form.Item label="Select Courses:">
          <BRSelect
              options={courseOptions}
              setValue={setCourses}
              value={selectedCourses}
              mode="multiple"
              disabled={useRequirements}
          />
        </Form.Item>
      )}

      {FEATURES.WORD_CERTIFICATES && (
        <Form.Item label="Certificate Format:">
          <Radio.Group onChange={handleFormatRadio} defaultValue={pdf}>
            <Radio
              value={true}
              name="pdf"
              id="pdf"
            >
              PDF
            </Radio>
            <Radio
              value={false}
              name="word"
              id="word"
              disabled={homePage}
            >
              Word
            </Radio>
          </Radio.Group>
        </Form.Item>
      )}
    </BRModal>
  );
};
