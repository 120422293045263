import React, { useEffect, useReducer, useMemo } from 'react';
import { Spin } from 'antd';
import { TabMenu } from '../components/TabMenu';
import { BRLayout } from '../components/BRLayout';
import {
  ICommand,
  IPerson,
  ITenure,
  ITenureApprovalStatuses,
  RESOURCE_STATUS,
} from '../types';
import { TABLE_IDS, DATE_OPTIONS, CLIENT_CONST } from '../constants';
import { useBRTableStore } from '../hooks/useBRTableStore';
import BRTable from '../components/BRTable';
import { Wizard, WizardProps } from '../components/Wizard/Wizard';
import { DocumentEditorContext, documentEditorReducer } from '../types/DocumentEditor';
import { EditTenureLengthModal } from '../components/modals/EditTenureLengthModal';
import { SessionContext } from '../components/App';
import { ROUTES } from '../routes';
import { CalendarOutlined, LoadingOutlined, MailOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useBRStore } from '../hooks/useBRStore';
import { TenureDocument, getDocumentSpecFromITenureAndWizard } from '../resources/TenureDocument';
import { AssignApprovalTemplatesModal } from '../components/modals/AssignApprovalTemplatesModal';
import { TenureApprovalsList } from '../components/TenureApprovalsList';
import { TenureAttachmentsList } from '../components/TenureAttachmentsList';
import { SendTenureNotificationModal } from '../components/modals/SendTenureNotificationModal';
import { UnassignApprovalTemplatesModal } from '../components/modals/UnassignApprovalTemplatesModal';
import { Command } from '../components/CommandButtons';
import { API_ROUTES } from '../constants/apiRoutes';
import { useBRFetch } from '../hooks/useBRFetch';
import { PERMISSION_TYPES } from '../helpers/hasPermission';

interface Props {
  uuid: string;
  tenureUuid: string;
  // DocumentPageProps
  tab?: string;
  step?: string;
}

export function TenureEditorPage(props: Props) {
  const { session } = React.useContext(SessionContext);
  const [dirty, setDirty] = React.useState(false);
  const [documentEditor, documentDispatch] = useReducer(documentEditorReducer, {});
  const [makeRequest] = useBRFetch();
  const document = documentEditor.document instanceof TenureDocument ? documentEditor.document : undefined;
  const personUuid = props.uuid;
  const tenureUuid = props.tenureUuid;
  const personUrlRoot = '/v0/people';

  const fetchDataParams = useMemo(() => ({ url: personUrlRoot, resourceId: personUuid }), [props.uuid]);

  const personStore = useBRStore<IPerson>({ fetchDataParams });
  const person = personStore.data;

  useEffect(() => { personStore.fetch(); }, [personStore.fetch]);

  const breadcrumbs = [
    {
      path: session.router.getUrl({ name: ROUTES.people }) || '',
      breadcrumbName: 'People',
    },
    {
      path: session.router.getUrl({ name: ROUTES.person }, { uuid: props.uuid }) || '',
      breadcrumbName: `${person.firstName} ${person.lastName}`,
    },
    {
      path: session.router.getUrl({ name: ROUTES.tenure }, { tenureUuid, uuid: props.uuid }) || '',
      breadcrumbName: tenureUuid === 'new' ? `Create ${CLIENT_CONST.TENURE_TERM}` : `${CLIENT_CONST.TENURE_TERM} #${document?.field('tenureNumber')}`,
    },
  ];

  // Set up the appropriate document for a tenure editor
  const getTenureEditorDocument = async () => {
    const data = await makeRequest(`${API_ROUTES.tenures}${tenureUuid}`);
    const tenure: ITenure = data.documentInterface || {
      uuid: tenureUuid || '',
      person: personUuid || '',
      createdDate: Date.now(),
    };
    documentDispatch({
      dispatch: documentDispatch,
      type: 'documentData',
      document: getDocumentSpecFromITenureAndWizard(tenure, data.wizard),
    });
    personStore.fetch();
  };

  useEffect(
    () => {
      if (props.tenureUuid) {
        getTenureEditorDocument();
      }
    },
    [documentDispatch, props.tenureUuid],
  );

  const approvalsStore = useBRStore<ITenureApprovalStatuses>({
    fetchDataParams: useMemo(() => ({ url: `/v0/tenures/${tenureUuid}/approvalstatuses` }), [props.tenureUuid]),
  });
  const approvals = approvalsStore.data;

  const events = useBRTableStore<ICommand>({
    fetchDataParams: useMemo(() => ({ url: API_ROUTES.commands, queryParams: { resource: tenureUuid } }), [props.tenureUuid]),
  });

  const outbox = useBRTableStore<ICommand>({
    fetchDataParams: React.useMemo(() => ({ url: `/v0/tenures/${tenureUuid}/outbox` }), [props.tenureUuid]),
  });

  const editApprovalTemplatesSuccess = async () => {
    await approvalsStore.fetch();
    await personStore.fetch();
    getTenureEditorDocument();
  };

  if (!document) {
    return (
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin={true}/>} />
      </div>
    );
  }

  const commands: Command[] = [];

  if (tenureUuid !== 'new') {
    commands.push({
      title: `Edit ${CLIENT_CONST.TENURE_TERM} Length`,
      icon: <CalendarOutlined />,
      modal: (
        <EditTenureLengthModal
          tenureDocument={document}
          onSuccess={getTenureEditorDocument}
        />
      ),
    });
  }

  const docAsITenure = document.asITenure(true);

  if (tenureUuid !== 'new' && document.tenureStatus !== RESOURCE_STATUS.inactive) {
    commands.push({
      title: 'Assign Approval Items',
      icon: <PlusOutlined />,
      modal: (
        <AssignApprovalTemplatesModal
          tenure={docAsITenure}
          assignedApprovals={approvals.templates}
          onSuccess={editApprovalTemplatesSuccess}
        />
      ),
    });
    if (approvals?.templates?.length > 0) {
      commands.push({
        title: 'Remove Approval Items',
        icon: <MinusOutlined />,
        modal: (
          <UnassignApprovalTemplatesModal
            tenure={docAsITenure}
            assignedApprovals={approvals.templates}
            onSuccess={editApprovalTemplatesSuccess}
          />
        ),
      });
    }
  }

  commands.push({
    title: 'Send Initial Registration',
    icon: <MailOutlined />,
    modal: (
      <SendTenureNotificationModal
        tenure={docAsITenure}
        notificationType="Initial"
        onSuccess={outbox.fetch}
      />
    ),
  });

  commands.push({
    title: 'Send Change of Status',
    icon: <MailOutlined />,
    modal: (
      <SendTenureNotificationModal
        tenure={docAsITenure}
        notificationType="Status"
        onSuccess={outbox.fetch}
      />
    ),
  });

  commands.push({
    title: 'Send Termination',
    icon: <MailOutlined />,
    modal: (
      <SendTenureNotificationModal
        tenure={docAsITenure}
        notificationType="Termination"
        onSuccess={outbox.fetch}
      />
    ),
  });

  commands.push({
    title: 'Send Standard Approval',
    icon: <MailOutlined />,
    modal: (
      <SendTenureNotificationModal
        tenure={docAsITenure}
        notificationType="Approval"
        onSuccess={outbox.fetch}
      />
    ),
    enabled: docAsITenure.startDate != null && docAsITenure.startDate > 0 && docAsITenure.endDate != null && docAsITenure.endDate > 0,
  });

  commands.push({
    title: 'Send Change of Status Approval',
    icon: <MailOutlined />,
    modal: (
      <SendTenureNotificationModal
        tenure={docAsITenure}
        notificationType="StatusApproval"
        onSuccess={outbox.fetch}
      />
    ),
  });

  const wizardProps: WizardProps = documentEditor.document?.field('wizardProps');

  const tabs = [];
  let currentTab = props.tab;
  let wizard: JSX.Element | undefined = undefined;

  if (wizardProps) {
    if (props.step) {
      const step = wizardProps.steps.find(s => s.name === props.step);
      breadcrumbs.push({
        path: session.router.getUrl({ name: ROUTES.tenureWizard }, { tenureUuid, uuid: props.uuid, step: props.step }) || '',
        breadcrumbName: step ? step.title : 'Unknown Step',
      });
    }

    wizard = <Wizard current={props.step} dirty={dirty} setDirty={setDirty} {...wizardProps} />;

    tabs.push({
      name: `${document.editable ? 'Edit' : 'Review'} Form`,
      key: 'form',
      component: wizard,
    });
    if (!currentTab) {
      currentTab = 'form';
    }
  }

  if (tenureUuid !== 'new') {
    tabs.push({
      name: 'Approvals',
      key: 'approvals',
      component: (
        <TenureApprovalsList
          approvalStore={approvalsStore}
          getTenureEditorDocument={getTenureEditorDocument}
        />
      ),
    });
    tabs.push({
      name: 'Attachments',
      key: 'attachments',
      component: (
        <TenureAttachmentsList
          approvalStore={approvalsStore}
          tenure={document}
        />
      ),
    });
  }

  tabs.push({
    name: 'Outbox',
    key: 'outbox',
    component: (
      <BRTable
        tableData={outbox}
        tableId={TABLE_IDS.TENURE_OUTBOX_TABLE}
        linkPath={ROUTES.outboxDetail}
      />
    ),
  });

  tabs.push({
    name: 'Audit Log',
    key: 'audit',
    permissions: PERMISSION_TYPES.audit,
    component: (
        <BRTable
          tableData={events}
          tableId={TABLE_IDS.TENURE_AUDIT_LOG}
          linkPath={ROUTES.command}
        />
      ),
  });

  const tenureDescriptionItems = tenureUuid === 'new' ? undefined : {
    'Start Date': document.field('dateRange')?.[0] ? new Date(document.field('dateRange')[0]).toLocaleString('en-US', DATE_OPTIONS) : 'N/A',
    'End Date': document.field('dateRange')?.[1] ? new Date(document.field('dateRange')[1]).toLocaleString('en-US', DATE_OPTIONS) : 'N/A',
  };

  return (
    <DocumentEditorContext.Provider value={{ documentEditor, documentDispatch }}>
      <BRLayout
        commands={tenureUuid !== 'new' ? commands : []}
        breadcrumbs={breadcrumbs}
        loading={false}
        tags={tenureUuid !== 'new' ? document.statusTag : undefined}
        title={(`${CLIENT_CONST.TENURE_TERM} for ${person.firstName} ${person.lastName}`)}
        descriptionItems={tenureDescriptionItems}
      >
        { tenureUuid === 'new' ?
          wizard : (
            <TabMenu route={ROUTES.tenure} tabs={tabs} />
          )
        }
      </BRLayout>
    </DocumentEditorContext.Provider>
  );
}
