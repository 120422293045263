import * as React from 'react';
import { CLIENT_CONST, TABLE_IDS } from '../constants';
import { BRLayout } from '../components/BRLayout';
import { IReport } from '../types/IReport';
import BRTable from '../components/BRTable';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { RerunReportModal } from '../components/modals/RerunReportModal';
import { RunCourseReportModal } from '../components/modals/RunCourseReportModal';
import { RunRequirementReportModal } from '../components/modals/RunRequirementReportModal';
import { RunRCRReportModal } from '../components/modals/RunRCRReportModal';
import { ROUTES } from '../routes';
import { TableRowSelection } from 'antd/lib/table/interface';
import { BarsOutlined, CalendarOutlined, DatabaseOutlined, RedoOutlined, UserOutlined } from '@ant-design/icons';
import { checkCommands } from '../helpers/checkCommands';
import { Command } from '../components/CommandButtons';
import { RunTenureSponsorReportModal } from '../components/modals/RunTenureSponsorReportModal';

interface IProps {

}

export function ReportListPage(props: IProps) {

  const reports = useBRTableStore<IReport>({
    fetchDataParams: { url: '/v0/reports' },
  });

  const [selected, setSelected] = React.useState([] as IReport[]);

  const allReportCommands: Command[] = [
    {
      title: 'Course Report',
      icon: <CalendarOutlined />,
      type: 'courseReport',
      modal: <RunCourseReportModal />,
    },
    {
      title: 'Requirement Report',
      icon: <DatabaseOutlined />,
      type: 'requirementReport',
      modal: <RunRequirementReportModal />,
    },
    {
      title: 'RCR Report',
      icon: <BarsOutlined />,
      type: 'rcrReport',
      modal: <RunRCRReportModal />,
    },
    {
      title: `${CLIENT_CONST.TENURE_TERM} PI/Sponsor Report`,
      icon: <UserOutlined />,
      type: 'tenureSponsorReport',
      modal: <RunTenureSponsorReportModal />,
    },
    {
      title: 'Run Report Again',
      icon: <RedoOutlined />,
      type: 'rerunReport',
      modal: (
        <RerunReportModal
          report={selected[0]}
        />
      ),
    },
  ];

  const rowSelection: TableRowSelection<any> = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    },
    type: 'radio',
  };

  const commands = checkCommands(allReportCommands, reports);

  return (
    <BRLayout
      commands={commands}
      title="Reports"
    >
      <BRTable
        linkPath={ROUTES.report}
        tableId={TABLE_IDS.REPORT_PAGE_TABLE}
        tableData={reports}
        rowSelection={rowSelection}
      />
    </BRLayout>
  );
}
