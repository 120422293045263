import * as React from 'react';
import { List, Avatar, message, Button } from 'antd';
import { DATE_OPTIONS, UI_COLORS } from '../../constants';
import { IExemption, IPerson, IPersonRequirement } from '../../types';
import { SessionContext } from '../App';
import { Link } from '../../hooks/useRoutes';
import { ROUTES } from '../../routes';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { ModalContext } from '../BRModal';
import { RemoveExemptionFromPersonModal } from '../modals/RemoveExemptionFromPersonModal';
import { BRStore } from '../../hooks/useBRStore';
import { BRListData } from '../../hooks/useBRListData';

interface Props {
  pr: BRStore<IPersonRequirement>;
  person: BRStore<IPerson>;
  requirements: BRListData<IPersonRequirement>;
  requirement?: string;
}

export function CoursesExemptedTable(props: Props) {

  const { session } = React.useContext(SessionContext);
  const [modal, setModal] = React.useState<React.ReactNode>();

  const closeModal = () => { setModal(undefined); };

  const formattedExemptions = React.useMemo(
    () => props.pr.data?.exemptions?.map(c => ({
      uuid: c.course && c.course.uuid,
      name: c.course && c.course.name,
      exemptionDate: c.course && c.exemptionDate,
    })) ?? [],
    [props.pr.data]);

  const listItem = (course: any) => {
    const courseLink = session.router.getUrl(
      { name: ROUTES.course },
      course,
    );

    const exemptionDate = course.exemptionDate && (new Date(course.exemptionDate))
                                                    .toLocaleDateString('en-US', DATE_OPTIONS);
    const description = `Exempted on ${exemptionDate}`;

    const onSuccess = () => {
      message.success('Remove exemption completed successfully.');
      props.pr.fetch();
      props.person.fetch();
      props.requirements.fetch();
      closeModal();
    };
    const openRemoveModal = () => {
      const removeModal = (
        <RemoveExemptionFromPersonModal
          person={props.person.data}
          requirement={props.requirement}
          course={course.uuid}
          onSuccess={onSuccess}
        />
      );
      setModal(removeModal);
    };

    return (
        <List.Item
          actions={[(
            <Button
              danger={true}
              icon={<DeleteOutlined />}
              onClick={openRemoveModal}
            />
          )]}
        >
          <List.Item.Meta
            avatar={(
              <Avatar
                icon={<CheckOutlined />}
                style={{ backgroundColor: UI_COLORS.deepBlue }}
              />
            )}
            title={<Link href={courseLink}>{course.name}</Link>}
            description={description}
          />
        </List.Item>
    );
  };

  return (
    <>
      <List
        dataSource={formattedExemptions}
        locale={{ emptyText: 'No Exemptions' }}
        renderItem={listItem}
      />
      {!!modal && (
        <ModalContext.Provider value={{ close: closeModal, onSuccess: closeModal, visible: !!modal }}>
          {modal}
        </ModalContext.Provider>
      )}
    </>
  );
}
