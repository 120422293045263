import React from 'react';
import { DatePicker, Button } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

export interface DateDropdownProps extends FilterDropdownProps {
  showTime?: boolean;
}

export function DateDropdown(props: DateDropdownProps) {

  const {
    selectedKeys,
    setSelectedKeys,
    confirm,
    showTime,
    clearFilters,
  } = props;

  const [date1, date2] = selectedKeys.map(key => moment(key));

  const onSubmit = () => {
    if (confirm) {
      confirm();
    }
  };

  const onDateRangeChange = (dates: RangeValue<moment.Moment> | null, dateString: string[]) => {
    if (dates) {
      const [date1, date2] = dates.map(moment => moment?.toISOString());
      setSelectedKeys([date1 || '', date2 || '']);
    } else {
      reset();
    }
  };

  const reset = () => {
    if (clearFilters) {
      clearFilters();
    }
  };

  return (
    <div style={{ padding: 8 }}>
      <div style={{ paddingBottom: 8 }}>
        <DatePicker.RangePicker
          value={(date1 && date2) ? [date1, date2] : undefined}
          style={{ width: '100%' }}
          showTime={showTime ? { format: 'HH:mm' } : false}
          onChange={onDateRangeChange}
          placeholder={['From', 'To']}
        />
      </div>
      <Button
        type="primary"
        onClick={onSubmit}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={reset} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  );
}
