import { handleResponse } from '../helpers/responseHandler';
import { CLIENT_CONST } from '../constants';
import { createToken } from '../helpers/tokenAuth';

export const authServices = {
  logout,
  login,
  ssoLogin,
  oauthLogin,
  impersonate,
  deimpersonate,
};

async function login(username: string, password: string) {
  const source = '/api/sessions';
  const grantType = 'password';

  return await fetch(source, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password, grant_type: grantType }),
  });
}

async function oauthLogin(accessToken: string) {
  const source = '/api/sessions';

  const response = await fetch(source, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      access_token: accessToken,
      grant_type: CLIENT_CONST.AUTH_METHOD,
      redirect_uri: window.location.origin,
    }),
  });
  const handledResponse = await handleResponse(response);
  if (handledResponse.person) {
    localStorage.setItem('user', JSON.stringify(handledResponse.person));
    localStorage.setItem('token', handledResponse.access_token);
    localStorage.setItem('scope', handledResponse.scope);
    localStorage.setItem('key', handledResponse.mac_key);
  }
  return handledResponse;
}

async function ssoLogin() {
  const source = '/api/sessions';
  const grantType = 'sso';

  const response = await fetch(source, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ grant_type: grantType }),
  });
  const handledResponse = await handleResponse(response);
  if (handledResponse.person) {
    localStorage.setItem('user', JSON.stringify(handledResponse.person));
    localStorage.setItem('token', handledResponse.access_token);
    localStorage.setItem('scope', handledResponse.scope);
    localStorage.setItem('key', handledResponse.mac_key);
  }
  return handledResponse;
}

async function impersonate(uuid: string) {
  const source = '/api/sessions';

  const props = {
    uri: source,
    method: 'POST',
    mac_key: localStorage.getItem('key'),
    access_token: localStorage.getItem('token'),
  };

  const response = await fetch(source, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: createToken(props),
    },
    credentials: 'include',
    body: JSON.stringify({
      grant_type: 'impersonate',
      person: uuid,
    }),
  });
  const handledResponse = await handleResponse(response);
  if (handledResponse.person) {
    localStorage.setItem('user', JSON.stringify(handledResponse.person));
    localStorage.setItem('token', handledResponse.access_token);
    localStorage.setItem('scope', handledResponse.scope);
    localStorage.setItem('key', handledResponse.mac_key);
  }
  return handledResponse;
}

async function deimpersonate() {
  const source = '/api/sessions';

  const props = {
    uri: source,
    method: 'POST',
    mac_key: localStorage.getItem('key'),
    access_token: localStorage.getItem('token'),
  };

  const response = await fetch(source, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: createToken(props),
    },
    credentials: 'include',
    body: JSON.stringify({
      grant_type: 'deimpersonate',
    }),
  });
  const handledResponse = await handleResponse(response);
  if (handledResponse.person) {
    localStorage.setItem('user', JSON.stringify(handledResponse.person));
    localStorage.setItem('token', handledResponse.access_token);
    localStorage.setItem('scope', handledResponse.scope);
    localStorage.setItem('key', handledResponse.mac_key);
  }
  return handledResponse;
}

function logout() {
  // remove user, token, and key from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('scope');
  localStorage.removeItem('key');
}
