import React, { createContext, useEffect, useReducer, ComponentType, useState, ReactElement, Dispatch, SetStateAction } from 'react';
import { Steps } from 'antd';
import { Step } from './Step';
import { Document, DocumentPredicate } from '../../resources/Document';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { SessionContext } from '../App';
import { IWizardStepSchema, IWizardFieldSchema } from '../../types/IWizardSchema';
import { CheckboxField } from './fields/CheckboxField';
import { DateField } from './fields/DateField';
import { FilesField } from './fields/FilesField';
import { RadioField } from './fields/RadioField';
import { SelectField } from './fields/SelectField';
import { TextField } from './fields/TextField';
import { TextAreaField } from './fields/TextAreaField';
import { YesNoField } from './fields/YesNoField';
import { SearchField } from './fields/SearchField';
import { DateRangeField } from './fields/DateRangeField';
import { DocumentSpec } from '../../types/DocumentSpec';

export interface ValidateProps {
  value: any;
  document: Document;
}

type ValidatePredicate = (props: ValidateProps) => boolean;
type SelectOptionPredicate = () => ReactElement[];
export type DocumentError = (d: Document) => string;

export enum WIZARD_FIELD_TYPES {
  checkbox = 'checkbox',
  date = 'date',
  dateRange = 'dateRange',
  files = 'files',
  radio = 'radio',
  search = 'search',
  select = 'select',
  textArea = 'textArea',
  text = 'text',
  yesNo = 'yesNo',
}

export interface WizardField {
  name: string;
  required?: boolean | DocumentPredicate;
  show?: boolean | DocumentPredicate;
  validate?: ValidatePredicate;
  readOnly?: boolean;
  editable?: boolean;
  clear?: boolean;
  label: string;
  help?: string | ReactElement;
  options?: string[] | CheckboxOptionType[];
  selectOptions?: ReactElement[] | SelectOptionPredicate;
  defaultOption?: string;
  field: ComponentType<any>;
}

export interface WizardStep {
  name: string;
  title: string;
  error?: string | DocumentError;
  intro?: string;
  fields: WizardField[];
}

export interface WizardProps {
  current?: string;
  uuid: string;
  routeName: any;
  routeProps: any;
  steps: WizardStep[];
  dirty?: boolean;
  setDirty?: Dispatch<SetStateAction<boolean>>;
  getDocument: (arg0: any, arg1: any) => DocumentSpec;
}

interface ValidState {
  [ index: string ]: boolean;
}

const defaultContext = {
  page: '',
  steps: [],
  valid: {},
  isNew: false,
  setValid: (value: boolean) => {},
};

interface WizardContext {
  page: string;
  steps: WizardStep[];
  valid: ValidState;
  isNew: boolean;
  setValid: (value: boolean) => void;
  dirty?: boolean;
  setDirty?: Dispatch<SetStateAction<boolean>>;
}

export const WizardContext = createContext<WizardContext>(defaultContext);

export function buildWizardFromData(uuid: string, routeName: any, routeProps: any, getDocument: (arg0: any, arg1: any) => DocumentSpec, steps: any): WizardProps {
  return {
    uuid,
    routeName,
    routeProps,
    getDocument,
    steps: steps ? steps.map((step: IWizardStepSchema) => {
      const { fields: fieldList, ...otherStepProps } = step;
      return {
        ...otherStepProps,
        fields: fieldList.map((field: IWizardFieldSchema) => {
          let fieldType = null;
          const { type: fieldTypeName, ...otherFieldProps } = field;
          switch (fieldTypeName) {
            case WIZARD_FIELD_TYPES.checkbox:
              fieldType = CheckboxField;
              break;
            case WIZARD_FIELD_TYPES.date:
              fieldType = DateField;
              break;
            case WIZARD_FIELD_TYPES.dateRange:
              fieldType = DateRangeField;
              break;
            case WIZARD_FIELD_TYPES.files:
              fieldType = FilesField;
              break;
            case WIZARD_FIELD_TYPES.radio:
              fieldType = RadioField;
              break;
            case WIZARD_FIELD_TYPES.search:
              fieldType = SearchField;
              break;
            case WIZARD_FIELD_TYPES.select:
              fieldType = SelectField;
              break;
            case WIZARD_FIELD_TYPES.text:
              fieldType = TextField;
              break;
            case WIZARD_FIELD_TYPES.textArea:
              fieldType = TextAreaField;
              break;
            case WIZARD_FIELD_TYPES.yesNo:
              fieldType = YesNoField;
              break;
            default:
              throw new Error(`Unrecognized wizard field type: ${field.type}`);
          }
          return {
            ...otherFieldProps,
            field: fieldType,
          };
        }),
      };
    }) : [],
  };
}

export const Wizard = (props: WizardProps) => {
  const { session } = React.useContext(SessionContext);
  const [validList, setValidList] = useState([] as boolean[]);

  const { uuid } = props;
  const page = props.current || props.steps[0]?.name;
  const step = props.steps.find(s => s.name === page);
  const index = step ? props.steps.indexOf(step) : -1;

  const defaultValidState: ValidState = {};

  const validReducer = (state: ValidState, update: ValidState) => {
    return {
      ...state,
      ...update,
    };
  };

  const [valid, setValid] = useReducer(validReducer, defaultValidState);

  const onChange = (i: number) => {
    session.router.go({ name: props.routeName }, { ...props.routeProps, step: props.steps[i].name });
  };

  const makeSetValid = (name: string) =>
    (value: boolean) => {
      setValid({ [name]: value });
    };

  useEffect(
    () => {
      /* let prevPage;
      const step = props.steps.find(s => s.name === page);
      if (step) {
        const index = props.steps.indexOf(step);
        prevPage = props.steps[index - 1] ? props.steps[index - 1].name : undefined;
      } */
      setValidList(props.steps.map(step => valid[step.name]));

    },
    [page, uuid, valid, props.steps],
  );

  return (
    <div className="wizard">
      <Steps
        direction="vertical"
        current={index}
        onChange={onChange}
        progressDot={props.steps.length === 1 ? () => <></> : false}  // Hide numbered progress dot if there's only one step
      >
        {props.steps.map((step, i) => (
          <Steps.Step
            key={step.name}
            title={props.steps.length > 1 ? step.title : ''}  // Hide step title if there's only one step
            disabled={validList.slice(0, i).includes(false)}
            description={(
              <WizardContext.Provider key={step.name} value={{ page, valid, dirty: props.dirty, setDirty: props.setDirty, steps: props.steps, setValid: makeSetValid(step.name), isNew: uuid === 'new' }}>
                <Step {...step} {...props}  />
              </WizardContext.Provider>
            )}
          />
        ))}
      </Steps>
    </div>
  );
};
