import * as React from 'react';
import { Descriptions } from 'antd';
import { DescriptionsProps } from 'antd/lib/descriptions';

interface DescriptionsItems {
  [label: string]: React.ReactElement | string | number | undefined;
}

interface Props extends DescriptionsProps {
  items: DescriptionsItems;
}

export function PageDescriptions(props: Props) {

  const getDescriptionItems = (items: DescriptionsItems) => {
    return Object.entries(items).map(([k, v]) => (
      <Descriptions.Item key={k} label={k}>{v}</Descriptions.Item>
    ));
  };

  return (
    <Descriptions
      title={props.title}
      bordered={true}
      size="small"
      {...props}
    >
      {getDescriptionItems(props.items)}
    </Descriptions>
  );
}
