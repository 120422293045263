import React, { useState, useEffect } from 'react';
import { Alert, Form, message, Modal, Space } from 'antd';

import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRLargeDataSelect } from '../BRForm';
import { IPerson } from '../../types';
import { IBasicPerson } from '../../types/IBasicPerson';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { useBRStore } from '../../hooks/useBRStore';
import { PersonCard } from '../PersonCard';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface Props extends BRModalProps {
  person: IPerson;
}

const fetchDataParams = { url: '/v0/people', queryParams: { basic: true } };

export const LinkPersonModal: React.FunctionComponent<Props> =
({ person, ...modalProps }) => {

  const unlinked = useBRListData<IBasicPerson>({ fetchDataParams });

  useEffect(() => { unlinked.fetch(); }, [unlinked.fetch]);

  const [account, setAccount] = useState('');
  const [comments, setComments] = useState('');

  const selectedPersonFetchDataParams = React.useMemo(() => ({ url: `/v0/people/${account}` }), [account]);
  const selectedPersonStore = useBRStore<IPerson>({ fetchDataParams: selectedPersonFetchDataParams });
  const selectedPerson = selectedPersonStore.data;
  const selectedDescriptionItems = selectedPersonStore.descriptionItems;

  useEffect(() => { selectedPersonStore.fetch(); }, [account, selectedPersonStore.fetch]);

  const onFinish = async () => {
    const formData = {
      comments,
      primary: person.uuid,
      subordinate: account,
    };

    await sendCommand({
      formData,
      command: COMMANDS.LINK_PERSON,
    });
  };

  const unlinkedOptions = unlinked.records.filter(a => a.uuid !== person.uuid)
    .sort(
      (a, b) => {
        if (a.createdBy && b.createdBy) {
          if (a.createdBy > b.createdBy) return 1;
          if (a.createdBy < b.createdBy) return -1;
        }
        return 0;
      })
    .map(
      (a) => {
        const label = `[${a.createdBy}] ${a.lastName}, ${a.firstName} (${a.primaryEmail})`;
        return {
          label,
          value: a.uuid || '',
          key: a.uuid,
        };
      },
    );

  return (
    <BRModal
      {...modalProps}
      title="Link Person"
      okText="Link Person"
      formProps={{ onFinish }}
    >
      <Form.Item label="Person">
        <Space direction="vertical" style={{ width: '100%' }}>
          <BRLargeDataSelect
            options={unlinkedOptions}
            setValue={setAccount}
            loading={unlinked.loading}
          />
          {selectedPerson?.uuid && (
            <>
              <Alert message="This action cannot be undone. The person record described below will no longer be independently accessible after this action. Please cancel if you are unsure how to proceed." type="warning" />
              <PersonCard person={selectedPerson} descriptionItems={selectedDescriptionItems} />
            </>
          )}
        </Space>
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments}/>

    </BRModal>
  );
};
