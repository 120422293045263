import React, { ChangeEvent, useState } from 'react';
import { Form, Typography } from 'antd';
import { FormProps } from 'antd/lib/form';
import { COMMANDS } from '../../constants';
import { BRModal, BRModalProps } from '../BRModal';
import { sendCommand } from '../../services/commands.services';
import TextArea from 'antd/lib/input/TextArea';
import { EQUIVALENCY_STATUS, IEquivalency } from '../../types/IEquivalency';

interface Props extends BRModalProps {
  equivalency: IEquivalency;
}

export const RespondToEquivalencyModal: React.FunctionComponent<Props> =
({ equivalency, ...modalProps }) => {
  const [comment, setComment] = useState(equivalency.learnerComment);

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setComment(value);
  };

  const onFinish = async () => {
    const formData: any = {};
    formData.uuid = equivalency.uuid;
    // Update status to let the reviewer know that the learner has responded
    formData.status = EQUIVALENCY_STATUS.pending;
    formData.learnerComment = comment;

    await sendCommand({
      formData,
      command: COMMANDS.UPDATE_EQUIVALENCY,
    });
  };

  return (
    <BRModal
      {...modalProps}
      title={'Respond to Equivalency Request'}
      formProps={{ onFinish }}
    >
      <Form.Item label="Reviewer Comment">
        <Typography.Text>{equivalency.reviewerComment}</Typography.Text>
      </Form.Item>
      <Form.Item label="Response">
        <TextArea
          value={comment}
          rows={8}
          onChange={handleCommentChange}
        />
      </Form.Item>
    </BRModal>
  );
};
