import React, { useState } from 'react';
import { Alert, Form } from 'antd';

import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { IPerson, ICourse, IExemption } from '../../types';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { BRTableStore, useBRTableStore } from '../../hooks/useBRTableStore';

interface Props extends BRModalProps {
  requirement?: string;
  course?: string;
  person: IPerson;
}

export const RemoveExemptionFromPersonModal: React.FunctionComponent<Props> =
({ person, ...modalProps }) => {

  const [requirement, setRequirement] = useState(modalProps.requirement ?? '');
  const [course, setCourse] = useState(modalProps.course ?? '');
  const [courseIdsError, setCourseIdsError] = useState('');
  const [reqIdError, setReqIdError] = useState('');
  const [comments, setComments] = useState('');

  const exemptions = useBRTableStore<IExemption>({
    fetchDataParams: React.useMemo(() => ({ url: `/v0/people/${person.uuid}/exemptions` }), [person]),
  });

  React.useEffect(
    () => {
      if (!modalProps.requirement || !modalProps.course) {
        exemptions.fetch();
      }
    },
    [exemptions.fetch]);

  const onFinish = async () => {
    setCourseIdsError('');
    setReqIdError('');
    if (!requirement) {
      setReqIdError('A requirement must be selected');
      return;
    }
    if (!course) {
      setCourseIdsError('A course must be selected');
      return;
    }

    const formData = {
      course,
      comments,
      requirement,
      person: person.uuid,
    };

    await sendCommand({
      formData,
      command: COMMANDS.REMOVE_EXEMPTION_FROM_PERSON,
    });
  };

  const selectedExemptions = exemptions?.records?.filter(e => e.requirement.uuid === requirement);

  const requirementOptions = React.useMemo(
    () => {
      const out: any[] = [];
      if (exemptions.records) {
        for (const e of exemptions.records) {
          if (!out.find(existing => existing.key === e.requirement.uuid)) {
            out.push({
              key: e.requirement.uuid,
              value: e.requirement.uuid || '',
              label: e.requirement.name,
            });
          }
        }
      }
      return out;
    },
    [exemptions.records]);

  const courseOptions = React.useMemo(
    () => {
      return selectedExemptions?.map(
        ex => ({
          key: ex.course.uuid,
          label: ex.course.name,
          value: ex.course.uuid || '',
        }));
    },
    [exemptions.records, requirement]);

  const title = `Remove Exemption from ${person.firstName} ${person.lastName}`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Remove Exemption"
      formProps={{ onFinish }}
    >
      {(!modalProps.course || !modalProps.requirement) && (
        <>
          <Form.Item label="For Requirement:">
            <BRSelect
              setValue={setRequirement}
              options={requirementOptions}
            />
            {reqIdError && <Alert message={reqIdError} type="warning" />}
          </Form.Item>

          <Form.Item label="And Course:">
            <BRSelect
              setValue={setCourse}
              options={courseOptions}
            />
            {courseIdsError && <Alert message={courseIdsError} type="warning" />}
          </Form.Item>

          <Form.Item label="From Person:">
            <span className="ant-form-text">
              {`${person.firstName} ${person.lastName}`}
            </span>
          </Form.Item>
        </>
      )}

      <BRFormComments comments={comments} setComments={setComments}/>

    </BRModal>
  );
};
