import React, { useContext } from 'react';
import { FieldProps, Field } from '../Field';
import { StepContext } from '../Step';
import { BRSelect } from '../../BRForm';
import { DocumentEditorContext } from '../../../types/DocumentEditor';

interface SelectFieldProps extends FieldProps {
  options: any[];
  defaultOption?: string;
}

export const SelectField = (props: SelectFieldProps) => {
  const { documentEditor } = useContext(DocumentEditorContext);
  const { value, setValue } = useContext(StepContext);

  if (!documentEditor.document) {
    return null;
  }

  const setSelectValue = (value: string) => {
    if (!documentEditor.document) {
      return;
    }

    setValue(value);
  };

  const getOptions = () => {
    return (props.options).map(
      p => ({
        // p can be either an obect with an "id" and/or "name" field, or a string
        key: p.id || p.name || p,
        value: p.id || p.name || p,
        label: p.name || p.id || p,
      }),
    );
  };

  const listOptions = getOptions();
  let valueIndex = -1;
  let defaultIndex = -1;

  listOptions.forEach((val, index) => {
    if (val.key === value) {
      valueIndex = index;
    }
    if (val.key === props.defaultOption) {
      defaultIndex = index;
    }
  });

  return (
    <Field {...props}>
      <BRSelect
        setValue={setSelectValue}
        options={listOptions}
        value={valueIndex >= 0 ? listOptions[valueIndex] : defaultIndex >= 0 ? listOptions[defaultIndex] : undefined}
        disabled={!documentEditor.document.editable || props.readOnly === true || !!props.readOnly}
        style={{ width: '100%' }}
      />
    </Field>
  );
};
