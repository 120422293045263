import React, { useEffect, useReducer, useMemo } from 'react';
import { Spin } from 'antd';
import { TabMenu } from '../components/TabMenu';
import { BRLayout } from '../components/BRLayout';
import {
  ICommand,
  IPerson,
  ITenureRequest,
} from '../types';
import { TABLE_IDS, DATE_OPTIONS, CLIENT_CONST } from '../constants';
import { useBRTableStore } from '../hooks/useBRTableStore';
import BRTable from '../components/BRTable';
import { Wizard, WizardProps } from '../components/Wizard/Wizard';
import { DocumentEditorContext, documentEditorReducer } from '../types/DocumentEditor';
import { SessionContext } from '../components/App';
import { ROUTES } from '../routes';
import { LoadingOutlined } from '@ant-design/icons';
import { useBRStore } from '../hooks/useBRStore';
import { Command } from '../components/CommandButtons';
import { TenureRequestDocument, getDocumentSpecFromITenureRequestAndWizard } from '../resources/TenureRequestDocument';
import { API_ROUTES } from '../constants/apiRoutes';
import { useBRFetch } from '../hooks/useBRFetch';
import { PERMISSION_TYPES } from '../helpers/hasPermission';

interface Props {
  uuid: string;
  tenureRequestUuid: string;
  // DocumentPageProps
  tab?: string;
  step?: string;
}

export function TenureRequestEditorPage(props: Props) {
  const { session } = React.useContext(SessionContext);
  const [documentEditor, documentDispatch] = useReducer(documentEditorReducer, {});
  const [makeRequest] = useBRFetch();
  const document = documentEditor.document instanceof TenureRequestDocument ? documentEditor.document : undefined;
  const personUuid = props.uuid;
  const tenureRequestUuid = props.tenureRequestUuid;
  const personUrlRoot = '/v0/people';

  const fetchDataParams = useMemo(() => ({ url: personUrlRoot, resourceId: personUuid }), [props.uuid]);

  const personStore = useBRStore<IPerson>({ fetchDataParams });
  const person = personStore.data;

  useEffect(() => { personStore.fetch(); }, [personStore.fetch]);

  const breadcrumbs = [
    {
      path: session.router.getUrl({ name: ROUTES.people }) || '',
      breadcrumbName: 'People',
    },
    {
      path: session.router.getUrl({ name: ROUTES.person }, { uuid: props.uuid }) || '',
      breadcrumbName: `${person.firstName} ${person.lastName}`,
    },
    {
      path: session.router.getUrl({ name: ROUTES.tenure }, { tenureUuid: tenureRequestUuid, uuid: props.uuid }) || '',
      breadcrumbName: tenureRequestUuid === 'new' ? `Create ${CLIENT_CONST.TENURE_TERM} Request` : `${CLIENT_CONST.TENURE_TERM} Request`,
    },
  ];

  // Set up the appropriate document for a tenure editor
  const getTenureRequestEditorDocument = () => {
    makeRequest(`${API_ROUTES.tenureRequests}${tenureRequestUuid}`).then(async (data) => {
      const tenureRequest: ITenureRequest = data.documentInterface || {
        uuid: tenureRequestUuid || '',
        owner: personUuid || '',
        createdDate: Date.now(),
      };
      documentDispatch({
        dispatch: documentDispatch,
        type: 'documentData',
        document: getDocumentSpecFromITenureRequestAndWizard(tenureRequest, data.wizard),
      });
    });
  };

  useEffect(
    () => {
      if (props.tenureRequestUuid) {
        getTenureRequestEditorDocument();
      }
    },
    [documentDispatch, props.tenureRequestUuid],
  );

  const events = useBRTableStore<ICommand>({
    fetchDataParams: useMemo(() => ({ url: API_ROUTES.commands, queryParams: { resource: tenureRequestUuid } }), [props.tenureRequestUuid]),
  });

  if (!document) {
    return (
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin={true}/>} />
      </div>
    );
  }

  const commands: Command[] = [];

  const wizardProps: WizardProps = documentEditor.document?.field('wizardProps');

  const tabs = [];
  let currentTab = props.tab;
  let wizard: JSX.Element | undefined = undefined;

  if (wizardProps) {
    if (props.step) {
      const step = wizardProps.steps.find(s => s.name === props.step);
      breadcrumbs.push({
        path: session.router.getUrl({ name: ROUTES.tenureRequestWizard }, { tenureRequestUuid, uuid: props.uuid, step: props.step }) || '',
        breadcrumbName: step ? step.title : 'Unknown Step',
      });
    }

    wizard = <Wizard current={props.step} {...wizardProps} />;

    tabs.push({
      name: `${document.editable ? 'Edit' : 'Review'} Form`,
      key: 'form',
      component: wizard,
    });
    if (!currentTab) {
      currentTab = 'form';
    }
  }

  if (tenureRequestUuid !== 'new') {
    tabs.push({
      name: 'Audit Log',
      key: 'audit',
      permissions: PERMISSION_TYPES.audit,
      component: (
          <BRTable
            tableData={events}
            tableId={TABLE_IDS.TENURE_REQUEST_AUDIT_LOG}
            linkPath={ROUTES.command}
          />
        ),
    });
  }

  const tenureRequestDescriptionItems = {
    'Start Date': document.field('startDate') ? new Date(document.field('startDate')).toLocaleString('en-US', DATE_OPTIONS) : 'N/A',
    'End Date': document.field('endDate') ? new Date(document.field('endDate')).toLocaleString('en-US', DATE_OPTIONS) : 'N/A',
  };

  return (
    <DocumentEditorContext.Provider value={{ documentEditor, documentDispatch }}>
      <BRLayout
        commands={tenureRequestUuid !== 'new' ? commands : []}
        breadcrumbs={breadcrumbs}
        loading={false}
        tags={tenureRequestUuid !== 'new' ? document.statusTag : undefined}
        title={(`${CLIENT_CONST.TENURE_TERM} Request for ${person.firstName} ${person.lastName}`)}
        descriptionItems={tenureRequestDescriptionItems}
      >
        { tenureRequestUuid === 'new' ?
          wizard : (
            <TabMenu route={ROUTES.tenureRequest} tabs={tabs} />
          )
        }
      </BRLayout>
    </DocumentEditorContext.Provider>
  );
}
