import React from 'react';
import qs from 'qs';
import { default as Route } from 'route-parser';
import { EventEmitter } from 'events';
import { IQueryStore } from '../types/storeTypes/IQueryStore';
import { PERMISSION_TYPES } from '../helpers/hasPermission';

export type Params = { [ index: string]: string };

export interface RouteSpec<K extends number> {
  path: string;
  fn?: (params: Params) => any;
  title?: string;
  children?: Routes<K, this>;
  permissions?: PERMISSION_TYPES[];
}

type CalculatedRouteSpec<K extends number, R extends RouteSpec<K>> = R & {
  id: K;
  parent: K;
  fullPath: string;
};

interface RouteResult<K extends number, T extends RouteSpec<K>> {
  name: K;
  spec: T;
  params: Params;
  result?: any;
  selected: CalculatedRouteSpec<K, T>[];
}

export interface RouteParams<K extends number> {
  name: K;
}

export interface RouteState<K extends number, T extends RouteSpec<K>> {
  go: (route: RouteParams<K> | string, params?: Params, options?: GoOptions) => void;
  getUrl: (route: RouteParams<K>, params?: Params, options?: URLOptions) => string | undefined;
  routes: Routes<K, T>;
  query: IQueryStore;
  hash?: string;
  current: RouteResult<K, T>;
}

export type Routes<K extends number, T> = {
  [key in K]?: T | false;
};

interface URLOptions {
  query?: any;
  hash?: string;
}

interface GoOptions extends URLOptions {
  query?: any;
  replace?: boolean;
}

class RouteEmitter extends EventEmitter {}

const routeEmitter = new RouteEmitter();

export function useRoutes<K extends number, T extends RouteSpec<K>>(routes: Routes<K, T>, prefix = '') {
  const routeObjects: { [ index: number ]: Route } = {};
  const routeSpecs: { [ index: number ]: CalculatedRouteSpec<K, T> } = {};

  const makeRoutes = (basePath: string, children: Routes<K, T>, parent?: K) => {
    for (const route in children) {
      if (children[route] === false) continue;
      const child = Object.assign({}, children[route]) as any;
      const fullPath = `${basePath}${child.path}`;
      child.id = route;
      child.parent = parent;
      routeObjects[route] = new Route(fullPath);
      if (parent != null) {
        const parentPermissions = routeSpecs[parent]?.permissions;
        if (parentPermissions) {
          const deduped = new Set([...(child.permissions ?? []), ...parentPermissions]);
          child.permissions = [...deduped];
        }
      }
      routeSpecs[route] = child;
      if (child.children) {
        makeRoutes(fullPath, child.children, route);
      }
    }
  };

  makeRoutes('', routes);

  const getUrl = ({ name }: RouteParams<K>,
                  params: Params = {},
                  { query, hash }: URLOptions = {}) => {
    if (routeObjects[name]) {
      const route = routeObjects[name];
      let path = prefix + (route.reverse(params || {}) || '');
      if (query) {
        const queryString = qs.stringify(query);
        if (queryString) {
          path += `?${queryString}`;
        }
        if (hash) {
          path += `#${hash}`;
        }
      }
      return path || '/';
    }
    return undefined;
  };

  const go = (route: RouteParams<K> | string, params: Params = {}, options: GoOptions = {}) => {
    const path = typeof route === 'string' ? route : getUrl(route, params, options);
    navigate(path || '', options.replace);
  };

  const getState: () => RouteState<K, T> = () => {
    let path = window.location.pathname.replace(prefix, '');
    if (path === '/') path = '';
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (query.columns) {
      Object.entries(query.columns).forEach(([k, v]) => {
        if (query && query.columns) query.columns[k] = (v === 'true');
      });
    }
    const hash = window.location.hash ? window.location.hash.substr(1) : undefined;

    for (const routeName in routeSpecs) {
      const spec = routeSpecs[routeName];
      const route = routeObjects[routeName];
      const params = route.match(path);
      const name = parseInt(routeName, 10) as K;

      if (params !== false) {
        const selected: CalculatedRouteSpec<K, T>[] = [];
        let curRoute: K | undefined = name;

        while (curRoute) {
          selected.unshift(routeSpecs[curRoute]);
          curRoute = routeSpecs[curRoute].parent;
        }

        return {
          go,
          getUrl,
          query,
          hash,
          routes,
          current: {
            path,
            name,
            spec,
            params,
            selected,
            id: routeName,
            result: spec.fn ? spec.fn.call(spec, params) : undefined,
          },
        };
      }
    }
    throw new Error('unhandled location');
  };

  const [state, setState] = React.useState(getState());

  const onChange = (event?: PopStateEvent) => setState(getState());

  React.useEffect(() => {
    window.addEventListener('popstate', onChange);
    routeEmitter.on('event', onChange);

    return () => {
      window.removeEventListener('popstate', onChange);
      routeEmitter.removeListener('event', onChange);
    };
  });

  return state;
}

export function navigate(path: string, replace = false) {
  setImmediate(() => {
    if (replace) {
      window.history.replaceState({}, path, path);
    } else {
      window.history.pushState({}, path, path);
    }
    routeEmitter.emit('event');
  });
}

export function setLinkProps(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!event.shiftKey && !event.ctrlKey && !event.altKey) {
      if (event.currentTarget instanceof HTMLAnchorElement) {
        event.preventDefault();
        navigate(event.currentTarget.href);
      }
      if (props.onClick) {
        props.onClick(event);
      }
    }
  };
  return { ...props, onClick } as any;
}

export const Link = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) =>
  <a {...setLinkProps(props)}>{props.children}</a>;
