import * as CryptoJS from 'crypto-js';
import { API_CONST } from '../constants';

interface Props {
  uri: string;
  method: string;
  mac_key: string | null;
  access_token: string | null;
}

const randChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';

const randStr = (length: number) => {
  const out = [];

  for (let i = 0; i < length; i += 1) {
    out.push(randChars.charAt(Math.floor(Math.random() * randChars.length)));
  }

  return out.join('');
};

export const createToken = (props: Props) => {

  const ts = Math.floor(Date.now() / 1000);
  const nonce = randStr(18);
  const str = `${ts}\n${nonce}\n${props.method || 'GET'}\n${''
  }${props.uri}\n${API_CONST.HOST}\n443\n\n`;
  // TODO change 443 back to API_HOST after resolving Docker issues

  const mac = CryptoJS.enc.Base64.stringify(
    CryptoJS.HmacSHA3(str, props.mac_key || ''),
  );

  return `MAC id="${props.access_token}", ts="${ts}", nonce="${nonce}", mac="${mac}"`;

};
