import React, { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, Form, DatePicker, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { ICourse, ICourseCategory } from '../../types';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import moment from 'moment';
import { useBRListData } from '../../hooks/useBRListData';
import { API_ROUTES } from '../../constants/apiRoutes';

interface Props extends BRModalProps {
  course: ICourse;
}

const courseCategoryDataParams = { url: API_ROUTES.courseCategories };

export const UpdateCourseModal: React.FunctionComponent<Props> =
({ course, ...modalProps }) => {

  const defaultRetirementDate = course.retirementDate ? moment(course.retirementDate) : null;

  const [retirementDate, setRetirementDate] = useState(defaultRetirementDate);
  const [retirementEdited, setRetirementEdited] = useState(false);
  const [categories, setCategories] = useState(course.categories?.map(c => c.uuid) || []);
  const [categoriesEdited, setCategoriesEdited] = useState(false);
  const [link, setLink] = useState(course.link);
  const [linkEdited, setLinkEdited] = useState(false);
  const [comments, setComments] = useState('');

  const courseCategories = useBRListData<ICourseCategory>({
    fetchDataParams: courseCategoryDataParams,
  });

  useEffect(
    () => {
      courseCategories.fetch();
    },
    [courseCategories.fetch]);

  const onFinish = async () => {
    const { uuid } = course;
    const formData = {
      uuid,
      comments,
      retirementDate: retirementEdited ? retirementDate : undefined,
      categories: categoriesEdited ? categories : undefined,
      link: linkEdited ? link : undefined,
    };

    await sendCommand({
      formData,
      command: COMMANDS.UPDATE_COURSE,
    });
  };

  const handleDateChange = (value: moment.Moment | null) => {
    setRetirementDate(value);
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { name, checked } = e.target;
    switch (name) {
      case 'retirementEdited':
        setRetirementEdited(checked);
        break;
      case 'categoriesEdited':
        setCategoriesEdited(checked);
        break;
      case 'linkEdited':
        setLinkEdited(checked);
        break;
      default:
        break;
    }
  };

  const handleLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  };

  const categoriesOptions = courseCategories.records &&
    courseCategories.records.map(c => ({
      key: c.uuid,
      label: c.name,
      value: c.uuid || '',
    }));

  const defaultCategories = courseCategories.records &&
    course.categories?.map(c => ({
      key: c.uuid,
      label: c.name,
      value: c.uuid || '',
    }));

  const title = `Update Information for ${course.name}`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Update Course"
      formProps={{ onFinish }}
    >
      <Form.Item
        label={(
          <Checkbox
            onChange={handleCheckboxChange}
            name="retirementEdited"
            checked={retirementEdited}
            id="retirementEdited"
          >
            Retirement Date
          </Checkbox>
        )}
      >
        <DatePicker
          allowClear={true}
          value={retirementDate}
          onChange={handleDateChange}
          disabled={!retirementEdited}
        />
      </Form.Item>

      <Form.Item
        label={(
          <Checkbox
            onChange={handleCheckboxChange}
            name="categoriesEdited"
            checked={categoriesEdited}
            id="categoriesEdited"
          >
            Categories
          </Checkbox>
        )}
      >
        <BRSelect
          defaultValue={defaultCategories}
          setValue={setCategories}
          options={categoriesOptions}
          disabled={!categoriesEdited}
          mode="multiple"
        />
      </Form.Item>

      <Form.Item
        label={(
          <Checkbox
            onChange={handleCheckboxChange}
            name="linkEdited"
            checked={linkEdited}
            id="linkEdited"
          >
            Course Link
          </Checkbox>
        )}
      >
        <Input
          type={'text'}
          value={link}
          onChange={handleLinkChange}
          disabled={!linkEdited}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments}/>

    </BRModal>
  );
};
