import React, { useState } from 'react';
import { Button, Form, Upload, Alert, Radio } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments } from '../BRForm';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useBRStore } from '../../hooks/useBRStore';
import { requestCSV } from '../../helpers/requestCSV';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

interface Props extends BRModalProps {
}

const fetchDataParams = { url: '/v0/bulkImport' };

export const UploadCSVModal: React.FunctionComponent<Props> =
({ ...modalProps }) => {

  const bulkImportStore = useBRStore<{ [type: string]: { title: string, templateLink: string }}>({ fetchDataParams });

  React.useEffect(
    () => {
      bulkImportStore.fetch();
    },
    [],
  );

  const [file, setFile] = useState<UploadFile>();
  const [type, setType] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [comments, setComments] = useState('');

  const onFinish = async () => {
    if (!type) {
      setErrorMessage('You must select an import type before importing');
      throw Error('No type selected');
    }
    if (!file) {
      setErrorMessage('You must select a file before importing');
      throw Error('No file selected');
    }
    const formData = new FormData();
    formData.append('files[]', file as any, file?.name);
    formData.append('type', type as any);
    await sendCommand({
      formData,
      command: COMMANDS.BULK_IMPORT,
    });
  };

  const selectType = (e: any) => {
    setType(e.target.value);
  };

  const handleFileChange = (uploadFileParams: UploadChangeParam) => {
    if (uploadFileParams.fileList.length === 0) {
      setFile(undefined);
      return false;
    }
    const file = uploadFileParams.file;
    if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
      setErrorMessage(`File ${file.name} is not a csv file`);
    } else {
      setErrorMessage('');
      setFile(file);
    }
    return false;
  };

  // this is an unfortunate hack necessary to prevent Upload from trying to upload when the dialog box is completed
  const doNothing = () => false;

  const downloadTemplate = (link: string) => {
    return (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      requestCSV(link, '', {}, {});
    };
  };

  const createBulkImportButtons = (data: { [type: string]: { title: string, templateLink: string }}) => {
    return Object.entries(bulkImportStore.data).map(([type, details]) => {
      return (
        <div key={type}>
          <Radio key={type} value={type}>
            {details.title}
          </Radio>
          <Button onClick={downloadTemplate(details.templateLink)} size="small" icon={<DownloadOutlined />} type="link">
            Template
          </Button>
        </div>
      );
    });
  };

  const title = 'Upload CSV';

  return(
    <BRModal
      {...modalProps}
      title={title}
      okText="Import"
      formProps={{ onFinish }}
    >
    {errorMessage && (
      <>
        <Alert message={errorMessage} type="warning"/>
        <br />
      </>
    )}
      <Form.Item label="Select Type">
        <Radio.Group onChange={selectType}>
          {bulkImportStore.loaded &&
            createBulkImportButtons(bulkImportStore.data)
          }
        </Radio.Group>
      </Form.Item>

      <Form.Item label="CSV File">
        <Upload
          name="file"
          accept=".csv"
          onChange={handleFileChange}
          fileList={file ? [file] : []}
          beforeUpload={doNothing}
        >
          <Button icon={<UploadOutlined />}>
            Upload CSV
          </Button>
        </Upload>
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments}/>

    </BRModal>
  );
};
