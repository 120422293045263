import { Select } from 'antd';
import { CascaderOptionType } from 'antd/lib/cascader';
import { useBRListData } from '../../../hooks/useBRListData';
import React from 'react';

interface Props {
  onChange: (e: any) => void;
  attributes: any;
  selectedOption: CascaderOptionType | undefined;
}


export function ResourceSelect(props: Props) {

  const selectParams = React.useMemo(
    () => ({ fetchDataParams: { url: `/v0/rules/data/${props.selectedOption?.resource}` } }),
    [props.selectedOption?.resource],
  );

  const optionStore = useBRListData<{ label: string, value: string }>(selectParams);

  React.useEffect(() => {
    optionStore.fetch();
  }, []);

  const currentOption = optionStore.records?.find(data => props.attributes.value === data.value);

  return (
    <Select
      style={{ width: '100%' }}
      options={optionStore.records}
      value={currentOption?.value}
      onChange={props.onChange}
      placeholder={`Select ${props.selectedOption?.resource}`}
      loading={optionStore.loading}
      showSearch={true}
      optionFilterProp={'label'}
    />
  );
}
