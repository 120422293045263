import React from 'react';
import { Tag, Avatar } from 'antd';
import { DATE_OPTIONS, DATE_TIME_OPTIONS, reportsTypeNames, UI_COLORS } from '../constants';
import { RESOURCE_STATUS } from '../types';
import { CheckCircleFilled, CheckCircleOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, ExclamationOutlined, CheckOutlined, QuestionOutlined } from '@ant-design/icons';

export function dateRenderer(dateValue: number) {
  if (dateValue) {
    const date = new Date(dateValue);
    return isNaN(date.getTime()) ? 'No Date Recorded' : date.toLocaleString('en-US', DATE_OPTIONS);
  }
  return 'No Date Recorded';
}

export function dateTimeRenderer(dateValue: number) {
  if (dateValue) {
    const date = new Date(dateValue);
    return isNaN(date.getTime()) ? 'No Date Recorded' :
      date.toLocaleString('en-US', DATE_TIME_OPTIONS);
  }
  return 'No Date Recorded';
}

export function statusTagRenderer(text: string, record: any = { active: true }, index?: number): JSX.Element {

  const labelColor = text === RESOURCE_STATUS.expiring ? 'orange'
                      : text === RESOURCE_STATUS.expired ? 'darkred'
                      : text === RESOURCE_STATUS.complete ? 'green'
                      : text === 'Active' ? 'green'
                      : text === 'Approved' ? 'green'
                      : text === 'Manually Imported' ? 'green'
                      : text === 'Active Modification Approved' ? 'green'
                      : text === RESOURCE_STATUS.notApplicable ? 'blue'
                      : text === 'Pending' ? 'orange'
                      : text === 'Processing' ? 'orange'
                      : text === RESOURCE_STATUS.actionRequired ? 'red'
                      : text === 'Failed' ? 'red'
                      : record?.active ? 'orange' : '#808080';

  const styles = {
    fontSize: '1.1em',
  };

  if (text) {
    return (
      <Tag color={labelColor} style={styles}>
        {text}
      </Tag>
    );
  }

  return <></>;
}

export function statusIconRenderer(status: RESOURCE_STATUS, selected = true): JSX.Element {
  const standardProps = { fontSize: '28px' };

  switch (status) {
    case RESOURCE_STATUS.actionRequired:
      if (selected) {
        return <ExclamationCircleFilled style={{ color: UI_COLORS.deepRed, ...standardProps }} />;
      }
      return <ExclamationCircleOutlined style={{ color: UI_COLORS.deepRed, ...standardProps }} />;
    case RESOURCE_STATUS.complete:
      if (selected) {
        return <CheckCircleFilled style={{ color: UI_COLORS.deepGreen, ...standardProps }} />;
      }
      return <CheckCircleOutlined style={{ color: UI_COLORS.deepGreen, ...standardProps }} />;
    default:
      return <></>;
  }
}

export function statusAvatarRenderer(status: RESOURCE_STATUS, selected = true): JSX.Element {
  let primaryColor = '';
  let color = '';
  let backgroundColor = '';
  let icon = <></>;

  switch (status) {
    case RESOURCE_STATUS.actionRequired:
      primaryColor = UI_COLORS.deepRed;
      icon =  <ExclamationOutlined />;
      break;
    case RESOURCE_STATUS.complete:
      primaryColor = UI_COLORS.deepGreen;
      icon = <CheckOutlined />;
      break;
    case RESOURCE_STATUS.expiring:
      primaryColor = UI_COLORS.deepOrange;
      icon = <ExclamationOutlined />;
      break;
    case RESOURCE_STATUS.expired:
      primaryColor = UI_COLORS.deepRed;
      icon = <ExclamationOutlined />;
      break;
    default:
      primaryColor = UI_COLORS.deepPurple;
      icon = <QuestionOutlined />;
  }

  if (selected) {
    backgroundColor = primaryColor;
  } else {
    color = primaryColor;
  }

  return <Avatar style={{ color, backgroundColor }} icon={icon} />;
}

export function booleanRenderer(value: boolean) {
  return value ? 'Yes' : 'No';
}

export function stringArrayRenderer(value: string[]) {
  if (value && !value.join) {
    return console.error('stringArrayRenderer: bad value', value);
  }
  return (value || []).join(', ');
}

export function objectArrayRenderer(value: any[]) {
  if (value && !value.map) {
    return console.error('objectArrayRenderer: bad value', value);
  }
  return (value || []).map(v => v.name).join(', ');
}

export function reportTypeRenderer(value: string) {
  const typeName = reportsTypeNames[value];
  if (!typeName) {
    return console.error('reportTypeRenderer: bad value', value);
  }
  return typeName;
}
