import * as React from 'react';
import { TABLE_IDS } from '../../constants';
import { BRLayout } from '../../components/BRLayout';
import BRTable from '../../components/BRTable';
import { useBRTableStore } from '../../hooks/useBRTableStore';
import { IMail } from '../../types/IMail';
import { ROUTES } from '../../routes';
import { checkCommands } from '../../helpers/checkCommands';
import { Command } from '../../components/CommandButtons';

interface IProps {

}

export function OutboxListPage(props: IProps) {

  const mails = useBRTableStore<IMail>({
    fetchDataParams: { url: '/v0/outbox' },
  });

  const allOutboxListCommands: Command[] = [];

  const commands = checkCommands(allOutboxListCommands, mails);

  const mailRowKey = (mail: IMail) => `outbox-row-${mail.uuid}`;

  return (
    <BRLayout
      commands={commands}
      title="Email Outbox"
    >
      <BRTable
        linkPath={ROUTES.outboxDetail}
        tableId={TABLE_IDS.OUTBOX_PAGE_TABLE}
        tableData={mails}
        rowKey={mailRowKey}
      />
    </BRLayout>
  );
}
