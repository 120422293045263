import React, { useState, useEffect, useContext } from 'react';
import { Alert, Button, Checkbox, Form, Input, Spin, Typography } from 'antd';
import { CLIENT_CONST } from '../constants';
import Paragraph from 'antd/lib/typography/Paragraph';
import { SessionContext } from './App';
import { authServices } from '../services/auth.services';
import { Link } from '../hooks/useRoutes';
import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface LoginFormProps {
  accessToken?: string;
  nextPath?: string;
  serverError?: boolean;
}

export function LoginForm(props: LoginFormProps = {}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [serverError, setServerError] = useState(!!props.serverError);
  const { session, dispatch } = useContext(SessionContext);
  const [siteDown, setSiteDown] = useState(!!session.siteDown);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };

  async function handleLogin(e: any) {
    e.preventDefault ? e.preventDefault() : e.returnValue = false;
    setSubmitted(true);
    const result = await authServices.login(username, password);
    setSubmitted(false);
    if (result.status === 400) {
      setServerError(false);
      setSiteDown(false);
      setLoginFailed(true);
    } else if (result.status === 503) {
      setLoginFailed(false);
      setServerError(false);
      setSiteDown(true);
    } else if (!result.ok) {
      setLoginFailed(false);
      setSiteDown(false);
      setServerError(true);
    } else {
      const response = await result.json();
      if (response.person) {
        localStorage.setItem('user', JSON.stringify(response.person));
        localStorage.setItem('token', response.access_token);
        localStorage.setItem('scope', response.scope);
        localStorage.setItem('key', response.mac_key);
      }
      dispatch({
        type: 'userData',
        user: response.person,
        scope: response.scope.split(' '),
      });
    }
    session.router.go(
      { name: session.router.current.name },
      session.router.current.params,
    );
  }

  useEffect(() => {
    // Attempt SSO login in background
    if (props.accessToken) {
      authServices.oauthLogin(props.accessToken).then((result) => {
        if (result.person) {
          dispatch({
            type: 'userData',
            user: result.person,
            scope: result.scope.split(' '),
          });
          if (props.nextPath) {
            session.router.go(props.nextPath);
          } else {
            session.router.go(
              { name: session.router.current.name },
              session.router.current.params,
            );
          }
        } else {
          setLoginFailed(true);
          // setTimeout(() => window.location.hash = '', 5000);
        }
      }).catch((result) => {
        setLoginFailed(true);
        // setTimeout(() => window.location.hash = '', 5000);
      });
    }
    authServices.ssoLogin().then((result) => {
      if (result.person) {
        dispatch({
          type: 'userData',
          user: result.person,
          scope: result.scope.split(' '),
        });
        session.router.go(
          { name: session.router.current.name },
          session.router.current.params,
        );
      }
    }).catch((e) => {
      console.info('SSO login failed.');
    });
  },        [session, dispatch, props.accessToken, props.nextPath]);

  return (
    <Spin tip="Logging in..." spinning={submitted} indicator={<LoadingOutlined />}>
      <Form
        className="login-form"
        onFinish={handleLogin}
      >
        {loginFailed && (
          <Alert
            message="Login Failed"
            description="There was an error with your email or password. Failed to log in."
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {serverError && (
          <Alert
            message="Server Error"
            description="There was an error communicating with the server. Please try again soon."
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {siteDown && (
          <Alert
            message="Site down for maintenance"
            description="The site is currently down for site maintenance. Please try again soon."
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}

        {props.accessToken && loginFailed && (
          <p><Link href={window.location.pathname}>Click here to try again.</Link></p>
        )}

        {props.accessToken && !loginFailed && !serverError && (
          <>
            <p>Attempting OAuth login...</p>
            <Spin />
          </>
        )}

        {!props.accessToken && (
          <>
            <Paragraph type="secondary">
              Sign in using your {CLIENT_CONST.INSTITUTE_NAME} account
            </Paragraph>

            <Form.Item label="Username" colon={false}>
              <Input
                type="text"
                name="username"
                required={true}
                value={username}
                onChange={handleChange}
                prefix={<UserOutlined className="input-icon"/>}
              />
            </Form.Item>

            <Form.Item label="Password" colon={false}>
              <Input
                type="password"
                name="password"
                required={true}
                value={password}
                onChange={handleChange}
                prefix={<LockOutlined className="input-icon"/>}
              />

              {/* Functiionality not implemented yet */}
              <Checkbox checked={true} className="login-form__checkbox">
                Keep Me Signed in
              </Checkbox>
            </Form.Item>

            <Form.Item className="login-form__submit">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form__button"
              >
                Sign in
              </Button>
              <Text>
                <a href="/contact">Help? Contact Support</a>
              </Text>
            </Form.Item>
          </>
        )}
      </Form>
    </Spin>
  );
}
