import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'antd';

import { BRModal, BRModalProps } from '../BRModal';
import { BRLargeDataSelect } from '../BRForm';
import { IBasicPerson } from '../../types/IBasicPerson';
import { useBRListData } from '../../hooks/useBRListData';
import { SessionContext } from '../App';
import { authServices } from '../../services/auth.services';
import { ROUTES } from '../../routes';

const fetchDataParams = { url: '/v0/people', queryParams: { basic: true } };

export const ImpersonateModal: React.FunctionComponent<BRModalProps> = (modalProps) => {
  const { session, dispatch } = useContext(SessionContext);

  const people = useBRListData<IBasicPerson>({ fetchDataParams });

  useEffect(() => { people.fetch(); }, [people.fetch]);

  const [account, setAccount] = useState('');

  const onFinish = async () => {
    const result = await authServices.impersonate(account);
    if (result.person) {
      dispatch({
        type: 'userData',
        user: result.person,
        scope: result.scope.split(' '),
      });
      session.router.go(
        { name: ROUTES.home },
      );
    }
  };

  const personOptions = people.records.filter(a => a.uuid !== session.user?.uuid)
    .sort(
      (a, b) => {
        if (a.createdBy && b.createdBy) {
          if (a.createdBy > b.createdBy) return 1;
          if (a.createdBy < b.createdBy) return -1;
        }
        return 0;
      })
    .map(
      (a) => {
        const label = `[${a.createdBy}] ${a.lastName}, ${a.firstName} (${a.username})`;
        return {
          label,
          value: a.uuid || '',
          key: a.uuid,
        };
      },
    );

  return (
    <BRModal
      {...modalProps}
      title="Impersonate"
      okText="Impersonate"
      formProps={{ onFinish }}
    >
      <Form.Item label="Person">
        <BRLargeDataSelect
          options={personOptions}
          setValue={setAccount}
          loading={people.loading}
        />
      </Form.Item>
    </BRModal>
  );
};
