import { Command } from '../components/CommandButtons';
import { BRStoreBase } from '../hooks/useBRStore';

export function checkCommands(allCommands: Command[], store: BRStoreBase | undefined): Command[] {

  const storeCommands: any = store?.commands ?? {};

  const allowedCommands = allCommands.filter((command) => {
    return command.type ? storeCommands[command.type] : true;
  });

  return allowedCommands;
}
