import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

// Select component
export interface BRSelectValue {
  key?: string;
  value: string;
  label: string;
}

interface BRSelectProps extends SelectProps<BRSelectValue | BRSelectValue[]> {
  setValue: React.Dispatch<React.SetStateAction<any>>;
}

export const BRSelect: React.FunctionComponent<BRSelectProps> =
({ options, setValue, ...props }) => {

  const handleOptionsFilter = (input: string, option: any): boolean => {
    return option.label?.toLowerCase()
    .indexOf(input.toLowerCase()) >= 0;
  };

  const handleSelect = (value: BRSelectValue | BRSelectValue[]) => {
    if (!value) {
      setValue(null);
    } else if (value instanceof Array) {
      setValue(value.map(v => v.value));
    } else {
      setValue(value.value);
    }
  };

  return (
    <Select
      showSearch={true}
      allowClear={true}
      filterOption={handleOptionsFilter}
      {...props}
      onChange={handleSelect}
      options={options}
      labelInValue={true}
    />
  );
};
