import { IApprovalStatus, RESOURCE_STATUS, IApprovalItem } from '../types';
import { IAttachment } from '../types/IAttachment';
import { IPhysicalAttachment } from '../types/IPhysicalAttachment';

export class ApprovalStatus {
  status: IApprovalStatus;

  constructor(status: IApprovalStatus) {
    this.status = status;
  }

  get statusValue(): RESOURCE_STATUS {
    if (this.status?.completedDate) {
      return RESOURCE_STATUS.complete;
    }

    return RESOURCE_STATUS.actionRequired;
  }

  get uuid(): string | undefined {
    return this.status?.uuid;
  }

  get completedDate(): number | undefined {
    return this.status?.completedDate;
  }

  get approvalItem(): IApprovalItem | undefined {
    return this.status?.approvalItem;
  }

  get attachments(): IAttachment[] {
    return this.status?.attachments || [];
  }

  get physicalAttachments(): IPhysicalAttachment[] {
    return this.status?.physicalAttachments || [];
  }

  get notes(): string {
    return this.status?.notes || '';
  }
}
