import * as React from 'react';
import { BookOutlined } from '@ant-design/icons';
import { Avatar, List } from 'antd';
import { getElementAttributes } from '../../helpers/getNodeAttributes';
import { RuleEditorEdit } from './RuleEditorEdit';
import { RuleEditorDelete } from './RuleEditorDelete';
import { RuleEditorNodeProps, RULE_NODE_COLORS } from '.';
import { ICourse } from '../../types';
import { Link } from '../../hooks/useRoutes';
import { SessionContext } from '../App';
import { ROUTES } from '../../routes';
import { useBRStore } from '../../hooks/useBRStore';

export function RuleEditorCourse(props: RuleEditorNodeProps) {
  const attributes = getElementAttributes(props.elt);
  const { session } = React.useContext(SessionContext);

  const selectParams = React.useMemo(
    () => ({ fetchDataParams: { url: `/v0/courses/${attributes.uuid}` } }),
    [attributes.uuid],
  );

  const courseStore = useBRStore<ICourse>(selectParams);

  React.useEffect(() => {
    courseStore.fetch();
  }, [])

  const course = courseStore.data;
  const uuid = attributes.uuid && <><Link href={session.router.getUrl({ name: ROUTES.course }, { uuid: `${course?.uuid}` })}>{course?.name}</Link></>;
  const retiresOn = attributes.retiresOn && <><strong>Retirement Date</strong> - {attributes.retiresOn}</>;
  const takenAfter = attributes.takenAfter && <><strong>Taken After</strong> - {attributes.takenAfter}</>;

  const description = <>{uuid}<br/>{retiresOn} {takenAfter}</>;

  // this still needs to consider whether the entire course is retired
  const isRetired = attributes.retiresOn && new Date(attributes.retiresOn) < new Date();

  return (
    <List.Item
      style={{ padding: '0.25em' }}
      actions={props.editable ? [
        <RuleEditorEdit {...props} key="Edit" />,
        <RuleEditorDelete {...props} key="Delete" />,
      ] : undefined}
    >
      <List.Item.Meta avatar={<Avatar icon={<BookOutlined />} style={{ backgroundColor: isRetired ? RULE_NODE_COLORS.RetiredCourse : RULE_NODE_COLORS.Course }} />} title={`Course${isRetired ? ' (Retired)' : ''}`} description={description} />
    </List.Item>
  );
}
