import * as React from 'react';

import { TabMenu, TabMenuTab } from '../components/TabMenu';
import { BRLayout } from '../components/BRLayout';
import {
  IPerson,
  IExemption,
  IPersonRequirement,
  ICommand,
  IPersonCourse,
  ITenure,
  ITenureRequest,
} from '../types';
import { TABLE_IDS, CLIENT_CONST } from '../constants';
import { DetailsTable } from '../components/DetailsTable';
import { buildPersonDetailTitle } from '../helpers/buildDetailPageTitle';
import { ExemptionsTable } from '../components/ExemptionsTable';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { useBRStore } from '../hooks/useBRStore';
import BRTable from '../components/BRTable';
import { UpdatePersonModal } from '../components/modals/UpdatePersonModal';
import { AddRequirementToPersonModal } from '../components/modals/AddRequirementToPersonModal';
import { AddCourseToPersonModal } from '../components/modals/AddCourseToPersonModal';
import { RemoveCourseFromPersonModal } from '../components/modals/RemoveCourseFromPersonModal';
import { AddExemptionToPersonModal } from '../components/modals/AddExemptionToPersonModal';
import {
  RemoveExemptionFromPersonModal,
} from '../components/modals/RemoveExemptionFromPersonModal';
import { GenerateCertificateModal } from '../components/modals/GenerateCertificateModal';
import { LinkPersonModal } from '../components/modals/LinkPersonModal';
import { ROUTES } from '../routes';
import { AddPersonToGroupModal } from '../components/modals/AddPersonToGroupModal';
import { RemovePersonFromGroupModal } from '../components/modals/RemovePersonFromGroupModal';
import { SessionContext } from '../components/App';
import { FEATURES } from '../constants/features';
import { IPersonTranscript } from '../types/IPersonTranscript';
import { LinkTranscriptModal } from '../components/modals/LinkTranscriptModal';
import {
  DownloadOutlined,
  EditOutlined,
  FileAddOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  LinkOutlined,
  MinusOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  StopOutlined
} from '@ant-design/icons';
import { requestPDF } from '../helpers/requestPDF';
import { statusTagRenderer } from '../helpers/dataRenderers';
import { IEquivalency } from '../types/IEquivalency';
import { PersonEquivalencyDrawer } from '../components/drawers/PersonEquivalencyDrawer';
import { RequestEquivalencyModal } from '../components/modals/RequestEquivalencyModal';
import { hasPermission, PERMISSION_TYPES } from '../helpers/hasPermission';
import { checkCommands } from '../helpers/checkCommands';
import { Command, CommandButtons } from '../components/CommandButtons';
import { PersonCourseDrawer } from '../components/drawers/PersonCourseDrawer';
import { Modal } from 'antd';
import { AddAwardToPersonModal } from '../components/modals/AddAwardToPersonModal';
import { PersonAwardDrawer } from '../components/drawers/PersonAwardDrawer';
import { IGroupMember } from '../types/IGroup';
import { UnlinkTranscriptModal } from '../components/modals/UnlinkTranscriptModal';
import { useBRListData } from '../hooks/useBRListData';
import { API_ROUTES } from '../constants/apiRoutes';
import { requestFile } from '../helpers/requestFile';

interface Props {
  uuid: string;
  tab?: string;
  equivalencyUuid?: string;
  courseTakenUuid?: string;
  awardUuid?: string;
}

export function PersonPage(props: Props) {
  const { session } = React.useContext(SessionContext);
  const personUuid = props.uuid;
  const urlRoot = React.useMemo(() => `/v0/people/${props.uuid}`, [props.uuid]);
  const tabId = props.tab;
  const equivalencyUuid = props.equivalencyUuid;
  const courseTakenUuid = props.courseTakenUuid;
  const awardUuid = props.awardUuid;

  const personStore = useBRStore<IPerson>({
    fetchDataParams: React.useMemo(() => ({ url: urlRoot }), [props.uuid]),
  });
  const person = personStore.data;

  const admin = hasPermission(PERMISSION_TYPES.audit, session);

  React.useEffect(
    () => {
      personStore.fetch().catch((e) => {
        Modal.error({ title: 'Unable to load person.', content: e.text ?? 'Unknown error.' });
      });
    },
    []);

  const requirements = useBRListData<IPersonRequirement>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/requirements`, queryParams: { all: true } }), [urlRoot]),
  });

  const transcripts = useBRTableStore<IPersonTranscript>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/transcripts` }), [urlRoot]),
  });

  const courses = useBRTableStore<IPersonCourse>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/courses` }), [urlRoot]),
  });

  const equivalencies = useBRTableStore<IEquivalency>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/equivalencies` }), [props.uuid]),
  });

  const awards = useBRTableStore<IGroupMember>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/groups`, queryParams: { filters: { type: ['Award'] } } }), [urlRoot]),
  });

  const protocols = useBRTableStore<IGroupMember>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/groups`, queryParams: { filters: { type: ['Protocol'] } } }), [urlRoot]),
  });

  const roles = useBRTableStore<IGroupMember>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/groups`, queryParams: { filters: { type: ['Role'] } } }), [urlRoot]),
  });

  const exemptions = useBRTableStore<IExemption>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/exemptions` }), [urlRoot]),
  });

  const outbox = useBRTableStore<ICommand>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/outbox` }), [urlRoot]),
  });

  const events = useBRTableStore<ICommand>({
    fetchDataParams: React.useMemo(() => ({ url: API_ROUTES.commands, queryParams: { resource: personUuid } }), [urlRoot]),
  });

  const tenures = useBRTableStore<ITenure>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/tenures` }), [urlRoot]),
  });

  const tenureRequests = useBRTableStore<ITenureRequest>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/tenurerequests` }), [urlRoot]),
  });

  const updateCurrent = () => {
    personStore.fetch();
    switch (props.tab) {
      case 'roles':
        roles.fetch();
        break;
      case 'events':
        events.fetch();
        break;
      case 'required':
      case 'recommended':
      case 'other':
        requirements.fetch();
        break;
      case 'awards':
        awards.fetch();
        break;
      case 'outbox':
        outbox.fetch();
        break;
      case 'protocols':
        protocols.fetch();
        break;
      case 'transcripts':
        transcripts.fetch();
        break;
      case 'courses':
        courses.fetch();
        break;
      case 'equivalencies':
        equivalencies.fetch();
        break;
      case 'exemptions':
        exemptions.fetch();
        break;
      case 'tenures':
        tenures.fetch();
        break;
      default:
        if (FEATURES.TRAINING) {
          requirements.fetch();
        } else if (FEATURES.ONBOARDING) {
          tenures.fetch();
        } else {
          outbox.fetch();
        }
        break;
    }
  };

  const menuTabs: TabMenuTab[] = [];

  if (FEATURES.TRAINING) {
    {
      const commands = checkCommands(
        [
          {
            title: 'Add Requirement',
            icon: <PlusOutlined />,
            type: 'addRequirementToPerson',
            modal: (
              <AddRequirementToPersonModal
                person={person}
                personReqs={requirements.records}
                onSuccess={updateCurrent}
              />
            ),
          },
        ],
        personStore,
      );

      menuTabs.push({
        name: 'Required Training',
        key: 'required',
        permissions: [PERMISSION_TYPES.requirements],
        component: (
          <>
            <CommandButtons commands={commands} />
            <DetailsTable person={personStore} requirements={requirements} mandatory={true} admin={admin} />
          </>
        ),
      });
    }

    menuTabs.push({
      name: 'Recommended Training',
      key: 'recommended',
      permissions: [PERMISSION_TYPES.requirements],
      component: <DetailsTable person={personStore} requirements={requirements} mandatory={false} admin={admin} />,
    });

    menuTabs.push({
      name: 'Other Training',
      key: 'other',
      permissions: [PERMISSION_TYPES.requirements],
      component: <DetailsTable person={personStore} requirements={requirements} applies={false} admin={admin} />,
    });

    /* menuTabs.push({
      name: 'Identifiers',
      key: 'identifiers',
      component: (
        <Descriptions bordered={true} column={{ xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
          { Object.keys(descriptionItems).map((key: string) =>
            <Descriptions.Item label={key} key={key}>{descriptionItems[key]}</Descriptions.Item>)
          }
        </Descriptions>
      ),
    }); */

    {
      const commandSet: Command[] = [
        {
          title: 'Link Transcript',
          icon: <LinkOutlined />,
          type: 'linkTranscript',
          modal: (
            <LinkTranscriptModal
              person={person}
              onSuccess={updateCurrent}
            />
          ),
        },
        {
          title: 'Unlink Transcript',
          icon: <StopOutlined />,
          type: 'unlinkTranscript',
          enabled: transcripts.records?.filter(transcript => transcript.provider?.name !== 'Manual').length > 0,
          modal: (
            <UnlinkTranscriptModal
              personUuid={person.uuid}
              onSuccess={updateCurrent}
            />
          ),
        },
      ];

      const commands = checkCommands(commandSet, personStore);

      menuTabs.push({
        name: 'Transcripts',
        key: 'transcripts',
        permissions: [PERMISSION_TYPES.transcripts],
        component: (
          <>
            <CommandButtons commands={commands} />
            <BRTable
              tableData={transcripts}
              tableId={TABLE_IDS.PERSON_TRANSCRIPTS_TABLE}
              linkPath={ROUTES.transcript}
            />
          </>
        ),
      });
    }

    {
      const commands = checkCommands(
        [
          {
            title: 'Add Course',
            icon: <PlusOutlined />,
            type: 'addCourseToPerson',
            modal: (
              <AddCourseToPersonModal
                person={person}
                onSuccess={updateCurrent}
              />
            ),
          },
          {
            title: 'Remove Course',
            icon: <MinusOutlined />,
            type: 'removeCourseFromPerson',
            modal: (
              <RemoveCourseFromPersonModal
                person={person}
                onSuccess={updateCurrent}
              />
            ),
          },
        ],
        personStore,
      );

      menuTabs.push({
        name: 'Courses',
        key: 'courses',
        permissions: [PERMISSION_TYPES.courses],
        component: (
          <>
            <CommandButtons commands={commands} />
            <BRTable
              tableData={courses}
              tableId={TABLE_IDS.PERSON_COURSES_TABLE}
              linkPath={ROUTES.personCourse}
              keepQuery={true}
              routeMap={{
                courseTaken: (row: IPersonCourse) => row.uuid,
                uuid: () => personUuid,
              }}
            />
          </>
      )});
    }

    if (FEATURES.EQUIVALENCIES) {
      menuTabs.push({
        name: 'Equivalencies',
        key: 'equivalencies',
        permissions: [PERMISSION_TYPES.courses],
        component: (
          <BRTable
            tableData={equivalencies}
            tableId={TABLE_IDS.PERSON_EQUIVALENCIES_TABLE}
            linkPath={ROUTES.equivalency}
            keepQuery={true}
            routeMap={{
              uuid: (row: IEquivalency) => row.person,
              equivalencyUuid: (row: IEquivalency) => row.uuid,
            }}
          />
        ),
      });
    }

    if (FEATURES.AWARDS) {
      const commands = checkCommands(
        [
          {
            title: 'Add Award',
            icon: <PlusOutlined />,
            type: 'addAwardToPerson',
            modal: (
              <AddAwardToPersonModal
                person={person}
                onSuccess={updateCurrent}
              />
            ),
          },
        ],
        personStore,
      );
      menuTabs.push({
        name: 'Awards',
        key: 'awards',
        permissions: [PERMISSION_TYPES.groups],
        component: (
          <>
            <CommandButtons commands={commands} />
            <BRTable
              tableData={awards}
              tableId={TABLE_IDS.PERSON_AWARDS_TABLE}
              linkPath={ROUTES.personAward}
              keepQuery={true}
              routeMap={{
                uuid: (row: IGroupMember) => personUuid,
                award: (row: IGroupMember) => row.uuid,
              }}
            />
          </>
        ),
      });
    }

    if (FEATURES.PROTOCOLS) {
      menuTabs.push({
        name: 'Protocols',
        key: 'protocols',
        permissions: [PERMISSION_TYPES.groups],
        component: (
          <BRTable
            tableData={protocols}
            tableId={TABLE_IDS.PERSON_PROTOCOLS_TABLE}
          />
        ),
      });
    }

    menuTabs.push({
      name: 'Roles',
      key: 'roles',
      permissions: [PERMISSION_TYPES.groups],
      component: (
        <BRTable
          tableData={roles}
          tableId={TABLE_IDS.PERSON_ROLES_TABLE}
        />
      ),
    });

    {
      const commands = checkCommands(
        [
          {
            title: 'Add Exemption',
            icon: <PlusOutlined />,
            type: 'addExemptionToPerson',
            modal: (
              <AddExemptionToPersonModal
                person={person}
                onSuccess={updateCurrent}
              />
            ),
          },
          {
            title: 'Remove Exemption',
            icon: <MinusOutlined />,
            type: 'removeExemptionFromPerson',
            modal: (
              <RemoveExemptionFromPersonModal
                person={person}
                onSuccess={updateCurrent}
              />
            ),
          },
        ],
        personStore,
      );

      menuTabs.push({
        name: 'Exemptions',
        key: 'exemptions',
        permissions: [PERMISSION_TYPES.courses],
        component: (
          <>
            <CommandButtons commands={commands} />
            <ExemptionsTable listData={exemptions} />
          </>
        ),
      });

    }
  }

  if (FEATURES.ONBOARDING && hasPermission(PERMISSION_TYPES.onboarding, session)) {
    {
      const createTenure = () => {
        session.router.go({ name: ROUTES.tenure }, { uuid: personUuid, tenureUuid: 'new' });
      };

      const commands = checkCommands(
        [
          {
            title: `Create ${CLIENT_CONST.TENURE_TERM}`,
            type: 'createTenure',
            icon: <FileAddOutlined />,
            onClick: createTenure,
          },
        ],
        personStore,
      );

      menuTabs.push({
        name: `${CLIENT_CONST.TENURE_TERM}s`,
        key: 'tenures',
        permissions: [PERMISSION_TYPES.tenures],
        component: (
          <>
            <CommandButtons commands={commands} />
            <BRTable
              tableData={tenures}
              tableId={TABLE_IDS.PERSON_TENURES_TABLE}
              linkPath={ROUTES.tenureWizard}
              routeMap={{
                uuid: (row: ITenure) => row.person,
                tenureUuid: (row: ITenure) => row.uuid,
              }}
            />
          </>
        ),
      });
    }

    if (session.user?.uuid === personUuid) {
      const createTenureRequest = () => {
        session.router.go({ name: ROUTES.tenureRequest }, { uuid: personUuid, tenureRequestUuid: 'new' });
      };

      const commands = checkCommands(
        [
          {
            title: `Request ${CLIENT_CONST.TENURE_TERM}`,
            icon: <FileAddOutlined />,
            type: 'requestTenure',
            onClick: createTenureRequest,
          },
        ],
        personStore,
      );

      menuTabs.push({
        name: `${CLIENT_CONST.TENURE_TERM} Requests`,
        key: 'tenureRequests',
        permissions: [PERMISSION_TYPES.tenures],
        component: (
          <>
            <CommandButtons commands={commands} />
            <BRTable
              tableData={tenureRequests}
              tableId={TABLE_IDS.TENURE_REQUESTS_TABLE}
              linkPath={ROUTES.tenureRequestWizard}
              routeMap={{
                uuid: (row: ITenureRequest) => row.owner,
                tenureRequestUuid: (row: ITenureRequest) => row.uuid,
              }}
            />
          </>
        ),
      });
    }
  }

  menuTabs.push({
    name: 'Outbox',
    key: 'outbox',
    permissions: [PERMISSION_TYPES.outbox],
    component: (
      <BRTable
        tableData={outbox}
        tableId={TABLE_IDS.PERSON_OUTBOX_TABLE}
        linkPath={ROUTES.outboxDetail}
      />
    ),
  });

  menuTabs.push({
    name: 'Audit Log',
    key: 'events',
    permissions: PERMISSION_TYPES.audit,
    component: (
      <BRTable
        tableData={events}
        tableId={TABLE_IDS.PERSON_AUDIT_LOG}
        linkPath={ROUTES.command}
      />
    ),
  });

  const allPersonCommands: Command[] = [
    {
      title: 'Update Person',
      icon: <EditOutlined />,
      type: 'updatePerson',
      modal: (
        <UpdatePersonModal
          person={person}
          onSuccess={updateCurrent}
        />
      ),
    },
    {
      title: 'Add To Group',
      icon: <PlusOutlined />,
      type: 'addPersonToGroup',
      modal: (
        <AddPersonToGroupModal
          person={person}
          onSuccess={updateCurrent}
        />
      ),
    },
    {
      title: 'Remove From Group',
      icon: <MinusOutlined />,
      type: 'removePersonFromGroup',
      modal: (
        <RemovePersonFromGroupModal
          person={person}
          onSuccess={updateCurrent}
        />
      ),
    },
    {
      title: 'Link Person',
      icon: <LinkOutlined />,
      type: 'linkPerson',
      modal: (
        <LinkPersonModal
          person={person}
          onSuccess={updateCurrent}
        />
      ),
    },
  ];

  allPersonCommands.push({
    title: 'Generate Certificate',
    icon: <DownloadOutlined />,
    type: 'generateCertificate',
    modal: <GenerateCertificateModal person={person} />,
  });

  allPersonCommands.push({
    title: 'Download Transcript',
    icon: <FilePdfOutlined />,
    type: 'downloadTranscript',
    onClick: () => requestPDF(`/v0/people/${personUuid}/transcript.pdf`, `Transcript for ${person.firstName} ${person.lastName}.pdf`),
  });

  const commands = checkCommands(allPersonCommands, personStore);

  if (FEATURES.TRAINING && FEATURES.WORD_CERTIFICATES && hasPermission(PERMISSION_TYPES.training, session)) {
    commands.push({
      title: 'Word Transcript',
      icon: <FileWordOutlined/>,
      onClick: () => requestFile(`/api/v0/people/${personUuid}/transcript.doc`, `Transcript for ${person.firstName} ${person.lastName}.doc`),
    });
  }

  const updateCourseDrawer = courseTakenUuid ? (
    <PersonCourseDrawer
      person={person}
      courseTakenUuid={courseTakenUuid}
      onSuccess={updateCurrent}
      show={!!courseTakenUuid}
    />
  ) : <></>;

  const updateAwardDrawer = (FEATURES.AWARDS && awardUuid) ? (
    <PersonAwardDrawer
      person={person}
      member={awardUuid}
      onSuccess={updateCurrent}
      show={!!awardUuid}
    />
  ) : <></>;

  const updateEquivalencyDrawer = (FEATURES.EQUIVALENCIES && equivalencyUuid) ? (
    <PersonEquivalencyDrawer
      equivalencyUuid={equivalencyUuid}
      isLearner={session.user?.uuid === personUuid}
      store={equivalencies}
      show={!!equivalencyUuid}
    />

  ) : <></>;

  const breadcrumbs = [
    {
      path: session.router.getUrl({ name: ROUTES.people }) || '',
      breadcrumbName: 'People',
    },
    {
      path: session.router.getUrl({ name: ROUTES.person }, { uuid: props.uuid }) || '',
      breadcrumbName: `${person.firstName} ${person.lastName}`,
    },
  ];

  return (
    <BRLayout
      commands={commands}
      breadcrumbs={breadcrumbs}
      loading={personStore.loading}
      tags={person?.status ? statusTagRenderer(person.status, person) : undefined}
      title={person?.firstName ? buildPersonDetailTitle(person) : 'Loading...'}
      descriptionItems={person && personStore.descriptionItems}
    >
      <TabMenu tabs={menuTabs} selected={tabId} />
      {updateCourseDrawer}
      {updateAwardDrawer}
      {updateEquivalencyDrawer}
    </BRLayout>
  );
}
