import * as React from 'react';
import { ICommandsAllowed } from '../types/ICommandsAllowed';
import { useBRFetch, IRequestParams } from './useBRFetch';

export interface BRStore<T = {}> extends BRStoreBase {
  fetch: () => Promise<T>;
  data: T;
}

export interface BRStoreBase {
  loading: boolean;
  loaded: boolean;
  fetch: () => Promise<any>;
  commands?: ICommandsAllowed;
  descriptionItems?: any;
}

type FetchDataParams = {
  url: string,
  resourceId?: string | number,
  queryParams?: IRequestParams,
};

type Props = {
  initialData?: any,
  fetchDataParams: FetchDataParams,
};

export const useBRStore = <T>(props: Props): BRStore<T> => {
  const [data, setData] = React.useState(props.initialData || { data: {} });
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [makeRequest] = useBRFetch();

  const fetch = React.useCallback(
    dataFetch,
    [props.fetchDataParams],
  );

  const { url, resourceId, queryParams } = props.fetchDataParams;

  async function dataFetch() {
    setLoaded(false);
    setLoading(true);
    const fetchUrl = resourceId ? `${url}/${resourceId}` : url;
    const response = await makeRequest(fetchUrl, queryParams);
    setLoading(false);
    setData(response);
    setLoaded(true);
    console.log(`returned BRData for ${fetchUrl}:`, response);
    return response;
  }

  return { ...data, loading, loaded, fetch };
};
