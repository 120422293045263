import React, { Dispatch, SetStateAction } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { DatePickerProps } from 'antd/lib/date-picker';

// Datepicker component
interface BRDatePickerProps {
  dateValue?: Date;
  setValue: Dispatch<SetStateAction<Date>>;
}

type ComboProps = BRDatePickerProps & DatePickerProps;

export const BRDatePicker: React.FunctionComponent<ComboProps> =
({ dateValue, setValue, ...props }) => {
  const handleChange = (date: moment.Moment | null) => {
    date && setValue(date.toDate());
  };

  return (
    <DatePicker
      {...props}
      value={moment(dateValue)}
      onChange={handleChange}
    />
  );
};
