import React, { useContext, useEffect } from 'react';
import {
  UserOutlined,
  ReadOutlined,
  SafetyCertificateOutlined,
  BookOutlined,
  SettingOutlined,
  DatabaseOutlined,
  MailOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  SecurityScanOutlined,
  ControlOutlined,
  HomeOutlined,
} from '@ant-design/icons';

import { SessionContext } from './components/App';
import { FEATURES } from './constants/features';
import { RouteSpec, Routes } from './hooks/useRoutes';
import { RequirementListPage } from './pages/RequirementListPage';
import { RequirementDetailPage } from './pages/RequirementDetailPage';
import { CourseListPage } from './pages/CourseListPage';
import { CourseDetailPage } from './pages/CourseDetailPage';
import { PeoplePage } from './pages/PeoplePage';
import { PersonPage } from './pages/PersonPage';
import { AuditListPage } from './pages/admin/AuditListPage';
import { AuditDetailPage } from './pages/admin/AuditDetailPage';
import { EventDetailPage } from './pages/admin/EventDetailPage';
import { OutboxListPage } from './pages/admin/OutboxListPage';
import { OutboxDetailPage } from './pages/admin/OutboxDetailPage';
import { ReportDetailPage } from './pages/ReportDetailPage';
import { ReportListPage } from './pages/ReportListPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { GroupsPage } from './pages/GroupsPage';
import { GroupDetailPage } from './pages/GroupDetailPage';
import { UnauthorizedPage } from './pages/UnauthorizedPage';
import { TranscriptListPage } from './pages/TranscriptListPage';
import { TenureEditorPage } from './pages/TenureEditorPage';
import { TenureRequestEditorPage } from './pages/TenureRequestEditorPage';
import { TranscriptDetailPage } from './pages/TranscriptDetailPage';
import { EquivalencyListPage } from './pages/admin/EquivalencyListPage';
import { PERMISSION_TYPES } from './helpers/hasPermission';
import { TemplateListPage } from './pages/TemplateListPage';
import { TemplateDetailPage } from './pages/TemplateDetailPage';
import { RulesListPage } from './pages/admin/RulesListPage';
import { RuleDetailPage } from './pages/admin/RuleDetailPage';
import { TenureRequestListPage } from './pages/admin/TenureRequestListPage';
import { TenureRequestDetailPage } from './pages/admin/TenureRequestDetailPage';
import { SettingsEditorPage } from './pages/admin/SettingsEditorPage';
import { CLIENT_CONST } from './constants';
import { RequirementCategoryListPage } from './pages/RequirementCategoryListPage';
import { HomePage } from './pages/HomePage';
import { RequirementCategoryDetailPage } from './pages/RequirementCategoryDetailPage';

export enum ROUTES {
  root,
  home,
  people,
  person,
  personTab,
  groups,
  group,
  training,
  requirements,
  requirement,
  requirementCategories,
  requirementCategory,
  courses,
  course,
  equivalency,
  transcripts,
  transcript,
  onboarding,
  approvalTemplates,
  audit,
  command,
  notifications,
  notification,
  outbox,
  outboxDetail,
  personCourse,
  personAward,
  reports,
  report,
  admin,
  tenures,
  tenure,
  tenureWizard,
  tenureRequest,
  tenureRequestWizard,
  event,
  adminEquivalency,
  adminEquivalencyDetail,
  rules,
  rule,
  templates,
  template,
  adminTenureRequest,
  adminTenureRequestDetail,
  settings,
  settingsWizard,
  unauthorized,
  // notFound MUST be last as it will match any path
  notFound,
}

interface RedirectProps {
  routeName: ROUTES;
  query?: any;
  [ index: string ]: any;
}

export const Redirect = (props: RedirectProps) => {
  const { session } = useContext(SessionContext);
  useEffect(() => {
    session.router.go({ name: props.routeName }, props, { query: props.query });
  },        []);
  return null;
};

export interface CoreRouteSpec extends RouteSpec<ROUTES> {
  title?: string;
  private?: boolean;
  icon?: JSX.Element;
}

export const routes: Routes<ROUTES, CoreRouteSpec> = {
  [ROUTES.root]: {
    path: '',
    private: true,
    fn: () => FEATURES.LEARNER_HOME ? <Redirect routeName={ROUTES.home} /> : <Redirect routeName={ROUTES.people} />,
    children: {
      [ROUTES.home]: FEATURES.LEARNER_HOME && {
        path: '/home',
        icon: <HomeOutlined />,
        title: 'Home',
        private: true,
        fn: () => <HomePage />,
      },
      [ROUTES.people]: {
        path: '/people',
        title: 'People',
        icon: <UserOutlined />,
        private: true,
        fn: () => <PeoplePage />,
        permissions: [PERMISSION_TYPES.people],
        children: {
          [ROUTES.person]: {
            path: '/:uuid(/:tab)',
            private: true,
            fn: ({ uuid, tab }) => <PersonPage uuid={uuid} tab={tab} />,
            children: {
              [ROUTES.tenure]: FEATURES.ONBOARDING && {
                path: '/tenures/:tenureUuid',
                private: true,
                permissions: [PERMISSION_TYPES.onboarding, PERMISSION_TYPES.tenures],
                fn: ({ uuid, tenureUuid }) => <TenureEditorPage uuid={uuid} tenureUuid={tenureUuid} />, // <TenurePage person={uuid} tenure={tenureUuid} />,
                children: {
                  [ROUTES.tenureWizard]: {
                    path: '/form(/:step)',
                    private: true,
                    fn: ({ uuid, tenureUuid, step }) => <TenureEditorPage uuid={uuid} tenureUuid={tenureUuid} step={step} />,
                  },
                },
              },
              [ROUTES.tenureRequest]: {
                path: '/tenurerequests/:tenureRequestUuid',
                private: true,
                fn: ({ uuid, tenureRequestUuid }) => <TenureRequestEditorPage uuid={uuid} tenureRequestUuid={tenureRequestUuid} />,
                children: {
                  [ROUTES.tenureRequestWizard]: {
                    path: '/form(/:step)',
                    private: true,
                    fn: ({ uuid, tenureRequestUuid, step }) => <TenureRequestEditorPage uuid={uuid} tenureRequestUuid={tenureRequestUuid} step={step} />,
                  },
                },
              },
              [ROUTES.equivalency]: FEATURES.EQUIVALENCIES && {
                path: '/equivalencies/:equivalencyUuid',
                private: true,
                permissions: [PERMISSION_TYPES.courses],
                fn: ({ uuid, equivalencyUuid }) => <PersonPage uuid={uuid} tab={'equivalencies'} equivalencyUuid={equivalencyUuid} />,
              },
              [ROUTES.personCourse]: {
                path: '/course/:courseTaken',
                private: true,
                permissions: [PERMISSION_TYPES.courses],
                fn: ({ uuid, courseTaken }) => <PersonPage uuid={uuid} tab={'courses'} courseTakenUuid={courseTaken}/>,
              },
              [ROUTES.personAward]: FEATURES.AWARDS && {
                path: '/awards/:award',
                private: true,
                permissions: [PERMISSION_TYPES.groups],
                fn: ({ uuid, award }) => <PersonPage uuid={uuid} tab={'awards'} awardUuid={award}/>,
              },
            },
          },
        },
      },
      [ROUTES.groups]: {
        path: '/groups',
        title: 'Groups',
        icon: <TeamOutlined />,
        private: true,
        permissions: [PERMISSION_TYPES.groups],
        fn: () => <GroupsPage />,
        children: {
          [ROUTES.group]: {
            path: '/:uuid(/:tab)',
            private: true,
            fn: ({ uuid }) => <GroupDetailPage uuid={uuid} />,
          },
        },
      },
      [ROUTES.training]: FEATURES.TRAINING && {
        path: '/training',
        title: 'Training',
        icon: <ReadOutlined />,
        permissions: [PERMISSION_TYPES.requirements, PERMISSION_TYPES.courses, PERMISSION_TYPES.transcripts],
        private: true,
        fn: () => <Redirect routeName={ROUTES.requirements} />,
        children: {
          [ROUTES.requirements]: {
            title: 'Requirements',
            path: '/requirements',
            permissions: [PERMISSION_TYPES.requirements],
            icon: <SafetyCertificateOutlined />,
            fn: () => <RequirementListPage />,
            private: true,
            children: {
              [ROUTES.requirement]: {
                path: '/:uuid(/:tab)',
                private: true,
                fn: ({ uuid }) => <RequirementDetailPage uuid={uuid} />,
              },
            },
          },
          [ROUTES.requirementCategories]: FEATURES.REQUIREMENT_CATEGORIES_PAGE && {
            title: 'Requirement Categories',
            path: '/requirementCategories',
            icon: <SafetyCertificateOutlined />,
            fn: () => <RequirementCategoryListPage />,
            private: true,
            children: {
              [ROUTES.requirementCategory]: {
                path: '/:uuid',
                private: true,
                fn: ({ uuid }) => <RequirementCategoryDetailPage uuid={uuid} />,
              },
            },
          },
          [ROUTES.courses]: {
            title: 'Courses',
            path: '/courses',
            permissions: [PERMISSION_TYPES.courses],
            icon: <BookOutlined />,
            fn: () => <CourseListPage />,
            private: true,
            children: {
              [ROUTES.course]: {
                path: '/:uuid(/:tab)',
                private: true,
                fn: ({ uuid }) => <CourseDetailPage uuid={uuid} />,
              },
            },
          },
          [ROUTES.transcripts]: {
            title: 'Transcripts',
            path: '/transcripts',
            permissions: [PERMISSION_TYPES.transcripts],
            icon: <UnorderedListOutlined />,
            fn: () => <TranscriptListPage />,
            private: true,
            children: {
              [ROUTES.transcript]: {
                path: '/:uuid(/:tab)',
                private: true,
                fn: ({ uuid }) => <TranscriptDetailPage uuid={uuid} />,
              },
            },
          },
        },
      },
      /* TODO: Re-enable this once it's more than a stub
      [ROUTES.onboarding]: FEATURES.ONBOARDING && {
        title: 'Onboarding',
        path: '/onboarding',
        icon: <UsergroupAddOutlined />,
        fn: () => <h1>Hello</h1>,
        private: true,
      },
      */
      [ROUTES.reports]: FEATURES.REPORTS && {
        title: 'Reports',
        path: '/reports',
        icon: <BookOutlined />,
        fn: () => <ReportListPage />,
        private: true,
        permissions: [PERMISSION_TYPES.reports],
        children: {
          [ROUTES.report]: {
            path: '/:type/:uuid(/:tab)',
            private: true,
            fn: ({ uuid, type }) => <ReportDetailPage uuid={uuid} type={type} />,
          },
        },
      },
      [ROUTES.admin]: {
        title: 'Admin',
        path: '/admin',
        icon: <SettingOutlined />,
        private: true,
        permissions: [PERMISSION_TYPES.audit, PERMISSION_TYPES.outbox, PERMISSION_TYPES.templates],
        fn: () => <Redirect routeName={ROUTES.audit} />,
        children: {
          [ROUTES.audit]: {
            title: 'Audit Log',
            path: '/log',
            permissions: [PERMISSION_TYPES.audit],
            icon: <DatabaseOutlined />,
            private: true,
            fn: () => <AuditListPage />,
            children: {
              [ROUTES.command]: {
                path: '/:uuid(/:tab)',
                private: true,
                permissions: [PERMISSION_TYPES.audit],
                fn: ({ uuid }) => <AuditDetailPage uuid={uuid} />,
                children: {
                  [ROUTES.event]: {
                    path: '/event/:id',
                    private: true,
                    permissions: [PERMISSION_TYPES.audit],
                    fn: ({ id, uuid }) => <EventDetailPage id={id} command={uuid} />,
                  },
                },
              },
            },
          },
          [ROUTES.outbox]: {
            title: 'Email Outbox',
            path: '/outbox',
            permissions: [PERMISSION_TYPES.outbox],
            icon: <MailOutlined />,
            private: true,
            fn: () => <OutboxListPage />,
            children: {
              [ROUTES.outboxDetail]: {
                path: '/:uuid(/:tab)',
                private: true,
                fn: ({ uuid }) => <OutboxDetailPage uuid={uuid} />,
              },
            },
          },
          [ROUTES.adminEquivalency]: FEATURES.EQUIVALENCIES && {
            title: 'Equivalency Review',
            path: '/equivalencies',
            permissions: [PERMISSION_TYPES.courses],
            icon: <UnorderedListOutlined />,
            private: true,
            fn: () => <EquivalencyListPage />,
            children: {
              [ROUTES.adminEquivalencyDetail]: {
                path: '/:uuid',
                private: true,
                fn: ({ uuid }) => <EquivalencyListPage uuid={uuid} />,
              },
            },
          },
          [ROUTES.templates]: FEATURES.TEMPLATE_EDITOR && {
            title: 'Template Center',
            path: '/templates',
            permissions: [PERMISSION_TYPES.templates],
            icon: <BookOutlined/>,
            private: true,
            fn: () => <TemplateListPage/>,
            children: {
              [ROUTES.template]: {
                path: '/:uuid(/:tab)',
                private: true,
                fn: ({ uuid }) => <TemplateDetailPage uuid={uuid}/>,
              },
            },
          },
          [ROUTES.rules]: FEATURES.RULE_EDITOR && {
            title: 'Access Rules',
            path: '/rules',
            // rule editor not yet in use... will need permission later
            icon: <SecurityScanOutlined />,
            private: true,
            fn: () => <RulesListPage />,
            children: {
              [ROUTES.rule]: {
                path: '/:uuid(/:tab)',
                private: true,
                fn: ({ uuid }) => <RuleDetailPage uuid={uuid} />,
              },
            },
          },
          [ROUTES.adminTenureRequest]: FEATURES.ONBOARDING && false && {
            title: `${CLIENT_CONST.TENURE_TERM} Request Review`,
            path: '/tenurerequests',
            permissions: [PERMISSION_TYPES.tenures],
            icon: <UnorderedListOutlined />,
            private: true,
            fn: () => <TenureRequestListPage />,
            children: {
              [ROUTES.adminTenureRequestDetail]: {
                path: '/:tenureRequestUuid',
                private: true,
                fn: ({ tenureRequestUuid }) => <TenureRequestDetailPage tenureRequestUuid={tenureRequestUuid} />,
              },
            },
          },
          [ROUTES.settings]: FEATURES.SETTINGS && {
            title: 'Settings',
            path: '/settings',
            icon: <ControlOutlined />,
            private: true,
            fn: () => <SettingsEditorPage />,
            children: {
              [ROUTES.settingsWizard]: {
                path: '/form(/:step)',
                private: true,
                fn: ({ step }) => <SettingsEditorPage step={step} />,
              },
            },
          },
        },
      },
    },
  },
  [ROUTES.unauthorized]: {
    path: '/unauthorized',
    fn: () => <UnauthorizedPage />,
  },
  [ROUTES.notFound]: {
    path: '*any',
    fn: () => <NotFoundPage />,
  },
};
