import * as React from 'react';
import { TABLE_IDS } from '../constants';
import { BRLayout } from '../components/BRLayout';
import BRTable from '../components/BRTable';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { ROUTES } from '../routes';
import { ITemplate } from '../types/ITemplate';
import { SessionContext } from '../components/App';
import { PlusOutlined } from '@ant-design/icons';
import { NewTemplateModal } from '../components/modals/NewTemplateModal';
import { Command } from '../components/CommandButtons';

interface IProps {
  type?: string;
  resource?: string;
}

export function TemplateListPage(props: IProps) {
  const { session } = React.useContext(SessionContext);

  const templates = useBRTableStore<ITemplate>({
    fetchDataParams: { url: '/v0/templates', queryParams: { type: props.type, resource: props.resource } },
  });

  const commands: Command[] = [];
  commands.push({
    title: 'New Template',
    icon: <PlusOutlined />,
    modal: (
      <NewTemplateModal
        resourceLabel={'Default'}
        onSuccess={templates.fetch}
      />
    ),
  });

  return (
    <BRLayout
      title={session.router.current.spec.title}
      commands={commands}
      loading={templates.loading}
    >
      <BRTable
        tableId={TABLE_IDS.TEMPLATES_TABLE}
        linkPath={ROUTES.template}
        tableData={templates}
      />
    </BRLayout>
  );
}
