import { ICommand, INotification, IPerson, IReport } from '../types';
import { reportsTypeNames } from '../constants';
import { IEvent } from '../types/IEvent';

export function buildPersonDetailTitle(person: IPerson) {
  return `${person.firstName} ${person.lastName}`;
}

export function buildReportDetailTitle(reportDetail: IReport) {
  const { firstRun, type } = reportDetail;
  const date = firstRun ? (new Date(firstRun)).toLocaleString() : '';
  return `${reportsTypeNames[type || ''] || ''} ${date}`;
}

export function buildNotificationDetailTitle(notificationDetail: INotification) {
  return `Notification ${notificationDetail.id}`;
}

export function buildCommandDetailTitle(commandDetail: ICommand) {
  if (commandDetail?.uuid) {
    return `Command ${commandDetail.type}`;
  }
  return 'Loading command details...';
}

export function buildEventDetailTitle(eventDetail: IEvent) {
  if (eventDetail?.id) {
    return `${eventDetail.type} Event`;
  }
  return 'Loading event details...';
}
