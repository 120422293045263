import * as React from 'react';
import { BRLayout } from '../components/BRLayout';
import { checkCommands } from '../helpers/checkCommands';
import { Command, CommandButtons } from '../components/CommandButtons';
import { DetailCards } from '../components/DetailCards';
import { useBRStore } from '../hooks/useBRStore';
import { IPerson, IPersonRequirement, RESOURCE_STATUS } from '../types';
import { Col, Modal, Row, Table, Tabs, Tooltip } from 'antd';
import { useBRListData } from '../hooks/useBRListData';
import { SessionContext } from '../components/App';
import { DownloadOutlined, FilePdfOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { RequestEquivalencyModal } from '../components/modals/RequestEquivalencyModal';
import { hasPermission, PERMISSION_TYPES } from '../helpers/hasPermission';
import { CUSTOM_TEXT, FEATURES } from '../constants/features';
import { GenerateCertificateModal } from '../components/modals/GenerateCertificateModal';
import { requestPDF } from '../helpers/requestPDF';
import { statusTagRenderer } from '../helpers/dataRenderers';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { ICourseStatus } from '../types/ICourseStatus';
import { DATE_OPTIONS } from '../constants';
import { StatusAvatar } from '../components/StatusAvatar';

export function HomePage() {
  const { session } = React.useContext(SessionContext);
  const currentUser = session.user;
  const person = currentUser ? currentUser.uuid : '';

  const admin = hasPermission(PERMISSION_TYPES.audit, session);

  const url = `/v0/people/${person}`;

  const personStore = useBRStore<IPerson>({
    fetchDataParams: React.useMemo(() => ({ url }), [url]),
  });

  const courses = useBRTableStore<ICourseStatus>({
    fetchDataParams: React.useMemo(() => ({ url: `${url}/coursesStatus` }), [url]),
  });

  function update() {
    personStore.fetch().catch((e) => {
      Modal.error({ title: 'Unable to load person.', content: e.text ?? 'Unknown error.' });
    });
    courses.fetch();
  }

  React.useEffect(update, [currentUser]);

  const requirements = useBRListData<IPersonRequirement>({
    fetchDataParams: React.useMemo(() => ({ url: `${url}/requirements`, queryParams: { all: true } }), [url]),
  });

  const allPersonCommands: Command[] = [
    {
      title: 'Request Course Equivalency',
      icon: <QuestionCircleOutlined />,
      type: 'createEquivalency',
      modal: (
        <RequestEquivalencyModal
          person={person}
          onSuccess={update}
        />
      ),
      enabled: !!currentUser,
    },
  ];

  if (currentUser && FEATURES.TRAINING) {
    allPersonCommands.push({
      title: 'Generate Certificate',
      icon: <DownloadOutlined />,
      type: 'generateCertificate',
      modal: <GenerateCertificateModal person={currentUser} homePage={true} />,
    });

    allPersonCommands.push({
      title: 'Download Transcript',
      icon: <FilePdfOutlined />,
      type: 'downloadTranscript',
      onClick: () => requestPDF(`/v0/people/${person}/transcript.pdf`, `Transcript for ${currentUser.firstName} ${currentUser.lastName}.pdf`),
    });
  }

  const commands = checkCommands(allPersonCommands, personStore);

  const completeFilter = [RESOURCE_STATUS.complete];
  const notCompleteFilter = [RESOURCE_STATUS.actionRequired, RESOURCE_STATUS.expired, RESOURCE_STATUS.expiring, RESOURCE_STATUS.incomplete];

  const title = personStore.data?.firstName ? `${personStore.data.firstName} ${personStore.data.lastName}` : '';
  const status = personStore.data?.status ? statusTagRenderer(personStore.data.status, personStore.data) : <></>;

  const courseStatusColumns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return <StatusAvatar size={36} status={status} />;
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, row: ICourseStatus) => {
        return row.link ? (<a href={row.link}>{name}</a>) : name;
      },
      sorter: {
        compare: (left: ICourseStatus, right: ICourseStatus) => (left.name ?? '').localeCompare(right.name ?? ''),
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date: number) => {
        return (new Date(date)).toLocaleDateString('en-US', DATE_OPTIONS);
      },
      sorter: {
        compare: (left: ICourseStatus, right: ICourseStatus) => left.date - right.date,
        defaultSortOrder: 'descend'
      },
      showSorterTooltip: false,
    }
  ];

  return (
    <BRLayout
      title={title}
      commands={commands}
      tags={status}
    >
    <Row>
      <Col span={12}>
          <div className="ant-page-header-heading-title">
            Training
          </div>
          <Tabs>
            <Tabs.TabPane tab={<Tooltip title={CUSTOM_TEXT.HOME_PAGE_REQUIRED_TOOLTIP}><span>Required <QuestionCircleOutlined /></span></Tooltip>} key="required">
              <DetailCards
                person={personStore}
                requirements={requirements}
                mandatory={true}
                description={true}
                admin={admin}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<Tooltip title={CUSTOM_TEXT.HOME_PAGE_RECOMMENDED_TOOLTIP}><span>Recommended <QuestionCircleOutlined /></span></Tooltip>} key="recommended">
              <DetailCards
                person={personStore}
                requirements={requirements}
                mandatory={false}
                description={true}
                admin={admin}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<Tooltip title={CUSTOM_TEXT.HOME_PAGE_COMPLETED_TOOLTIP}><span>Completed <QuestionCircleOutlined /></span></Tooltip>} key="completed">
              <DetailCards
                person={personStore}
                requirements={requirements}
                description={true}
                admin={admin}
                initialStatusFilter={completeFilter}
                applies={false}
              />
            </Tabs.TabPane>
          </Tabs>

      </Col>
      <Col span={12}>
          <div className="ant-page-header-heading-title">
            Courses
          </div>
          <Tabs>
            <Tabs.TabPane tab={<Tooltip title={CUSTOM_TEXT.HOME_PAGE_COURSES_TOOLTIP}><span>Your Courses <QuestionCircleOutlined /></span></Tooltip>} key="courses">
              <Table columns={courseStatusColumns} dataSource={courses.records} pagination={false} rowKey={record => `${record.course}-${record.date}`} footer={() => (<></>)} />
            </Tabs.TabPane>
          </Tabs>

      </Col>
    </Row>
    </BRLayout>
  );
}
