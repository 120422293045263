import React, { useEffect, createContext, useReducer, useState } from 'react';
import qs from 'qs';
import { useRoutes } from '../hooks/useRoutes';
import { PublicPageLayout } from './PublicPageLayout';
import { Session, SessionAction } from '../types/Session';
import { LoginForm } from './LoginForm';
import { CLIENT_CONST } from '../constants';
import { Button, Result } from 'antd';
import { CoreRouteSpec, routes, ROUTES } from '../routes';
import sad_rabbitJpg from '../images/sad_rabbit.jpg';
import { useBRFetch } from '../hooks/useBRFetch';
import { API_ROUTES } from '../constants/apiRoutes';
import { hasPermission } from '../helpers/hasPermission';
import { UnauthorizedPage } from '../pages/UnauthorizedPage';
import { FullSpin } from './FullSpin';

function sessionReducer(state: Session<ROUTES, CoreRouteSpec>, action: SessionAction<ROUTES>): Session<ROUTES, CoreRouteSpec> {
  let out = state;
  switch (action.type) {
    case 'reset':
      if (!action.impersonate) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('key');
      }
      out = {
        router: state.router,
        reset: true
      };
      break;
    case 'siteDown':
      out = {
        ...state,
        siteDown: true,
      };
      break;
    case 'changeRoute':
      out = {
        ...state,
        router: action.router,
      };
      break;
    case 'userData':
      out = {
        router: state.router,
        user: action.user,
        scope: action.scope,
        reset: false
      };
      break;
    default:
      throw new Error('invalid SessionAction');
  }
  // console.warn('ACTION', action, '\nNEW STATE', out);
  return out;
}

export const SessionContext = createContext({
  session: {} as Session<ROUTES, CoreRouteSpec>,
  dispatch: (action: SessionAction<ROUTES>) => { },
});

export const App: React.FC = () => {
  const router = useRoutes<ROUTES, CoreRouteSpec>(routes);
  const [session, dispatch] = useReducer(sessionReducer, { router });
  const [makeRequest] = useBRFetch();

  useEffect(() => {
    if (router.current.spec.title) {
      window.document.title = router.current.spec.title;
    }
    // dispatch({
    //   router,
    //   type: 'changeRoute',
    // });
  },        [router]);

  session.router = router;

  if (session.siteDown) {
    const reload = () => window.location.reload();
    return (
      <Result
        icon={<img src={sad_rabbitJpg} />}
        title="Sad News"
        subTitle="Something went wrong talking to the server. It may be down for maintenance."
        extra={<Button type="primary" onClick={reload}>Try Again</Button>}
      />
    );
  }

  if (!session.user) {
    const user = localStorage.getItem('user');
    const scope = localStorage.getItem('scope');
    if (user) {
      dispatch({
        type: 'userData',
        user: JSON.parse(user),
        scope: (scope || '').split(' '),
      });
      return null;
    }

    if (CLIENT_CONST.AUTH_METHOD === 'cayuse') {
      if (!window.location.hash) {
        makeRequest(API_ROUTES.properties).then(({ properties }) => {
          let location = window.location.origin;
          if (!location.includes('localhost')) {
            location = encodeURIComponent(`${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}${window.location.pathname}`);
          }
          if (session.reset) {
            window.location.href = `${properties.OAUTH_LOGOUT_URI}?client_id=${properties.OAUTH_CLIENT_ID}&logout_uri=${location}&redirect_uri=${location}`;
          } else {
            window.location.href = `${properties.OAUTH_AUTH_URI}?client_id=${properties.OAUTH_CLIENT_ID}&tenant_id=${properties.TENANT_ID}&redirect_uri=${location}&scope=${properties.OAUTH_SCOPE}&response_type=${properties.OAUTH_RESPONSE_TYPE}&state=${window.location.pathname}`;
          }
        });
        return <FullSpin />;
      }
    }
    if (CLIENT_CONST.AUTH_METHOD === 'oauth') {
      if (!window.location.hash) {
        const { OAUTH_CLIENT_ID, OAUTH_AUTH_URI, OAUTH_SCOPE, OAUTH_RESPONSE_TYPE } = CLIENT_CONST;
        window.location.href = `${OAUTH_AUTH_URI}?client_id=${OAUTH_CLIENT_ID}&redirect_uri=${window.location.origin}&scope=${OAUTH_SCOPE}&response_type=${OAUTH_RESPONSE_TYPE}&state=${window.location.pathname}`;
        return <FullSpin />;
      }
    }
    if (CLIENT_CONST.AUTH_METHOD === 'azure') {
      if (!window.location.hash) {
        const { OAUTH_CLIENT_ID, OAUTH_AUTH_URI, OAUTH_SCOPE, OAUTH_RESPONSE_TYPE } = CLIENT_CONST;
        window.location.href = `${OAUTH_AUTH_URI}?client_id=${OAUTH_CLIENT_ID}&redirect_uri=${window.location.origin}&scope=${OAUTH_SCOPE}&response_type=${OAUTH_RESPONSE_TYPE}&response_mode=fragment`;
        return <FullSpin />;
      }
    }
    if (CLIENT_CONST.AUTH_METHOD === 'local') {
      return (
        <SessionContext.Provider value={{ session, dispatch }}>
          <PublicPageLayout title={`Welcome to ${CLIENT_CONST.TITLE}`}>
            <LoginForm />
          </PublicPageLayout>
        </SessionContext.Provider>
      );
    }
  }

  if ((CLIENT_CONST.AUTH_METHOD === 'oauth' || CLIENT_CONST.AUTH_METHOD === 'cayuse') && window.location.hash) {
    const encoded = window.location.hash.split('#', 2)[1];
    const data = qs.parse(encoded) as any;
    if (data.access_token) {
      return (
        <SessionContext.Provider value={{ session, dispatch }}>
          <PublicPageLayout title={`Welcome to ${CLIENT_CONST.TITLE}`}>
            <LoginForm accessToken={data.access_token} nextPath={data.state} />
          </PublicPageLayout>
        </SessionContext.Provider>
      );
    }
  }

  if (CLIENT_CONST.AUTH_METHOD === 'azure' && window.location.hash) {
    const encoded = window.location.hash.split('#', 2)[1];
    const data = qs.parse(encoded) as any;
    if (data.code) {
      return (
        <SessionContext.Provider value={{ session, dispatch }}>
          <PublicPageLayout title={`Welcome to ${CLIENT_CONST.TITLE}`}>
            <LoginForm accessToken={data.code} />
          </PublicPageLayout>
        </SessionContext.Provider>
      );
    }
  }

  if (router.current.spec.permissions && !hasPermission(router.current.spec.permissions, session)) {
    return (
      <SessionContext.Provider value={{ session, dispatch }}>
        <UnauthorizedPage />
      </SessionContext.Provider>
    );
  }

  return (
    <SessionContext.Provider value={{ session, dispatch }}>
      {router.current.result}
    </SessionContext.Provider>
  );
};
