import * as React from 'react';
import { BRLayout } from '../components/BRLayout';
import BRTable from '../components/BRTable';
import { IPerson } from '../types';
import { TABLE_IDS } from '../constants';
import { useBRTableStore } from '../hooks/useBRTableStore';
import {
  CreateUnlinkedAALASAccountModal,
} from '../components/modals/CreateUnlinkedAALASAccountModal';
import { ROUTES } from '../routes';
import { NewPersonModal } from '../components/modals/NewPersonModal';
import { PlusOutlined } from '@ant-design/icons';
import { checkCommands } from '../helpers/checkCommands';
import { UploadCSVModal } from '../components/modals/UploadCSVModal';

interface Props {

}

export function PeoplePage(props: Props) {

  const people = useBRTableStore<IPerson>({
    fetchDataParams: { url: '/v0/people' },
  });

  const allPeopleCommands = [
    {
      title: 'Create Unlinked AALAS Account',
      icon: <PlusOutlined />,
      type: 'createUnlinkedAALASAccount',
      modal: (
        <CreateUnlinkedAALASAccountModal
          onSuccess={people.fetch}
        />
      ),
    },
    {
      title: 'Create Person',
      icon: <PlusOutlined />,
      type: 'newPerson',
      modal: (
        <NewPersonModal
          onSuccess={people.fetch}
        />
      ),
    },
    {
      title: 'Bulk Import',
      icon: <PlusOutlined />,
      type: 'bulkImport',
      modal: (
        <UploadCSVModal
          onSuccess={people.fetch}
        />
      ),
    },
  ];

  const commands = checkCommands(allPeopleCommands, people);

  return (
    <BRLayout
      loading={people.loading}
      title="People"
      commands={commands}
    >
      <BRTable
        linkPath={ROUTES.person}
        tableId={TABLE_IDS.PEOPLE_PAGE_TABLE}
        tableData={people}
      />
    </BRLayout>
  );
}
