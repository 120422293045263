import React, { useState, ChangeEvent } from 'react';
import { Form, Input } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments } from '../BRForm';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';

export const CreateRequirementCategoryModal: React.FunctionComponent<BRModalProps> =
({ ...modalProps }) => {

  const [name, setName] = useState('');
  const [comments, setComments] = useState('');

  const onFinish = async () => {
    const formData = {
      name,
      comments,
    };

    await sendCommand({
      formData,
      command: COMMANDS.CREATE_REQUIREMENT_CATEGORY,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      default:
        break;
    }
  };

  const title = 'Create New Requirement Category';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Create Requirement Category"
      formProps={{ onFinish }}
    >
        <Form.Item label="Requirement Category Name">
          <Input
            onChange={handleChange}
            value={name}
            type="text"
            name="name"
            required={true}
            minLength={5}
          />
        </Form.Item>

        <BRFormComments comments={comments} setComments={setComments}/>

    </BRModal>
  );
};
