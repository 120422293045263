import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { IRequirement, IRequirementCategory } from '../../types';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { API_ROUTES } from '../../constants/apiRoutes';
import { CategorizedCascader } from '../CategorizedCascader';
import { FEATURES } from '../../constants/features';

interface Props extends BRModalProps {
  requirement?: IRequirement;
  category?: IRequirementCategory;
}
const categoryDataParams = { url: API_ROUTES.requirementCategories, queryParams: { all: true } };
const requirementDataParams = { url: API_ROUTES.requirements, queryParams: { basic: true } };

export const AddRequirementToCategoryModal: React.FunctionComponent<Props> =
({ requirement: importedRequirement, category: importedCategory, ...modalProps }) => {

  const categories = useBRListData<IRequirementCategory>({
    fetchDataParams: categoryDataParams,
  });

  const requirements = useBRListData<any>({ // "any" because "basic" requirement results only include category name in "categories" array
    fetchDataParams: requirementDataParams,
  });

  const [category, setCategory] = useState(importedCategory?.uuid ?? '');
  const [requirement, setRequirement] = useState(importedRequirement?.uuid ?? '');
  const [comments, setComments] = useState('');

  useEffect(
    () => {
      if (!importedCategory) {
        categories.fetch();
      }
    },
    []);

  useEffect(
    () => {
      if (!importedRequirement) {
        requirements.fetch();
      }
    },
    []);

  const onFinish = async () => {
    const formData: {
      requirement: string,
      category: string,
      comments: string,
    } = {
      requirement,
      category,
      comments,
    };

    await sendCommand({
      formData,
      command: COMMANDS.ADD_REQUIREMENT_TO_CATEGORY,
    });
  };

  function mapValue(value: any) {
    return {
      key: value.uuid,
      value: value.uuid,
      label: value.name || value.uuid,
    };
  }

  const categoryOptions = (importedCategory ? [importedCategory] : categories.records).filter(c => !importedRequirement?.categories?.find(rc => rc.uuid === c.uuid)).map(mapValue);
  const filteredRequirements = (importedRequirement ? [importedRequirement] : requirements.records).filter(r => !r.categories?.find((rc: string) => rc === importedCategory?.name));
  const requirementOptions = filteredRequirements.map(mapValue);
  const title = `Add ${importedRequirement ? importedRequirement.name : 'Requirement' } to ${importedCategory ? importedCategory.name : 'Category' }`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Add to Category"
      formProps={{ onFinish }}
    >
      <Form.Item label="Add Requirement">
        {FEATURES.CATEGORIZED_REQUIREMENT_SELECTOR ? (
          <CategorizedCascader
            resources={filteredRequirements}
            setResourceUuid={setRequirement}

            disabled={!!importedRequirement}
          />
        ) : (
          <BRSelect
            options={requirementOptions}
            setValue={setRequirement}
            loading={requirements.loading}
            disabled={!!importedRequirement}
            value={importedRequirement && requirementOptions[0]}
          />
        )}
      </Form.Item>

      <Form.Item label="To Category">
        <BRSelect
          options={categoryOptions}
          setValue={setCategory}
          disabled={!!importedCategory}
          value={importedCategory && categoryOptions[0]}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
