import React, { useState } from 'react';
import { IPersonRequirement, RESOURCE_STATUS, IPerson } from '../../types';
import { List, Drawer, Button, Space, message, Tooltip } from 'antd';
import { COMMANDS, DATE_OPTIONS } from '../../constants';
import { Link } from '../../hooks/useRoutes';
import { SessionContext } from '../App';
import { ROUTES } from '../../routes';
import { PersonRequirementDrawer } from '../drawers/PersonRequirementDrawer';
import { ModalContext } from '../BRModal';
import { RemoveRequirementFromPersonModal } from '../modals/RemoveRequirementFromPersonModal';
import { BRStore } from '../../hooks/useBRStore';
import { BRListData } from '../../hooks/useBRListData';
import { StatusAvatar } from '../StatusAvatar';
import { hasPermission } from '../../helpers/hasPermission';
import { PERMISSION_TYPES } from '../../helpers/hasPermission';
import { checkCommands } from '../../helpers/checkCommands';
import { PlusCircleOutlined } from '@ant-design/icons';

interface Props {
  pr: IPersonRequirement;
  person: BRStore<IPerson>;
  requirements: BRListData<IPersonRequirement>;
  optional?: boolean;
  admin: boolean;
}

export function ListItem(props: Props) {

  const { session } = React.useContext(SessionContext);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modal, setModal] = React.useState<React.ReactNode>();

  const openDrawer = () => setDrawerVisible(true);

  const closeDrawer = () => {
    setModal(undefined);
    setDrawerVisible(false);
  };

  const onModalSuccess = () => {
    message.success('Remove requirement completed successfully.');
    props.person.fetch();
    props.requirements.fetch();
    closeDrawer();
  };

  const removeModal = (
    <RemoveRequirementFromPersonModal
      person={props.person.data}
      requirement={props.pr.uuid}
      onSuccess={onModalSuccess}
    />
  );

  const openRemoveModal = () => {
    setModal(removeModal);
  };

  const closeModal = () => { setModal(undefined); };

  function getExpirationText(pr: IPersonRequirement) {
    let date: number | undefined;
    switch (pr.status) {
      case RESOURCE_STATUS.complete:
        date = pr.completedDate;
        if (date) {
          const formatted = (new Date(date)).toLocaleDateString('en-US', DATE_OPTIONS);
          const date2 = pr.endDate;
          if (date2) {
            const formatted2 = (new Date(date2)).toLocaleDateString('en-US', DATE_OPTIONS);
            return `Completed on ${formatted} — Expires on ${formatted2}`;
          }
          return `Completed on ${formatted}`;
        }
        return 'Complete';
      case RESOURCE_STATUS.expired:
        date = pr.endDate;
        if (date) {
          const formatted = (new Date(date)).toLocaleDateString('en-US', DATE_OPTIONS);
          return `Expired on ${formatted}`;
        }
        return 'Expired';
      case RESOURCE_STATUS.expiring:
        date = pr.endDate;
        if (date) {
          const formatted = (new Date(date)).toLocaleDateString('en-US', DATE_OPTIONS);
          return `Expiring on ${formatted}`;
        }
        return 'Expiring';
    }
    return pr.status || 'Status Not Calculated';
  }

  const { pr } = props;
  const expiration = getExpirationText(pr);

  
  const removeRequirementCommand = {
    title: 'Remove Requirement',
    icon: <></>,
    type: COMMANDS.REMOVE_REQUIREMENT_FROM_PERSON,
  };
  const formatManualText = (manuallyAddedUntil: number) => {
    const manualDate = new Date(manuallyAddedUntil);
    const yearsFromNow = new Date();
    yearsFromNow.setFullYear((new Date()).getFullYear() + 10);
    const thingy = manualDate < yearsFromNow ? manualDate.toDateString() : '> 10 years';
    return <Tooltip placement="left" title={`Manually Added Until: ${thingy}`}><PlusCircleOutlined></PlusCircleOutlined></Tooltip>;
  }

  const manuallyAddedUntilText = pr.manuallyAddedUntil
  ? formatManualText(pr.manuallyAddedUntil)
  : '';

  const requirementTitle = hasPermission(PERMISSION_TYPES.audit, session)
    ? <><Link href={session.router.getUrl({ name: ROUTES.requirement }, { uuid: `${pr.uuid}` })}>{pr.name || 'Requirement'}</Link> Details {manuallyAddedUntilText}</>
    : <>{pr.name || 'Requirement'} Details</>;

  return (
    <>
      <List.Item
        onClick={openDrawer}
        className="site-clickable"
      >
        <List.Item.Meta
          avatar={
            <StatusAvatar status={pr.status || 'Default'} />
          }
          title={pr.name}
          description={expiration}
        />
        <div
          style={{ width: '100px' }}
        >
          {manuallyAddedUntilText}
        </div>
      </List.Item>
      <Drawer
        title={requirementTitle}
        placement="right"
        width={window.innerWidth > 810 ? 810 : '100%'}
        closable={true}
        onClose={closeDrawer}
        visible={drawerVisible}
        destroyOnClose={true}
        footer={(
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Button onClick={closeDrawer}>Close</Button>
              { checkCommands([removeRequirementCommand], props.person).length && pr.manuallyAddedUntil ? (
                  <Button type="primary" danger={true} onClick={openRemoveModal}>{removeRequirementCommand.title}</Button>
              ) : <></>}
            </Space>
          </div>
        )}
      >
        <PersonRequirementDrawer pr={props.pr} person={props.person} requirements={props.requirements} requirement={props.pr.uuid} admin={props.admin}/>
      </Drawer>
      {!!modal && (
        <ModalContext.Provider value={{ close: closeModal, onSuccess: closeModal, visible: !!modal }}>
          {modal}
        </ModalContext.Provider>
      )}
    </>
  );
}
