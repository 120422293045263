import { CLIENT_CONST } from '../constants';

export const FORM_TYPE_NAMES = {
  history: 'History',
  tenure: `${CLIENT_CONST.TENURE_TERM}`,
};

export const FORM_TYPE_ICONS = {
  history: 'edit',
  tenure: 'user',
};

export const FORM_TYPE_ICON_COLORS = {
  history: 'grey',
  tenure: '#8800aa',
};
