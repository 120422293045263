import * as React from 'react';
import { BRLayout } from '../components/BRLayout';
import { TABLE_IDS } from '../constants';
import BRTable from '../components/BRTable';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { NewCourseModal } from '../components/modals/NewCourseModal';
import { UploadCSVModal } from '../components/modals/UploadCSVModal';
import { API_ROUTES } from '../constants/apiRoutes';
import { SessionContext } from '../components/App';
import { ROUTES } from '../routes';
import { PlusOutlined } from '@ant-design/icons';
import { checkCommands } from '../helpers/checkCommands';

interface Props {

}

export function CourseListPage(props: Props) {
  const { session } = React.useContext(SessionContext);

  const courses = useBRTableStore({
    fetchDataParams: { url: API_ROUTES.courses },
  });

  const allCourseListCommands = [
    {
      title: 'New Course',
      icon: <PlusOutlined />,
      type: 'newCourse',
      modal: (
        <NewCourseModal
          onSuccess={courses.fetch}
        />
      ),
    },
    // {
    //   title: 'Bulk Import',
    //   icon: <PlusOutlined />,
    //   type: 'bulkImport',
    //   modal: (
    //     <UploadCSVModal
    //       onSuccess={courses.fetch}
    //     />
    //   ),
    // },
  ];

  const commands = checkCommands(allCourseListCommands, courses);

  return (
    <BRLayout
      loading={courses.loading}
      title={session.router.current.spec.title}
      commands={commands}
    >
      <BRTable
        linkPath={ROUTES.course}
        tableId={TABLE_IDS.COURSES_PAGE_TABLE}
        tableData={courses}
      />
    </BRLayout>
  );
}
