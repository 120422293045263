import * as React from 'react';
import { BRLayout } from '../components/BRLayout';
import BRTable from '../components/BRTable';
import { Command } from '../components/CommandButtons';
import { TABLE_IDS } from '../constants';
import { checkCommands } from '../helpers/checkCommands';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { ROUTES } from '../routes';
import { IGroup } from '../types/IGroup';

interface Props {

}

export function GroupsPage(props: Props) {

  const groups = useBRTableStore<IGroup>({ fetchDataParams: { url: '/v0/groups' } });

  const allGroupListCommands: Command[] = [];

  const commands = checkCommands(allGroupListCommands, groups);

  return (
    <BRLayout
      loading={groups.loading}
      title="Groups"
      commands={commands}
    >
      <BRTable
        linkPath={ROUTES.group}
        tableId={TABLE_IDS.GROUPS_PAGE_TABLE}
        tableData={groups}
      />
    </BRLayout>
  );
}
