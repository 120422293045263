import { FormSpec } from './FormSpec';

export enum FormType {
  history = 'history',
  tenure = 'tenure',
  tenureRequest = 'tenureRequest',
  settings = 'settings',
}

export enum FormFieldType {
  string = 'string',
  phone = 'phone',
  boolean = 'boolean',
  email = 'email',
}

export type FormSpecMap = { [ index: string ]: FormSpec };
