import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { IPerson } from '../../types';
import { sendCommand } from '../../services/commands.services';
import { CLIENT_CONST, COMMANDS } from '../../constants';
import { useBRListData } from '../../hooks/useBRListData';
import { ROUTES } from '../../routes';
import { SessionContext } from '../App';
import { BRLargeDataSelect } from '../BRForm';

const peopleDataParams = { url: '/v0/people', queryParams: { basic: true } };

export const RunTenureSponsorReportModal: React.FunctionComponent<BRModalProps> =
({ ...modalProps }) => {

  const { session } = useContext(SessionContext);

  const [sponsors, setSponsors] = useState<string[]>([]);

  const people = useBRListData<IPerson>({
    fetchDataParams: peopleDataParams,
  });

  useEffect(
    () => {
        people.fetch();
      },
      [people.fetch]);

  const onFinish = async () => {
    const formData = {
      type: 'tenureSponsorReport',
      sponsors,
    };

    const response = await sendCommand({
      formData,
      command: COMMANDS.RUN_REPORT,
    });

    session.router.go({ name: ROUTES.report }, response);
  };

  const sponsorsOptions = people.records &&
    people.records.map(p => ({
      key: p.uuid,
      label: `${p.firstName} ${p.lastName}`,
      value: p.uuid || '',
    }));

  const title = ` ${CLIENT_CONST.TENURE_TERM}s by Principal Investigator/Sponsor Report`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Run Report"
      formProps={{ onFinish }}
    >
      <Form.Item
        label="Principal Investigator(s)/Sponsor(s)"
      >
        <BRLargeDataSelect
          setValue={setSponsors}
          options={sponsorsOptions}
          mode="multiple"
        />
      </Form.Item>

    </BRModal>
  );
};
