import * as React from 'react';
import { Button, Drawer, message, Space } from 'antd';

import { BRForm } from '../BRForm';
import { FormProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';

export interface BRDrawerProps extends DrawerProps {
  show: boolean;
  onClose?: () => void;
  okText?: string;
  showOk?: boolean;
  dirty?: boolean;
  buttons?: React.ReactNode[];
  cancelText?: string;
}

export const BRDrawer: React.FunctionComponent<BRDrawerProps & {formProps?: FormProps}> =
({
   formProps = {},
   show,
   onClose,
   showOk = true,
   okText,
   dirty,
   cancelText,
   ...props
}) => {
  const [visible, setVisible] = React.useState(true);

  React.useEffect(() => {
    setVisible(show);
  },
                  [show],
  );

  const closeModal = () => {
    setVisible(false);
    onClose && onClose();
  };

  const onOk = async (e: any) => {
    try {
      formProps.onFinish && formProps.onFinish(e);
      closeModal();
    } catch (e: any) {
      message.error(e.message);
    }
  };

  return (
    <Drawer
      onClose={closeModal}
      {...props}
      visible={visible}
      footer={ props.footer ?? (
        <div style={{ textAlign: 'right' }}>
          <Space>
            <Button onClick={closeModal} block={true}>{cancelText || 'Close'}</Button>
            {props.buttons}
            { showOk && <Button type="primary" disabled={dirty === false} onClick={onOk} block={true}>{okText || 'OK'}</Button> }
          </Space>
        </div>
      )}
    >
      <BRForm {...formProps} layout="vertical">
        {props.children}
      </BRForm>
    </Drawer>
  );
};
