import React, { useEffect, useReducer } from 'react';
import { Spin } from 'antd';
import { BRLayout } from '../../components/BRLayout';
import {
  ISettings,
} from '../../types';
import { Wizard, WizardProps } from '../../components/Wizard/Wizard';
import { DocumentEditorContext, documentEditorReducer } from '../../types/DocumentEditor';
import { SessionContext } from '../../components/App';
import { SettingsDocument, getDocumentSpecFromISettingsAndWizard } from '../../resources/SettingsDocument';
import { LoadingOutlined } from '@ant-design/icons';
import { ROUTES } from '../../routes';
import { API_ROUTES } from '../../constants/apiRoutes';
import { useBRFetch } from '../../hooks/useBRFetch';

interface Props {
  step?: string;
}

export function SettingsEditorPage(props: Props) {
  const { session } = React.useContext(SessionContext);
  const [documentEditor, documentDispatch] = useReducer(documentEditorReducer, {});
  const [makeRequest] = useBRFetch();
  const document = documentEditor.document instanceof SettingsDocument ? documentEditor.document : undefined;

  const breadcrumbs = [
    {
      path: session.router.getUrl({ name: ROUTES.admin }) || '',
      breadcrumbName: 'Admin',
    },
    {
      path: session.router.getUrl({ name: ROUTES.settings }) || '',
      breadcrumbName: 'Settings',
    },
  ];

  // Set up the appropriate document for a settings editor
  const getSettingsEditorDocument = () => {
    makeRequest(API_ROUTES.settings).then(async (data) => {
      const settings: ISettings = data.documentInterface || {
        createdDate: Date.now(),
      };
      documentDispatch({
        dispatch: documentDispatch,
        type: 'documentData',
        document: getDocumentSpecFromISettingsAndWizard(settings, data.wizard),
      });
    });
  };

  useEffect(
    () => {
      getSettingsEditorDocument();
    },
    [documentDispatch, props.step],
  );

  if (!document) {
    return (
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin={true}/>} />
      </div>
    );
  }

  const wizardProps: WizardProps = documentEditor.document?.field('wizardProps');
  wizardProps.routeName = ROUTES.settingsWizard;
  let wizard: JSX.Element | undefined = undefined;

  if (wizardProps) {
    if (props.step) {
      const step = wizardProps.steps.find(s => s.name === props.step);
      breadcrumbs.push({
        path: session.router.getUrl({ name: ROUTES.settingsWizard }, { step: props.step }) || '',
        breadcrumbName: step ? step.title : 'Unknown Step',
      });
    }

    wizard = <Wizard current={props.step} {...wizardProps} />;
  }

  return (
    <DocumentEditorContext.Provider value={{ documentEditor, documentDispatch }}>
      <BRLayout
        loading={false}
        title={('Settings')}
        breadcrumbs={breadcrumbs}
      >
        {wizard}
      </BRLayout>
    </DocumentEditorContext.Provider>
  );
}
