import { IBlackboxData } from './IBlackboxData';

export interface IGroup {
  uuid: string;
  type: GROUP_TYPE;
  name: string;
  active?: boolean;
  description?: string;
  state?: string;
  startDate?: string | number;
  endDate?: string | number;
  siteData?: { [index: string]: any };
  members: IGroupMember[];
  link?: string;
  events?: number[];
  roles?: string[];
  manuallyAdded?: boolean;
  createdBy?: string;
}

export interface IGroupMember {
  uuid?: string;
  group?: string;
  name?: string;
  person?: string;
  roles?: string[];
  startDate?: number;
  endDate?: number;
  description?: string;
  createdBy?: string;
  state?: string;
  link?: string;
  type?: GROUP_TYPE;
  active?: boolean;
  siteData?: IBlackboxData;
  manuallyAdded?: boolean;
}

export enum GROUP_TYPE {
  protocol = 'Protocol',
  role = 'Role', // site role?
}

export const GROUP_TYPE_NAME = {
  [GROUP_TYPE.protocol]: 'Protocol',
  [GROUP_TYPE.role]: 'Role',
};
