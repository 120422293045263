import { IAttachment } from './IAttachment';

export enum EQUIVALENCY_STATUS {
  pending = 'Pending',
  approved = 'Approved',
  returned = 'Returned',
}

export interface IEquivalency {
  uuid: string;
  person: any;
  course: {
    name: string,
    uuid: string,
  };
  status: EQUIVALENCY_STATUS;
  completionDate: number;
  attachments: IAttachment[];
  learnerComment?: string;
  reviewerComment?: string;
}
