import qs from 'qs';
import { createToken } from './tokenAuth';
import { isColumnVisible } from '../components/BRTable/helpers';
import { IQueryStore } from '../types/storeTypes/IQueryStore';

export function requestCSV(
    baseUri: string,
    baseFilename: string,
    query: IQueryStore = {},
    schema: any,
) {
  const columns: any = Object.assign({}, schema || {});
  Object.keys(columns).forEach((col: string) => {
    columns[col] = isColumnVisible(col, query, schema);
  });
  const uriQuery = {
    columns,
    filters: query.filters,
    sort: query.sort,
  };
  const uri = `/api${baseUri}${qs.stringify(uriQuery, { addQueryPrefix: true })}`;
  const method = 'GET';
  let filename: string | null;

  const props = {
    uri,
    method,
    mac_key: localStorage.getItem('key'),
    access_token: localStorage.getItem('token'),
  };

  const requestOptions = {
    method,
    headers: {
      Accept: 'text/csv',
      Authorization: createToken(props),
    },
  };

  fetch(uri, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response.json());
      }
      filename = response.headers.get('Content-Disposition');
      if (filename && filename.indexOf('filename') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(filename);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename || baseFilename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      return a.download;
    })
    .catch(error => error);
}
