import * as React from 'react';
import { Card, Timeline } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { DATE_OPTIONS, DATE_TIME_OPTIONS } from '../constants';

export interface TimelineVersion {
  label: string;
  date?: Date;
}

interface Props {
  versions: TimelineVersion[];
  selected: number;
  onSelection?: (index: number) => void;
  dirty?: boolean;
}

export function VersionTimeline(props: Props) {

  function setSelected(index: number) {
    if (props.onSelection) {
      props.onSelection(index);
    }
  }

  const createVersion = (version: TimelineVersion, index: number) => {
    const highlight = props.selected === index;
    let color = 'grey';
    let dot = undefined;
    let label: string | React.ReactElement = version.label;

    // is the draft dirty?
    if (index === 0 && props.dirty) {
      color = 'red';
      dot = <WarningOutlined/>;
      label = <strong style={{ color: 'red' }}>Unsaved {label}</strong>;
    } else if (highlight) {
      color = 'green';
    }

    const date = version.date ? new Date(version.date).toLocaleDateString('en-US', DATE_TIME_OPTIONS) : undefined;

    const onClick = () => {
      setSelected(index);
    };

    return (
      <Timeline.Item color={color} dot={dot ? dot : ''} key={`timeline_${index}`} >
        <Card
          bordered={highlight}
          style={{ padding: '0 0.25em' }}
          bodyStyle={{ padding: 0 }}
          onClick={onClick}
        >
          {label}
          {(label && date) && <br />}
          {date}
        </Card>
      </Timeline.Item>
    );
  };

  return (
    <span className="templateEditorHistory">
      <h3>Version History</h3>
      <Timeline style={{ cursor: 'pointer ' }}>
        {props.versions.map((version, index) => {
          return createVersion(version, index);
        })}
      </Timeline>
    </span>
  );
}
