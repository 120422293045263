import * as React from 'react';
import { ReadOutlined } from '@ant-design/icons';
import { Avatar, List } from 'antd';
import { getElementAttributes } from '../../helpers/getNodeAttributes';
import { RuleEditorAdd } from './RuleEditorAdd';
import { RuleEditorEdit } from './RuleEditorEdit';
import { RuleEditorDelete } from './RuleEditorDelete';
import { RuleEditorNodeProps, RULE_NODE_COLORS } from '.';
import { UI_COLORS } from '../../constants';

interface RuleEditorRenewalGroupsProps {
  elt: Element;
  setSelected: (elt: Element) => void;
  setXml: () => void;
}

export function RuleEditorRenewalGroup(props: RuleEditorNodeProps) {
  const attributes = getElementAttributes(props.elt);
  const description = <><strong>True if:</strong> {attributes.match === 'all' ? 'all children are true' : 'any child is true'}</>;

  return (
    <List.Item
      style={{ padding: '0.25em' }}
      actions={props.editable ? [
        <RuleEditorAdd {...props} key="Add" />,
        <RuleEditorEdit {...props} key="Edit" />,
        <RuleEditorDelete {...props} key="Delete" />,
      ] : undefined}
    >
      <List.Item.Meta avatar={<Avatar icon={<ReadOutlined />} style={{ backgroundColor: RULE_NODE_COLORS.RenewalGroup }} />} title="Renewal Group" description={description} />
    </List.Item>
  );
}
