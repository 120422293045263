import React, { ChangeEvent, useState } from 'react';
import { Form } from 'antd';
import { FormProps } from 'antd/lib/form';
import { COMMANDS } from '../../constants';
import { BRModal, BRModalProps } from '../BRModal';
import { sendCommand } from '../../services/commands.services';
import TextArea from 'antd/lib/input/TextArea';
import { EQUIVALENCY_STATUS, IEquivalency } from '../../types/IEquivalency';

interface Props extends BRModalProps {
  equivalency: IEquivalency;
  status: EQUIVALENCY_STATUS;
}

export const ReviewEquivalencyModal: React.FunctionComponent<Props> =
({ equivalency, status, ...modalProps }) => {
  const [comment, setComment] = useState(equivalency.reviewerComment);

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setComment(value);
  };

  const onFinish = async () => {
    const formData: any = {};
    formData.uuid = equivalency.uuid;
    formData.status = status;
    formData.reviewerComment = comment;

    await sendCommand({
      formData,
      command: COMMANDS.UPDATE_EQUIVALENCY,
    });
  };

  return (
    <BRModal
      {...modalProps}
      title={status === EQUIVALENCY_STATUS.approved ? 'Approve Request' : `Return to ${equivalency.person.name}`}
      formProps={{ onFinish }}
    >
      <Form.Item label="Comment">
        <TextArea
          value={comment}
          rows={8}
          onChange={handleCommentChange}
        />
      </Form.Item>
    </BRModal>
  );
};
