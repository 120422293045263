import React, { useState } from 'react';
import { Alert, Form } from 'antd';

import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { ITenure, IApprovalTemplate } from '../../types';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';

interface Props extends BRModalProps {
  tenure: ITenure;
  assignedApprovals: IApprovalTemplate[];
}

export const UnassignApprovalTemplatesModal: React.FunctionComponent<Props> =
({ tenure, assignedApprovals, ...modalProps }) => {

  const [approvalTemplates, setApprovalTemplates] = useState([] as string[]);
  const [approvalTemplateIdsError, setApprovalTemplateIdsError] = useState('');
  const [comments, setComments] = useState('');

  const onFinish = async () => {
    setApprovalTemplateIdsError('');
    if (!approvalTemplates.length) {
      setApprovalTemplateIdsError('At least one approval template must be selected');
      return;
    }

    const formData = {
      approvalTemplates,
      comments,
      tenure: tenure.uuid,
    };

    await sendCommand({
      formData,
      command: COMMANDS.UNASSIGN_APPROVAL_TEMPLATES_FROM_TENURE,
    });
  };

  const selectTemplates = assignedApprovals ? assignedApprovals
    .filter(t => t.manuallyAssigned)
    .map(t => ({
      key: t.uuid,
      value: t.uuid || '',
      label: t.name,
    })) : [];

  const title = 'Unassign Approval Templates';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Unassign Approval Templates"
      formProps={{ onFinish }}
    >
      <Form.Item label={'Approval Templates:'}>
        {approvalTemplateIdsError && <Alert message={approvalTemplateIdsError} />}
        <BRSelect
          setValue={setApprovalTemplates}
          options={selectTemplates}
          mode="multiple"
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
