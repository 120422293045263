import * as React from 'react';
import { BRLayout } from '../components/BRLayout';
import { TABLE_IDS } from '../constants';
import BRTable from '../components/BRTable';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { API_ROUTES } from '../constants/apiRoutes';
import { SessionContext } from '../components/App';
import { ROUTES } from '../routes';
import { TableRowSelection } from 'antd/lib/table/interface';
import { LinkTranscriptModal } from '../components/modals/LinkTranscriptModal';
import { UnlinkTranscriptModal } from '../components/modals/UnlinkTranscriptModal';
import { ITranscript } from '../types';
import { LinkOutlined, StopOutlined } from '@ant-design/icons';
import { checkCommands } from '../helpers/checkCommands';
import { Command } from '../components/CommandButtons';

interface Props {

}

export function TranscriptListPage(props: Props) {
  const { session } = React.useContext(SessionContext);

  const [selected, setSelected] = React.useState<ITranscript>();

  const transcripts = useBRTableStore({
    fetchDataParams: { url: API_ROUTES.transcripts },
  });

  const rowSelection: TableRowSelection<any> = {
    onSelect: setSelected,
    type: 'radio',
  };

  const onSuccess = () => transcripts.fetch();
  const allTranscriptListCommands: Command[] = [
    {
      title: 'Link Transcript',
      icon: <LinkOutlined />,
      type: 'linkTranscript',
      enabled: !!(selected && selected.unlinked),
      modal: (
        <LinkTranscriptModal
          transcript={selected}
          onSuccess={onSuccess}
        />
      ),
    },
    {
      title: 'Unlink Transcript',
      icon: <StopOutlined />,
      type: 'unlinkTranscript',
      enabled: !!(selected && !selected.unlinked),
      modal: (
        <UnlinkTranscriptModal
          transcript={selected}
          onSuccess={onSuccess}
        />
      ),
    },
  ];

  const commands = checkCommands(allTranscriptListCommands, transcripts);

  return (
    <BRLayout
      loading={transcripts.loading}
      title={session.router.current.spec.title}
      commands={commands}
    >
      <BRTable
        linkPath={ROUTES.transcript}
        tableId={TABLE_IDS.TRANSCRIPTS_PAGE_TABLE}
        tableData={transcripts}
        rowSelection={rowSelection}
      />
    </BRLayout>
  );
}
