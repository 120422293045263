import { Button, Drawer, Space, Typography } from 'antd';
import React from 'react';
import { DATE_OPTIONS } from '../../constants';
import { requestFile } from '../../helpers/requestFile';
import { useBRStore } from '../../hooks/useBRStore';
import { BRTableStore } from '../../hooks/useBRTableStore';
import { ROUTES } from '../../routes';
import { IAttachment } from '../../types';
import { EQUIVALENCY_STATUS, IEquivalency } from '../../types/IEquivalency';
import { SessionContext } from '../App';
import { BRDrawer, BRDrawerProps } from '../BRDrawer';
import { ModalContext } from '../BRModal';
import { EditEquivalencyModal } from '../modals/EditEquivalencyModal';
import { RespondToEquivalencyModal } from '../modals/RespondToEquivalencyModal';
import { ReviewEquivalencyModal } from '../modals/ReviewEquivalencyModal';

interface Props extends BRDrawerProps {
  equivalencyUuid: string;
  store: BRTableStore<IEquivalency>;
  isLearner?: boolean;
  isReviewer?: boolean;
  onSuccess?: () => void;
}

export const PersonEquivalencyDrawer: React.FunctionComponent<Props> = ({ equivalencyUuid, store, isLearner, isReviewer, ...drawerProps }) => {
  const { session } = React.useContext(SessionContext);
  const [currentModal, setCurrentModal] = React.useState<React.ReactNode>();
  const [modalVisible, setModalVisible] = React.useState(false);

  const fetchDataParams = React.useMemo(() => ({ url: `/v0/equivalencies/${equivalencyUuid}` }), [equivalencyUuid]);
  const equivalencyStore = useBRStore<IEquivalency>({ fetchDataParams });
  const equivalency = equivalencyStore?.data;

  React.useEffect(
    () => {
      equivalencyStore.fetch();
    },
    [equivalencyStore.fetch]);

  const modals = React.useMemo(() => ({
    returnEquivalency: (
      <ReviewEquivalencyModal
        equivalency={equivalency}
        status={EQUIVALENCY_STATUS.returned}
        onSuccess={store.fetch}
      />
    ),
    approveEquivalency: (
      <ReviewEquivalencyModal
        equivalency={equivalency}
        status={EQUIVALENCY_STATUS.approved}
        onSuccess={store.fetch}
      />
    ),
    respondToEquivalency: (
      <RespondToEquivalencyModal
        equivalency={equivalency}
        onSuccess={store.fetch}
      />
    ),
    editEquivalency: (
      <EditEquivalencyModal
        equivalency={equivalency}
        onSuccess={store.fetch}
      />
    ),
  }), [equivalency, store.fetch]);

  const onClose = () => {
    if (isReviewer) {
      session.router.go({ name: ROUTES.adminEquivalency }, {}, { query: session.router.query });
    } else {
      session.router.go({ name: ROUTES.person }, { ...session.router.current.params, tab: 'equivalencies' }, { query: session.router.query });
    }
  };

  const modalSuccess = () => {
    setModalVisible(false);
    store.fetch();
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const onReturn = () => {
    setCurrentModal(modals.returnEquivalency);
    setModalVisible(true);
  };

  const onApprove = () => {
    setCurrentModal(modals.approveEquivalency);
    setModalVisible(true);
  };

  const onRespond = () => {
    setCurrentModal(modals.respondToEquivalency);
    setModalVisible(true);
  };

  const onEdit = () => {
    setCurrentModal(modals.editEquivalency);
    setModalVisible(true);
  };

  const downloadLinkedAttachment = (attachment: IAttachment) => async () => {
    await requestFile(attachment.url, attachment.name, attachment.mimetype);
  };

  const buttons: React.ReactNode[] = [];

  if (isLearner && equivalency.status !== EQUIVALENCY_STATUS.approved) {
    buttons.push(<Button type="primary" onClick={onEdit} block={true}>Edit</Button>);
    if (equivalency.status === EQUIVALENCY_STATUS.returned) {
      buttons.push(<Button type="primary" onClick={onRespond} block={true}>Respond</Button>);
    }
  }

  if (isReviewer && equivalency.status !== EQUIVALENCY_STATUS.approved) {
    buttons.push(<Button type="primary" onClick={onReturn} block={true}>Return</Button>);
    buttons.push(<Button type="primary" onClick={onApprove} block={true}>Approve</Button>);
  }

  return (
    <div>
      <BRDrawer
        {...drawerProps}
        title={`Equivalency: ${equivalency.course?.name}`}
        onClose={onClose}
        buttons={buttons}
        dirty={equivalency.status !== EQUIVALENCY_STATUS.approved}
        showOk={false}
        cancelText="Cancel"
      >
        <Space direction="vertical">
          { equivalency.completionDate ? (
            <div>
              <Typography.Paragraph><b>Completion Date</b></Typography.Paragraph>
              <Typography.Paragraph>{new Date(equivalency.completionDate).toLocaleDateString('en-US', DATE_OPTIONS)}</Typography.Paragraph>
            </div>
          ) : <></>}
          { equivalency.attachments?.length ? (
            <div>
              <Typography.Paragraph><b>Attachments</b></Typography.Paragraph>
              <Space direction="vertical">
                { equivalency.attachments.map((attachment) => {
                  return (
                    <Button type="link" key={`attachment-${attachment.uuid}`} onClick={downloadLinkedAttachment(attachment)}>{attachment.displayname}</Button>
                  );
                })}
              </Space>
            </div>
          ) : <></>}
          { equivalency.learnerComment ? (
            <div>
              <Typography.Paragraph><b>Learner Comment</b></Typography.Paragraph>
              <Typography.Paragraph>{equivalency.learnerComment}</Typography.Paragraph>
            </div>
          ) : <></>}
          { equivalency.reviewerComment ? (
            <div>
              <Typography.Paragraph><b>Reviewer Comment</b></Typography.Paragraph>
              <Typography.Paragraph>{equivalency.reviewerComment}</Typography.Paragraph>
            </div>
          ) : <></>}
        </Space>
      </BRDrawer>
      { currentModal && (
        <ModalContext.Provider value={{ close: closeModal, onSuccess: modalSuccess, visible: modalVisible }}>
          {currentModal}
        </ModalContext.Provider>
      )}
    </div>
  );
};
