import React from 'react';
import { Form } from 'antd';
import { FormProps } from 'antd/lib/form';

export * from './BRLargeDataSelect';
export * from './BRDatePicker';
export * from './BRFormComments';
export * from './BRSelect';

export const BRForm = (props: FormProps) => {

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  return (
    <Form {...formItemLayout} {...props}>
      {props.children}
    </Form>
  );
};
