import React, { useContext, ChangeEvent } from 'react';
import { FieldProps, Field } from '../Field';
import TextArea from 'antd/lib/input/TextArea';
import { StepContext } from '../Step';
import { DocumentEditorContext } from '../../../types/DocumentEditor';

interface TextAreaFieldProps extends FieldProps {

}

export const TextAreaField = (props: TextAreaFieldProps) => {
  const { documentEditor } = useContext(DocumentEditorContext);
  const { value, setValue } = useContext(StepContext);

  if (!documentEditor.document) {
    return null;
  }

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!documentEditor.document) {
      return;
    }

    setValue(e.target.value);
  };

  return (
    <Field {...props}>
      <TextArea
        name={props.name}
        readOnly={!documentEditor.document.editable || !!props.readOnly}
        defaultValue={value}
        onChange={onChange}
      />
    </Field>
  );
};
