import React, { useState } from 'react';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments } from '../BRForm';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';
import { IRequirementCategory } from 'client/src/types';

interface Props extends BRModalProps {
  category: IRequirementCategory;
}

export const RemoveRequirementCategoryModal: React.FunctionComponent<Props> =
({ category, ...modalProps }) => {

  const [comments, setComments] = useState('');

  const onFinish = async () => {
    const formData = {
      category: category.uuid,
      comments,
    };

    await sendCommand({
      formData,
      command: COMMANDS.REMOVE_REQUIREMENT_CATEGORY,
    });
  };

  const title = 'Remove Requirement Category';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Remove Requirement Category"
      formProps={{ onFinish }}
    >
        <p>The <strong>{category.name}</strong> category will be deleted, and removed from all of its associated requirements.</p>
        <BRFormComments comments={comments} setComments={setComments}/>
    </BRModal>
  );
};
