import React from 'react';
import { v4 } from 'uuid';

export function useDom(xml?: string) {
  const [increment, setIncrement] = React.useState(0);
  const [isDirty, setIsDirty] = React.useState(false);

  React.useEffect(() => {
    setIsDirty(false);
  }, [xml]);

  const dom = React.useMemo(
    () => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(xml || '', 'application/xml');

      const addUnique = (element: Element) => {
        element.setAttribute('unique', v4());
        for (let i = 0; i < element.children.length; i += 1) {
          addUnique(element.children[i]);
        }
      }

      // add unique IDs to nodes for React rendering
      addUnique(dom.documentElement)

      const callback = function(mutationsList: any, observer: any) {
        setIsDirty(true);
        setIncrement((item) => {
          console.log(item);
          return item + 1
        });
      }

      const observer = new MutationObserver(callback);
      const config = { attributes: true, childList: true, subtree: true };
    
      observer.observe(dom.documentElement, config);

      return dom;
    },
    [xml],
  );

  const getDomXmlString = () => {
    const removeUnique = (node: Node) => {
      // have to retype, but should be an Element
      const element = node as Element;
      element.removeAttribute('unique');
      for (let i = 0; i < element.children.length; i += 1) {
        removeUnique(element.children[i]);
      }
    }
    const serializer = new XMLSerializer();

    // duplicate node and remove unique identifiers
    const newNode = dom.documentElement.cloneNode(true);
    removeUnique(newNode);

    return serializer.serializeToString(newNode);
  }

  return { dom, isDirty, getDomXmlString };
}
