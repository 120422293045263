import { Cascader } from 'antd';
import { CascaderOptionType } from 'antd/lib/cascader';
import React from 'react';

interface Props {
  options: CascaderOptionType[];
  selectedOption: CascaderOptionType | undefined;
  onChange: (e: any) => void;
  attributes: any;
  element: Element | undefined;
  parentElement: Element | undefined | null;
  setAttibutes: (attributes: { [key: string]: string }) => void;
}

export function InputCascader(props: Props) {

  // This is the options for the AntD Cascader component. Ideally this will be generated from a backend schema
  // Such a schema would probably need to be specific for each Rule type, as sometimes rules deal with Interfaces and sometimes actual Resources
  const inputOptions = [...props.options];

  const getIterableBranches = (options: CascaderOptionType[]): CascaderOptionType[] => {
    return options.reduce((accum: CascaderOptionType[], option: CascaderOptionType) => {
      if (option.iterable) {
        // if item is iterable, that's the end of the selection
        accum.push({
          ...option,
          children: undefined,
        });
        return accum;
      }
      const children = getIterableBranches(option.children || []);
      if (children.length) {
        // if there are children from getIterableBranches,
        // at least one ends in an iterable option
        // all other branches have been filtered out
        accum.push({
          ...option,
          children,
        });
      }
      return accum;
    }, []);
  };

  return (
    <Cascader
      options={props.element?.nodeName === 'Loop' ? getIterableBranches(inputOptions) : inputOptions}
      value={props.attributes.input?.split('.')}
      onChange={props.onChange}
      changeOnSelect={props.element?.nodeName !== 'Loop'}
      style={{ width: '100%' }}
      placeholder={'Select input'}
    />
  );
}
