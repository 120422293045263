import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments } from '../BRForm';
import { CLIENT_CONST, COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';

export const CreateUnlinkedAALASAccountModal: React.FunctionComponent<BRModalProps> =
({ ...modalProps }) => {

  const [externalId, setExternalId] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [comments, setComments] = useState('');

  const onFinish = async () => {

    await sendCommand({
      formData: {
        externalId,
        email,
        firstName,
        lastName,
        comments,
      },
      command: COMMANDS.CREATE_UNLINKED_AALAS_ACCOUNT,
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'externalId':
        setExternalId(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
    }
  };

  const title = 'Create Unlinked AALAS Account';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Create"
      formProps={{ onFinish }}
    >
      <Form.Item
        label={`${CLIENT_CONST.INSTITUTE_NAME} Username`}
        help="Username will also be used as AALAS username."
      >
        <Input
          onChange={handleChange}
          value={externalId}
          type="text"
          name="externalId"
          required={true}
          minLength={5}
        />
      </Form.Item>
      <Form.Item label="Email">
        <Input
          onChange={handleChange}
          value={email}
          type="email"
          name="email"
          required={true}
          minLength={5}
        />
      </Form.Item>

      <Form.Item label="First Name">
        <Input
          onChange={handleChange}
          value={firstName}
          type="text"
          name="firstName"
          required={true}
          minLength={1}
        />
      </Form.Item>
      <Form.Item label="Last Name">
        <Input
          onChange={handleChange}
          value={lastName}
          type="text"
          name="lastName"
          required={true}
          minLength={1}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
