import React, { useContext, useRef } from 'react';
import { Menu } from 'antd';
import { CoreRouteSpec, routes, ROUTES } from '../routes';
import { SessionContext } from './App';
import { Link } from '../hooks/useRoutes';
import { hasPermission } from '../helpers/hasPermission';
import { CaretDownOutlined } from '@ant-design/icons';

const { SubMenu, Item } = Menu;

export function NavMenu() {
  const { session } = useContext(SessionContext);
  const parent = useRef(null);

  if (routes[ROUTES.root] === false) { return null; }

  const children = (routes[ROUTES.root] as CoreRouteSpec)?.children || {};

  const makeSubmenu = (basePath: string, key: string, item: CoreRouteSpec) => {
    if (!item || !item.title) return null;

    if (item.permissions) {
      if (!hasPermission(item.permissions, session)) return null;
    }

    const path = `${basePath}${item.path}`;

    if (item.children && Object.values(item.children).filter((x: any) => !!x.title).length > 0) {
      return (
        <SubMenu
          title={(
            <Link href={path}>
              {item.icon}
              {item.title}
            </Link>
          )}
          key={key}
        >
          {Object.entries(item.children).map(([key, value]) => value && makeSubmenu(path, key, value))}
        </SubMenu>
      );
    }

    return (
      <Item key={key}>
        <Link href={path}>
          {item.icon}
          {item.title}
        </Link>
      </Item>
    );
  };

  return (
    <Menu
      ref={parent}
      mode="horizontal"
      selectedKeys={session.router.current.selected.map(r => r.id.toString())}
    >
      {Object.keys(children).map(key => children[key] && makeSubmenu('', key, children[key]))}
    </Menu>
  );
}
