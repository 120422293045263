import React, { useState, useEffect } from 'react';
import { Form } from 'antd';

import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { ITranscript } from '../../types';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';

interface Props extends BRModalProps {
  personUuid?: string;
  transcript?: ITranscript;
}
export const UnlinkTranscriptModal: React.FunctionComponent<Props> =
(props) => {
  const [transcript, setTranscript] = useState('');
  const [comments, setComments] = useState('');

  const fetchDataParams = React.useMemo(
    () => ({ url: `/v0/people/${props.personUuid}/transcripts`, queryParams: { all: true } }),
    [props.personUuid],
  )

  const transcripts = useBRListData<ITranscript>({ fetchDataParams });

  useEffect(
    () => {
      if (!props.transcript) {
        transcripts.fetch();
      } else {
        setTranscript(props.transcript.uuid);
      }
    },
    []);

  const onFinish = async () => {
    const formData = {
      transcript,
      comments,
    };

    await sendCommand({
      formData,
      command: COMMANDS.UNLINK_TRANSCRIPT,
    });
  };

  const transcriptOptions = React.useMemo(
    () => 
      (props.transcript ? [props.transcript] : transcripts.records.filter(
        transcript => transcript.provider?.name !== 'Manual')).map(
        (g: ITranscript) => {
          const label = `${g.firstName} ${g.lastName}${g.keys.email ? ` (${g.keys.email[0]})` : ''} ${g.provider.name} ${g.providerId}`;
          return {
            label,
            key: g.uuid,
            value: g.uuid || '',
          };
        },
      ),
    [props.transcript, transcripts.records]);

  return (
    <BRModal
      {...props}
      title="Unlink Transcript"
      okText="Unlink"
      formProps={{ onFinish }}
    >
      <Form.Item label="Unlink Transcript">
        <BRSelect
          options={transcriptOptions}
          setValue={setTranscript}
          disabled={!!props.transcript}
          value={props.transcript && transcriptOptions[0]}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments}/>
    </BRModal>
  );
};
