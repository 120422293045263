import qs from 'qs';
import { createToken } from './tokenAuth';

const debounce: any = {};

export async function requestPDF(
    baseUri: string,
    baseFilename: string,
    query: any = {},
) {
  if (debounce[baseUri]) { return; }
  debounce[baseUri] = true;

  setTimeout(() => delete debounce[baseUri], 2000);
  const uri = `/api${baseUri}${qs.stringify(query, { addQueryPrefix: true })}`;
  const method = 'GET';
  let filename: string | null;

  const props = {
    uri,
    method,
    mac_key: localStorage.getItem('key'),
    access_token: localStorage.getItem('token'),
  };

  const requestOptions = {
    method,
    headers: {
      Accept: 'application/pdf',
      Authorization: createToken(props),
    },
  };

  const response = await fetch(uri, requestOptions);

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error ?? 'Unknown server error.')
  }

  filename = response.headers.get('Content-Disposition');

  if (filename && filename.indexOf('filename') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(filename);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || baseFilename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  return a.download;
}
