import * as React from 'react';
import { BRLayout } from '../../components/BRLayout';
import { DATE_TIME_OPTIONS } from '../../constants';
import { buildCommandDetailTitle, buildEventDetailTitle } from '../../helpers/buildDetailPageTitle';
import { useBRStore } from '../../hooks/useBRStore';
import { IEvent } from '../../types/IEvent';
import { default as JSONPretty } from 'react-json-pretty';
import { Tabs } from 'antd';
import { ROUTES } from '../../routes';
import { SessionContext } from '../../components/App';
import { Route } from 'antd/es/breadcrumb/Breadcrumb';
import { ICommand } from '../../types';
import { checkCommands } from '../../helpers/checkCommands';
import { API_ROUTES } from '../../constants/apiRoutes';
import { Command } from '../../components/CommandButtons';

interface Props {
  id: string;
  command: string;
}

export function EventDetailPage(props: Props) {
  const { session } = React.useContext(SessionContext);

  const { id, command: commandUuid } = props;

  const urlRoot = `/v0/event/${id}`;
  const eventStore = useBRStore<IEvent>({
    fetchDataParams: React.useMemo(() => ({ url: urlRoot }), [props.id]),
  });
  const event = eventStore.data;

  React.useEffect(
    () => {
      eventStore.fetch();
    },
    [eventStore.fetch]);

  const urlCommandsRoot = `${API_ROUTES.commands}/${commandUuid}`;
  const commandStore = useBRStore<ICommand>({
    fetchDataParams: React.useMemo(() => ({ url: urlCommandsRoot }), [commandUuid]),
  });
  const command = commandStore.data;

  React.useEffect(
    () => {
      commandStore.fetch();
    },
    [commandStore.fetch]);

  const breadcrumbs: Route[] = [
    {
      path: session.router.getUrl({ name: ROUTES.audit }) || '',
      breadcrumbName: 'Audit Log',
    },
    {
      path: session.router.getUrl({ name: ROUTES.command }, { uuid: command?.uuid || '' }) || '',
      breadcrumbName: buildCommandDetailTitle(command),
    },
    {
      path: session.router.getUrl({ name: ROUTES.event }, { id: event.id?.toString() || '' }) || '',
      breadcrumbName: buildEventDetailTitle(event),
    },
  ];

  const descriptionItems = {
    ID: event.id,
    Time: event.when ? new Date(event.when).toLocaleDateString('en-US', DATE_TIME_OPTIONS) : 'Unknown',
  };

  const allEventCommands: Command[] = [];

  const commands = checkCommands(allEventCommands, eventStore);

  return (
    <BRLayout
      title={buildEventDetailTitle(event)}
      descriptionItems={descriptionItems}
      breadcrumbs={breadcrumbs}
      commands={commands}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={'Raw Event'} key="2">
          <JSONPretty json={JSON.stringify(event)} />
        </Tabs.TabPane>
      </Tabs>
    </BRLayout>
  );
}
