export const ENABLED_MODULES = {
  TRAINING: process.env.REACT_APP_ENABLE_TRAINING === 'true',
  ONBOARDING: process.env.REACT_APP_ENABLE_ONBOARDING === 'true',
};

export const FEATURES = {
  ...ENABLED_MODULES,
  REQUIREMENT_CATEGORIES: process.env.REACT_APP_ENABLE_REQUIREMENT_CATEGORIES === 'true',
  REQUIREMENT_CATEGORIES_PAGE: process.env.REACT_APP_ENABLE_REQUIREMENT_CATEGORIES_PAGE === 'true',
  CATEGORIZED_REQUIREMENT_SELECTOR: process.env.REACT_APP_ENABLE_CATEGORIZED_REQUIREMENT_SELECTOR === 'true',
  CATEGORIZED_COURSE_SELECTOR: process.env.REACT_APP_ENABLE_CATEGORIZED_COURSE_SELECTOR === 'true',
  AALAS: process.env.REACT_APP_ENABLE_AALAS === 'true',
  REPORTS: process.env.REACT_APP_ENABLE_REPORTS === 'true',

  // Training module reports
  REPORTS_COURSE: process.env.REACT_APP_ENABLE_REPORTS_COURSE === 'true',
  REPORTS_REQUIREMENT: process.env.REACT_APP_ENABLE_REPORTS_REQUIREMENT === 'true',
  REPORTS_RCR: process.env.REACT_APP_ENABLE_REPORTS_RCR === 'true',
  REPORTS_CATEGORY: process.env.REACT_APP_ENABLE_REPORTS_CATEGORY === 'true',

  // Onboarding module reports
  REPORTS_TENURE_SPONSOR: process.env.REACT_APP_ENABLE_REPORTS_TENURE_SPONSOR === 'true',

  AWARDS: process.env.REACT_APP_ENABLE_AWARDS === 'true',
  NEW_PERSON: process.env.REACT_APP_ENABLE_NEW_PERSON === 'true',
  UPDATE_PERSON: process.env.REACT_APP_ENABLE_UPDATE_PERSON === 'true',
  LINK_PERSON: process.env.REACT_APP_ENABLE_LINK_PERSON === 'true',
  NEW_REQUIREMENT: process.env.REACT_APP_ENABLE_NEW_REQUIREMENT === 'true',
  DEACTIVATE_REQUIREMENT: process.env.REACT_APP_ENABLE_DEACTIVATE_REQUIREMENT === 'true',
  EQUIVALENCIES: process.env.REACT_APP_ENABLE_EQUIVALENCIES === 'true',
  SCHEDULED_DATE: process.env.REACT_APP_ENABLE_SCHEDULED_DATE === 'true',
  RULE_EDITOR: process.env.REACT_APP_ENABLE_RULE_EDITOR === 'true',
  COURSE_EDITOR: process.env.REACT_APP_ENABLE_COURSE_EDITOR !== 'false',
  PROTOCOLS: process.env.REACT_APP_ENABLE_PROTOCOLS === 'true',
  INACTIVE_PERSON_STATUS: process.env.USE_INACTIVE_PERSON_STATUS === 'true',
  TEMPLATE_EDITOR: process.env.REACT_APP_ENABLE_TEMPLATE_EDITOR === 'true',
  BULK_IMPORT: process.env.REACT_APP_ENABLE_BULK_IMPORT === 'true',
  SETTINGS: process.env.REACT_APP_ENABLE_SETTINGS === 'true',
  AALAS_SETTINGS: process.env.REACT_APP_ENABLE_AALAS_SETTINGS === 'true',
  CITI_SETTINGS: process.env.REACT_APP_ENABLE_CITI_SETTINGS === 'true',
  CORNERSTONE_SETTINGS: process.env.REACT_APP_ENABLE_CORNERSTONE_SETTINGS === 'true',
  LEARNER_HOME: process.env.REACT_APP_ENABLE_LEARNER_HOME === 'true',
  WORD_CERTIFICATES: process.env.REACT_APP_ENABLE_WORD_CERTIFICATES === 'true',
  MANUAL_REQUIREMENT_TIME_LIMIT: process.env.REACT_APP_ENABLE_MANUAL_REQUIREMENT_TIME_LIMIT === 'true',
};

export const CUSTOM_TEXT = {
  HOME_PAGE_REQUIRED_TOOLTIP: process.env.REACT_APP_HOME_PAGE_REQUIRED_TOOLTIP,
  HOME_PAGE_RECOMMENDED_TOOLTIP: process.env.REACT_APP_HOME_PAGE_RECOMMENDED_TOOLTIP,
  HOME_PAGE_COMPLETED_TOOLTIP: process.env.REACT_APP_HOME_PAGE_COMPLETED_TOOLTIP,
  HOME_PAGE_COURSES_TOOLTIP: process.env.REACT_APP_HOME_PAGE_COURSES_TOOLTIP
};

export const ENABLED_FEATURES = Object.entries(FEATURES).filter(([k, v]) => !!v).map(([k]) => k);
