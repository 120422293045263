import React, { useContext, ChangeEvent } from 'react';
import { FieldProps, Field } from '../Field';
import { Input } from 'antd';
import { StepContext } from '../Step';
import { DocumentEditorContext } from '../../../types/DocumentEditor';

interface TextFieldProps extends FieldProps {
  type?: string;
}

export const TextField = (props: TextFieldProps) => {
  const { documentEditor } = useContext(DocumentEditorContext);
  const { value, setValue } = useContext(StepContext);

  if (!documentEditor.document) {
    return null;
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!documentEditor.document) {
      return;
    }

    setValue(e.target.value);
  };

  return (
    <Field {...props}>
      <Input
        type={props.type || 'text'}
        readOnly={!documentEditor.document.editable || !!props.readOnly}
        name={props.name}
        defaultValue={value}
        onChange={onChange}
      />
    </Field>
  );
};
