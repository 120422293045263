import * as React from 'react';
import { Avatar, Tooltip } from 'antd';
import { CheckOutlined, ClockCircleOutlined, ExclamationOutlined, MinusOutlined, QuestionOutlined, RetweetOutlined, WarningOutlined } from '@ant-design/icons';
import { UI_COLORS } from '../constants';
import { RESOURCE_STATUS } from '../types';
import { COURSE_STATUS } from '../types/ICourseStatus';
import { AvatarProps } from 'antd/lib/avatar';

interface Props {
  status?: string;
}

export function getStatusColor(status?: string) {
  switch (status) {
    case RESOURCE_STATUS.complete:
    case COURSE_STATUS.equivalent:
    case COURSE_STATUS.complete:
      return UI_COLORS.deepGreen;
    case RESOURCE_STATUS.draft:
      return UI_COLORS.gray;
    case RESOURCE_STATUS.expiring:
    case COURSE_STATUS.scheduled:
    case COURSE_STATUS.pending:
      return UI_COLORS.deepOrange;
    case RESOURCE_STATUS.actionRequired:
    case RESOURCE_STATUS.expired:
    case COURSE_STATUS.returned:
      return UI_COLORS.deepRed;
  }
  return UI_COLORS.deepPurple;
}

export function getStatusIcon(status?: string) {
  switch (status) {
    case RESOURCE_STATUS.actionRequired:
      return <ExclamationOutlined />;
    case RESOURCE_STATUS.complete:
    case COURSE_STATUS.complete:
      return <CheckOutlined />;
    case RESOURCE_STATUS.draft:
      return <MinusOutlined />;
    case RESOURCE_STATUS.expired:
      return <ExclamationOutlined />;
    case RESOURCE_STATUS.expiring:
      return <ExclamationOutlined />;
    case COURSE_STATUS.scheduled:
      return <ClockCircleOutlined />;
    case COURSE_STATUS.equivalent:
      return <RetweetOutlined />;
    case COURSE_STATUS.pending:
      return <RetweetOutlined />;
    case COURSE_STATUS.returned:
      return <WarningOutlined />;
  }
  return <QuestionOutlined />;
}

export const StatusAvatar = (props: Props & AvatarProps) => {
  const avatarProps = {
    ...props,
    icon: getStatusIcon(props.status),
    style: { backgroundColor: getStatusColor(props.status) }
  };
  return <Tooltip title={props.status} >
    <Avatar {...avatarProps} />
  </Tooltip>;
}
