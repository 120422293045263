import React, { useState, ChangeEvent, useEffect } from 'react';
import { Alert, Checkbox, Form, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { useBRListData } from '../../hooks/useBRListData';
import { ICourseCategory, IProvider } from '../../types';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { API_ROUTES } from '../../constants/apiRoutes';

const categoriesDataParams = { url: API_ROUTES.courseCategories };
const providersDataParams = { url: API_ROUTES.courseProviders };

export const NewCourseModal: React.FunctionComponent<BRModalProps> =
({ ...modalProps }) => {

  const allCategories = useBRListData<ICourseCategory>({
    fetchDataParams: categoriesDataParams,
  });

  const allProviders = useBRListData<IProvider>({
    fetchDataParams: providersDataParams,
  });

  useEffect(
    () => {
      allCategories.fetch();
      allProviders.fetch();
    },
    [
      allCategories.fetch,
      allProviders.fetch,
    ]);

  const [name, setName] = useState('');
  const [categories, setCategories] = useState([] as { id: number }[]);
  const [categoriesError, setCategoriesError] = useState(false);
  const [provider, setProvider] = useState(0);
  const [providerError, setProviderError] = useState(false);
  const [providerId, setProviderId] = useState('');
  const [link, setLink] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);
  const [comments, setComments] = useState('');

  const onFinish = async () => {
    if (!categories || categories.length < 1 || !provider || provider === 0) {
      setCategoriesError(categories.length < 1);
      setProviderError(provider === 0 || provider === undefined);
      return;
    }

    const formData = {
      name,
      categories,
      provider,
      providerId,
      link,
      description,
      active,
      comments,
    };

    await sendCommand({
      formData,
      command: COMMANDS.NEW_COURSE,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'providerId':
        setProviderId(value);
        break;
      case 'link':
        setLink(value);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setActive(checked);
  };

  const categoriesOptions = allCategories.records && allCategories.records.map(
    c => ({
      key: c.uuid,
      label: c.name,
      value: c.uuid || '',
    }),
  );

  const providersOptions = allProviders.records && allProviders.records.map(
    p => ({
      key: p.uuid,
      label: p.name,
      value: p.uuid || '',
    }),
  );

  return (
    <BRModal
      {...modalProps}
      title="Create New Course"
      okText="Create Course"
      formProps={{ onFinish }}
    >
      <Form.Item label="Course Name">
        <Input
          onChange={handleChange}
          value={name}
          type="text"
          name="name"
          required={true}
          minLength={5}
        />
      </Form.Item>

      <Form.Item label="Categories">
        <BRSelect
          setValue={setCategories}
          options={categoriesOptions}
          mode="multiple"
        />
        {categoriesError && <Alert message="Please select at least one category" type="warning"/>}
      </Form.Item>

      <Form.Item label="Provider">
        <BRSelect
          setValue={setProvider}
          options={providersOptions}
        />
        {providerError && <Alert message="Please select a provider" type="warning" />}
      </Form.Item>

      <Form.Item label="Provider Course ID">
        <Input
          onChange={handleChange}
          value={providerId}
          type="text"
          name="providerId"
          required={true}
        />
      </Form.Item>

      <Form.Item label="Course Link">
        <Input
          onChange={handleChange}
          value={link}
          type="url"
          name="link"
          required={true}
        />
      </Form.Item>

      <BRFormComments
        comments={description}
        setComments={setDescription}
        label="Course Description"
      />

      <Form.Item label="Active">
        <Checkbox
          onChange={handleCheckboxChange}
          name="active"
          checked={active}
          id="active"
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments}/>

    </BRModal>
  );
};
