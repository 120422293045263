import * as React from 'react';
import { default as JSONPretty } from 'react-json-pretty';
import { BRLayout } from '../../components/BRLayout';
import { TabMenu } from '../../components/TabMenu';
import { DATE_TIME_OPTIONS, TABLE_IDS } from '../../constants';
import { buildCommandDetailTitle } from '../../helpers/buildDetailPageTitle';
import { useBRStore } from '../../hooks/useBRStore';
import BRTable from '../../components/BRTable';
import { useBRTableStore } from '../../hooks/useBRTableStore';
import { IAttachment, ICommand } from '../../types';
import { IEvent } from '../../types/IEvent';
import { ROUTES } from '../../routes';
import { List } from 'antd';
import { API_ROUTES } from '../../constants/apiRoutes';

interface Props {
  uuid: string;
}

export function AuditDetailPage(props: Props) {
  const commandStore = useBRStore<ICommand>({
    fetchDataParams: React.useMemo(() => ({ url: `${API_ROUTES.commands}/${props.uuid}` }), [props.uuid]),
  });
  const command = commandStore.data;

  const events = useBRTableStore<IEvent>({
    fetchDataParams: React.useMemo(() => ({ url: '/v0/events', queryParams: { command: props.uuid } }), [props.uuid]),
  });

  React.useEffect(
    () => {
      commandStore.fetch();
    },
    [commandStore.fetch]);

  React.useEffect(
    () => {
      events.fetch();
    },
    [events.fetch]);

  const menuTabs = [];

  /*
  const getResourcePath = (resourceType: string, uuid: string) => {
    return ROUTES[resourceType] ? session.router.getUrl({ name: ROUTES[resourceType] }, { uuid }) : null;
  };

  const addResourcePath = (type: string, uuid: string, result: ResourcePath[]) => {
    const path = getResourcePath(type, uuid);
    if (path) {
      result.push({ type, uuid, path });
    }
  };

  const flattenResources = (data: object, result: ResourcePath[]) => {
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        if (key === 'people') {
          if (Array.isArray(value)) {
            value.forEach((person: object) => {
              addResourcePath('person', person['uuid'], result);
            });
          }
        } else {
          if (typeof (value) === 'string') {
            addResourcePath(key, value, result);
          } else if (typeof (value) === 'object') {
            flattenResources(value, result);
          }
        }
      }
    }
  };

  const resources: ResourcePath[] = [];
  if (command.data) {
    flattenResources(command.resources, resources);
  }

  const getResourceLink = (resource: ResourcePath) => {
    return <Link href={resource.path}>{`${resource.type}: ${resource.uuid}`}</Link>;
  };

  const resourceListItem = (resource: ResourcePath) => {
    return (
        <List.Item>
          <List.Item.Meta
              title={getResourceLink(resource)}
          />
        </List.Item>
    );
  };
  */

  const descriptionItems = command.uuid ? {
    UUID: command.uuid,
    Time: command.when ?
      new Date(command.when).toLocaleDateString('en-US', DATE_TIME_OPTIONS) : 'Unknown',
    User: command.author?.name || 'System Process',
  } : {};

  const rowClickEnabled = (row: IEvent) => {
    return !!row.id;
  };

  const attachmentItem = (item: IAttachment) => (
    <List.Item>
      <List.Item.Meta
        title={<a href={item.url} download={item.name}>{item.displayname || item.name}</a>}
      />
    </List.Item>
  );

  menuTabs.push({
    name: 'Events',
    key: 'events',
    component: (
      <BRTable
        rowKey="id"
        tableId={TABLE_IDS.AUDIT_DETAIL_TABLE}
        tableData={events}
        hideCsv={true}
        linkPath={ROUTES.event}
        rowClickEnabled={rowClickEnabled}
        routeMap={{
          id: row => row.id,
          uuid: row => row.command,
        }}
      />
    ),
  });

  if (command?.attachments?.length && command?.attachments?.length > 0) {
    menuTabs.push({
      name: 'Attachments',
      key: 'attachments',
      component: (
        <List
          dataSource={command.attachments}
          renderItem={attachmentItem}
          loading={commandStore.loading}
        />
      ),
    });

  }

  /*
  menuTabs.push({
    name: 'Resources',
    key: 'resources',
    component: (
      <List
        dataSource={resources}
        renderItem={resourceListItem}
        loading={command.loading}
      />
    ),
  });
  */

  if (command.errors?.length) {
    menuTabs.push({
      name: 'Errors',
      key: 'errors',
      component: <JSONPretty id="json-pretty" json={command.errors || ''} />,
    });

  }

  if (command.comments) {
    menuTabs.push({
      name: 'Comments',
      key: 'comments',
      component: <p>{command.comments}</p>,
    });
  }

  return (
    <BRLayout
      title={buildCommandDetailTitle(command)}
      descriptionItems={descriptionItems}
    >
      <TabMenu tabs={menuTabs} />
    </BRLayout>
  );
}
