import React, { useState } from 'react';
import { IPersonRequirement, RESOURCE_STATUS, IPerson, EvalObject } from '../../types';
import { Drawer, Button, Space, Card, Progress } from 'antd';
import { DATE_OPTIONS } from '../../constants';
import { PersonRequirementDrawer } from '../drawers/PersonRequirementDrawer';
import { ModalContext } from '../BRModal';
import { BRStore } from '../../hooks/useBRStore';
import { BRListData } from '../../hooks/useBRListData';
import { getStatusColor, StatusAvatar } from '../StatusAvatar';
import { hasPermission, PERMISSION_TYPES } from '../../helpers/hasPermission';
import Link from 'antd/lib/typography/Link';
import { SessionContext } from '../App';
import { ROUTES } from '../../routes';

interface Props {
  pr: IPersonRequirement;
  person: BRStore<IPerson>;
  requirements: BRListData<IPersonRequirement>;
  optional?: boolean;
  admin: boolean;
}

export function CardItem(props: Props) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modal, setModal] = React.useState<React.ReactNode>();

  const { session } = React.useContext(SessionContext);

  const openDrawer = () => setDrawerVisible(true);

  const closeDrawer = () => {
    setModal(undefined);
    setDrawerVisible(false);
  };

  const closeModal = () => { setModal(undefined); };

  function getExpirationText(pr: IPersonRequirement) {
    let date: number | undefined;
    switch (pr.status) {
      case RESOURCE_STATUS.complete:
        date = pr.completedDate;
        if (date) {
          const formatted = (new Date(date)).toLocaleDateString('en-US', DATE_OPTIONS);
          const date2 = pr.endDate;
          if (date2) {
            const formatted2 = (new Date(date2)).toLocaleDateString('en-US', DATE_OPTIONS);
            return <>{`Completed on ${formatted}`}<br/>{`Expires on ${formatted2}`}</>;
          }
          return <>{`Completed on ${formatted}`}<br/>Never expires</>;
        }
        return 'Complete';
      case RESOURCE_STATUS.expired:
        date = pr.endDate;
        if (date) {
          const formatted = (new Date(date)).toLocaleDateString('en-US', DATE_OPTIONS);
          return `Expired on ${formatted}`;
        }
        return 'Expired';
      case RESOURCE_STATUS.expiring:
        date = pr.endDate;
        if (date) {
          const formatted = (new Date(date)).toLocaleDateString('en-US', DATE_OPTIONS);
          return `Expiring on ${formatted}`;
        }
        return 'Expiring';
    }
    return (<>{pr.status || 'Status Not Calculated'}</>);
  }

  const { pr } = props;
  const descriptionNode = getExpirationText(pr);
  const percent = pr.percent ? Math.round(pr.percent * 100) : 0;

  const requirementTitle = hasPermission(PERMISSION_TYPES.audit, session)
  ? <><Link href={session.router.getUrl({ name: ROUTES.requirement }, { uuid: `${pr.uuid}` })}>{pr.name || 'Requirement'}</Link> Details</>
  : <>{pr.name || 'Requirement'} Details</>;

  return (
    <span className={'card-item'}>
      <Card
        onClick={openDrawer}
        className="site-clickable"
        bordered={true}
      >
        <Card.Meta
          avatar={
            <StatusAvatar size={48} status={pr.status || 'Default'} />
          }
          title={pr.name}
          description={
            <>
              {descriptionNode}
              <Progress percent={percent} strokeColor={getStatusColor(pr.status)} showInfo={percent < 100} />
            </>
          }
        />
      </Card>
      <Drawer
        title={requirementTitle}
        placement="right"
        width={window.innerWidth > 810 ? 810 : '100%'}
        closable={true}
        onClose={closeDrawer}
        visible={drawerVisible}
        destroyOnClose={true}
        footer={(
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Button onClick={closeDrawer}>Close</Button>
            </Space>
          </div>
        )}
      >
        <PersonRequirementDrawer pr={pr} person={props.person} requirements={props.requirements} requirement={pr.uuid} admin={props.admin} />
      </Drawer>
      {!!modal && (
        <ModalContext.Provider value={{ close: closeModal, onSuccess: closeModal, visible: !!modal }}>
          {modal}
        </ModalContext.Provider>
      )}
    </span>
  );
}
