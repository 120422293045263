import React, { useState, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { IPerson } from '../../types';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { IGroup, IGroupMember } from '../../types/IGroup';

interface Props extends BRModalProps {
  person?: IPerson;
  group?: IGroup;
}

export const RemovePersonFromGroupModal: React.FunctionComponent<Props> =
({ person: importedPerson, group: importedGroup, ...modalProps }) => {

  const groupDataParams = useMemo(() => ({ url: `/v0/people/${importedPerson?.uuid}/groups`, queryParams: { all: true } }), [importedPerson]);
  const peopleDataParams = useMemo(() => ({ url: `/v0/groups/${importedGroup?.uuid}/people`, queryParams: { all: true } }), [importedGroup]);

  const groups = useBRListData<IGroup & IGroupMember>({
    fetchDataParams: groupDataParams,
  });

  const people = useBRListData<IPerson & IGroupMember>({
    fetchDataParams: peopleDataParams,
  });

  const [memberUuid, setMemberUuid] = useState('');
  const [comments, setComments] = useState('');

  useEffect(
    () => {
      if (!importedGroup) {
        groups.fetch();
      }
    },
    [groups.fetch]);

  useEffect(
    () => {
      if (!importedPerson) {
        people.fetch();
      }
    },
    [people.fetch]);

  const onFinish = async () => {
    const groupMember = memberMap[memberUuid];
    const formData: {
      uuid?: string,
      group?: string,
      person?: string,
      comments: string,
    } = {
      comments,
      uuid: groupMember?.uuid,
      group: importedGroup ? importedGroup.uuid : groupMember?.group,
      person: importedPerson ? importedPerson.uuid : groupMember?.person,
    };

    await sendCommand({
      formData,
      command: COMMANDS.REMOVE_PERSON_FROM_GROUP,
    });
  };

  const memberMap: { [uuid: string]: IGroupMember } = {};

  function mapGroup(g: IGroup | IGroupMember) {
    return {
      key: g.uuid || '',
      value: g.uuid || '',
      label: g.name || '',
    };
  }
  const groupOptions = importedGroup ? [mapGroup(importedGroup)] : (groups.records.filter(g => g.manuallyAdded)).map(
    (gm: IGroup & IGroupMember) => {
      if (gm.uuid) {
        memberMap[gm.uuid] = gm;
      }
      return mapGroup(gm);
    });

  function mapPerson(p: IPerson & IGroupMember) {
    return {
      key: p.uuid,
      value: p.uuid || '',
      label: `${p.firstName} ${p.lastName}`,
    };
  }
  const personOptions = importedPerson ? [mapPerson(importedPerson)] : people.records.map(
    (gm: IPerson & IGroupMember) => {
      if (gm.uuid) {
        memberMap[gm.uuid] = gm;
      }
      return mapPerson(gm);
    });

  const title = `Remove ${importedPerson ? `${importedPerson.firstName} ${importedPerson.lastName}` : 'Person' } from ${importedGroup ? importedGroup.name : 'Group' }`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Remove from Group"
      formProps={{ onFinish }}
    >
      <Form.Item label="Remove Person">
        <BRSelect
          options={personOptions}
          setValue={setMemberUuid}
          disabled={!!importedPerson}
          value={importedPerson && personOptions[0]}
        />
      </Form.Item>

      <Form.Item label="From Group">
        <BRSelect
          options={groupOptions}
          setValue={setMemberUuid}
          disabled={!!importedGroup}
          value={importedGroup && groupOptions[0]}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
