import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Form, Input } from 'antd';

// Comments Form component
interface BRFormCommentsProps {
  comments: string;
  setComments: Dispatch<SetStateAction<string>>;
  label?: string;
  labelCol?: any;
}

const { TextArea } = Input;

export function BRFormComments(props: BRFormCommentsProps): any {
  const handleComments = (e: ChangeEvent<HTMLTextAreaElement>) => {
    props.setComments(e.target.value);
  };

  return (
    <Form.Item label={props.label ? props.label : 'Comments'} labelCol={props.labelCol ? props.labelCol : null}>
      <TextArea
        value={props.comments}
        onChange={handleComments}
      />
    </Form.Item>
  );
}
