import React, { useState } from 'react';
import { Form, DatePicker, Button, Alert, Popconfirm } from 'antd';
import { BRModal, BRModalProps, ModalContext } from '../BRModal';
import { BRFormComments } from '../BRForm';
import { COMMANDS, CLIENT_CONST } from '../../constants';
import { sendCommand } from '../../services/commands.services';
import moment from 'moment';
import { Document } from '../../resources/Document';
import { ExclamationCircleFilled } from '@ant-design/icons';

interface Props extends BRModalProps {
  tenureDocument: Document;
}

export const EditTenureLengthModal: React.FunctionComponent<Props> =
({ tenureDocument, ...modalProps }) => {
  const isExpired = (date: moment.Moment | null): boolean => {
    // Expiration shouldn't happen until the end of the day
    return date != null && date.endOf('day').isSameOrBefore(moment());
  };

  const { close, onSuccess } = React.useContext(ModalContext);
  const dateRange = tenureDocument.field('dateRange');
  const startMoment = dateRange && dateRange[0] ? moment(dateRange[0]) : moment();
  const defaultEndDate = dateRange && dateRange[1] ? new Date(dateRange[1]) :
      CLIENT_CONST.DEFAULT_TENURE_LENGTH_MONTHS ? startMoment.clone().add(CLIENT_CONST.DEFAULT_TENURE_LENGTH_MONTHS, 'month').toDate() : null;
  let testMoment: moment.Moment;
  const [newEndDate, setNewEndDate] = useState(defaultEndDate);
  const [dateError, setDateError] = useState(false);
  const [comments, setComments] = useState('');
  const [expired, setExpired] = useState(isExpired(moment(defaultEndDate)));

  if (dateRange) {
    const now = (new Date()).getTime();
    if (dateRange[0] && dateRange[0] > now) {
      testMoment = moment(dateRange[0]);
    } else if (dateRange[1]) {
      testMoment = moment(dateRange[1]);
    } else {
      testMoment = moment(now);
    }
  }

  const onFinish = async () => {

    const formData = {
      comments,
      uuid: tenureDocument.id,
      endDate: newEndDate?.getTime() || null,
    };

    await sendCommand({
      formData,
      command: COMMANDS.EDIT_TENURE_LENGTH,
    });

    if (modalProps.onSuccess) {
      modalProps.onSuccess();
    }

    onSuccess();
  };

  const handleNewEndDateChange = (date: moment.Moment | null) => {
    // Make sure the tenure doesn't end more than fifteen months from start date or now
    setDateError(date != null && CLIENT_CONST.MAX_TENURE_LENGTH_MONTHS != null && moment(date).isAfter(testMoment.add(CLIENT_CONST.MAX_TENURE_LENGTH_MONTHS, 'month')));

    // Check whether setting the given date will cause the tenure to expire
    setExpired(isExpired(date));

    setNewEndDate(date?.toDate() || null);
  };

  const endTenureNow = () => {
    // Ending the tenure now will set the end date to yesterday, since a tenure isn't considered over until the end of the day
    handleNewEndDateChange(moment().subtract(1, 'days'));
  };

  const title = `Edit ${CLIENT_CONST.TENURE_TERM} Length`;

  const footer = (
    <>
      <Button onClick={close}>Cancel</Button>
      <Popconfirm
        title={`The end date you set will cause this ${CLIENT_CONST.TENURE_TERM} to expire. Do you wish to continue?`}
        cancelText="No"
        okText="Yes"
        disabled={!expired}
        onConfirm={onFinish}
        icon={<ExclamationCircleFilled style={{ color: 'red' }} />}
      >
        <Button type="primary" onClick={expired ? undefined : onFinish} disabled={dateError}>Edit Length</Button>
      </Popconfirm>
    </>
  );

  return (
    <BRModal
      {...modalProps}
      title={title}
      footer={footer}
    >
      <Form.Item label="New End Date">
        <DatePicker
          value={newEndDate && newEndDate.getTime() > 0 ? moment(newEndDate) : null}
          onChange={handleNewEndDateChange}
          name="newEndDate"
        />
        &nbsp;
        <Button type="primary" onClick={endTenureNow}>
          End {CLIENT_CONST.TENURE_TERM} Now
        </Button>
        {dateError && <Alert message={`${CLIENT_CONST.TENURE_TERM} cannot be more than ${CLIENT_CONST.MAX_TENURE_LENGTH_MONTHS} months long`} type="error"/>}
      </Form.Item>
      <BRFormComments comments={comments} setComments={setComments} />
    </BRModal>
  );
};
