import React, { useEffect, useState } from 'react';
import { Transfer, Tree } from 'antd';
import { Key } from 'antd/lib/table/interface';

const UNCATEGORIZED = 'Uncategorized';
// Since keys are single strings and must be unique, and resources can appear under multiple categories, we need to add the category name
// to the resource key, and use a separator to make it easily distinguishable from the resource UUID
const RESOURCE_KEY_SEPARATOR = '~';
const UUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
const COMPONENT_HEIGHT = 400;
const TREE_HEIGHT = 315;
const COLUMN_WIDTH = 325;

interface Props {
  resources: any[];
  setSelectedResources: (keys: string[]) => void;
}

export function CategorizedTransfer(props: Props) {
  const [categories, setCategories] = useState<any[]>(buildTransferOptions(props.resources));
  const [filteredCategories, setFilteredCategories] = useState<any[]>(buildTransferOptions(props.resources, true));
  const [transferDataSource, setTransferDataSource] = useState<any[]>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [expandedCategoryKeys, setExpandedCategoryKeys] = useState<Key[]>([]);

  useEffect(
    () => { setCategories(buildTransferOptions(props.resources)) },
    [props.resources],
  );
  
  useEffect(
    () => { setFilteredCategories(buildTransferOptions(props.resources, true)) },
    [transferDataSource, searchFilter],
  );
  
  useEffect(
    () => { setTransferDataSource(flatten(categories)); },
    [categories],
  );

  // Expand all categories when a search filter exists, but collapse them when the search field is empty
  useEffect(
    () => { setExpandedCategoryKeys(searchFilter.length > 0 ? filteredCategories.map(cat => cat.key) : []); },
    [filteredCategories],
  );

  function flatten(list: any[] = []): any[] {
    let flattenedItems: any[] = [];

    list.forEach(item => {
      flattenedItems.push(item);
      flattenedItems = [...flattenedItems, ...flatten(item.children)];
    });

    return flattenedItems;
  };

  function matchesSearchFilter(value: string) {
    return value?.toLowerCase().indexOf(searchFilter?.toLowerCase()) >= 0;
  };
  
  function buildTransferOptions(resources: any[], filtered = false): any[] {
    let hasUncategorized = false;

    if (!resources || resources.length === 0) {
      return [];
    }

    // The option list starts with only uncategorized resources
    const resOptions: any[] = [{
      title: UNCATEGORIZED,
      key: UNCATEGORIZED,
      category: true,
      children: [],
    }];
  
    for (const res of resources) {
      if (res.categories?.length > 0) {
        // Add the resource to all of its categories
        for (const cat of res.categories) {
          // Allow categories to be strings or objects
          const catName = cat.name ?? cat;
          const catUuid = cat.uuid ?? cat;
          const resKey = `${catUuid}${RESOURCE_KEY_SEPARATOR}${res.uuid}`;
          let catOption = resOptions.find(o => o.title === catName);
          if (!catOption && (!filtered || matchesSearchFilter(res.name) || matchesSearchFilter(catName))) {
            catOption = {
              title: catName,
              key: catUuid,
              category: true,
              children: [],
            }
            resOptions.push(catOption);
          }
          if (!filtered || matchesSearchFilter(res.name)) {
            catOption.children?.push({
              title: res.name,
              key: resKey,
              category: false,
            });  
          }
        }
      } else {
        hasUncategorized = true;
        const resKey = `${UNCATEGORIZED}${RESOURCE_KEY_SEPARATOR}${res.uuid}`;
        // Add the resource to the list of uncategorized resources
        if (!filtered || matchesSearchFilter(res.name)) {
          resOptions[0].children?.push({
            title: res.name,
            key: resKey,
            category: false,
          });
        }
      }
    }
  
    if (!hasUncategorized || (resOptions[0].children.length === 0 && !matchesSearchFilter(UNCATEGORIZED))) {
      // If no resources are uncategorized, remove that as an option
      resOptions.shift();
    }

    // Sort category names alphabetically
    resOptions.sort((a: any, b: any) => a.title ? a.title.localeCompare(b.title) : 1);

    // Within each category, sort resource names alphabetically
    resOptions.forEach(cat => cat.children?.sort((a: any, b: any) => a.title ? a.title.localeCompare(b.title) : 1));
  
    return resOptions;
  };

  const isChecked = (selectedKeys: any[], eventKey: any) => selectedKeys?.indexOf(eventKey) !== -1;

  const generateTree = (treeNodes: any[] = [], checkedKeys: any[] = []): any =>
    treeNodes.map(({ children, ...props }) => ({
      ...props,
      disabled: checkedKeys.includes(props.key),
      children: generateTree(children, checkedKeys),
    }));

  // Make category names bold
  const titleRender = (item: any) => item.category ? <strong>{item.title}</strong> : item.title;

  // Parse selected values to separate categories from resources
  const onChange = (keys: string[]) => {
    setTargetKeys(keys);
    const selectedResources = new Set<string>();
    for (const key of keys) {
      const keyParts = key.split(RESOURCE_KEY_SEPARATOR);
      // Look for the separator, and make sure that what comes after it is a UUID (i.e. it's not part of the category name)
      if (keyParts.length > 1 && keyParts[keyParts.length - 1].match(UUID_REGEX)) {
        selectedResources.add(keyParts[keyParts.length - 1]);
      } else {
        // Add all of the resources in the category
        categories.find(category => category.key === key)?.children.forEach((child: any) => {
          const childKeyParts = child.key.split(RESOURCE_KEY_SEPARATOR);
          selectedResources.add(childKeyParts[childKeyParts.length - 1]);
        })
      }
    }
    props.setSelectedResources(Array.from(selectedResources));
  };

  // Show options as a tree on the left side of the transfer; otherwise, use the default display
  const generateTransferContent = ({ direction, onItemSelect, selectedKeys }: {
    direction: string,
    onItemSelect: (key: string, selected: boolean) => void,
    selectedKeys: string[],
  }): any => {
    if (direction === 'left') {
      const checkedKeys = [...selectedKeys, ...targetKeys];
      return (
        <Tree
          blockNode
          checkable
          checkStrictly
          height={TREE_HEIGHT}
          checkedKeys={checkedKeys}
          expandedKeys={expandedCategoryKeys}
          titleRender={titleRender}
          treeData={generateTree(filteredCategories, targetKeys)}
          onCheck={(_, { node: { key } }) => {
            onItemSelect(`${key}`, !isChecked(checkedKeys, key));
          }}
          onSelect={(_, { node: { key } }) => {
            onItemSelect(`${key}`, !isChecked(checkedKeys, key));
          }}
          onExpand={(expandedKeys, {expanded: bool, node}) => setExpandedCategoryKeys(expandedKeys)}
        />
      );
    }
    return null;
  }

  const filterOption = (filter: string, option: any) => {
    return option.title?.toLowerCase().indexOf(filter?.toLowerCase()) >= 0;
  };

  const onSearch = (direction: 'left' | 'right', value: string): void => {
    if (direction === 'left') {
      setSearchFilter(value);
    }
  };
  
  return (
    <Transfer
      showSearch
      listStyle={{
        width: COLUMN_WIDTH,
        height: COMPONENT_HEIGHT,
      }}
      targetKeys={targetKeys}
      dataSource={transferDataSource}
      className="tree-transfer"
      render={titleRender}
      showSelectAll={false}
      onChange={onChange}
      filterOption={filterOption}
      onSearch={onSearch}
    >
      {generateTransferContent}
    </Transfer>
  );
};
