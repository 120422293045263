import * as React from 'react';
import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Avatar, List } from 'antd';
import { getElementAttributes } from '../../helpers/getNodeAttributes';
import { RuleEditorDelete } from './RuleEditorDelete';
import { RuleEditorEdit } from './RuleEditorEdit';
import { RuleEditorNodeProps, RULE_NODE_COLORS } from '.';

export function RuleEditorTest(props: RuleEditorNodeProps) {
  const attributes = getElementAttributes(props.elt);
  const description = <><strong>Input:</strong> {attributes.input} <strong>True if:</strong> {attributes.condition} {attributes.value && <><strong>Is:</strong> {attributes.value}</>}</>;

  return (
    <List.Item
      style={{ padding: '0.25em' }}
      actions={props.editable ? [
        <RuleEditorEdit {...props} key="Edit" />,
        <RuleEditorDelete {...props} key="Delete" />,
      ] : undefined}
    >
      <List.Item.Meta avatar={<Avatar icon={<CheckOutlined />} style={{ backgroundColor: RULE_NODE_COLORS.Test }} />} title="Test" description={description} />
    </List.Item>
  );
}
