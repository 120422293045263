import React, { useContext } from 'react';
import { BRModal, BRModalProps } from '../BRModal';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { ROUTES } from '../../routes';
import { SessionContext } from '../App';

export const RunRCRReportModal: React.FunctionComponent<BRModalProps> =
({ ...modalProps }) => {

  const { session } = useContext(SessionContext);

  const onFinish = async () => {
    const formData = { type: 'rcrReport' };
    const response = await sendCommand({
      formData,
      command: COMMANDS.RUN_REPORT,
    });
    session.router.go({ name: ROUTES.report }, response);
  };

  const title = 'RCR Report';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Run Report"
      formProps={{ onFinish }}
    >
      <p><em>No options necessary.</em></p>
    </BRModal>
  );
};
