import * as React from 'react';
import { RedoOutlined } from '@ant-design/icons';
import { Avatar, List } from 'antd';
import { getElementAttributes } from '../../helpers/getNodeAttributes';
import { RuleEditorDelete } from './RuleEditorDelete';
import { RuleEditorEdit } from './RuleEditorEdit';
import { RuleEditorAdd } from './RuleEditorAdd';
import { RuleEditorNodeProps, RULE_NODE_COLORS } from '.';

export function RuleEditorLoop(props: RuleEditorNodeProps) {
  const attributes = getElementAttributes(props.elt);
  const description = <><strong>For Each:</strong> {attributes.input} <strong>True if:</strong> <em>all</em> children match <em>any</em> loop item</>;

  return (
    <List.Item
      style={{ padding: '0.25em' }}
      actions={props.editable ? [
        <RuleEditorAdd {...props} key="Add" />,
        <RuleEditorEdit {...props} key="Edit" />,
        <RuleEditorDelete {...props} key="Delete" />,
      ] : undefined}
    >
      <List.Item.Meta avatar={<Avatar icon={<RedoOutlined />} style={{ backgroundColor: RULE_NODE_COLORS.Loop }} />} title="Loop" description={description} />
    </List.Item>
  );
}
