import React from 'react';
import { Form } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { IMail } from '../../types';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';

interface Props extends BRModalProps {
  mail: IMail;
}

export const ResendMailModal: React.FunctionComponent<Props> =
({ mail, ...modalProps }) => {

  const onFinish = async () => {
    const { uuid } = mail;
    const formData = { mail: uuid };

    await sendCommand({
      formData,
      command: COMMANDS.RESEND_MAIL,
    });
  };

  return(
      <BRModal
        {...modalProps}
        title="Resend this email?"
        okText="Resend"
        formProps={{ onFinish }}
      >
        <Form.Item label="Subject">
          <span className="ant-form-text">
            {mail.subject}
          </span>
        </Form.Item>

      </BRModal>
  );
};
