import * as React from 'react';
import { TabMenu } from '../components/TabMenu';
import { BRLayout } from '../components/BRLayout';
import { ICourseCategory, IRequirement, ICommand, ICourse, ITranscript, ICourseTaken } from '../types';
import { DATE_OPTIONS, TABLE_IDS } from '../constants';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useBRTableStore } from '../hooks/useBRTableStore';
import BRTable from '../components/BRTable';
import { useBRStore } from '../hooks/useBRStore';
import { UpdateCourseModal } from '../components/modals/UpdateCourseModal';
import { ActivateCourseModal } from '../components/modals/ActivateCourseModal';
import { DeactivateCourseModal } from '../components/modals/DeactivateCourseModal';
import { ROUTES } from '../routes';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { checkCommands } from '../helpers/checkCommands';
import { Tag } from 'antd';
import { API_ROUTES } from '../constants/apiRoutes';
import { PERMISSION_TYPES } from '../helpers/hasPermission';

interface Props {
  uuid: string;
}

export function CourseDetailPage(props: Props) {
  const courseUuid = props.uuid;
  const urlRoot = `/v0/courses/${courseUuid}`;

  const courseStore = useBRStore<ICourse>({ fetchDataParams: React.useMemo(() => ({ url: urlRoot }), [props.uuid]) });
  const course = courseStore.data;

  React.useEffect(
    () => {
      courseStore.fetch();
    },
    [courseStore.fetch]);

  const people = useBRTableStore<ICourseTaken & ITranscript>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/people` }), [props.uuid]),
  });

  const requirements = useBRTableStore<IRequirement>({
    fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/requirements` }), [props.uuid]),
  });

  const events = useBRTableStore<ICommand>({
    fetchDataParams: React.useMemo(() => ({ url: API_ROUTES.commands, queryParams: { resource: courseUuid } }), [props.uuid]),
  });

  const menuTabs = [
    {
      name: 'People',
      key: 'people',
      component: (
        <BRTable
          tableId={TABLE_IDS.COURSE_PEOPLE_TABLE}
          linkPath={ROUTES.person}
          tableData={people}
          routeMap={{
            uuid: row => row.person,
          }}
        />
      ),
    },
    {
      name: 'Training Requirements',
      key: 'requirements',
      component: (
        <BRTable
          tableId={TABLE_IDS.COURSE_REQ_TABLE}
          linkPath={ROUTES.requirement}
          tableData={requirements}
        />
      ),
    },
    {
      name: 'Audit Log',
      key: 'events',
      permissions: PERMISSION_TYPES.audit,
      component: (
        <BRTable
          tableId={TABLE_IDS.COURSE_AUDIT_LOG}
          linkPath={ROUTES.command}
          tableData={events}
        />
      ),
    },
  ];

  // const status = {
  //   color: course.active ? 'green' : 'red',
  //   text: course.active ? 'Active' : 'Disabled',
  // };

  const allCourseCommands = [
    {
      title: 'Update Course',
      icon: <EditOutlined />,
      type: 'updateCourse',
      modal: (
        <UpdateCourseModal
          course={course}
          onSuccess={courseStore.fetch}
        />
      ),
    }];

  allCourseCommands.push(course?.active ? {
    title: 'Deactivate Course',
    icon: <CloseCircleOutlined />,
    type: 'activateCourse',
    modal: (
      <DeactivateCourseModal
        course={course}
        onSuccess={courseStore.fetch}
      />
    ),
  } : {
    title: 'Activate Course',
    icon: <EditOutlined />,
    type: 'activateCourse',
    modal: (
      <ActivateCourseModal
        course={course}
        onSuccess={courseStore.fetch}
      />
    ),
  });

  const commands = checkCommands(allCourseCommands, courseStore);

  const categories = course?.categories?.length
  ? course.categories.reduce(
      (acc: string, val: ICourseCategory, i: number, array: ICourseCategory[]) => {
        return i + 1 === array.length ? acc + val.name : `${acc}${val.name}, `;
      },
      '',
    )
  : 'N/A';

  const descriptionItems = {
    Provider: course?.provider ? course.provider.name : 'N/A',
    Categories: categories,
    'Retirement Date': course?.retirementDate ? new Date(course.retirementDate).toLocaleDateString('en-US', DATE_OPTIONS) : 'None',
    // Description: course.description || 'N/A',
    'Provider Course ID': course?.providerId || 'N/A',
    'Course Link': course?.link
      ? <a href={course.link} target="_blank" rel="noopener noreferrer">{course.link}</a>
      : 'N/A',
  };

  return (
    <BRLayout
      loading={courseStore.loading}
      tags={course?.retirementDate && new Date(course.retirementDate) < new Date() ? <Tag color="red">Retired</Tag> : undefined}
      title={course?.name}
      commands={commands}
      descriptionItems={descriptionItems}
    >
      <LoadingAnimation loading={courseStore.loading} />
      <TabMenu tabs={menuTabs} />
    </BRLayout>
  );
}
