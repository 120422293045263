import React, { useState, useEffect, ChangeEvent } from 'react';
import { Checkbox, Form, Input, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import moment from 'moment';
import { BRModal, BRModalProps } from '../BRModal';
import { BRDatePicker, BRFormComments, BRSelect } from '../BRForm';
import { IPerson, ICourse } from '../../types';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { FEATURES } from '../../constants/features';
import { BRSelectValue } from '../BRForm/BRSelect';
import { CategorizedCascader } from '../CategorizedCascader';

interface Props extends BRModalProps {
  person: IPerson;
  defaultCourse?: string;
}

const fetchDataParams = { url: '/v0/courses', queryParams: { all: true } };

export const AddCourseToPersonModal: React.FunctionComponent<Props> =
({ person, defaultCourse, ...modalProps }) => {
  const today = new Date();
  const tomorrow = new Date(new Date().setDate(today.getDate() + 1));

  const courses = useBRListData<ICourse>({ fetchDataParams });

  useEffect(() => {
    courses.fetch();
  },        [courses.fetch]);

  const [courseId, setCourseId] = useState(defaultCourse ?? '');
  const [courseDate, setCourseDate] = useState(new Date());
  const [scheduled, setScheduled] = useState(false);
  const [teaching, setTeaching] = useState(false);
  const [comments, setComments] = useState('');
  const [equivalent, setEquivalent] = useState(false);
  const [topic, setTopic] = useState('');
  const [format, setFormat] = useState('');
  const [instructorRole, setInstructorRole] = useState('');

  const handleTopic = (e: ChangeEvent<HTMLInputElement>) => { setTopic(e.target.value); };

  const handleScheduled = (e: RadioChangeEvent) => {
    const scheduled = e.target.value === 'scheduled';
    setScheduled(scheduled);
    setCourseDate(scheduled ? tomorrow : today);
  };

  const handleCheckboxes = (e: CheckboxChangeEvent) => {
    const { name, checked } = e.target;
    switch (name) {
      case 'teaching':
        setTeaching(checked);
        break;
      case 'equivalent':
        setEquivalent(checked);
        break;
      default:
        break;
    }
  };

  const handleFormat = (e: ChangeEvent<HTMLInputElement>) => { setFormat(e.target.value); };

  const handleInstructorRole = (e: ChangeEvent<HTMLInputElement>) => {
    setInstructorRole(e.target.value);
  };

  const onFinish = async () => {
    const formData: {
      course: string,
      person: string,
      teaching: boolean,
      scheduledDate: number | undefined,
      completedDate: number | undefined,
      comments: string,
      courseComments: string,
      equivalent: boolean,
      topic: string,
      format: string,
      instructorRole: string,
    } = {
      teaching,
      comments,
      equivalent,
      topic,
      format,
      instructorRole,
      person: person.uuid,
      course: courseId,
      courseComments: comments,
      scheduledDate: undefined,
      completedDate: undefined,
    };
    if (scheduled) {
      formData.scheduledDate = courseDate.getTime();
    } else {
      formData.completedDate = courseDate.getTime();
    }

    await sendCommand({
      formData,
      command: COMMANDS.ADD_COURSE_TO_PERSON,
    });
  };

  const courseOptions: BRSelectValue[] = courses.records ? courses.records.map(
    c => ({
      key: c.uuid,
      value: c.uuid || '',
      label: c.name || '',
    })) : [];

  // Used by Ant Design's DatePicker component to limit pickable dates
  // Requires use of moment js date formats
  const disabledCourseDates = (current: moment.Moment | undefined) => (
    current ? (scheduled ? current < moment(tomorrow) : current > moment(today)) : false
  );

  const title = `Add Course to ${person.firstName} ${person.lastName}`;

  const datePicker = (
    <BRDatePicker
      dateValue={courseDate}
      setValue={setCourseDate}
      disabledDate={disabledCourseDates}
    />
  );

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Add Course"
      formProps={{ onFinish }}
    >
      <Form.Item label="Add Course">
        {FEATURES.CATEGORIZED_COURSE_SELECTOR && !defaultCourse ? (
          <CategorizedCascader
            resources={courses.records}
            setResourceUuid={setCourseId}
          />
        ) : (
          <BRSelect
            value={courseId ? courseOptions.find(c => c.key === courseId) : undefined}
            options={courseOptions}
            setValue={setCourseId}
            disabled={!!defaultCourse}
          />
        )}
      </Form.Item>

      <Form.Item label="To Person">
        <span className="ant-form-text">
          {`${person.firstName} ${person.lastName}`}
        </span>
      </Form.Item>

      {FEATURES.SCHEDULED_DATE &&
        (
          <Form.Item label="Date">
            <Radio.Group onChange={handleScheduled} defaultValue={scheduled ? 'scheduled' : 'completed'}>
              <Radio value="completed" name="completed" checked={!scheduled}>Completed</Radio>
              <Radio value="scheduled" name="scheduled" checked={scheduled}>Scheduled</Radio>
            </Radio.Group>
            <br/>
            {datePicker}
          </Form.Item>
        )
        ||
        (
          <Form.Item label="Completed Date">
            {datePicker}
          </Form.Item>
        )
      }

      <Form.Item label={`Taught by ${person.firstName} ${person.lastName}`}>
        <Checkbox
          name="teaching"
          checked={teaching}
          onChange={handleCheckboxes}
        />
        {teaching && (
          <>
            <Input
              type="text"
              name="topic"
              addonBefore="Topic"
              value={topic}
              onChange={handleTopic}
              disabled={!teaching}
            />
            <br />
            <Input
              type="text"
              name="format"
              addonBefore="Format"
              value={format}
              onChange={handleFormat}
              disabled={!teaching}
            />
            <Input
              type="text"
              name="instructorRole"
              addonBefore="Instructor Role"
              value={instructorRole}
              onChange={handleInstructorRole}
              disabled={!teaching}
            />
          </>
          )
        }
      </Form.Item>

      <Form.Item label="Course equivalency">
        <Checkbox
          onChange={handleCheckboxes}
          name="equivalent"
          checked={equivalent}
          id="equivalent"
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
