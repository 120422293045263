import React, { useState, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { IRequirement, IRequirementCategory } from '../../types';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';

interface Props extends BRModalProps {
  requirement?: IRequirement;
  category?: IRequirementCategory;
}

export const RemoveRequirementFromCategoryModal: React.FunctionComponent<Props> =
({ requirement: importedRequirement, category: importedCategory, ...modalProps }) => {

  const requirementDataParams = useMemo(() => ({ url: `/v0/requirements/categories/${importedCategory?.uuid}/requirements`, queryParams: { basic: true } }), [importedCategory]);

  const requirements = useBRListData<any>({ // "any" because "basic" requirement results only include category name in "categories" array
    fetchDataParams: requirementDataParams,
  });

  const [category, setCategory] = useState(importedCategory?.uuid ?? '');
  const [requirement, setRequirement] = useState(importedRequirement?.uuid ?? '');
  const [comments, setComments] = useState('');

  useEffect(
    () => {
      if (!importedRequirement) {
        requirements.fetch();
      }
    },
    [requirements.fetch]);

  const onFinish = async () => {
    const formData: {
      requirement: string,
      category: string,
      comments: string,
    } = {
      requirement,
      category,
      comments,
    };

    await sendCommand({
      formData,
      command: COMMANDS.REMOVE_REQUIREMENT_FROM_CATEGORY,
    });
  };

  function mapValue(value: any) {
    return {
      key: value.uuid,
      value: value.uuid,
      label: value.name || value.uuid,
    };
  }

  const categoryOptions = (importedCategory ? [importedCategory] : importedRequirement?.categories || []).map(mapValue);
  const requirementOptions = (importedRequirement ? [importedRequirement] : requirements.records.filter(r => r.categories?.find((rc: string) => rc === importedCategory?.name))).map(mapValue);
  const title = `Remove ${importedRequirement ? importedRequirement.name : 'Requirement' } from ${importedCategory ? importedCategory.name : 'Category' }`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Remove from Category"
      formProps={{ onFinish }}
    >
      <Form.Item label="Remove Requirement">
        <BRSelect
          options={requirementOptions}
          setValue={setRequirement}
          disabled={!!importedRequirement}
          value={importedRequirement && requirementOptions[0]}
        />
      </Form.Item>

      <Form.Item label="From Category">
        <BRSelect
          options={categoryOptions}
          setValue={setCategory}
          disabled={!!importedCategory}
          value={importedCategory && categoryOptions[0]}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
