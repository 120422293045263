import * as React from 'react';
import { BRLayout } from '../../components/BRLayout';
import { ICommand } from '../../types';
import { TABLE_IDS } from '../../constants';
import BRTable from '../../components/BRTable';
import { useBRTableStore } from '../../hooks/useBRTableStore';
import { UploadCSVModal } from '../../components/modals/UploadCSVModal';
import { ROUTES } from '../../routes';
import { PlusOutlined } from '@ant-design/icons';
import { checkCommands } from '../../helpers/checkCommands';
import { API_ROUTES } from '../../constants/apiRoutes';
import { Command } from '../../components/CommandButtons';

interface Props {

}

export function AuditListPage(props: Props) {

  const commandsStore = useBRTableStore<ICommand>({
    fetchDataParams: { url: API_ROUTES.commands },
  });

  const allAuditCommands: Command[] = [
    {
      title: 'Bulk Import',
      icon: <PlusOutlined />,
      type: 'bulkImport',
      modal: (
        <UploadCSVModal
          onSuccess={commandsStore.fetch}
        />
      ),
    },
  ];

  const commands = checkCommands(allAuditCommands, commandsStore);

  return (
    <BRLayout
      title="Audit Log"
      commands={commands}
    >
      <BRTable
        linkPath={ROUTES.command}
        tableId={TABLE_IDS.AUDIT_LOG_TABLE}
        tableData={commandsStore}
      />
    </BRLayout>
  );
}
