import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import { Alert, Form, Input } from 'antd';
import { BRModal, BRModalProps, ModalContext } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { FormProps } from 'antd/lib/form';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';
import { SessionContext } from '../App';
import { ROUTES } from '../../routes';
import { useBRListData } from '../../hooks/useBRListData';
import { IGroup } from '../../types/IGroup';

interface Props extends BRModalProps {
}

const groupDataParams = { url: '/v0/groups', queryParams: { all: true, filters: { type: ['Role'] } } };

export const NewPersonModal: React.FunctionComponent<Props> =
({ ...modalProps }) => {

  const { session } = useContext(SessionContext);
  const { close } = useContext(ModalContext);

  const groups = useBRListData<IGroup>({
    fetchDataParams: groupDataParams,
  });

  const [firstName, setFirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [nameError, setNameError] = useState('');
  const [department, setDepartment] = useState('');
  const [division, setDivision] = useState('');
  const [roles, setRoles] = useState([]);
  const [emailPrimary, setEmailPrimary] = useState('');
  const [emailSecondary, setEmailSecondary] = useState('');
  const [emailError, setEmailError] = useState('');
  const [comments, setComments] = useState('');

  useEffect(
    () => {
      groups.fetch();
    },
    [groups.fetch]);

  const onFinish = async () => {
    let error = false;
    setEmailError('');
    setNameError('');
    if (!emailPrimary) {
      setEmailError('Primary Email must be provided');
      error = true;
    }

    if (!firstName || !lastName) {
      setNameError('First and Last Names must be provided');
      error = true;
    }

    if (error) {
      return;
    }

    const email = [emailPrimary];
    if (emailSecondary) {
      email.push(emailSecondary);
    }

    const formData = {
      firstName,
      lastName,
      email,
      roles,
      comments,
      department: department || undefined,
      division: division || undefined,
    };

    const response = await sendCommand({
      formData,
      command: COMMANDS.NEW_PERSON,
    });

    session.router.go({ name: ROUTES.person }, response);
  };

  const groupOptions = groups.records.map(
      g => ({
        key: g.uuid,
        value: g.uuid || '',
        label: g.name,
      }),
    );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setlastName(value);
        break;
      case 'emailPrimary':
        setEmailPrimary(value);
        break;
      case 'emailSecondary':
        setEmailSecondary(value);
        break;
      case 'department':
        setDepartment(value);
        break;
      case 'division':
        setDivision(value);
        break;
      default:
        break;
    }
  };

  const title = 'Create New Person';

  const siteSpecificFields = process.env.REACT_APP_SITE === 'chop';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Create Person"
      formProps={{ onFinish }}
    >
      <Form.Item label="First Name">
      {nameError && <Alert message={nameError} />}
        <Input
          onChange={handleChange}
          value={firstName}
          type="text"
          name="firstName"
          required={true}
          minLength={1}
        />
      </Form.Item>

      <Form.Item label="Last Name">
      {nameError && <Alert message={nameError} />}
        <Input
          onChange={handleChange}
          value={lastName}
          type="text"
          name="lastName"
          required={true}
          minLength={1}
        />
      </Form.Item>

      <Form.Item label="Primary Email">
      {emailError && <Alert message={emailError} />}
        <Input
          onChange={handleChange}
          value={emailPrimary}
          type="email"
          name="emailPrimary"
          required={true}
          minLength={5}
        />
      </Form.Item>

      {siteSpecificFields && (
        <>
          <Form.Item label="Secondary Email">
            <Input
              onChange={handleChange}
              value={emailSecondary}
              type="email"
              name="emailSecondary"
              minLength={5}
            />
          </Form.Item>
          <Form.Item label="Department">
            <Input
              onChange={handleChange}
              value={department}
              type="text"
              name="department"
              minLength={1}
            />
          </Form.Item>
          <Form.Item label="Division">
            <Input
              onChange={handleChange}
              value={division}
              type="text"
              name="division"
              minLength={1}
            />
          </Form.Item>
          <Form.Item label="Role(s)">
            <BRSelect
              options={groupOptions}
              mode="multiple"
              setValue={setRoles}
            />
          </Form.Item>
        </>
      )}

      <BRFormComments comments={comments} setComments={setComments}/>

    </BRModal>
  );
};
