import React, { useEffect } from 'react';
import { List, Avatar } from 'antd';
import { IExemption } from '../types';
import { DATE_OPTIONS, UI_COLORS } from '../constants';
import { BRListData } from '../hooks/useBRListData';
import { CheckOutlined } from '@ant-design/icons';

const sortExemptions = (a: IExemption, b: IExemption) => {
  if (a.requirement.name && b.requirement.name) {
    if (a.requirement.name > b.requirement.name) return 1;
    if (a.requirement.name < b.requirement.name) return -1;
  }
  return 0;
};

export const ExemptionsTable: React.FunctionComponent<{
  listData: BRListData<IExemption>,
}> = ({ listData }) => {

  const { records, loading, fetch } = listData;

  useEffect(() => { fetch(); }, [fetch]);

  const renderListItem = (item: IExemption) => {
    return (
    <List.Item>
      <List.Item.Meta
        avatar={(
          <Avatar
            icon={<CheckOutlined />}
            style={{ backgroundColor: UI_COLORS.deepBlue }}
          />
        )}
        title={`Requirement: ${item.requirement.name}`}
        description={`Course: ${item.course.name} - Exempted on ${(new Date(item.exemptionDate)).toLocaleDateString('en-US', DATE_OPTIONS)}`}
      />
    </List.Item>
    );
  };

  return (
    <List
      dataSource={records}
      renderItem={renderListItem}
      loading={loading}
    />
  );
};
