import * as React from 'react';
import { TABLE_IDS } from '../../constants';
import { BRLayout } from '../../components/BRLayout';
import BRTable from '../../components/BRTable';
import { useBRTableStore } from '../../hooks/useBRTableStore';
import { ROUTES } from '../../routes';
import { IEquivalency } from '../../types/IEquivalency';
import { PersonEquivalencyDrawer } from '../../components/drawers/PersonEquivalencyDrawer';
import { checkCommands } from '../../helpers/checkCommands';
import { Command } from '../../components/CommandButtons';

interface Props {
  uuid?: string;
}

export function EquivalencyListPage(props: Props) {
  const equivalencies = useBRTableStore<IEquivalency>({
    fetchDataParams: { url: '/v0/equivalencies' },
  });

  const allEquivalencyListCommands: Command[] = [];

  const commands = checkCommands(allEquivalencyListCommands, equivalencies);

  return (
    <BRLayout
      commands={commands}
      title="Equivalency Review"
    >
      <BRTable
        linkPath={ROUTES.adminEquivalencyDetail}
        tableId={TABLE_IDS.EQUIVALENCIES_TABLE}
        tableData={equivalencies}
      />
      { props.uuid ? (
        <PersonEquivalencyDrawer equivalencyUuid={props.uuid} isReviewer={true} store={equivalencies} show={!!props.uuid} />
      ) : <></>}
    </BRLayout>
  );
}
