import React, { useContext, useEffect } from 'react';
import { FieldProps, Field } from '../Field';
import { StepContext } from '../Step';
import { BRLargeDataSelect } from '../../BRForm';
import { DocumentEditorContext } from '../../../types/DocumentEditor';
import { useBRListData } from '../../../hooks/useBRListData';
import { IPerson } from '../../../types';

export enum SearchFieldType {
  person = 'person',
}

interface SearchFieldProps extends FieldProps {
  searchFieldType: SearchFieldType;
  defaultOption?: string;
}

const peopleDataParams = { url: '/v0/people', queryParams: { basic: true } };

export const SearchField = (props: SearchFieldProps) => {
  const { documentEditor } = useContext(DocumentEditorContext);
  const { value, setValue } = useContext(StepContext);

  const people = useBRListData<IPerson>({
    fetchDataParams: peopleDataParams,
  });

  useEffect(
    () => {
      people.fetch();
    },
    [people.fetch],
  );

  if (!documentEditor.document) {
    return null;
  }

  const setSearchValue = (value: any) => {
    if (!documentEditor.document) {
      return;
    }

    setValue(value);
  };

  const getListData = () => {
    switch (props.searchFieldType) {
      case SearchFieldType.person:
        return people;
      default:
        throw new Error(`Unrecognized search type: ${props.searchFieldType}`);
    }
  };

  const listData = getListData();

  const getOptions = () => {
    switch (props.searchFieldType) {
      case SearchFieldType.person:
        return (listData.records).map(
          p => ({
            key: p.uuid,
            value: p.uuid || '',
            label: `${p.lastName}, ${p.firstName} <${p.keys?.email?.join(', ')}>`,
          }),
        );
      default:
        throw new Error(`Unrecognized search type: ${props.searchFieldType}`);
    }
  };

  const listOptions = getOptions().sort((a, b) => {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  });

  let valueIndex = -1;
  let defaultIndex = -1;

  listOptions.forEach((val, index) => {
    if (val.key === value) {
      valueIndex = index;
    }
    if (val.key === props.defaultOption) {
      defaultIndex = index;
    }
  });

  return (
    <Field {...props}>
      <BRLargeDataSelect
        options={listOptions}
        setValue={setSearchValue}
        loading={listData.loading}
        value={valueIndex >= 0 ? listOptions[valueIndex] : defaultIndex >= 0 ? listOptions[defaultIndex] : undefined}
        disabled={!documentEditor.document.editable || !!props.readOnly}
        style={{ width: '100%' }}
      />
    </Field>
  );
};
