import React, { useEffect, useMemo } from 'react';
import { IPersonRequirement, IPerson, RESOURCE_STATUS } from '../../types';
import { CardItem } from './CardItem';
import { BRListData } from '../../hooks/useBRListData';
import { BRStore } from '../../hooks/useBRStore';
import { Empty } from 'antd';

export const DetailCards: React.FunctionComponent<{
  person: BRStore<IPerson>,
  requirements: BRListData<IPersonRequirement>,
  mandatory?: boolean,
  applies?: boolean,
  description?: boolean,
  admin: boolean
  initialStatusFilter?: string[],
}> = ({ person, requirements, mandatory, applies = true, description, admin, initialStatusFilter }) => {
  const { fetch, records } = requirements;
  const allStatuses = [RESOURCE_STATUS.actionRequired, RESOURCE_STATUS.complete, RESOURCE_STATUS.expired, RESOURCE_STATUS.expiring, RESOURCE_STATUS.incomplete];
  const statusFilter = initialStatusFilter || allStatuses;

  useEffect(() => { fetch(); }, [fetch]);

  const filteredRecords = useMemo(
    () => {
      let out = records ? records.filter(pr => (applies === false || pr.applies === true) && pr.status && statusFilter.includes(pr.status)) : [];

      if (mandatory !== undefined) {
        out = out.filter(pr => pr.mandatory === mandatory || mandatory === undefined);
      }

      return out;
    },
    [records, mandatory, applies, statusFilter],
  );

  return (
    <>
      <div className="requirement-card-container">
        {
          filteredRecords.map((pr, index) => (
            <CardItem pr={pr} person={person} requirements={requirements} admin={admin} key={pr.uuid} />
          ))
        }
      </div>
      {filteredRecords.length === 0 ?
        (<Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="None"
        />)
        :
        (<></>)
      }
    </>
  );
};
