import * as React from 'react';
import { TabMenu } from '../components/TabMenu';
import { BRLayout } from '../components/BRLayout';
import { IPerson, ICommand, PROTOCOL_SUMMARIZED_STATE } from '../types';
import { TABLE_IDS, DATE_OPTIONS } from '../constants';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { Tag } from 'antd';
import { useBRTableStore } from '../hooks/useBRTableStore';
import BRTable from '../components/BRTable';
import { useBRStore } from '../hooks/useBRStore';
import { ROUTES } from '../routes';
import { IGroup, GROUP_TYPE, IGroupMember } from '../types/IGroup';
import { AddPersonToGroupModal } from '../components/modals/AddPersonToGroupModal';
import { RemovePersonFromGroupModal } from '../components/modals/RemovePersonFromGroupModal';
import { Command } from '../components/CommandButtons';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { checkCommands } from '../helpers/checkCommands';
import { statusTagRenderer } from '../helpers/dataRenderers';
import { API_ROUTES } from '../constants/apiRoutes';
import { PERMISSION_TYPES } from '../helpers/hasPermission';

interface Props {
  uuid: string;
}

export function GroupDetailPage(props: Props) {

  const urlRoot = `/v0/groups/${props.uuid}`;
  const fetchDataParams = React.useMemo(() => ({ url: urlRoot }), [props.uuid]);

  const groupStore = useBRStore<IGroup>({ fetchDataParams });
  const group = groupStore.data;

  React.useEffect(
    () => {
      groupStore.fetch();
    },
    [groupStore.fetch]);

  const people = useBRTableStore<IPerson & IGroupMember>({ fetchDataParams: React.useMemo(() => ({ url: `${urlRoot}/people` }), [props.uuid]) });

  const events = useBRTableStore<ICommand>({ fetchDataParams: React.useMemo(() => ({ url: API_ROUTES.commands, queryParams: { resource: props.uuid } }), [props.uuid]) });

  const updateData = () => {
    groupStore.fetch();
    people.fetch();
    events.fetch();
  };

  /*
  const status = {
    color: 'red',
    text: 'No Data',
  };

  // should be updated to account for non-protocol groups and multi-client states
  if (group.state === PROTOCOL_SUMMARIZED_STATE.complete) {
    status.color = 'green';
    status.text = 'Complete';
  } else if (group.state === PROTOCOL_SUMMARIZED_STATE.creation) {
    status.color = 'purple';
    status.text = 'Creation';
  } else if (group.state === PROTOCOL_SUMMARIZED_STATE.inProgress) {
    status.color = 'blue';
    status.text = 'In Progress';
  } else {
    status.color = 'grey';
    status.text = 'Not applicable';
  }
  */

  const allGroupCommands: Command[] = [];

  if (group?.type !== GROUP_TYPE.protocol && (group?.createdBy === 'Application' || group?.createdBy === 'Manual')) {
    // this is a temporary measure until this conditional can be expressed in a rule or some other system
    allGroupCommands.push({
      title: 'Add Person',
      icon: <PlusOutlined />,
      type: 'addPersonToGroup',
      modal: (
        <AddPersonToGroupModal
          group={group}
          onSuccess={updateData}
        />
      ),
    });
    allGroupCommands.push({
      title: 'Remove Person',
      icon: <MinusOutlined />,
      type: 'removePersonFromGroup',
      modal: (
        <RemovePersonFromGroupModal
          group={group}
          onSuccess={updateData}
        />
      ),
    });
  }

  const commands = checkCommands(allGroupCommands, groupStore);

  const menuTabs = [
    {
      name: 'People',
      key: 'people',
      component: (
        <BRTable
          tableId={TABLE_IDS.GROUP_PEOPLE_TABLE}
          linkPath={ROUTES.person}
          tableData={people}
          routeMap={{
            uuid: (row: IGroupMember) => row.person,
          }}
        />
      ),
    },

    {
      name: 'Audit Log',
      key: 'events',
      permissions: PERMISSION_TYPES.audit,
      component: (
        <BRTable
          tableId={TABLE_IDS.GROUP_AUDIT_LOG}
          linkPath={ROUTES.command}
          tableData={events}
        />
      ),
    },
  ];

  // description table items
  const descriptionItems = {
    'Group ID': group.uuid || 'N/A',
    Description: group.description || 'N/A',
    Type: group.type || 'N/A',
    State: group.state || 'N/A',
    'Start Date': group.startDate ? new Date(group.startDate).toLocaleDateString('en-US', DATE_OPTIONS) : 'Unknown' || 'N/A',
    'End Date': group.endDate ? new Date(group.endDate).toLocaleDateString('en-US', DATE_OPTIONS) : 'Unknown' || 'N/A',
    Source: group.createdBy,
  };

  return (
    <BRLayout
      commands={commands}
      loading={groupStore.loading}
      title={group.name}
      descriptionItems={descriptionItems}
      tags={statusTagRenderer(group.state ?? 'Unknown', group)}
    >
      <LoadingAnimation loading={groupStore.loading} />
      <TabMenu tabs={menuTabs} />
    </BRLayout>
  );
}
