import { createContext } from 'react';
import { Document } from '../resources/Document';
import { DocumentSpec } from './DocumentSpec';
import { FormType } from './Form';
import { TenureDocument } from '../resources/TenureDocument';
import { TenureRequestDocument } from '../resources/TenureRequestDocument';
import { SettingsDocument } from '../resources/SettingsDocument';

// Consists of a map of named forms and a document
export interface DocumentEditor {
  document?: Document;
}

// An action type for processing document data
export interface DocumentData {
  type: 'documentData';
  document?: DocumentSpec;
  dispatch: (value: DocumentEditorAction) => void;
}

// Only one type of action for now, which updates the editor's document (containing the form data)
export type DocumentEditorAction = DocumentData;

export function documentEditorReducer(state: DocumentEditor, action: DocumentEditorAction): DocumentEditor {
  let out = state;
  switch (action.type) {
    case 'documentData':
      if (action.document) {
        let newDocument: Document;
        if (action.document.type === FormType.tenure) {
          newDocument = new TenureDocument(action.document);
        } else if (action.document.type === FormType.tenureRequest) {
          newDocument = new TenureRequestDocument(action.document);
        } else if (action.document.type === FormType.settings) {
          newDocument = new SettingsDocument(action.document);
        } else {
          throw new Error(`Unknown document type: ${action.document.type}`);
        }
        out = {
          ...state,
          document: newDocument,
        };
      } else {
        out = {
          ...state,
          document: undefined,
        };
      }
      break;
    default:
      throw new Error('invalid documentEditorAction');
  }
  console.warn('ACTION', action, '\nNEW STATE', out);
  return out;
}

export const DocumentEditorContext = createContext({
  documentEditor: {} as DocumentEditor,
  documentDispatch: (action: DocumentEditorAction) => { },
});
