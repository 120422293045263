import React, { useState } from 'react';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments } from '../BRForm';
import { IPerson } from '../../types';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { IGroupMember } from '../../types/IGroup';

interface Props extends BRModalProps {
  person: IPerson;
  groupMember: IGroupMember;
}

export const RemoveAwardFromPersonModal: React.FunctionComponent<Props> =
  ({ person, groupMember, ...modalProps }) => {

    const [comments, setComments] = useState('');

    const onFinish = async () => {
      const formData = {
        comments,
        uuid: groupMember.uuid,
        person: person.uuid,
        group: groupMember.group,
      };

      await sendCommand({
        formData,
        command: COMMANDS.REMOVE_PERSON_FROM_GROUP,
      });
    };

    const title = `Remove ${groupMember.name} Award from ${person.firstName} ${person.lastName}`;

    return (
      <BRModal
        {...modalProps}
        title={title}
        okText="Remove Award"
        formProps={{ onFinish }}
      >
        <BRFormComments comments={comments} setComments={setComments} />
      </BRModal>
    );
  };
