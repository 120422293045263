import React, { ChangeEvent, useEffect, useState } from 'react';
import { default as ReactQuill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Input } from 'antd';

export interface TemplateVersionDetailProps {
  subject: string;
  text: string;
  readonly: boolean;
  onSubjectChange?(subject: string): void;
  onTextChange?(subject: string): void;
}

export const TemplateVersionDetailPage: React.FunctionComponent<TemplateVersionDetailProps> =
({ subject, text, readonly, ...props }) => {

  const [content, setContent] = useState(text);
  const [currentSubject, setCurrentSubject] = useState<string>(subject);

  useEffect(() => {
    setContent(text);
  },
            [text],
  );

  useEffect(() => {
    setCurrentSubject(subject);
  },
            [subject],
  );

  const modules = {
    toolbar: !readonly,
  };

  const onSubjectChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentSubject(e.target.value);
    if (props.onSubjectChange) {
      props.onSubjectChange(e.target.value);
    }
  };

  const onTextChange = (e: string) => {
    setContent(e);
    if (props.onTextChange) {
      props.onTextChange(e);
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <h4>Subject:</h4>
      <Input value={currentSubject} onChange={onSubjectChange} disabled={readonly} />
      <h4>Body:</h4>
      <ReactQuill
        theme="snow"
        value={content}
        readOnly={readonly}
        modules={modules}
        onChange={onTextChange}
      />
    </div>
  );
};
