import React, { useState, useEffect } from 'react';
import { Alert, Form } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { ITenure, IApprovalTemplate } from '../../types';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';
import { useBRListData } from '../../hooks/useBRListData';

interface Props extends BRModalProps {
  tenure: ITenure;
  assignedApprovals: IApprovalTemplate[];
}

const fetchDataParams = { url: '/v0/approvaltemplates' };

export const AssignApprovalTemplatesModal: React.FunctionComponent<Props> =
({ tenure, assignedApprovals, ...modalProps }) => {

  const availableApprovalTemplates = useBRListData<IApprovalTemplate>({ fetchDataParams });

  useEffect(() => { availableApprovalTemplates.fetch(); }, []);

  const [approvalTemplates, setApprovalTemplates] = useState([] as string[]);
  const [approvalTemplateIdsError, setApprovalTemplateIdsError] = useState('');
  const [comments, setComments] = useState('');

  const onFinish = async () => {
    setApprovalTemplateIdsError('');
    if (!approvalTemplates.length) {
      setApprovalTemplateIdsError('At least one approval template must be selected');
      return;
    }

    const formData = {
      approvalTemplates,
      comments,
      tenure: tenure.uuid,
    };

    sendCommand({
      formData,
      command: COMMANDS.ASSIGN_APPROVAL_TEMPLATES_TO_TENURE,
    });
  };

  const selectTemplates = availableApprovalTemplates.records ? availableApprovalTemplates.records
    .filter(t => !assignedApprovals?.find(a => a.uuid === t.uuid))
    .map(t => ({
      key: t.uuid,
      value: t.uuid || '',
      label: t.name,
    })) : [];

  const title = 'Assign Approval Templates';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Assign Approval Templates"
      formProps={{ onFinish }}
    >
      <Form.Item label={'Approval Templates:'}>
        {approvalTemplateIdsError && <Alert message={approvalTemplateIdsError} />}
        <BRSelect
          setValue={setApprovalTemplates}
          options={selectTemplates}
          mode="multiple"
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
