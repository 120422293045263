
export enum COURSE_STATUS {
  complete = 'Complete',
  scheduled = 'Scheduled',
  equivalent = 'Equivalency',
  pending = 'Pending Equivalency',
  returned = 'Equivalency Returned'
}

export interface ICourseStatus {
  course: string; // uuid
  name: string;
  date: number;
  status: COURSE_STATUS;
  link?: string;
}
