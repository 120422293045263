import * as React from 'react';
import { message, Modal } from 'antd';

import { BRForm } from '../BRForm';
import { ModalProps } from 'antd/lib/modal';
import { FormProps } from 'antd/lib/form';
import { LoadingOutlined } from '@ant-design/icons';

export interface BRModalProps extends ModalProps {
  onSuccess?: () => void;
  okDisabled?: boolean;
}

export const ModalContext = React.createContext({
  close: () => {},
  onSuccess: () => {},
  visible: false,
});

export const BRModal: React.FunctionComponent<BRModalProps & {formProps?: FormProps}> =
({ formProps = {}, ...props }) => {
  const { close, onSuccess, visible } = React.useContext(ModalContext);
  const [disabled, setDisabled] = React.useState(false);

  const onFinish = async (e: any) => {
    const hide = message.loading(`${props.title} is running...`, 0);

    setDisabled(true);
    if (formProps.onFinish) {
      try {
        formProps.onFinish(e);
      } catch (ee: any) {
        hide();
        message.error(ee.message);
        setDisabled(false);
        return;
      }
    }
    if (props.onSuccess) {
      props.onSuccess();
    }
    hide();
    onSuccess();
    setDisabled(false);
  };

  return (
    <Modal
      width={720}
      {...props}
      okText={disabled ? <LoadingOutlined spin={true} /> : props.okText ?? 'Submit'}
      visible={visible}
      closable={!disabled}
      onCancel={close}
      onOk={onFinish}
      okButtonProps={{ disabled: disabled || props.okDisabled }}
      cancelButtonProps={{ disabled }}
    >
      {/* <Spin tip="Loading..." spinning={props.commandsProcessing}>
        {props.commandsError && <Alert type="error" message={props.commandsError} />} */}
        <BRForm {...formProps} onFinish={onFinish}>
          {props.children}
        </BRForm>
      {/* </Spin> */}

    </Modal>
  );
};
