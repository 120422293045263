import * as React from 'react';
import { TabMenu } from '../components/TabMenu';
import { BRLayout } from '../components/BRLayout';
import { ICommand, ITranscript, ICourseTaken } from '../types';
import { TABLE_IDS } from '../constants';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useBRTableStore } from '../hooks/useBRTableStore';
import BRTable from '../components/BRTable';
import { useBRStore } from '../hooks/useBRStore';
import { ROUTES } from '../routes';
import { LinkTranscriptModal } from '../components/modals/LinkTranscriptModal';
import { LinkOutlined, StopOutlined } from '@ant-design/icons';
import { checkCommands } from '../helpers/checkCommands';
import { UnlinkTranscriptModal } from '../components/modals/UnlinkTranscriptModal';
import { API_ROUTES } from '../constants/apiRoutes';
import { Command } from '../components/CommandButtons';
import { PERMISSION_TYPES } from '../helpers/hasPermission';

interface Props {
  uuid: string;
}

export function TranscriptDetailPage(props: Props) {
  const fetchDataParams = React.useMemo(() => ({ url: `/v0/transcripts/${props.uuid}` }), [props.uuid]);

  const transcriptStore = useBRStore<ITranscript>({ fetchDataParams });
  const transcript = transcriptStore.data;

  React.useEffect(
    () => {
      transcriptStore.fetch();
    },
    []);

  const courses = useBRTableStore<ICourseTaken>({
    fetchDataParams: React.useMemo(() => ({ url: `/v0/transcripts/${props.uuid}/courses` }), [props.uuid]),
  });

  const events = useBRTableStore<ICommand>({
    fetchDataParams: React.useMemo(() => ({ url: API_ROUTES.commands, queryParams: { resource: props.uuid } }), [props.uuid]),
  });

  const menuTabs = [
    {
      name: 'Courses',
      key: 'people',
      component: (
        <BRTable
          tableId={TABLE_IDS.TRANSCRIPT_COURSES_TABLE}
          linkPath={ROUTES.course}
          tableData={courses}
          routeMap={{
            uuid: courseTaken => courseTaken.course,
          }}
        />
      ),
    },
    {
      name: 'Audit Log',
      key: 'events',
      permissions: PERMISSION_TYPES.audit,
      component: (
        <BRTable
          tableId={TABLE_IDS.TRANSCRIPT_AUDIT_LOG}
          linkPath={ROUTES.command}
          tableData={events}
        />
      ),
    },
  ];

  const allTranscriptCommands: Command[] = [
    {
      title: 'Link Transcript',
      icon: <LinkOutlined />,
      type: 'linkTranscript',
      modal: (
        <LinkTranscriptModal
          transcript={transcript}
          onSuccess={transcriptStore.fetch}
        />
      ),
    },
    {
      title: 'Unlink Transcript',
      icon: <StopOutlined />,
      type: 'unlinkTranscript',
      enabled: !!transcript.person?.uuid && transcript.provider?.name !== 'Manual',
      modal: (
        <UnlinkTranscriptModal
          transcript={transcript}
          onSuccess={transcriptStore.fetch}
        />
      ),
    },
  ];

  const commands = checkCommands(allTranscriptCommands, transcriptStore);

  const name = `${transcript.firstName} ${transcript.lastName}`;

  const descriptionItems = {
    Name: `${transcript.firstName} ${transcript.lastName}`,
    Email: (transcript.keys && transcript.keys.email) ? transcript.keys.email.join(', ') : 'N/A',
    'Provider ID': transcript.providerId || 'N/A',
  };

  return (
    <BRLayout
      loading={transcriptStore.loading}
      title={`${transcript.provider ? transcript.provider.name : 'Unknown'}: ${name}`}
      commands={commands}
      descriptionItems={descriptionItems}
    >
      <LoadingAnimation loading={transcriptStore.loading} />
      <TabMenu tabs={menuTabs} />
    </BRLayout>
  );
}
