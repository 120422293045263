import * as React from 'react';
import { BRLayout } from '../components/BRLayout';
import { Command } from '../components/CommandButtons';
import { ITemplate } from '../types/ITemplate';
import { useBRStore } from '../hooks/useBRStore';
import { TabMenu } from '../components/TabMenu';
import { sendCommand } from '../services/commands.services';
import { COMMANDS, TABLE_IDS } from '../constants';
import { TemplateVersionDetailPage } from './TemplateVersionDetailPage';
import { CopyOutlined, EditOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { VersionTimeline, TimelineVersion } from '../components/VersionsTimeline';
import { ITemplateVersion } from '../types/ITemplateVersion';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { SessionContext } from '../components/App';
import { message } from 'antd';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { ICommand } from '../types';
import BRTable from '../components/BRTable';
import { ROUTES } from '../routes';
import { PERMISSION_TYPES } from '../helpers/hasPermission';

const UNSELECTED = -1;
const DRAFT_INDEX = 0;

const EDITOR_TAB = 'editor';
const INDENT_CLASS = 'ql-indent-'

interface IProps {
  uuid: string;
}

function updateListElements(dom: Document, selector: string) {
  const elements = dom.querySelectorAll(selector)
  elements.forEach(element => element.setAttribute('style', 'list-style-position: inside'));
}

export function TemplateDetailPage(props: IProps) {
  const { session } = React.useContext(SessionContext);
  const tabId = session.router.current.params.tab;

  const fetchDataParams = React.useMemo(() => ({ url: `/v0/templates/${props.uuid}` }), [props.uuid]);
  const templateStore = useBRStore<ITemplate>({ fetchDataParams });
  const template = templateStore.data;

  const [dirty, setDirty] = React.useState(false);
  const [selected, setSelected] = React.useState<number>(DRAFT_INDEX);
  const [timeline, setTimeline] = React.useState<TimelineVersion[]>([]);
  const [editorSelected, setEditorSelected] = React.useState(tabId === undefined || tabId === EDITOR_TAB);

  const renderFetchParams = React.useMemo(() => ({
    url: '/v0/template/render',
    queryParams: { type: template.type, resource: template.resource },
  }),                                     [templateStore]);
  const rendered = useBRStore<{ text: string }>({ fetchDataParams: renderFetchParams });

  const events = useBRTableStore<ICommand>({
    fetchDataParams: React.useMemo(() => ({ url: '/v0/commands', queryParams: { resource: props.uuid } }), [props.uuid]),
  });

  React.useEffect(
    () => {
      const tab = session.router.current.params.tab;
      setEditorSelected(tabId === undefined || tab === EDITOR_TAB);
    },
    [props]);

  React.useEffect(
    () => {
      templateStore.fetch();
    },
    [templateStore.fetch]);

  React.useEffect(
    () => {
      if (templateStore.loaded) {
        setDirty(false);
        let newTimeline: TimelineVersion[];
        newTimeline = template.versions.map((version) => {
          return {
            label: `Version ${version.versionNumber}`,
            date: version.createdOn,
          };
        });
        newTimeline.unshift({ label: 'Draft' });
        setTimeline(newTimeline);

        if (selected === UNSELECTED) {
          setSelected(1);
        } else {
          setSelected(DRAFT_INDEX);
        }

        rendered.fetch();
      }
    },
    [templateStore.loaded]);

  function onSelectionChanged(index: number) {
    setSelected(index);
  }

  let selectedVersion: ITemplateVersion|undefined = undefined;
  if (template && templateStore.loaded && selected >= 0) {
    if (selected === DRAFT_INDEX) {
      selectedVersion = template.draft;
    } else {
      selectedVersion = template.versions[selected - 1];
    }
  }

  function getFormData() {
    let text = selectedVersion?.text;
    if (text) {
      const dom = new DOMParser().parseFromString(text, 'text/html');
      updateListElements(dom, 'ul');
      updateListElements(dom, 'ol');
      const paragraphs = dom.querySelectorAll('p')
      paragraphs.forEach(element => element.setAttribute('style', 'margin-bottom: 0'));

      const indented = dom.querySelectorAll(`[class*="${INDENT_CLASS}"]`);
      indented.forEach((element) => {
        const clazz = element.getAttribute('class');
        if (clazz) {
          let position = clazz.search(INDENT_CLASS);
          if (position >= 0) {
            position += INDENT_CLASS.length;
            const indent = parseInt(clazz.substr(position, position + 1), 10);
            element.setAttribute('style', `padding-left: ${3 * indent}em`);
          }
        }
      });
      text = dom.body.innerHTML;
    }

    return {
      text,
      subject: selectedVersion?.subject,
      uuid: template.uuid,
      type: template.type,
      resource: template.resource,
    };
  }

  async function selectDraft() {
    setSelected(DRAFT_INDEX);
  }

  async function saveDraft() {
    await sendCommand({
      formData: getFormData(),
      command: COMMANDS.UPDATE_TEMPLATE_DRAFT,
    });
    message.success('Draft saved.');
    await templateStore.fetch();
  }

  async function publish() {
    await sendCommand({
      formData: getFormData(),
      command: COMMANDS.PUBLISH_TEMPLATE,
    });
    message.success('New version published.');
    // clear out the selected version, so it will reselect the new first version
    setSelected(UNSELECTED);
    await templateStore.fetch();
  }

  function restoreVersion() {
    if (selectedVersion) {
      template.draft.subject = selectedVersion.subject;
      template.draft.text = selectedVersion.text;
      setDirty(true);
    }
    setSelected(DRAFT_INDEX);
  }

  const commands: Command[] = [];
  commands.push({
    title: 'Restore',
    icon: <CopyOutlined />,
    onClick: restoreVersion,
    enabled: editorSelected && selected > DRAFT_INDEX,
  });

  commands.push({
    title: 'Edit Draft',
    icon: <EditOutlined />,
    onClick: selectDraft,
    enabled: editorSelected && selected > DRAFT_INDEX,
  });

  commands.push({
    title: 'Save Draft',
    icon: <SaveOutlined />,
    onClick: saveDraft,
    enabled: editorSelected && selected === DRAFT_INDEX,
  });

  commands.push({
    title: 'Publish',
    icon: <UploadOutlined />,
    onClick: publish,
    enabled: editorSelected && selected === DRAFT_INDEX,
  });

  const resourceLabel = template.resourceLabel ? template.resourceLabel : template.resource ? template.resource : 'Default';
  const title = `${template.typeLabel} : ${resourceLabel} Template`;

  function onSubjectChange(subject: string) {
    if (selectedVersion && selected === DRAFT_INDEX) {
      if (!dirty) {
        setDirty(selectedVersion.subject !== subject);
      }
      selectedVersion.subject = subject;
    }
  }

  function onTextChange(text: string) {
    if (selectedVersion && selected === DRAFT_INDEX) {
      if (!dirty) {
        setDirty(selectedVersion.text !== text);
      }
      selectedVersion.text = text;
    }
  }

  const menuTabs = [];
  menuTabs.push({
    name: 'Editor',
    key: EDITOR_TAB,
    component: (
      <div>
        <div style={{ width: '85%', height: '100%', minHeight: 400, float: 'left', margin: 'auto', paddingLeft: 15 }}>
          {templateStore.loaded && selectedVersion ?
            (
              <TemplateVersionDetailPage
                subject={selectedVersion && selectedVersion.subject ? selectedVersion.subject : ''}
                text={selectedVersion ? selectedVersion.text : ''}
                readonly={selected !== DRAFT_INDEX}
                onSubjectChange={onSubjectChange}
                onTextChange={onTextChange}
              />
            )
            :
            (<LoadingAnimation loading={true} />)
          }
        </div>
        <div style={{ height: '100%', width: 140, float: 'left', paddingLeft: 20 }}>
          <VersionTimeline versions={timeline} selected={selected} onSelection={onSelectionChanged} dirty={dirty} />
        </div>
      </div>
    ),
  });

  menuTabs.push({
    name: 'Example',
    key: 'example',
    component: (
      <div dangerouslySetInnerHTML={{ __html: rendered.data?.text }} />
    ),
  });

  menuTabs.push({
    name: 'Audit Log',
    key: 'events',
    permissions: PERMISSION_TYPES.audit,
    component: (
      <BRTable
        tableData={events}
        tableId={TABLE_IDS.TEMPLATE_AUDIT_LOG}
        linkPath={ROUTES.command}
      />
    ),
  });

  return (
    <BRLayout
      title={title}
      loading={templateStore.loading}
      commands={commands}
    >
      <TabMenu tabs={menuTabs} selected={tabId}/>
    </BRLayout>
  );
}
