import React, { useContext } from 'react';
import { FieldProps, Field } from '../Field';
import { DatePicker } from 'antd';
import { StepContext } from '../Step';
import { DocumentEditorContext } from '../../../types/DocumentEditor';
import moment from 'moment';

interface DateFieldProps extends FieldProps {
  type?: string;
}

export const DateField = (props: DateFieldProps) => {
  const { documentEditor } = useContext(DocumentEditorContext);
  const { value, setValue } = useContext(StepContext);

  if (!documentEditor.document) {
    return null;
  }

  const onChange = (date: moment.Moment | null) => {
    if (!documentEditor.document) {
      return;
    }
    date && setValue(date);
  };

  return (
    <Field {...props}>
        <DatePicker
          disabled={!documentEditor.document.editable || !!props.readOnly}
          value={value ? moment(value) : undefined}
          onChange={onChange}
          name={props.name}
        />
    </Field>
  );
};
