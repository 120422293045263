import React, { useContext } from 'react';
import { FieldProps, Field } from '../Field';
import { DatePicker } from 'antd';
import { RangeValue, EventValue } from 'rc-picker/lib/interface';
import { StepContext } from '../Step';
import { DocumentEditorContext } from '../../../types/DocumentEditor';
import moment from 'moment';
import { CLIENT_CONST } from '../../../constants';

const { RangePicker } = DatePicker;

interface DateRangeFieldProps extends FieldProps {
  parts: any[];
  type?: string;
}

export const DateRangeField = (props: DateRangeFieldProps) => {
  const { documentEditor } = useContext(DocumentEditorContext);
  const { value, setValue } = useContext(StepContext);

  if (!documentEditor.document) {
    return null;
  }

  const isDateDisabled = (date: EventValue<moment.Moment>): boolean => {
    return value[0] != null && date != null && CLIENT_CONST.MAX_TENURE_LENGTH_MONTHS != null &&
        date.clone().subtract(CLIENT_CONST.MAX_TENURE_LENGTH_MONTHS, 'months').isAfter(moment(value[0]));
  };

  const onCalendarChange = (dates: RangeValue<moment.Moment>) => {
    if (!documentEditor.document) {
      return;
    }
    const startDate = dates ? dates[0] : null;
    // The end date will be set relative to the start date if a defaultRange is specified
    const endDate = startDate && (!value[0] || startDate.valueOf() !== value[0].valueOf()) && CLIENT_CONST.DEFAULT_TENURE_LENGTH_MONTHS != null ?
        startDate.clone().add(CLIENT_CONST.DEFAULT_TENURE_LENGTH_MONTHS, 'month') : (dates ? dates[1] : null);
    setValue([startDate, endDate]);
  };

  return (
    <Field {...props}>
        <RangePicker
          allowEmpty={[true, true]}
          disabled={[value[0] && (!documentEditor.document.editable || (!!props.readOnly && props.parts[0].editable === false)), value[1] && (!documentEditor.document.editable || (!!props.readOnly && props.parts[1].editable === false))]}
          disabledDate={isDateDisabled}
          value={[value[0] ? moment(value[0]) : null, value[1] ? moment(value[1]) : null]}
          onCalendarChange={onCalendarChange}
          name={props.name}
        />
    </Field>
  );
};
