import React, { useContext, createContext, useReducer, useEffect, useState, useCallback } from 'react';
import { WizardContext, WizardStep, WizardField, DocumentError } from './Wizard';
import { Button, message, Modal } from 'antd';
import { FORM_TYPE_NAMES } from '../../helpers/FORM_TYPES';
import { DocumentEditorContext } from '../../types/DocumentEditor';
import { postWizardDocument } from '../../services/wizardDocuments.services';
import { SessionContext } from '../App';
import { FullscreenExitOutlined, LoadingOutlined, CloudUploadOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { fieldHelper } from '../../helpers/fieldHelpers';
import { DocumentSpec } from '../../types/DocumentSpec';
import { useBRFetch } from '../../hooks/useBRFetch';

interface StepProps {
  name: string;
  error?: string | DocumentError;
  intro?: string;
  fields: WizardField[];
  routeName: any;
  routeProps: any;
  getDocument: (arg0: any, arg1: any) => DocumentSpec;
}

export interface FieldState {
  [ index: string ]: any;
}

const defaultContext = {
  value: undefined as any,
  setValue: (value: any) => {},
};

export const StepContext = createContext(defaultContext);

export const Step = (props: StepProps) => {
  const { session } = React.useContext(SessionContext);
  const { name, fields, intro, error } = props;
  const { isNew, dirty, setDirty, page, steps, setValid } = useContext(WizardContext);
  const { documentEditor, documentDispatch } = useContext(DocumentEditorContext);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({} as FieldState);
  const [makeRequest] = useBRFetch();

  const valuesReducer = (state: FieldState, update: FieldState) => {
    return {
      ...state,
      ...update,
    };
  };

  const getValues = useCallback(() => {
    const out: FieldState = {};
    if (documentEditor.document) {
      for (const field of fields) {
        out[field.name] = documentEditor.document.field(field.name);
      }
    }
    return out;
  },                            [documentEditor.document, fields]);

  const [values, setValues] = useReducer(valuesReducer, getValues());

  const getShow = useCallback(() => {
    const out: FieldState = {};
    if (documentEditor.document) {
      for (const field of fields) {
        if (typeof field.show === 'boolean') {
          out[field.name] = field.show;
        } else if (typeof field.show === 'string') {
          out[field.name] = fieldHelper(field.show, documentEditor.document);
        } else {
          out[field.name] = 'default';
        }
      }
    }
    return out;
  },                          [documentEditor.document, fields]);

  const getRequired = useCallback(() => {
    const out: FieldState = {};
    const show = getShow();
    if (documentEditor.document) {
      for (const field of fields) {
        if (!show[field.name]) {
          out[field.name] = false;
        } else if (typeof field.required === 'boolean') {
          out[field.name] = field.required;
        } else if (typeof field.required === 'string') {
          out[field.name] = fieldHelper(field.required, documentEditor.document);
        }
      }
    }
    return out;
  },                              [documentEditor.document, fields, getShow]);

  const getErrors = useCallback(() => {
    const out: FieldState = {};
    const required = getRequired();
    if (documentEditor.document) {
      for (const field of fields) {
        if (field.validate) {
          out[field.name] = !field.validate.call(undefined, {
            value: values[field.name],
            document: documentEditor.document,
          });
        }
        if (!out[field.name] && required[field.name]) {
          if (Array.isArray(values[field.name])) {
            out[field.name] = values[field.name].length === 0;
          } else {
            out[field.name] = !values[field.name] && values[field.name] !== false;
          }
        }
      }
    }
    return out;
  },                            [documentEditor.document, fields, values, getRequired]);

  const hasTrue = (arg: { [ index: string ]: any }) => Object.values(arg).includes(true);

  const [initDone, setInitDone] = useState(false);

  useEffect(
    () => {
      if (documentEditor.document && !initDone) {
        setValues(getValues());
        setValid(!hasTrue(getErrors()));
        setInitDone(true);
      }
    },
    [documentEditor.document, getValues, getErrors, setValid, initDone],
  );

  if (!documentEditor.document) {
    return null;
  }

  const thisStep = steps.find(s => s.name === name);
  let thisIndex = -1;
  let prevStep: WizardStep | undefined = undefined;
  let nextStep: WizardStep | undefined = undefined;

  if (thisStep) {
    thisIndex = steps.indexOf(thisStep);
    prevStep = steps[thisIndex - 1];
    nextStep = steps[thisIndex + 1];
  } else {
    return null;
  }

  const goToStep = (step: WizardStep | undefined) => {
    const errors = getErrors();
    setErrors(errors);
    if (documentEditor.document && step && !Object.values(errors).includes(true)) {
      if (documentEditor.document.editable) {
        setSaving(true);
        postWizardDocument(documentEditor.document, values).then((postData) => {
          if (!documentEditor.document || !step) return;
          setValid(!hasTrue(errors));
          setSaving(false);
          makeRequest(`${documentEditor.document.apiGetRoot}${postData?.uuid || documentEditor.document.id}`).then(async (data) => {
            data = props.getDocument(data.documentInterface, data.wizard);
            if (setDirty !== undefined) {
              setDirty(false);
            }
            documentDispatch({
              dispatch: documentDispatch,
              type: 'documentData',
              document: data,
            });
          });
          if (documentEditor.document?.id === 'new') {
            message.success(`Created ${documentEditor.document?.displayName} successfully.`);
          } else {
            message.success(`Updated ${documentEditor.document?.displayName} successfully.`);
          }
          session.router.go({ name: props.routeName }, { ...props.routeProps, step: step.name });
        }).catch((reason) => {
          console.log(reason);
          if (!documentEditor.document) return;
          setSaving(false);
          Modal.error({
            title: 'Connection Error',
            content: 'There was an error saving your form to the server. Please try again later.',
          });
        });
      } else {
        session.router.go({ name: props.routeName }, { ...props.routeProps, step: step.name });
      }
    } else {
      Modal.error({
        title: 'Error',
        content: getError() || 'Your form contains errors and could not be saved. Please resolve the errors and retry.',
      });
    }
  };

  const goBack = () => {
    goToStep(prevStep);
  };

  const goNext = () => {
    goToStep(nextStep);
  };

  const goSubmit = () => {
    goToStep(thisStep);
  };

  const goExit = () => {
    // props.dispatch(push(`/${props.urlRoot}`));
  };

  const makeSetValue = (name: string) =>
    (value: any) => {
      if (documentEditor.document) {
        documentEditor.document.field(name, value);
      }
      if (setDirty !== undefined) {
        setDirty(true);
      }
      setValues({ [name]: value });
    };

  const getError = () => {
    if (typeof error === 'function' && documentEditor.document) {
      return error(documentEditor.document);
    }
    return error;
  };

  const show = getShow();
  const required = getRequired();

  return (
    <div style={{ margin: '2em 0', display: name === page ? 'block' : 'none' }}>
      {intro && <p>{intro}</p>}
      <form>
        {fields.map((field) => {
          if (!documentEditor.document) {
            return null;
          }
          const Field = field.field;
          return show[field.name] && (
            <StepContext.Provider key={field.name} value={{ value: values[field.name], setValue: makeSetValue(field.name) }}>
              <Field {...field} required={required[field.name]} error={errors[field.name]} readOnly={field.readOnly || (field.editable === false && values[field.name] !== undefined && documentEditor.document?.id !== 'new') || false}/>
            </StepContext.Provider>
          );
        })}
      </form>
      <div className="form-field">
        <div className="form-label" />
        <div className="form-value align-right">
          {nextStep && <Button icon={<FullscreenExitOutlined />} onClick={goExit}>Exit</Button>}
          {prevStep && <Button icon={<CaretLeftOutlined />} onClick={goBack}>Back</Button>}
          {nextStep && <Button disabled={saving || dirty === false} icon={saving ? <LoadingOutlined /> : <CaretRightOutlined />} type="primary" onClick={goNext}>{documentEditor.document.editable && 'Save & '}Continue</Button>}
          {!nextStep && <Button disabled={!documentEditor.document.editable || dirty === false} icon={<CloudUploadOutlined />} type="primary" onClick={goSubmit}>{isNew ? 'Create' : 'Save'} {FORM_TYPE_NAMES[documentEditor.document.type]}</Button>}
        </div>
      </div>
    </div>
  );
};
