import * as React from 'react';
import { Table } from 'antd';
import { SessionContext } from './App';
import { ROUTES } from '../routes';
import { ICourse } from '../types';

interface RCTProps {
  courseMap: { [ index: string ]: ICourse };
  xml: string;
}

export function RequirementCoursesTable(props: RCTProps) {
  const { session } = React.useContext(SessionContext);

  const onRowClicked = (rowValue: any) => {
    session.router.go(
      { name: ROUTES.course },
      rowValue,
    );
  };

  const columns = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width: '40%',
    },
    {
      title: 'Provider',
      dataIndex: ['provider', 'name'],
      key: 'provider',
      hidden: true,
    },
    {
      title: 'Valid For',
      dataIndex: 'validFor',
      key: 'validFor',
      hidden: true,
      render: (text: string) => {
        return text ? `${text} year${text !== '1' ? 's' : ''}` : '';
      },
    },
    {
      title: 'Taken After',
      dataIndex: 'takenAfter',
      key: 'takenAfter',
      hidden: true,
    },
    {
      title: 'Retirement Date',
      dataIndex: 'retiresOn',
      key: 'retiresOn',
      hidden: true,
    },
  ];

  let key = 0;

  function nodeTitle(node: Element) {
    switch (node.tagName) {
      case 'CourseGroup':
        return <em>Complete <strong>{node.getAttribute('match') === 'all' ? 'all courses' : 'at least one'}</strong> from this group:</em>;
      case 'Course':
        const uuid = node.getAttribute('uuid');
        if (uuid) {
          const name = props.courseMap[uuid]?.name;
          if (name) {
            return name;
          }
        }
        return 'Unknown Course';
      case 'RenewalGroup':
        const maxTimes = node.getAttribute('maxTimes');
        if (maxTimes) {
          return <em>Renew up to {maxTimes} time{maxTimes === '1' ? '' : 's'} with any of this group:</em>;
        }
        return <em>Renew with any of this group:</em>;
    }
    return 'Unknown';
  }

  function parseCourseNode(node: HTMLElement) {
    const row: any = {};
    const uuid = node.getAttribute('uuid');
    columns.forEach((column) => {

      row[column.key] = node.getAttribute(column.key);

      if (!row[column.key] && uuid) {
        row[column.key] = props.courseMap[uuid]?.[column.key];
      }

      if (row[column.key]) {
        column.hidden = false;
      }
    });

    if (row.validFor) {
      for (const child of Array.from(node.children)) {
        child.setAttribute('validFor', row.validFor);
      }
    }

    return {
      ...row,
      title: nodeTitle(node),
      key: `${key += 1}`,
      children: node.children.length > 0 ? Array.prototype.map.call(node.children, parseCourseNode) : undefined,
    };
  }

  const domParser = new DOMParser();
  const xml = domParser.parseFromString(props.xml, 'text/xml');
  const data = parseCourseNode(xml.documentElement);

  const activeColumns = columns.filter(column => !column.hidden);

  const onRow = (record: any) => ({ onClick: () => { if (record.uuid) { onRowClicked(record); } } });

  return (
    <Table
      size="small"
      columns={activeColumns}
      dataSource={[data]}
      onRow={onRow}
      className={'clickable-table'}
      expandable={{
        expandRowByClick: true,
        defaultExpandAllRows: true,
        indentSize: 25,
      }}
    />
  );
}
