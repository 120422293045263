import React, { useContext } from 'react';
import { IQueryStore } from '../types/storeTypes/IQueryStore';
import { IResourceSchema, IPagination } from '../types';
import { requestCSV } from '../helpers/requestCSV';
import { SessionContext } from '../components/App';
import { useBRFetch, IRequestParams } from './useBRFetch';
import { BRStoreBase } from './useBRStore';

export interface BRTableStore<T> extends BRStoreBase {
  records: T[];
  schema: IResourceSchema;
  pagination: IPagination;
  [index: string]: any;
  fetch: (newQuery?: IQueryStore) => Promise<any>;
  makeCSVRequest: () => void;
}

type FetchDataParams = {
  url: string,
  resourceId?: string | number,
  queryParams?: IRequestParams,
};

type Props = {
  initialData?: any,
  fetchDataParams: FetchDataParams,
};

// useTableData is meant for the management of data for the BRTable component
// It differs from other hooks because it only pays attention to particular queries
// and is able to update the query when necessary

// use this if you need to manage query information in the tables

export const useBRTableStore = <T>(props: Props): BRTableStore<T> => {
  const { session } = useContext(SessionContext);
  const [data, setData] = React.useState(props.initialData);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [query, setQuery] = React.useState<IQueryStore>(session.router.query);
  const [makeRequest] = useBRFetch();

  const fetch = React.useCallback(
    dataFetch,
    [props.fetchDataParams, query],
  );

  const { url, resourceId } = props.fetchDataParams;

  async function dataFetch(newQuery?: IQueryStore) {
    setLoading(true);
    setLoaded(false);
    let fetchParams: IQueryStore = {};
    const fetchUrl = resourceId ? `${url}/${resourceId}` : url;
    if (newQuery) {
      setQuery(newQuery);
    }
    // this redefines fetchParams to the params that the backend cares about
    const { page, pageSize, columns, filters, sort } = newQuery ?? query;
    fetchParams = { page, pageSize, columns, filters, sort };
    if (props.fetchDataParams.queryParams) {
      fetchParams = {
        ...fetchParams,
        ...props.fetchDataParams.queryParams,
        filters: {
          ...(fetchParams.filters || {}),
          ...(props.fetchDataParams.queryParams.filters || {}),
        },
      };
    }
    const response = await makeRequest(
      fetchUrl,
      fetchParams,
    );
    setLoading(false);
    console.log(`returned BRTableData for ${fetchUrl}:`, response);
    setData(response);
    setLoaded(true);
    return response;
  }

  async function makeCSVRequest() {
    const fetchUrl = resourceId ? `${url}/${resourceId}` : url;
    requestCSV(fetchUrl, 'csv', session.router.query, data.schema);
  }

  return { ...data, loading, loaded, fetch, makeCSVRequest };
};
