import * as React from 'react';
import { Table, Button, message, Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { DATE_OPTIONS } from '../../constants';
import { Link } from '../../hooks/useRoutes';
import { SessionContext } from '../App';
import { ROUTES } from '../../routes';
import { ModalContext } from '../BRModal';
import { RemoveExemptionFromPersonModal } from '../modals/RemoveExemptionFromPersonModal';
import { AddExemptionToPersonModal } from '../modals/AddExemptionToPersonModal';
import { AddCourseToPersonModal } from '../modals/AddCourseToPersonModal';
import { BRStore } from '../../hooks/useBRStore';
import { EvalObject, IPerson, IPersonRequirement, RESOURCE_STATUS } from '../../types';
import { BRListData } from '../../hooks/useBRListData';

interface Props {
  person: BRStore<IPerson>;
  requirements: BRListData<IPersonRequirement>;
  pr: BRStore<IPersonRequirement>;
  requirement?: string;
  admin: boolean;
}

interface DataSource {
  menu?: React.ReactNode;
  key: string;
  name?: JSX.Element | string;
  expirationDate?: string;
  completedDate?: string;
  tag?: string;
  children?: DataSource[];
}

export function EvaluationTable(props: Props) {

  const { session } = React.useContext(SessionContext);
  const [modal, setModal] = React.useState<React.ReactNode>();

  const closeModal = () => { setModal(undefined); };

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      width: '100%',
    },
    /*
    {
      dataIndex: 'tag',
      key: 'tag',
      title: 'Status',
      render: (tag: any) => {
        if (!tag || tag === RESOURCE_STATUS.incomplete) {
          // return null;
        }
        const color = tag === RESOURCE_STATUS.complete
          ? 'green'
          : tag === 'Exempt'
          ? 'geekblue'
          : tag === RESOURCE_STATUS.expiring
          ? 'gold'
          : tag === RESOURCE_STATUS.expired || tag === RESOURCE_STATUS.actionRequired
          ? 'red'
          : '#aaa';
        return <Tag color={color}>{tag}</Tag>;
      },
    },
    {
      dataIndex: 'completedDate',
      key: 'completedDate',
      title: 'Completed Date',
    },
    {
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      title: 'Expiration Date',
    }, */
    {
      dataIndex: 'menu',
      key: 'menu',
    },
  ];

  let key = 0;

  function parseLink(link: string) {
    if (!link) {
      return undefined;
    }
    return link.startsWith('http') ? link : `http://${link}`;
  }

  const displayRequirementCourses = (node: EvalObject, andOr?: string, inRenewal = false): DataSource[] => {

    const childRenewal = node.evaluation === RESOURCE_STATUS.expiring || node.evaluation === RESOURCE_STATUS.expired;
    const childInRenewal = inRenewal || node.type === 'RenewalGroup';

    const children = node.children.reduce(
      (accum: DataSource[], childNode) => {
        if (childNode.evaluation === RESOURCE_STATUS.complete) {
          return accum;
        }

        if (childRenewal && !childInRenewal && childNode.type === 'Course') {
          return accum;
        }

        if (!childRenewal && childNode.type === 'RenewalGroup') {
          return accum;
        }

        if (childNode.props.retiresOn) {
          const retiresOnDate = new Date(childNode.props.retiresOn);
          if (childNode.evaluation === RESOURCE_STATUS.incomplete && retiresOnDate < new Date()) {
            return accum;
          }
        }

        let childAndOrString: string | undefined = undefined;

        if (accum.length !== 0) {
          if (node.type === 'needAll') {
            childAndOrString = 'And';
          } else {
            childAndOrString = 'Or';
          }

        }

        return accum.concat(displayRequirementCourses(childNode, childAndOrString, childInRenewal));
      },
      [],
    );

    let menu: React.ReactNode;
    const menuItems: React.ReactNode[] = [];

    if (node.type === 'Course') {
      const openAddCourseModal = () => {
        const onSuccess = () => {
          message.success('Add course completed successfully.');
          props.pr.fetch();
          props.person.fetch();
          props.requirements.fetch();
          closeModal();
        };
        const addModal = (
          <AddCourseToPersonModal
            person={props.person.data}
            defaultCourse={node.props.uuid}
            onSuccess={onSuccess}
          />
        );
        setModal(addModal);
      };
      if (props.admin) {
        menuItems.push((
          <Button
            key="addc"
            size="small"
            icon={<PlusOutlined/>}
            onClick={openAddCourseModal}
          >
            Add Completion
          </Button>
        ));
      }
      const onSuccess = () => {
        message.success('Add exemption completed successfully.');
        props.pr.fetch();
        props.person.fetch();
        props.requirements.fetch();
        closeModal();
      };
      const openAddExemptionModal = () => {
        const addModal = (
          <AddExemptionToPersonModal
            person={props.person.data}
            requirement={props.requirement}
            course={node.props.uuid}
            onSuccess={onSuccess}
          />
        );
        setModal(addModal);
      };
      if (props.admin) {
        menuItems.push((
          <Button
            key="adde"
            size="small"
            icon={<PlusOutlined/>}
            onClick={openAddExemptionModal}
          >
            Add Exemption
          </Button>
        ));
      }
    }

    if (menuItems.length > 0) {
      menu = <Space>{menuItems}</Space>;
    }

    if ((node.type === 'needAll' || node.type === 'needOne') && children.length === 0) {
      return [];
    }

    return [{
      menu,
      key: `${key += 1}`,
      name: nodeToTitle(node, andOr),
      expirationDate: node.expiryCourse?.expirationDate && new Date(node.expiryCourse.expirationDate).toLocaleDateString('en-US', DATE_OPTIONS),
      completedDate: node.expiryCourse?.completedDate && new Date(node.expiryCourse.completedDate).toLocaleDateString('en-US', DATE_OPTIONS),
      children: children.length ? children : undefined,
      tag: node.exempt ? 'Exempt' : node.evaluation,
    }];
  };

  const nodeToTitle = (node: EvalObject, andOr?: string) => {
    let nodeString: JSX.Element | undefined = undefined;

    if (node.type === 'needAll') {
      nodeString = <strong style={{ fontSize: '1.1em' }}>{andOr ? `${andOr},` : 'Need'} all items from this group:</strong>;
    }
    if (node.type === 'needOne') {
      nodeString = <strong style={{ fontSize: '1.1em' }}>{andOr ? `${andOr},` : 'Need'} any item from this group:</strong>;
    }
    if (node.type === 'Course') {
      if (props.admin) {
        nodeString = <Link href={session.router.getUrl({ name: ROUTES.course }, { uuid: node.props.uuid })}>{node.props.name}</Link>;
      } else {
        nodeString = <p>{node.props.name}</p>;
      }
    }
    if (node.type === 'RenewalGroup') {
      nodeString = <strong style={{ fontSize: '1.1em' }}>{andOr ? `${andOr}, renew` : 'Renew'} with any item from this group:</strong>;
    }
    return nodeString;
  };

  const requiredCoursesData = React.useMemo(
    () => {
      return props.pr.data.evaluation ? displayRequirementCourses(props.pr.data.evaluation) : [];
    },
    [props.pr.data.evaluation, props.person, props.requirements],
  );

  return props.pr.data.evaluation ? (
    <>
      <Table
        columns={columns}
        dataSource={requiredCoursesData}
        className={'clickable-table'}
        pagination={false}
        size="small"
        expandable={{
          indentSize: 30,
          defaultExpandAllRows: true,
        }}
      />
      {!!modal && (
        <ModalContext.Provider value={{ close: closeModal, onSuccess: closeModal, visible: !!modal }}>
          {modal}
        </ModalContext.Provider>
      )}
    </>
  ) : <></>;
}
