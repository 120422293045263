import React, { useState, ChangeEvent } from 'react';
import { Alert, Form, Input } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';

interface Props extends BRModalProps {
  approvalStatus: string;
}

export const AddPhysicalAttachmentToApprovalModal: React.FunctionComponent<Props> =
({ ...modalProps }) => {

  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [nameError, setNameError] = useState('');

  const onFinish = async () => {
    let error = false;

    if (!name) {
      setNameError('A name for this physical attachment is required');
      error = true;
    }

    if (error) {
      return;
    }

    const formData = {
      approvalStatus: modalProps.approvalStatus,
      physicalAttachments: [{
        name,
        location,
      }],
    };

    await sendCommand({
      formData,
      command: COMMANDS.ADD_PHYSICAL_ATTACHMENTS_TO_APPROVAL_STATUS,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'location':
        setLocation(value);
        break;
      default:
        break;
    }
  };

  const title = 'Add Physical Attachment';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Add Physical Attachment"
      formProps={{ onFinish }}
    >
        <Form.Item label="Name">
        {nameError && <Alert message={nameError} />}
          <Input
            onChange={handleChange}
            value={name}
            type="text"
            name="name"
            required={true}
            minLength={1}
          />
        </Form.Item>

        <Form.Item label="Location">
          <Input
            onChange={handleChange}
            value={location}
            type="text"
            name="location"
          />
        </Form.Item>
    </BRModal>
  );
};
