import * as React from 'react';
import { Table, Button, Tag, Select, Form, Menu, Dropdown } from 'antd';
import { TABLE_IDS, DATE_OPTIONS, DATE_TIME_OPTIONS } from '../../constants';
import { DATA_TYPES } from '../../types';
import { TableProps } from 'antd/lib/table';
import { isColumnVisible, getColumnRenderer } from './helpers';
import { DateDropdown, DateDropdownProps } from './FilterRenderers/DateDropdown';
import { IQueryStore } from '../../types/storeTypes/IQueryStore';
import { BRTableStore } from '../../hooks/useBRTableStore';
import { SessionContext } from '../App';
import { ROUTES } from '../../routes';
import ResizableHeader from './ResizableHeader';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import { SearchOutlined, LoadingOutlined, CloudDownloadOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

export interface BRTableProps extends TableProps<any> {
  linkPath?: ROUTES;
  tableId: TABLE_IDS;
  rowKey?: any;
  rowClickable?: (row: any) => boolean;
  tableData: BRTableStore<any>;
  // If the route parameters linked to from the table data don't match the
  // column names within the table, they can be mapped here
  routeMap?: { [routeParam: string]: (row: any) => string | number | undefined };
  keepQuery?: boolean;
  hideCsv?: boolean;
  rowClickEnabled?: (record: any) => boolean;
}

export function BRTable(props: BRTableProps) {
  const { session } = React.useContext(SessionContext);
  const { router } = session;
  const user = session.user || { uuid: 'unknown' };
  const userStateId = `${user.uuid}_NewTableState`;
  const storage = JSON.parse(localStorage.getItem(userStateId) || '{}');

  const queryStateReducer = (state: IQueryStore, action: IQueryStore) => {
    const newState: IQueryStore = {
      sort: action.sort ?? state.sort,
      filters: action.filters ?? state.filters,
      columns: action.columns ?? state.columns,
      pageSize: action.pageSize ?? state.pageSize,
    };

    localStorage.setItem(userStateId, JSON.stringify({
      ...storage,
      [props.tableId]: newState,
    }));

    newState.page = action.page ?? state.page;

    return newState;
  };

  const [queryState, queryStateDispatch] = React.useReducer(queryStateReducer, { ...storage[props.tableId], ...router.query, page: router.query.page || 1 });

  let searchInput: any;

  React.useEffect(
    () => {
      if (queryState) {
        props.tableData.fetch(queryState);
        const query = Object.assign({}, queryState);
        if (query.page === 1) {
          delete query.page;
        }
        if (query.pageSize === 20) {
          delete query.pageSize;
        }
        session.router.go(
          { name: session.router.current.name },
          session.router.current.params,
          { query, replace: true },
        );
      }
    },
    [queryState]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    // this function will be called whenever a filter/sort/pagination is changed
    // in Ant Table and set the table state
    const newFilters: any = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key]?.length) {
        newFilters[key] = filters[key];
      }
    });
    const newQuery = {
      filters: newFilters,
      page: pagination.current,
      pageSize: pagination.pageSize,
      sort: sorter.columnKey && { [sorter.columnKey]: sorter.order },
    };

    queryStateDispatch(newQuery);
  };

  const handleColumnChange = (newColumn: IQueryStore['columns']) => {
    const newColumns = {
      columns: {
        ...queryState.columns,
        ...newColumn,
      },
    };

    queryStateDispatch(newColumns);
  };

  const onRowClicked = (rowValue: any, rowIndex?: number) => {
    if (props.linkPath) {
      let routeParams: any = rowValue;
      // If the column names don't match the route parameters, remap them here
      if (props.routeMap) {
        routeParams = {};
        for (const routeParam in props.routeMap) {
          const routeValue = props.routeMap[routeParam](rowValue);
          if (routeValue != null) {
            routeParams[routeParam] = routeValue;
          } else {
            throw new Error(`Missing hyperlink information in row ${rowIndex} in BRTableData`);
          }
        }
      }

      session.router.go(
        { name: props.linkPath },
        routeParams,
        props.keepQuery ? { query: session.router.query } : undefined,
      );
    }
  };

  const getColumnTags = (columns: any[]) => {
    const tags = [];
    const menuItems = [];
    for (const c of columns) {
      if (isColumnVisible(c.value, queryState, schema)) {
        const handleClose = () => {
          const newColumn = {
            [c.value]: false,
          };
          handleColumnChange(newColumn);
        };
        tags.push((
          <Tag
            key={c.value}
            closable={true}
            onClose={handleClose}
          >
            {c.label}
          </Tag>
        ));
      } else {
        menuItems.push((
          <Menu.Item
            key={c.value}
          >
            {c.label}
          </Menu.Item>
        ));
      }

    }
    const handleAdd = ({ key }: any) => {
      const newColumn = {
        [key]: true,
      };
      handleColumnChange(newColumn);
    };
    const menu = (
      <Menu
        onClick={handleAdd}
      >
        {menuItems}
      </Menu>

    );
    if (menuItems.length > 0) {
      tags.push((
        <Dropdown
          key="drop"
          trigger={['click']}
          overlay={menu}
        >
          <Tag><PlusOutlined /> Add Column</Tag>
        </Dropdown>
      ));
    }
    return tags;
  };

  const getDateColumnProps = (column: any) => ({
    filterDropdown: (props: DateDropdownProps) => <DateDropdown {...props} />,
  });

  const getDateTimeColumnProps = (column: any) => ({
    filterDropdown: (props: DateDropdownProps) => <DateDropdown showTime={true} {...props} />,
  });

  const getStringColumnProps = (column: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => {
      const { schema } = props.tableData;
      const search = () => confirm && confirm();
      const onChange = (e: any) => setSelectedKeys && setSelectedKeys(e || []);
      const reset = () => clearFilters && clearFilters();
      const options = (
        schema[column.key].options ||
        (schema[column.key].type === DATA_TYPES.boolean ?
          ['Yes', 'No'] :
          [])
      ).map((option: string) => <Option value={option} key={option}>{option}</Option>);
      const mode = (schema[column.key].customOptions === false || schema[column.key].type === DATA_TYPES.boolean)
        ? 'multiple'
        : 'tags';
      return (
        <Form layout="inline" style={{ padding: '8px' }}>
          <Form.Item style={{ marginRight: '8px' }}>
            <Select
              mode={mode}
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${schema[column.key].title}`}
              value={selectedKeys || []}
              onChange={onChange}
              allowClear={true}
              style={{ width: '250px' }}
            >
            {options}
            </Select>
          </Form.Item>
          <Form.Item style={{ marginRight: '8px' }}>
            <Button
              type="primary"
              onClick={search}
              icon={<SearchOutlined />}
            />
          </Form.Item>
          <Form.Item style={{ marginRight: 0 }}>
            <Button onClick={reset} icon={<CloseOutlined />} />
          </Form.Item>
        </Form>
      );
    },
  });

  const getColumnSearchProps = (column: any) => {
    let filterProps;
    switch (column.type) {
      case (DATA_TYPES.dateTime):
        filterProps = getDateTimeColumnProps(column);
        break;
      case (DATA_TYPES.date):
        filterProps = getDateColumnProps(column);
        break;
      default: // string
        filterProps = getStringColumnProps(column);
        break;
    }
    return ({
      ...filterProps,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInput?.focus());
        }
      },
    });
  };

  const clearFilter = (key: string, index?: number) => {
    const newFilters = {
      ...queryState.filters,
    };

    if (index === undefined) {
      newFilters[key] = [];
    } else {
      newFilters[key] = newFilters[key].filter((f: string, i: number) => i !== index);
    }

    queryStateDispatch({ filters: newFilters });
  };

  const makeFilterTag = (key: string, text: string, onClose: (e: React.MouseEvent<HTMLElement>) => void) => {
    return (
      <Tag key={key} closable={true} onClose={onClose} visible={true}>
        {text}
      </Tag>
    );
  };

  const getFooter = (currentPageData: Object[]) => {
    if (props.hideCsv) {
      return <div>&nbsp;</div>;
    }

    const clickHandler = () => props.tableData.makeCSVRequest();
    const text = 'Download CSV';
    return (
      <Button
        size="small"
        type="primary"
        onClick={clickHandler}
        icon={<CloudDownloadOutlined />}
      >
        {text}
      </Button>
    );
  };

  const { schema } = props.tableData;
  const [columnWidths, setColumnWidths] = React.useState({});

  const handleResize = (index: string) => (e: any, { size }: any) => {

    const newWidths = {
      ...columnWidths,
      [index]: size.width,
    };

    setColumnWidths(newWidths);
  };

  const schemaColumns = React.useMemo(
    () => {
      return schema ? Object.entries(schema).map(([key, value]) => {
        const col = value as any;
        const newColumn = {
          ...col,
          key,
          dataIndex: col.dataIndex || key,
          width: columnWidths[key] ? columnWidths[key] : col.width,
          render: getColumnRenderer(col.type, col.colorMap),
          sorter: true,
          sortOrder: queryState.sort?.[key],
          filteredValue: queryState.filters?.[key],
          onHeaderCell: (column: any) => ({
            width: column.width,
            onResize: handleResize(key),
          }),
        };
        return {
          ...newColumn,
          ...getColumnSearchProps(newColumn),
        };
      }) : [];
    },
    [schema, queryState]);

  const columns = React.useMemo(
    () => {
      return schemaColumns.filter((col: any) => {
        return isColumnVisible(col.key, queryState, schema);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schema, queryState],
  );

  const optionalColumns = React.useMemo(
    () => {
      if (schema) {
        return Object.entries(schema)
                     .filter(([key, val]) => val.optional !== false)
                     .map(([key, val]) => ({
                       value: key,
                       label: val.title || key,
                     }));
      }
      return [];
    },
    [schema]);

  const rowClickEnabled = props.rowClickEnabled || function (record: any) {
    return props.linkPath && record.uuid && (!props.rowClickable || props.rowClickable(record));
  };

  const defaultTableOptions: TableProps<any> = {
    scroll: { x: true },
    bordered: false,
    onRow: (record: any, index?: number) =>
      ({ onClick: () => { if (rowClickEnabled(record)) { onRowClicked(record, index); } } }),
    rowKey: 'uuid',
    size: 'small',
    className: props.linkPath ? 'clickable-table' : undefined,
    dataSource: props.tableData.records,
  };

  const overrideTableOptions: TableProps<any> = {
    columns,
    pagination: {
      ...props.tableData.pagination,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50'],
      className: 'pagination-in-footer',
    },
    onChange: handleTableChange,
    loading: props.tableData.loading ?
      { indicator: <LoadingOutlined style={{ fontSize: '3em' }} /> } : undefined,
    footer: getFooter,
    components: { header: { cell: ResizableHeader } },
  };

  const filterTags = React.useMemo(
    () => {
      const out: JSX.Element[] = [];
      if (schema && queryState.filters) {
        for (const [key, val] of Object.entries(queryState.filters)) {
          if (val && (val as string[]).length && schema[key]) {
            if (schema[key].type === DATA_TYPES.date) {
              const text = val
                .map((datetime: any) => {
                  return new Date(datetime)
                    .toLocaleDateString('en-US', DATE_OPTIONS);
                })
                .join(' - ');
              const filterText = `${schema[key].title}: ${text}`;
              const onClose = () => clearFilter(key);
              out.push(makeFilterTag(key, filterText, onClose));
            } else if (schema[key].type === DATA_TYPES.dateTime) {
              const text = val
                .map((datetime: any) => {
                  return new Date(datetime)
                    .toLocaleDateString('en-US', DATE_TIME_OPTIONS);
                })
                .join(' - ');
              const filterText = `${schema[key].title}: ${text}`;
              const onClose = () => clearFilter(key);
              out.push(makeFilterTag(key, filterText, onClose));
            } else {
              val.forEach((text: string, i: number) => {
                const filterText = `${schema[key].title}: "${text}"`;
                const onClose = () => clearFilter(key, i);
                out.push(makeFilterTag(`${key}:${i}`, filterText, onClose));
              });
            }

          }
        }
      }
      return out;
    },
    [schema, queryState.filters]);

  return (
    <>
      {filterTags.length ? <p><strong>Filters: </strong> {filterTags} </p> : undefined}
      { schema && optionalColumns.length > 0 && (
        <p style={{ lineHeight: '2em' }}>
          <strong>Columns: </strong>
          {getColumnTags(optionalColumns)}
        </p>
      )}
      <Table
        {...defaultTableOptions}
        {...props}
        {...overrideTableOptions}
      />
    </>
  );
}

export default BRTable;
