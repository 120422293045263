import React, { ReactNode } from 'react';
import { Document } from '../../resources/Document';

export interface FieldProps {
  name: string;
  required?: boolean;
  error?: boolean;
  clear?: boolean;
  readOnly?: boolean;
  label: string;
  help?: string;
  children?: ReactNode | ReactNode[];
}

export function getFieldReadOnly(field: FieldProps, document: Document) {
  return field.readOnly;
}

export const Field = ({ required, error, label, help, children }: FieldProps) => {
  return (
    <div className="form-field">
      <div className={error ? 'form-label form-error' : 'form-label'}>
        <label>
          {required && <span className="form-required">* </span>}
          {label}
        </label>
        {help && <div className="form-help">{help}</div>}
      </div>
      <div className="form-value">
        {children}
      </div>
    </div>
  );
};
