import React, { useEffect, useState, MouseEvent } from 'react';
import { Collapse, List, Button, Drawer, Typography, Form, DatePicker, Upload, Space, Divider, Layout, Checkbox, Table, Empty, message } from 'antd';
import moment, { Moment } from 'moment';
import { SessionContext } from './App';
import { dateRenderer, statusIconRenderer, statusAvatarRenderer } from '../helpers/dataRenderers';
import { BRStore } from '../hooks/useBRStore';
import { IApprovalTemplate, IApprovalItem, ITenureApprovalStatuses, IApprovalStatus, RESOURCE_STATUS } from '../types';
import { ApprovalStatus } from '../resources/ApprovalStatus';
import { BRFormComments } from './BRForm';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import { COMMANDS } from '../constants';
import { sendCommand } from '../services/commands.services';
import { IAttachment } from '../types/IAttachment';
import { IPhysicalAttachment } from '../types/IPhysicalAttachment';
import { AddPhysicalAttachmentToApprovalModal } from './modals/AddPhysicalAttachmentToApprovalModal';
import { requestFile } from '../helpers/requestFile';
import { ModalContext } from './BRModal';

export const TenureApprovalsList: React.FunctionComponent<{
  approvalStore: BRStore<ITenureApprovalStatuses>,
  getTenureEditorDocument: () => void,
}> = ({ approvalStore, getTenureEditorDocument }) => {
  const { fetch } = approvalStore;
  const { records: statuses, templates } = approvalStore.data;
  const { session } = React.useContext(SessionContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<IApprovalStatus | undefined>(undefined);
  const [statusMap, setStatusMap] = useState({});
  const [blockDrawer, setBlockDrawer] = useState(false);

  // Form data
  const [completed, setCompleted] = useState(false);
  const [completedDate, setCompletedDate] = useState(null as unknown as Moment);
  const [notes, setNotes] = useState('');

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const modalCancel = () => setModalIsOpen(false);
  const modalSuccess = () => {
    setModalIsOpen(false);
    message.success('Attachment added.');
  };

  const onChange = (key: string | string[]) => {
    const query = {
      ...session.router.query,
      selection: typeof key === 'object' ? key.filter(k => k !== session.router.query.selection)[0] : key,
    };
    session.router.go(
      { name: session.router.current.name },
      session.router.current.params,
      { query, replace: true },
    );
  };

  useEffect(
    () => {
      // Initialize the query string so that the first template is expanded by default
      if (templates?.length > 0 && !session.router.query.selection) {
        onChange(templates[0].uuid);
      }
    },
    [templates],
  );

  useEffect(() => { fetch(); }, [fetch]);

  useEffect(
    () => {
      if (statuses) {
        const newStatusMap = {};
        statuses.forEach((status: IApprovalStatus) => {
          newStatusMap[status.approvalItem.uuid] = new ApprovalStatus(status);
          if (status.uuid === selectedStatus?.uuid) {
            setSelectedStatus(status);
          }
        });
        setStatusMap(newStatusMap);
      }
    },
    [statuses]);

  const openDrawer = (event: MouseEvent<HTMLElement>) => {
    if (!blockDrawer) {
      const status = statusMap[event.currentTarget.id];
      setSelectedStatus(status);
      if (status && status.completedDate) {
        setCompleted(true);
        setCompletedDate(moment(status.completedDate));
      } else {
        setCompleted(false);
      }
      setNotes(status?.notes ? status.notes : '');
      setDrawerOpen(true);
    }
  };

  const closeDrawer = () => setDrawerOpen(false);

  const getEmptyDisplay = (): JSX.Element => {
    if (!templates || templates.length === 0) {
      return <Empty />;
    }

    return <></>;
  };

  const getTemplatesDisplay = (templates: IApprovalTemplate[]) => {
    return templates.map((template: IApprovalTemplate) => {
      return (
        <Collapse.Panel
          key={template.uuid}
          header={(<Typography.Text strong={true} style={{ marginLeft: '10px' }}>{template.name}</Typography.Text>)}
        >
          <Layout>
            <Layout.Sider width={40} style={{ backgroundColor: 'white' }}>
              <Divider type="vertical" style={{ height: '100%', marginLeft: '14px' }} />
            </Layout.Sider>
            <Layout.Content style={{ backgroundColor: 'white', overflowY: 'hidden' }}>
              <List itemLayout="horizontal">
                {getApprovalItemsDisplay(template.approvalItems || [])}
              </List>
            </Layout.Content>
          </Layout>
        </Collapse.Panel>
      );
    });
  };

  const getApprovalTemplateStatusDisplay = (template: IApprovalTemplate | undefined, active: boolean): JSX.Element => {
    let statusValue = RESOURCE_STATUS.complete;

    if (template?.approvalItems) {
      for (const item of template?.approvalItems) {
        const status = statusMap[item.uuid];
        if (status && status.statusValue !== RESOURCE_STATUS.complete) {
          statusValue = status.statusValue;
        }
      }
    }

    return statusIconRenderer(statusValue, active);
  };

  const getApprovalItemsDisplay = (items: IApprovalItem[]): JSX.Element[] => {
    return (
      items.map((item) => {
        const status = statusMap[item.uuid];
        return (
          <List.Item
            key={item.uuid}
            id={item.uuid}
            style={{ cursor: 'pointer', background: item.uuid === session.router.hash ? 'lightyellow' : undefined }}
            onClick={openDrawer}
          >
            <List.Item.Meta
              avatar={statusAvatarRenderer(status?.statusValue)}
              title={(<Typography.Text strong={true}>{item.name}</Typography.Text>)}
              description={(
                <Space direction="vertical">
                  {getCompletedDateDisplay(status)}
                  {getAttachmentsDisplay(status)}
                  {getPhysicalAttachmentsDisplay(status)}
                  {getNotesDisplay(status)}
                </Space>
              )}
            />
          </List.Item>
        );
      })
    );
  };

  const getCompletedDateDisplay = (status: ApprovalStatus): JSX.Element => {
    if (status?.completedDate) {
      return (
        <Typography.Text style={{ width: '100%' }}>Completed Date: {dateRenderer(status.completedDate)}</Typography.Text>
      );
    }

    return <></>;
  };

  const downloadLinkedAttachment = (attachment: IAttachment) => async () => {
    await requestFile(attachment.url, attachment.name, attachment.mimetype);
  };

  const downloadAntAttachment = async (attachment: UploadFile) => {
    await requestFile(attachment.url || '', attachment.name, attachment.type);
  };

  const onMouseEnterDownload = () => {
    setBlockDrawer(true);
  };

  const onMouseLeaveDownload = () => {
    setBlockDrawer(false);
  };

  const getAttachmentsDisplay = (status: ApprovalStatus): JSX.Element => {
    if (status?.attachments.length > 0) {
      return (
        <Space direction="vertical">
          <Typography.Text><b>File Attachments</b></Typography.Text>
          {status.attachments.map((attachment) => {
            return (
              <Button type="link" key={`attachment-${attachment.uuid}`} onClick={downloadLinkedAttachment(attachment)} onMouseEnter={onMouseEnterDownload} onMouseLeave={onMouseLeaveDownload}>{attachment.displayname}</Button>
            );
          })}
        </Space>
      );
    }

    return <></>;
  };

  const physicalAttachmentListTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
  ];

  const getPhysicalAttachmentsDisplay = (status: ApprovalStatus): JSX.Element => {
    if (status?.physicalAttachments.length > 0) {
      return (
        <Space direction="vertical">
          <Typography.Text><b>Physical Attachments</b></Typography.Text>
          <Table
            dataSource={status.physicalAttachments}
            columns={physicalAttachmentListTableColumns}
            rowKey={getPhysicalAttachmentTableRowKey}
            size="small"
            pagination={{ hideOnSinglePage: true, size: 'small' }}
          />
        </Space>
      );
    }

    return <></>;
  };

  const getNotesDisplay = (status: ApprovalStatus): JSX.Element => {
    if (status?.notes) {
      return (
        <Space direction="vertical">
          <Typography.Text><b>Notes</b></Typography.Text>
          <Typography.Text style={{ width: '100%' }}>{status.notes}</Typography.Text>
        </Space>
      );
    }

    return <></>;
  };

  const handleCompletedChange = (e: any) => {
    if (e.target.checked) {
      setCompleted(true);
      setCompletedDate(moment());
    } else {
      setCompleted(false);
      setCompletedDate(null as unknown as Moment);
    }
  };

  const handleCompletedDate = (date: Moment | null) => {
    if (date !== null) {
      const now = moment();
      // If today's date is selected, make sure the time isn't later than right now.
      if (date.year() === now.year() && date.dayOfYear() === now.dayOfYear()) {
        setCompletedDate(now);
      } else {
        setCompletedDate(date);
      }
    }
  };

  const disabledDate = (date: Moment) => {
    const now = moment();
    return date.year() > now.year() || (date.year() === now.year() && date.dayOfYear() > now.dayOfYear());
  };

  const handleSubmit = async () => {
    if (!selectedStatus) {
      return;
    }
    const formData: any = {};
    formData.approvalStatus = selectedStatus?.uuid;
    formData.completed = completed;
    if (completedDate) {
      formData.completedDate = completedDate.valueOf();
    }
    formData.notes = notes;

    await sendCommand({
      formData,
      command: COMMANDS.UPDATE_APPROVAL_STATUS,
    });

    cleanUpSubmit();
  };

  const cleanUpSubmit = () => {
    closeDrawer();
    fetch();
    getTenureEditorDocument();
  };

  const onFileAdd = async (file: UploadFile) => {
    if (!selectedStatus?.uuid) {
      throw new Error(`Tried to upload file ${file.name}, but approval status UUID is not known`);
    }

    const formData = new FormData();
    formData.append('approvalStatus', selectedStatus.uuid);
    formData.append('files[]', file as any, file.name);

    await sendCommand({
      formData,
      command: COMMANDS.ADD_ATTACHMENTS_TO_APPROVAL_STATUS,
    });

    fetch();
  };

  const onFileRemove = async (file: UploadFile) => {
    try {
      await sendCommand({
        formData: {
          approvalStatus: selectedStatus?.uuid,
          deletedAttachmentUUIDs: [file.uid],
        },
        command: COMMANDS.REMOVE_ATTACHMENTS_FROM_APPROVAL_STATUS,
      });

      fetch();

      return true;
    } catch (e) {
      return false;
    }
  };

  const getPanelExpandIcon = (panelProps: any): JSX.Element => {
    return getApprovalTemplateStatusDisplay(templates?.find(template => template.uuid === panelProps.panelKey), panelProps.isActive);
  };

  const deletePhysicalAttachment = (uuid: string) => async (e: any) => {
    const formData: any = {};
    formData.approvalStatus = selectedStatus?.uuid;
    formData.deletedAttachmentUUIDs = [uuid];

    await sendCommand({
      formData,
      command: COMMANDS.REMOVE_PHYSICAL_ATTACHMENTS_FROM_APPROVAL_STATUS,
    });

    fetch();
  };

  const physicalAttachmentFormTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: <DeleteOutlined />,
      dataIndex: 'uuid',
      key: 'uuid',
      render: (uuid: string) => <a onClick={deletePhysicalAttachment(uuid)}><DeleteOutlined /></a>,
    },
  ];

  const drawerLabelCol = { span: 5 };

  const getPhysicalAttachmentTableRowKey = (attachment: IPhysicalAttachment) => attachment.uuid;

  const addPhysicalAttachment = (e: any) => setModalIsOpen(true);

  return (
    <div>
      {getEmptyDisplay()}
      <Collapse accordion={true} ghost={true} expandIcon={getPanelExpandIcon} style={{ fontSize: '14px' }} activeKey={session.router.query.selection} onChange={onChange}>
        {getTemplatesDisplay(templates || [])}
      </Collapse>
      <Drawer
        title="Edit Approval Status"
        placement="right"
        width={window.innerWidth > 720 ? 720 : '100%'}
        closable={true}
        onClose={closeDrawer}
        visible={drawerOpen}
        footer={(
          <Button type="primary" onClick={handleSubmit} block={true}>Save</Button>
        )}
      >
        <Typography.Title>{selectedStatus?.approvalItem?.name}</Typography.Title>
        <Form>
          <Form.Item
            label={(
              <Checkbox
                onChange={handleCompletedChange}
                name="completed"
                checked={completed}
                id="completed"
              >
                Completed
              </Checkbox>
            )}
            labelCol={drawerLabelCol}
          >
            <DatePicker
              value={completedDate}
              onChange={handleCompletedDate}
              name="newEndDate"
              disabled={!completed}
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item label="File Attachments" labelCol={drawerLabelCol}>
            <Upload
              multiple={true}
              disabled={false}
              fileList={selectedStatus?.attachments?.map((attachment: IAttachment): UploadFile => {
                return {
                  uid: attachment.uuid,
                  name: attachment.displayname,
                  size: attachment.size,
                  type: attachment.mimetype,
                  url: attachment.url,
                };
              }) || []}
              beforeUpload={onFileAdd}
              onRemove={onFileRemove}
              onDownload={downloadAntAttachment}
              onPreview={downloadAntAttachment}
            >
              <Button><UploadOutlined />Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Physical Attachments" labelCol={drawerLabelCol}>
              <Table
                dataSource={selectedStatus?.physicalAttachments}
                columns={physicalAttachmentFormTableColumns}
                rowKey={getPhysicalAttachmentTableRowKey}
                style={{ display: selectedStatus && selectedStatus.physicalAttachments && selectedStatus.physicalAttachments.length > 0 ? 'block' : 'none' }}
                size="small"
                pagination={{ hideOnSinglePage: true, size: 'small' }}
              />
              <Form.Item>
                <Button onClick={addPhysicalAttachment}>Add</Button>
              </Form.Item>
          </Form.Item>
          <BRFormComments comments={notes} setComments={setNotes} label="Notes" labelCol={drawerLabelCol}/>
        </Form>
      </Drawer>
      <ModalContext.Provider value={{ close: modalCancel, onSuccess: modalSuccess, visible: modalIsOpen }}>
        <AddPhysicalAttachmentToApprovalModal
          approvalStatus={selectedStatus?.uuid || ''}
          onSuccess={fetch}
        />
      </ModalContext.Provider>
    </div>
  );
};
