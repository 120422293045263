import { COMMANDS } from '../constants';
import { DocumentSpec } from '../types/DocumentSpec';

export abstract class Document {
  private spec: DocumentSpec;
  readonly created?: Date;

  protected constructor(spec: DocumentSpec) {
    this.spec = spec;
    this.created = spec.created ? new Date(spec.created) : undefined;
  }

  get type() {
    return this.spec.type;
  }

  get status() {
    return this.spec.fields?.status;
  }

  get id() {
    return `${this.spec.id}`;
  }

  get name() {
    return this.spec.fields?.name;
  }

  get editable() {
    return !!this.spec.fields?.editable;
  }

  get displayName() {
    return this.spec.displayName;
  }

  get createCommand() {
    return this.spec.createCommand;
  }

  get updateCommand() {
    return this.spec.updateCommand;
  }

  get apiGetRoot() {
    return this.spec.apiGetRoot;
  }

  get fields() {
    return this.spec.fields;
  }

  get changedFields() {
    return this.spec.changedFields;
  }

  field(field: string, value?: any) {
    if (field === 'id') {
      return this.spec.id;
    }
    if (field === 'type') {
      return this.spec.type;
    }
    if (value !== undefined && this.editable) {
      this.spec.fields[field] = value;
      this.spec.changedFields[field] = value;
    } else if (this.spec.fields) {
      return this.spec.fields[field];
    } else {
      return undefined;
    }
  }

  get email() {
    return this.spec.fields?.email;
  }

  is(type: string) {
    return this.spec.type === type;
  }

  abstract asInterface(): any;
}

export type DocumentPredicate = (doc: Document) => boolean;
