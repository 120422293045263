import { Document } from '../resources/Document';

const fieldHelpers: { [ index: string ]: (document: Document) => boolean } = {
  locationOther,
  newPerson,
  notNewPerson,
};

function locationOther(document: Document): boolean {
  const location = document.field('location') || document.field('location');
  // Check for the "Other" location. Have to check against the uuid
  return (location && location === '4c15f630-6a64-4128-9a65-b9d86f6d4136');
}

function newPerson(document: Document): boolean {
  // Check that the checkbox for adding a new person is selected
  return document.field('newPerson') != null && document.field('newPerson') === true;
}

function notNewPerson(document: Document): boolean {
  // Check that the checkbox for adding a new person is selected
  return document.field('newPerson') != null && document.field('newPerson') === false;
}

export function fieldHelper(helper: string, document: Document, defaultValue: boolean = false) {
  if (!fieldHelpers[helper]) {
    return defaultValue;
  }
  return fieldHelpers[helper](document);
}
