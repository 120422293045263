import React, { useState, ChangeEvent, useEffect } from 'react';
import { Form, Input, DatePicker } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { IPerson } from '../../types';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { Moment } from 'moment';
import { useBRListData } from '../../hooks/useBRListData';
import { IGroup } from '../../types/IGroup';

interface Props extends BRModalProps {
  person: IPerson;
}

const groupDataParams = {
  url: '/v0/groups',
  queryParams: {
    all: true,
    filters: { type: 'Award', createdBy: 'Application' },
  },
};

export const AddAwardToPersonModal: React.FunctionComponent<Props> =
({ person, ...modalProps }) => {

  const [description, setDescription] = useState<string>();
  const [dateRange, setDateRange] = useState<RangeValue<Moment>>();
  const [comments, setComments] = useState('');

  const groups = useBRListData<IGroup>({
    fetchDataParams: groupDataParams,
  });

  const [group, setGroup] = useState('');

  useEffect(
    () => {
      groups.fetch();
    },
    [groups.fetch],
  );

  const onFinish = async () => {
    const formData: {
      group: string,
      comments: string,
      description: string | undefined,
      startDate: Moment | undefined,
      endDate: Moment | undefined,
      person: string,
    } = {
      group,
      comments,
      description,
      startDate: dateRange?.[0] || undefined,
      endDate: dateRange?.[1] || undefined,
      person: person.uuid,
    };

    if (!group || !description || !dateRange?.[0]) {
      throw new Error('Please complete all required fields.');
    }

    await sendCommand({
      formData,
      command: COMMANDS.ADD_PERSON_TO_GROUP,
    });
  };

  const title = `Add Award to ${person.firstName} ${person.lastName}`;

  const groupOptions = groups.records.map(
      g => ({
        key: g.uuid,
        value: g.uuid || '',
        label: g.name,
      }),
    );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'description':
        setDescription(value);
        break;
      default:
        break;
    }
  };

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Add Award"
      formProps={{ onFinish }}
    >

      <Form.Item label="Award Type" required={true}>
        <BRSelect
          loading={!groups.loaded}
          options={groupOptions}
          setValue={setGroup}
        />
      </Form.Item>

      <Form.Item label="Description" required={true} rules={[{ required: true, message: 'Please input a description!' }]}>
        <Input
          onChange={handleChange}
          type="text"
          name="description"
          value={description}
          required={true}
        />
      </Form.Item>

      <Form.Item label="Date Range" required={true}>
        <DatePicker.RangePicker
          value={dateRange}
          allowClear={true}
          allowEmpty={[false, true]}
          onChange={setDateRange}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
