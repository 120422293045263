import * as React from 'react';
import { TabMenu, TabMenuTab } from '../components/TabMenu';
import { BRLayout } from '../components/BRLayout';
import { IRequirement, IRequirementCategory } from '../types';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useBRStore } from '../hooks/useBRStore';
import { checkCommands } from '../helpers/checkCommands';
import { Command } from '../components/CommandButtons';
import BRTable from '../components/BRTable';
import { ROUTES } from '../routes';
import { COMMANDS, TABLE_IDS } from '../constants';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { SessionContext } from '../components/App';
import { RemoveRequirementCategoryModal } from '../components/modals/RemoveRequirementCategoryModal';
import { AddRequirementToCategoryModal } from '../components/modals/AddRequirementToCategoryModal';
import { RemoveRequirementFromCategoryModal } from '../components/modals/RemoveRequirementFromCategoryModal';

interface Props {
  uuid: string;
}

export function RequirementCategoryDetailPage(props: Props) {
  const categoryUuid = props.uuid;
  const urlRoot = `/v0/requirements/categories/${categoryUuid}`;
  const fetchDataParams = React.useMemo(() => ({ url: urlRoot }), [props.uuid]);
  const { session } = React.useContext(SessionContext);

  const requirementCategoryStore = useBRStore<IRequirementCategory>({ fetchDataParams });
  const category = requirementCategoryStore.data;

  const requirements = useBRTableStore<IRequirement>({
    fetchDataParams: { url: `${urlRoot}/requirements` },
  });

  React.useEffect(
    () => {
      requirementCategoryStore.fetch();
    },
    [requirementCategoryStore.fetch]);

  const menuTabs: TabMenuTab[] = [{
    name: 'Requirements',
    key: 'requirements',
    component: (
      <BRTable
        tableData={requirements}
        linkPath={ROUTES.requirement}
        tableId={TABLE_IDS.REQ_CATEGORY_REQS_TABLE}
      />
    ),
  }];

  const allRequirementCategoryCommands: Command[] = [
    {
      title: 'Remove Requirement Category',
      icon: <MinusCircleOutlined />,
      type: COMMANDS.REMOVE_REQUIREMENT_CATEGORY,
      modal: (
        <RemoveRequirementCategoryModal
          category={category}
          onSuccess={() => session.router.go({ name: ROUTES.requirementCategories })}
        />
      ),
    },
    {
      title: 'Add Requirement',
      icon: <PlusCircleOutlined />,
      type: COMMANDS.ADD_REQUIREMENT_TO_CATEGORY,
      modal: (
        <AddRequirementToCategoryModal
          category={category}
          onSuccess={requirements.fetch}
        />
      ),
    },
    {
      title: 'Remove Requirement',
      icon: <MinusCircleOutlined />,
      type: COMMANDS.REMOVE_REQUIREMENT_FROM_CATEGORY,
      modal: (
        <RemoveRequirementFromCategoryModal
          category={category}
          onSuccess={requirements.fetch}
        />
      ),
    },
  ];

  const commands = checkCommands(allRequirementCategoryCommands, requirementCategoryStore);

  const descriptionItems = {
    Name: category.name,
  };

  return (
    <BRLayout
      loading={requirementCategoryStore.loading}
      title={category.name || 'Unnamed Category'}
      commands={commands}
      descriptionItems={descriptionItems}
    >
      <LoadingAnimation loading={requirementCategoryStore.loading} />
      <TabMenu tabs={menuTabs} />
    </BRLayout>
  );
}
