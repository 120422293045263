import * as React from 'react';
import { BRLayout } from '../components/BRLayout';
import { IRequirementCategory } from '../types';
import { COMMANDS, TABLE_IDS } from '../constants';
import BRTable from '../components/BRTable';
import { useBRTableStore } from '../hooks/useBRTableStore';
import { checkCommands } from '../helpers/checkCommands';
import { API_ROUTES } from '../constants/apiRoutes';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CreateRequirementCategoryModal } from '../components/modals/CreateRequirementCategoryModal';
import { Command } from '../components/CommandButtons';
import { ROUTES } from '../routes';

interface Props {

}

export function RequirementCategoryListPage(props: Props) {

  const categories = useBRTableStore<IRequirementCategory>({
    fetchDataParams: { url: API_ROUTES.requirementCategories },
  });

  const allRequirementCategoryListCommands: Command[] = [
    {
      title: 'Create Requirement Category',
      icon: <PlusCircleOutlined />,
      type: COMMANDS.CREATE_REQUIREMENT_CATEGORY,
      modal: (
        <CreateRequirementCategoryModal
          onSuccess={categories.fetch}
        />
      ),
    },
  ];

  const commands = checkCommands(allRequirementCategoryListCommands, categories);

  return (
    <BRLayout
      commands={commands}
      loading={categories.loading}
      title="Requirement Categories"
    >
      <BRTable
        linkPath={ROUTES.requirementCategory}
        tableId={TABLE_IDS.REQ_CATEGORIES_PAGE_TABLE}
        tableData={categories}
      />
    </BRLayout>
  );
}
