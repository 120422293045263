import * as React from 'react';
import { Tag } from 'antd';
import { PROTOCOL_SUMMARIZED_STATE, DATA_TYPES, COLOR_MAP, IResourceSchema } from '../../types';
import { IQueryStore } from '../../types/storeTypes/IQueryStore';
import { dateRenderer, dateTimeRenderer, statusTagRenderer, booleanRenderer, stringArrayRenderer, objectArrayRenderer, reportTypeRenderer } from '../../helpers/dataRenderers';

export enum columnTypes {
  dateColumn = 'dateColumn',
  categoriesColumn = 'categoriesColumn',
  requirementsColumn = 'requirementsColumn',
}

export const sumStateRenderer = (text: string, record: any, index: number) => {
  const labelColor = text === PROTOCOL_SUMMARIZED_STATE.inProgress ? 'blue'
                      : text === PROTOCOL_SUMMARIZED_STATE.creation ? 'purple'
                      : text === PROTOCOL_SUMMARIZED_STATE.notApplicable ? 'grey'
                      : text === PROTOCOL_SUMMARIZED_STATE.complete ? 'green'
                      : '#808080';

  const styles = {
    fontSize: '1.1em',
  };

  if (text) {
    return (
      <Tag color={labelColor} style={styles}>
        {text}
      </Tag>
    );
  }
  return [];
};

export const getColumnRenderer = (
  type: DATA_TYPES = DATA_TYPES.string,
  colorMap: COLOR_MAP = COLOR_MAP.status,
) => {
  if (type === DATA_TYPES.status) {
    return colorMapRenderers[colorMap];
  }
  return columnRenderers[type];
};

export const colorMapRenderers = {
  [COLOR_MAP.sumState]: sumStateRenderer,
  [COLOR_MAP.status]: statusTagRenderer,
};

export const columnRenderers = {
  [DATA_TYPES.date]: dateRenderer,
  [DATA_TYPES.dateTime]: dateTimeRenderer,
  [DATA_TYPES.status]: statusTagRenderer,
  [DATA_TYPES.boolean]: booleanRenderer,
  [DATA_TYPES.stringArray]: stringArrayRenderer,
  [DATA_TYPES.objectArray]: objectArrayRenderer,
  [DATA_TYPES.reportType]: reportTypeRenderer,
};

export const isColumnVisible = (dataIndex: string, query: IQueryStore, schema: IResourceSchema) => {
  if (schema[dataIndex] && schema[dataIndex].optional === false) {
    return schema[dataIndex].hidden !== true;
  }
  // if query contains column info, use query. Else use schema.
  if (query.columns && query.columns.hasOwnProperty(dataIndex)) {
    return query.columns[dataIndex];
  }
  return !(schema[dataIndex] && schema[dataIndex].hidden);
};
