import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'antd';

import { BRModal, BRModalProps } from '../BRModal';
import { BRSelect } from '../BRForm';
import { ICourse } from '../../types';
import { useBRListData } from '../../hooks/useBRListData';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { SessionContext } from '../App';
import { ROUTES } from '../../routes';
import { CategorizedTransfer } from '../CategorizedTransfer';
import { FEATURES } from '../../constants/features';

const fetchDataParams = { url: '/v0/courses', queryParams: { all: true } };

export const RunCourseReportModal: React.FunctionComponent<BRModalProps> =
({ ...modalProps }) => {

  const { session } = useContext(SessionContext);

  const allCourses = useBRListData<ICourse>({ fetchDataParams });

  useEffect(
    () => {
      allCourses.fetch();
    },
    [allCourses.fetch]);

  const [courses, setCourses] = useState<string[]>([]);

  const onFinish = async () => {
    const formData = {
      courses,
      type: 'courseReport',
    };

    const response = await sendCommand({
      formData,
      command: COMMANDS.RUN_REPORT,
    });

    session.router.go({ name: ROUTES.report }, response);
  };

  const courseOptions = allCourses.records
    .map(c => ({
      value: c.uuid,
      label: `${c.name} (${c.categories && c.categories.length
        ? c.categories.map(cat => cat.name).join(', ')
        : 'No Category'})`,
    }))
    .sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    })
    .map(c => ({
      key: c.value,
      label: c.label,
      value: c.value || '',
    }));

  const title = 'Course Report';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Run Report"
      formProps={{ onFinish }}
    >
      {FEATURES.CATEGORIZED_COURSE_SELECTOR ? (
        <CategorizedTransfer
          resources={allCourses.records}
          setSelectedResources={setCourses}
        />
      ) : (
        <Form.Item label="Select one or more">
          <BRSelect
            options={courseOptions}
            mode="multiple"
            setValue={setCourses}
            placeholder="Courses..."
          />
        </Form.Item>
      )}
    </BRModal>
  );
};
