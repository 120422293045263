import * as React from 'react';
import { Resizable } from 'react-resizable';

const ResizableHeader = (props: any) => {
  // tslint:disable-next-line: prefer-const
  let { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  if (typeof width === 'string') {
    width = parseInt(width, 10);
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      minConstraints={[10, 10]}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizableHeader;
