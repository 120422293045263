import React, { useContext, useState, useEffect } from 'react';
import { FieldProps, Field, getFieldReadOnly } from '../Field';
import { Upload, Button } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { DocumentEditorContext } from '../../../types/DocumentEditor';
import { UploadOutlined } from '@ant-design/icons';

export const FilesField = (props: FieldProps) => {
  const { documentEditor } = useContext(DocumentEditorContext);
  const [value, setValue] = useState([] as any[]);

  useEffect(() => {
    if (documentEditor.document) {
      // TODO: If needed, figure out a new way for the user to save uploaded files here (the "documents" property no longer exists)
      /*setValue(documentEditor.document.documents(props.name).map((doc: any) => ({
        uid: doc.seq,
        name: doc.name,
        url: doc.uri,
        status: 'done',
      })));*/
    }
  },        [documentEditor.document, props.name]);

  if (!documentEditor.document) {
    return null;
  }

  const request = ({ file, onSuccess, onError }: any) => {
    if (!documentEditor.document) {
      return;
    }
    // TODO: Needs to be implemented when the attachment service exists
  };

  const onChange = (info: any) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.uri;
      }
      return file;
    });

    setValue(fileList);
  };

  const onRemove = async (file: UploadFile) => {
    if (!documentEditor.document) {
      return false;
    }
    try {
      // TODO: Needs to be implemented when the attachment service exists
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <Field {...props}>
      <Upload
        multiple={true}
        disabled={getFieldReadOnly(props, documentEditor.document)}
        customRequest={request}
        fileList={value}
        onChange={onChange}
        onRemove={onRemove}
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
    </Field>
  );
};
