import * as React from 'react';
import { BRLayout } from '../../components/BRLayout';
import { useBRStore } from '../../hooks/useBRStore';
import { IRule } from '../../types/IRule';
import { RuleEditor } from '../../components/RuleEditor';
import { TabMenu } from '../../components/TabMenu';

interface Props {
  uuid: string;
}

export function RuleDetailPage(props: Props) {

  const rule = useBRStore<IRule>({
    fetchDataParams: React.useMemo(() => ({ url: `/v0/rules/${props.uuid}` }), [props.uuid]),
  });

  const currentXml = rule?.data?.draft?.xml || rule?.data?.versions?.[0]?.xml;

  React.useEffect(
    () => {
      rule.fetch();
    },
    [rule.fetch]);

  const descriptionItems = {
    UUID: rule.data.uuid,
    Type: rule.data.type,
  };

  const menuTabs = [];

  menuTabs.push({
    name: 'Editor',
    key: 'edit',
    component: (
      <RuleEditor ruleStore={rule} />
    ),
  });

  menuTabs.push({
    name: 'Raw XML',
    key: 'xml',
    component: (
      <pre>{currentXml}</pre>
    ),
  });

  return (
    <BRLayout
      title="Rule"
      descriptionItems={descriptionItems}
    >
      <TabMenu tabs={menuTabs} />
    </BRLayout>
  );
}
