import React, { useContext } from 'react';
import { FieldProps, Field } from '../Field';
import { Radio, Button } from 'antd';
import { StepContext } from '../Step';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import { DocumentEditorContext } from '../../../types/DocumentEditor';

interface OptionsFieldProps extends FieldProps {
  options: string[] | CheckboxOptionType[];
}

export const RadioField = (props: OptionsFieldProps) => {
  const { documentEditor } = useContext(DocumentEditorContext);
  const { value, setValue } = useContext(StepContext);

  if (!documentEditor.document) {
    return null;
  }

  const onClear = () => setValue([]);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <Field {...props}>
      <Radio.Group
        options={props.options}
        value={value}
        disabled={!documentEditor.document.editable || !!props.readOnly}
        onChange={onChange}
      />
      {props.clear && <div><Button size="small" onClick={onClear}>Clear</Button></div>}
    </Field>
  );
};
