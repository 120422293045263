import * as React from 'react';
import { List, Avatar, message, Button } from 'antd';
import { DATE_OPTIONS, UI_COLORS } from '../../constants';
import { ICourseTaken, ICourse, IPersonRequirement, IPerson } from '../../types';
import { SessionContext } from '../App';
import { Link } from '../../hooks/useRoutes';
import { ROUTES } from '../../routes';
import { CheckOutlined, DeleteOutlined, ExclamationOutlined } from '@ant-design/icons';
import { BRStore } from '../../hooks/useBRStore';
import { RemoveCourseFromPersonModal } from '../modals/RemoveCourseFromPersonModal';
import { ModalContext } from '../BRModal';
import { BRListData } from '../../hooks/useBRListData';

interface Props {
  pr: BRStore<IPersonRequirement>;
  coursesCompleted: any[];
  person: BRStore<IPerson>;
  requirements: BRListData<IPersonRequirement>;
  admin: boolean;
}

export function CoursesCompletedTable(props: Props) {

  const { session } = React.useContext(SessionContext);
  const [modal, setModal] = React.useState<React.ReactNode>();

  const now = new Date();

  const closeModal = () => { setModal(undefined); };

  const formattedCoursesTaken = props.coursesCompleted.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.completedDate > b.completedDate) {
      return 1;
    }
    if (a.completedDate < b.completedDate) {
      return -1;
    }
    return 0;
  });

  const listItem = (courseTaken: any) => {
    const courseLink = session.router.getUrl(
      { name: ROUTES.course },
      { uuid: courseTaken.course },
    );

    const expired = false; // course.expirationDate && (course.expirationDate <= now);

    const completionDate = courseTaken.completedDate && (new Date(courseTaken.completedDate))
                                                    .toLocaleDateString('en-US', DATE_OPTIONS);
    const expirationDate = undefined; // course.expirationDate && (new Date(course.expirationDate))
                                      //              .toLocaleDateString('en-US', DATE_OPTIONS);
    let description: string;
    let avatar: React.ReactNode;

    if (expired) {
      avatar = (
        <Avatar
          icon={<ExclamationOutlined />}
          style={{ backgroundColor: UI_COLORS.deepRed }}
        />
      );
      description = `Completed on ${completionDate} — Expired on ${expirationDate}`;
    } else if (expirationDate) {
      avatar = (
        <Avatar
          icon={<CheckOutlined />}
          style={{ backgroundColor: UI_COLORS.deepGreen }}
        />
      );
      description = `Completed on ${completionDate} — Expires on ${expirationDate}`;
    } else {
      avatar = (
        <Avatar
          icon={<CheckOutlined />}
          style={{ backgroundColor: UI_COLORS.deepGreen }}
        />
      );
      description = `Completed on ${completionDate}`;
    }

    const actions: any[] = [];

    if (props.admin && courseTaken.transcript?.provider?.name === 'Manual') {
      const onSuccess = () => {
        message.success('Remove course completed successfully.');
        props.pr.fetch();
        props.person.fetch();
        props.requirements.fetch();
        closeModal();
      };
      const openRemoveModal = () => {
        const removeModal = ((
          <RemoveCourseFromPersonModal
            person={props.person.data}
            course={courseTaken.uuid}
            onSuccess={onSuccess}
          />
        ));
        setModal(removeModal);
      };
      actions.push(<Button danger={true} icon={<DeleteOutlined />} onClick={openRemoveModal} />);
    }

    return (
        <List.Item
          actions={actions}
        >
          <List.Item.Meta
            avatar={avatar}
            title={<Link href={courseLink}>{courseTaken.name}</Link>}
            description={description}
          />
        </List.Item>
    );
  };

  return (
    <>
      <List
        dataSource={formattedCoursesTaken}
        locale={{ emptyText: 'No Relevant Coursework' }}
        renderItem={listItem}
      />
      {!!modal && (
        <ModalContext.Provider value={{ close: closeModal, onSuccess: closeModal, visible: !!modal }}>
          {modal}
        </ModalContext.Provider>
      )}
    </>
  );
}
