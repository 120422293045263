import { BookOutlined, BranchesOutlined, CheckOutlined, PlusOutlined, ReadOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { v4 } from 'uuid';
import { RuleEditorNodeProps, RULE_NODE_COLORS } from '.';

const ElementSchema = {
  Block: '<Block />',
  Loop: '<Loop />',
  Test: '<Test />',
  Course: '<Course />',
  CourseGroup: '<CourseGroup />',
  RenewalGroup: '<RenewalGroup />',
};

export function RuleEditorAdd(props: RuleEditorNodeProps) {

  const onAdd = (e: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(ElementSchema[e.key], 'application/xml');
    doc.documentElement.setAttribute('unique', v4());
    props.openEditor(doc.documentElement, props.elt);
  };

  return (
    <Dropdown
      overlay={(
        <Menu onClick={onAdd}>
          {props.isCourseEditor
          ? (
          <>
            <Menu.Item key={'Course'}><BookOutlined style={{ color: RULE_NODE_COLORS.Course }} /> Course</Menu.Item>
            <Menu.Item key={'CourseGroup'}><ReadOutlined style={{ color: RULE_NODE_COLORS.CourseGroup }} /> CourseGroup</Menu.Item>
            <Menu.Item key={'RenewalGroup'}><ReadOutlined style={{ color: RULE_NODE_COLORS.RenewalGroup }} /> RenewalGroup</Menu.Item>
          </>
          )
          : (
          <>
            <Menu.Item key={'Block'}><BranchesOutlined style={{ color: RULE_NODE_COLORS.Block }} /> Block</Menu.Item>
            <Menu.Item key={'Loop'}><RedoOutlined style={{ color: RULE_NODE_COLORS.Loop }} /> Loop</Menu.Item>
            <Menu.Item key={'Test'}><CheckOutlined style={{ color: RULE_NODE_COLORS.Test }} /> Test</Menu.Item>
          </>
          )}
        </Menu>
      )}
      trigger={['click']}
    >
      <Button icon={<PlusOutlined />}>Add</Button>
    </Dropdown>
  );
}
