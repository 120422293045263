import * as React from 'react';
import { Collapse, Empty, Avatar } from 'antd';
import { EvaluationTable } from '../DetailsTable/EvaluationTable';
import { GroupsTable } from '../DetailsTable/GroupsTable';
import { BRStore, useBRStore } from '../../hooks/useBRStore';
import { EvalObject, IPerson, IPersonRequirement, RESOURCE_STATUS } from '../../types';
import { CoursesScheduledTable } from '../DetailsTable/CoursesScheduledTable';
import { CoursesExemptedTable } from '../DetailsTable/CoursesExemptedTable';
import { CoursesCompletedTable } from '../DetailsTable/CoursesCompletedTable';
import { UI_COLORS } from '../../constants';
import { CheckOutlined } from '@ant-design/icons';
import { BRListData } from '../../hooks/useBRListData';
import { SessionContext } from '../App';

interface Props {
  person: BRStore<IPerson>;
  requirements: BRListData<IPersonRequirement>;
  requirement?: string;
  admin: boolean;
  pr: IPersonRequirement;
}

function courseMatch(a: any) {
  return (b: any) => a.name === b.name && a.completedDate === b.completedDate;
}

function getCoursesTaken(ev: EvalObject) {
  let coursesTaken = ev.coursesTaken?.map((ct: any) => ({
    ...ct,
    expirationDate: ev.expiryCourse?.expirationDate,
  })) || [];

  for (const child of ev.children) {
    coursesTaken = coursesTaken.concat(getCoursesTaken(child));
  }

  const out: any[] = [];

  for (const course of coursesTaken) {
    if (!out.find(courseMatch(course))) {
      out.push(course);
    }
  }

  return out;
}

export function PersonRequirementDrawer(props: Props) {
  const fetchDataParams = React.useMemo(() => ({ url: `/v0/people/${props.person.data.uuid}/requirements/${props.requirement}` }), [props.person, props.requirement]);
  const pr = useBRStore<IPersonRequirement>({ fetchDataParams });
  const { session } = React.useContext(SessionContext);

  React.useEffect(() => { pr.fetch(); }, []);

  const coursesCompleted = React.useMemo(
    () => pr.data?.evaluation ? getCoursesTaken(pr.data.evaluation) : [],
    [pr.data]);
  
  const links = props.pr.links ? props.pr.links.map((link) => {
    return <li key={link.name}><a href={link.url} target="_blank">{link.name}</a></li>
  }) : [];

  return (
    <>
      {props.pr.links?.length ?
        <><p>Information on completing this requirement may be found at the following links:</p>
          <ul>
            {links}
          </ul>
        </>
        :
        ''
      }
      <Collapse defaultActiveKey={['1', '2', '3', '4']}>
        <Collapse.Panel header={'Courses Needed'} key="1">
          { pr?.data?.evaluation?.evaluation !== RESOURCE_STATUS.complete ? (
            <EvaluationTable pr={pr} requirement={props.requirement} requirements={props.requirements} person={props.person} admin={props.admin} />
          ) : (
            <Empty
              image={
                <Avatar icon={<CheckOutlined />} size={64} style={{ backgroundColor: UI_COLORS.deepGreen }} />
              }
              description={'Requirement Satisfied'}
              imageStyle={{
                height: 64,
              }}
            />

          )
        }
        </Collapse.Panel>
        {(coursesCompleted.length) &&
        (
          <Collapse.Panel header="Completed Courses" key="2">
            <CoursesCompletedTable pr={pr} coursesCompleted={coursesCompleted} person={props.person} requirements={props.requirements} admin={props.admin} />
          </Collapse.Panel>

        )
        }
        {(pr?.data?.coursesScheduled?.length) &&
          (
          <Collapse.Panel header={'Scheduled Courses'} key="2">
            <CoursesScheduledTable pr={pr} />
          </Collapse.Panel>
        )}
        {(pr?.data?.exemptions?.length) &&
          (
          <Collapse.Panel header={'Exemptions'} key="3">
            <CoursesExemptedTable pr={pr} person={props.person} requirements={props.requirements} requirement={props.requirement} />
          </Collapse.Panel>
        )}
        {(pr?.data?.groups?.length) &&
          (
          <Collapse.Panel header={'Related Groups'} key="4">
            <GroupsTable pr={pr} />
          </Collapse.Panel>
        )}
      </Collapse>
    </>
  );
}
