import React, { ChangeEvent, ReactElement, ReactEventHandler } from 'react';
import { Button, DatePicker, Drawer, InputNumber, Select, Space, Form, Tooltip, Input } from 'antd';
import { CourseSelect } from './CourseSelect';
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { InputCascader } from './InputCascader';
import { CascaderOptionType, CascaderValueType } from 'antd/lib/cascader';
import { ValueSelect } from './ValueSelect';
import { ResourceSelect } from './ResourceSelect';
import Checkbox from 'antd/lib/checkbox/Checkbox';

interface Props {
  element?: Element;
  // parentElement should be used, as element.parentNode is undefined for new nodes (RuleEditorAdd)
  parentElement: Element | undefined | null;
  onClose: () => void;
  schema?: any;
}

export interface RuleAttributes {
  [index: string]: string | undefined;
}

const getDisplayedFields = (nodeName: string | undefined, selectedOption: CascaderOptionType | undefined) => {
  switch (nodeName) {
    case 'Block':
      return {
        result: true,
      };
    case 'Loop':
      return {
        input: true,
        match: true,
        result: true,
      };
    case 'Test':
      return {
        input: true,
        condition: selectedOption && !selectedOption.iterable && selectedOption.type !== 'select',
        value: selectedOption && !selectedOption.iterable && selectedOption.type !== 'boolean' && selectedOption.type !== 'select',
        select: selectedOption?.type === 'select',
      };
    case 'Course':
      return {
        course: true,
        retiresOn: true,
        takenAfter: true,
      };
    case 'CourseGroup':
      return {
        match: true,
        validFor: true,
      };
    case 'RenewalGroup':
      return {
        match: true,
        validFor: true,
      };
    default:
      return {};
  }
};

export const getDefaultOptions = (element: Element | undefined | null): { [index: string]: string } => {
  const attributes = {};
  element?.getAttributeNames().forEach((name) => {
    attributes[name] = element?.getAttribute(name);
  });

  return attributes;
};

const editorSchema = {
  Block: {
    result: ['any', 'all'],
  },
  Loop: {
    input: '',
    match: ['any', 'all'],
    result: ['any', 'all'],
  },
  Test: {
    input: '',
    condition: {
      equals: [], // whatever the value is for the input
      notEquals: [], // whatever the value is for the input
      always: [], // no value
      never: [], // no value
      true: [], // input is truthy
      false: [], // input is falsy
      olderThanYears: [], // number of years older than a date input
    },
    value: '',
  },
  // equals: (a: any, b: any) => (a === b) ? TRUE : FALSE,
  // notEquals: (a: any, b: any) => (a !== b) ? TRUE : FALSE,
  // always: () => TRUE,
  // never: () => FALSE,
  // true: (a: any) => a ? TRUE : FALSE,
  // false: (a: any) => a ? FALSE : TRUE,
  // olderThanYears: (a: Date, b: string) => {
  //   const oldDate = new Date(now);
  //   oldDate.setFullYear(now.getFullYear() - parseInt(b, 10));
  //   return (a && (new Date(a)).getTime() < oldDate.getTime()) ? TRUE : FALSE;
  // },
};

const matchOptions = [
  {
    value: 'any',
    label: 'any',
  },
  {
    value: 'all',
    label: 'all',
  },
];

const resultOptions = [
  {
    value: 'any',
    label: 'any',
  },
  {
    value: 'all',
    label: 'all',
  },
];

// Select options by data type
const getConditionOptions = (optionType?: string) => {
  return conditionOptions[optionType ?? 'string'] ?? conditionOptions['string'];
}

const conditionOptions = {
  string: [
    {
      label: 'Always',
      value: 'always',
    },
    {
      label: 'Never',
      value: 'never',
    },
    {
      label: 'Equals',
      value: 'equals',
    },
    {
      label: 'Does Not Equal',
      value: 'notEquals',
    },
  ],
  boolean: [
    {
      label: 'True',
      value: 'true',
    },
    {
      label: 'False',
      value: 'false',
    },
  ],
  date: [
    {
      label: 'Always',
      value: 'always',
    },
    {
      label: 'Never',
      value: 'never',
    },
    {
      label: 'Older Than Years',
      value: 'olderThanYears',
    },
  ],
};

export const RuleEditorDrawer: React.FunctionComponent<Props> =
  ({ element, schema, ...props }) => {

    // the spread is done to create a new array to mutate (when adding Context)
    const inputOptions = schema && [...schema];

    const elementType = element?.nodeName;

    const getContext = (element: Element | undefined | null): string[] => {
      // this function is designed to recursively get the entire "context" chain, if the current input begins with context
      // it will still return the current input if it does not have a context
      const attributes = getDefaultOptions(element);
      let input = attributes.input?.split('.');
  
      if (input?.[0] === 'context') {
        const inputBegin = getContext(element?.parentElement);
        input.shift();
        input = inputBegin.concat(input);
      }
      return input;
    };
  
    const context = getContext(props.parentElement);
    
    if (context && inputOptions) {
      // if the context exists, create a new chain in the inputOptions for the context line
      // This will need to be adapted to always provide a context selection if the parent element has an input but the edited element does not use "context"
      let newContext: any[] = inputOptions;
      let contextName;
      context.forEach((contextItem) => {
        const item = newContext.find(item => item.value === contextItem);
        newContext = item?.children || [];
        contextName = item?.label || 'Unknown';
      });
      inputOptions.push({
        label: `Context (${contextName})`,
        value: 'context',
        children: newContext,
      });
    }

    const [attributes, setAttributes] = React.useState<RuleAttributes>(getDefaultOptions(element));
    const [selectedOption, setSelectedOption] = React.useState<CascaderOptionType>();

    React.useEffect(
      () => {
        const newAttributes = getDefaultOptions(element);
        setAttributes(newAttributes);

        let newOption: CascaderOptionType = {
          children: inputOptions,
        };
        newAttributes.input?.split('.').forEach(
          (inputItem: string) => {
            newOption = newOption?.children?.find(item => item.value === inputItem) || {};
          });
        setSelectedOption(newOption);
      },
      [element],
    );

    const onConfirm = async () => {
      Object.entries(attributes).forEach(([key, value]) => {
        if (value != null) {
          element?.setAttribute(key, value);
        } else {
          element?.removeAttribute(key);
        }
      });
      if (element) {
        props.parentElement?.prepend(element);
      }
      props.onClose();
    };

    const onInputCascaderChange = (value: CascaderValueType, selectedOptions?: CascaderOptionType[]) => {
      const selectedOption = selectedOptions?.[selectedOptions.length - 1];

      // This is generally for string and number arrays, whose Test value would be ['context', '']
      const trimmedValue = value.filter(v => !!v);

      setAttributes({
        ...attributes,
        input: trimmedValue.join('.'),
      });
      setSelectedOption(selectedOption);
    };

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setAttributes({
        ...attributes,
        input: e.target.value,
      });
    }

    const onValueChange = (value: string) => {
      setAttributes({
        ...attributes,
        value,
      });
    }

    const onEvaluateAsChange = (e: any) => {
      setAttributes({
        ...attributes,
        evaluateAs: e.target?.checked ? 'variable' : undefined,
      })
    }

    const onConditionChange = (condition: string) => {
      setAttributes({
        ...attributes,
        condition,
      });
    };

    const onMatchChange = (match: string) => {
      setAttributes({
        ...attributes,
        match,
      });
    };

    const onResultChange = (result: string) => {
      setAttributes({
        ...attributes,
        result,
      });
    };

    const onValidForChange = (validFor: string | number | undefined) => {
      setAttributes({
        ...attributes,
        validFor: validFor ? `${validFor}` : undefined,
      });
    };

    const onCourseChange = (uuid: string) => {
      setAttributes({
        ...attributes,
        uuid,
      });
    };

    const onSelectChange = (uuid: string) => {
      setAttributes({
        ...attributes,
        condition: 'equals',
        value: uuid,
      });
    }

    const onRetirementChange = (date: moment.Moment | null) => {
      setAttributes({
        ...attributes,
        retiresOn: date?.format('MM/DD/YYYY'),
      });
    };

    const onTakenAfterChange = (date: moment.Moment | null) => {
      setAttributes({
        ...attributes,
        takenAfter: date?.format('MM/DD/YYYY'),
      });
    };

    const displayedFields = getDisplayedFields(elementType, selectedOption);

    return (
      <Drawer
        width={720}
        onClose={props.onClose}
        visible={!!element}
        title={`Edit ${element?.nodeName}`}
        destroyOnClose={true}
        footer={(
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Space>
                  <Button type="primary" onClick={onConfirm} block={true}>Confirm</Button>
              </Space>
              <Button onClick={props.onClose} block={true}>Cancel</Button>
            </Space>
          </div>
        )}
      >
        <Form
          layout="vertical"
        >
        {displayedFields.input && (
          <Form.Item
            label="Input"
          >
            {inputOptions
            ? <InputCascader
                options={inputOptions}
                selectedOption={selectedOption}
                onChange={onInputCascaderChange}
                attributes={attributes}
                element={element}
                parentElement={props.parentElement}
                setAttibutes={setAttributes}
              />
            : <Input
                value={attributes.input}
                onChange={onInputChange}
              />
            }
          </Form.Item>
        )}
        {displayedFields.select && (
          <Form.Item
            label="Select Item"
          >
            <ResourceSelect
              selectedOption={selectedOption}
              onChange={onSelectChange}
              attributes={attributes}
            />
          </Form.Item>
        )}
        {displayedFields.condition && (
          <Form.Item
            label="Condition"
          >
            <Select
              value={attributes.condition}
              options={getConditionOptions(selectedOption?.type)}
              onChange={onConditionChange}
              placeholder={'Select condition'}
            />
          </Form.Item>
        )}
        {displayedFields.value && (
          <Form.Item
            label="Value"
          >
            <ValueSelect
              selectedOption={selectedOption}
              onChange={onValueChange}
              value={attributes.value}
              placeholder={'Select value'}
            />
            <Tooltip title={'Value will be evaluated as a programmatic variable rather than the literal text (advanced)'}>
              <Checkbox
                checked={attributes.evaluateAs === 'variable'}
                onChange={onEvaluateAsChange}
              >
                Evaluate As Variable
              </Checkbox>
            </Tooltip>
          </Form.Item>
        )}
        {displayedFields.match && (
          <Form.Item
            label="Match"
            tooltip={{
              title: `An 'any' matching ${elementType} will pass if one or more of its children pass.\n An 'all' match requires all of its children to pass.`,
              icon: <QuestionCircleOutlined style={{ marginInlineStart: '4px' }} />,
            }}
            rules={[{ required: true, message: 'Please select a match type' }]}
          >
            <Select
              options={matchOptions}
              value={attributes.match}
              onChange={onMatchChange}
              placeholder={'Select match type'}
            />
          </Form.Item>
        )}
        {displayedFields.result && (
          <Form.Item
            label="Result"
          >
            <Select
              options={resultOptions}
              value={attributes.result}
              onChange={onResultChange}
              placeholder={'Select result type'}
            />
          </Form.Item>
        )}
        {displayedFields.validFor && (
          <Form.Item
            label="Valid For Years"
            tooltip={{
              title: 'The length of time (in years) it takes for courses under this tag to expire.',
              icon: <QuestionCircleOutlined style={{ marginInlineStart: '4px' }} />,
            }}
          >
            <InputNumber
              min={1}
              max={99}
              value={attributes.validFor ? parseInt(attributes.validFor, 10) : undefined}
              onChange={onValidForChange}
            />
          </Form.Item>
        )}
        {displayedFields.course && (
          <Form.Item
            label="Select Course"
            rules={[{ required: true, message: 'Please select a course' }]}
          >
            <CourseSelect
              attributes={attributes}
              onChange={onCourseChange}
            />
          </Form.Item>
        )}
        {displayedFields.retiresOn && (
          <Form.Item
            label="Retirement Date"
            tooltip={{
              title: 'Course will not be an option for requirement completion on or after this date.',
              icon: <QuestionCircleOutlined style={{ marginInlineStart: '4px' }} />,
            }}
          >
            <DatePicker
              value={attributes.retiresOn ? moment(attributes.retiresOn) : null}
              onChange={onRetirementChange}
            />
          </Form.Item>
        )}
        {displayedFields.takenAfter && (
          <Form.Item
            label="Taken After"
            tooltip={{
              title: 'Course must be completed after this date to qualify.',
              icon: <QuestionCircleOutlined style={{ marginInlineStart: '4px' }} />,
            }}
          >
            <DatePicker
              value={attributes.takenAfter ? moment(attributes.takenAfter) : null}
              onChange={onTakenAfterChange}
            />
          </Form.Item>
        )}
        </Form>
      </Drawer>
    );
  };
