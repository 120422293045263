import * as React from 'react';
import { Table } from 'antd';
import { IGroup } from '../../types/IGroup';
import { getColumnRenderer } from '../BRTable/helpers';
import { DATA_TYPES, IPersonRequirement } from '../../types';
import { ColumnsType } from 'antd/lib/table';
import { FEATURES } from '../../constants/features';
import { BRStore } from '../../hooks/useBRStore';

interface Props {
  pr: BRStore<IPersonRequirement>;
}

export function GroupsTable(props: Props) {

  const columns: ColumnsType<IGroup> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '100px',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '100px',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '100px',
    },
  ];

  if (FEATURES.AWARDS) {
    columns.push({
      title: 'Award Type',
      dataIndex: ['siteData', 'grantType'],
      key: 'awardType',
    });
  }

  columns.push({
    title: 'Roles',
    dataIndex: 'roles',
    key: 'roles',
    render: getColumnRenderer(DATA_TYPES.stringArray),
  });
  columns.push({
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width: '100px',
    render: getColumnRenderer(DATA_TYPES.status),
  });
  columns.push({
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    render: getColumnRenderer(DATA_TYPES.date),
  });
  columns.push({
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    render: getColumnRenderer(DATA_TYPES.date),
  });

  return (
    <Table
      columns={columns}
      dataSource={props.pr?.data?.groups ?? []}
      pagination={false}
      rowKey="uuid"
      size={'middle'}
    />
  );
}
