import React, { useState, ChangeEvent, useEffect } from 'react';
import { Form, Input } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';
import { IRequirementCategory } from '../../types';
import { useBRListData } from '../../hooks/useBRListData';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';
import { API_ROUTES } from '../../constants/apiRoutes';

const fetchDataParams = { url: API_ROUTES.requirementCategories, queryParams: { basic: true } };

export const NewRequirementModal: React.FunctionComponent<BRModalProps> =
({ ...modalProps }) => {

  const requirementCategories = useBRListData<IRequirementCategory>({ fetchDataParams });

  useEffect(
    () => {
      requirementCategories.fetch();
    },
    [requirementCategories.fetch]);

  const [name, setName] = useState('');
  const [categories, setCategories] = useState([] as string[]);
  const [description, setDescription] = useState('');
  const [comments, setComments] = useState('');

  const onFinish = async () => {
    const formData = {
      name,
      categories,
      description,
      comments,
    };

    await sendCommand({
      formData,
      command: COMMANDS.NEW_REQUIREMENT,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'description':
        setDescription(value);
        break;
      default:
        break;
    }
  };

  const categoriesOptions = requirementCategories.records &&
    requirementCategories.records.map(c => ({
      key: c.uuid,
      label: c.name,
      value: c.uuid || '',
    }));

  const title = 'Create New Requirement';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Create Requirement"
      formProps={{ onFinish }}
    >
        <Form.Item label="Requirement Name">
          <Input
            onChange={handleChange}
            value={name}
            type="text"
            name="name"
            required={true}
            minLength={5}
          />
        </Form.Item>

        <Form.Item label="Categories">
          <BRSelect
            setValue={setCategories}
            options={categoriesOptions}
            mode="multiple"
          />
        </Form.Item>

        <Form.Item label="Description">
          <Input
            onChange={handleChange}
            value={description}
            type="text"
            name="description"
          />
        </Form.Item>

        <BRFormComments comments={comments} setComments={setComments}/>

    </BRModal>
  );
};
