export enum DATA_TYPES {
  string = 'string',
  date = 'date',
  dateTime = 'dateTime',
  status = 'status',
  boolean = 'boolean',
  stringArray = 'stringArray',
  number = 'number',
  objectArray = 'objectArray',
  reportType = 'reportType',
}

export enum PROTOCOL_SUMMARIZED_STATE {
  complete = 'Complete',
  creation = 'Creation',
  inProgress = 'In Progress',
  notApplicable = 'N/A',
}

export enum COLOR_MAP {
  status = 'status',
  sumState = 'sumState',
}

export interface IColumnSchema {
  title: string;
  type: DATA_TYPES;
  sortable?: boolean;
  optional?: boolean;
  filterable?: boolean;
  hidden?: boolean;
  options?: string[];
  customOptions?: boolean;
  colorMap?: COLOR_MAP;
  width?: number;
}

export interface IResourceSchema {
  [index: string]: IColumnSchema;
}
