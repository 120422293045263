import React, { ChangeEvent, useState } from 'react';
import { Button, DatePicker, Form, message } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import { IPerson } from '../../types';
import { BRFormComments } from '../BRForm';
import moment from 'moment';
import { FormProps } from 'antd/lib/form';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS, DATE_OPTIONS } from '../../constants';
import { useBRStore } from '../../hooks/useBRStore';
import { BRDrawer, BRDrawerProps } from '../BRDrawer';
import { ROUTES } from '../../routes';
import { SessionContext } from '../App';
import { Link } from '../../hooks/useRoutes';
import { ModalContext } from '../BRModal';
import { IGroupMember } from '../../types/IGroup';
import TextArea from 'antd/lib/input/TextArea';
import { RemoveAwardFromPersonModal } from '../modals/RemoveAwardFromPersonModal';

interface Props extends BRDrawerProps {
  person: IPerson;
  member: string;
  onSuccess?: () => void;
}

export const PersonAwardDrawer: React.FunctionComponent<Props> =
  ({ person, member, onSuccess, ...drawerProps }) => {

    const { session } = React.useContext(SessionContext);
    const today = new Date();
    const [dateRange, setDateRange] = useState<RangeValue<moment.Moment>>();
    const [description, setDescription] = useState('');
    const [comments, setComments] = useState('');
    const [editable, setEditable] = useState(false);
    const [dirty, setDirty] = useState(false);

    const fetchDataParams = React.useMemo(() => ({ url: `/v0/people/${person.uuid}/groups/${member}` }), [person, member]);
    const awardStore = useBRStore<IGroupMember>({ fetchDataParams });
    const award = awardStore.data;

    function dirtyWrap<T>(fn: React.Dispatch<T>) {
      return (arg: T) => {
        setDirty(true);
        fn(arg);
      };
    }

    const [modal, setModal] = React.useState<React.ReactNode>();

    const onModalSuccess = () => {
      onSuccess && onSuccess();
      onClose();
      message.success('Remove award completed successfully.');
    };

    const removeModal = (
      <RemoveAwardFromPersonModal
        person={person}
        groupMember={award}
        onSuccess={onModalSuccess}
      />
    );

    const openRemoveModal = () => {
      setModal(removeModal);
    };

    const closeModal = () => { setModal(undefined); };

    React.useEffect(
      () => {
        awardStore.fetch();
      },
      [awardStore.fetch]);

    React.useEffect(
      () => {
        if (award) {
          setDateRange([
            award.startDate ? moment(award.startDate) : null,
            award.endDate ? moment(award.endDate) : null,
          ]);
          setDescription(award.description ? award.description : '');
          setEditable(award.manuallyAdded ? award.manuallyAdded : false);
        } else {
          setEditable(false);
        }
      },
      [awardStore.loaded],
    );

    const handleSubmit = async () => {
      const formData = {
        description,
        comments,
        award: award.uuid,
        group: award.group,
        person: person.uuid,
        startDate: dateRange?.[0] || undefined,
        endDate: dateRange?.[1] || undefined,
      };

      if (!description || !dateRange?.[0]) {
        throw new Error('Please complete all required fields.');
      }

      await sendCommand({
        formData,
        command: COMMANDS.UPDATE_PERSON_AWARD,
      }).then(
        () => {
          message.success('Award updated.');
          onSuccess && onSuccess();
        },
      ).catch(() => {});
    };

    const formProps: FormProps = {
      onFinish: handleSubmit,
    };

    const onClose = () => {
      setModal(undefined);
      session.router.go({ name: ROUTES.person }, { uuid: person.uuid, tab: 'awards' }, { query: session.router.query });
    };

    const buttons: React.ReactNode[] = [];

    buttons.push((
      <Button key="remove" type="primary" danger={true} onClick={openRemoveModal} disabled={!awardStore.loaded || !editable}>Remove Award</Button>
    ));

    const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => dirtyWrap(setDescription)(e.target.value);
    const handleDateChange = (r: RangeValue<moment.Moment>) => dirtyWrap(setDateRange)(r);
    const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => setComments(e.target.value);

    const title = award ?
      (<><Link href={session.router.getUrl({ name: ROUTES.group }, { uuid: `${award.group}` })}>{award.name || 'Award'}</Link> Details</>)
      :
      (<>Award Not Found</>);

    return (
      <>
        <BRDrawer
          {...drawerProps}
          width={400}
          title={title}
          okText="Save"
          cancelText="Cancel"
          formProps={formProps}
          dirty={dirty}
          onClose={onClose}
          buttons={buttons}
        >
            { (editable) ?
            (
              <>
                <Form.Item label="Date Range" required={true}>
                  <DatePicker.RangePicker
                    value={dateRange}
                    allowClear={true}
                    allowEmpty={[false, true]}
                    onChange={handleDateChange}
                  />
                </Form.Item>
                <Form.Item label={'Description'} required={true}>
                  <TextArea
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </Form.Item>
                <Form.Item label={'Comments'}>
                  <TextArea
                    value={comments}
                    onChange={handleCommentChange}
                  />
                </Form.Item>
              </>
            )
            :
            (
              <>
                <Form.Item label={'Start Date'}>
                  <em className="ant-form-text">
                    {dateRange?.[0] ? dateRange[0].toDate().toLocaleString('en-US', DATE_OPTIONS) : 'No Date Recorded'}
                  </em>
                </Form.Item>
                <Form.Item label={'End Date'}>
                  <em className="ant-form-text">
                    {dateRange?.[1] ? dateRange[1].toDate().toLocaleString('en-US', DATE_OPTIONS) : 'No Date Recorded'}
                  </em>
                </Form.Item>
                <Form.Item label="Description">
                  <span className="ant-form-text">
                    {description}
                  </span>
                </Form.Item>
              </>
            )
          }
        </BRDrawer>
        {!!modal && (
          <ModalContext.Provider value={{ close: closeModal, onSuccess: closeModal, visible: !!modal }}>
            {modal}
          </ModalContext.Provider>
        )}
      </>
    );
  };
