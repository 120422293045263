import * as React from 'react';
import { List, Avatar } from 'antd';
import { DATE_OPTIONS, UI_COLORS } from '../../constants';
import { IPersonRequirement } from '../../types';
import { SessionContext } from '../App';
import { Link } from '../../hooks/useRoutes';
import { ROUTES } from '../../routes';
import { HistoryOutlined } from '@ant-design/icons';
import { BRStore } from '../../hooks/useBRStore';

interface Props {
  pr: BRStore<IPersonRequirement>;
}

export function CoursesScheduledTable(props: Props) {

  const { session } = React.useContext(SessionContext);

  const formattedCoursesScheduled = React.useMemo(
    () => props.pr?.data?.coursesScheduled?.map(c => ({
      uuid: c.course && c.course.uuid,
      name: c.course && c.course.name,
      scheduledDate: c.course && c.scheduledDate,
      completedDate: c.course && c.completedDate,
    })) ?? [],
    [props.pr.data]);

  const scheduledCourses: {
    uuid?: string;
    name?: string;
    scheduledDate?: number;
    completedDate?: number;
  }[] = formattedCoursesScheduled;

  const listItem = (course: any) => {
    const courseLink = session.router.getUrl(
      { name: ROUTES.course },
      course,
    );

    const scheduledDate = course.scheduledDate && (new Date(course.scheduledDate))
                                                    .toLocaleDateString('en-US', DATE_OPTIONS);
    const description = `Scheduled on ${scheduledDate}`;

    return (
        <List.Item>
          <List.Item.Meta
            avatar={(
              <Avatar
                icon={<HistoryOutlined />}
                style={{ backgroundColor: UI_COLORS.orange }}
              />
            )}
            title={<Link href={courseLink}>{course.name}</Link>}
            description={description}
          />
        </List.Item>
    );
  };

  return (
    <List
      dataSource={scheduledCourses}
      locale={{ emptyText: 'No Relevant Coursework' }}
      renderItem={listItem}
    />
  );
}
