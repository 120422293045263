import { IBlackboxData } from './IBlackboxData';

export enum TENURE_REQUEST_STATUS {
  pending = 'Pending',
  approved = 'Approved',
  returned = 'Returned',
}

export interface ITenureRequest {
  uuid: string;
  owner?: string;
  personUuid?: string;
  newPerson?: boolean;
  newPersonFirstName?: string;
  newPersonLastName?: string;
  newPersonDepartment?: string;
  newPersonDivision?: string;
  newPersonPrimaryEmail?: string;
  newPersonSecondaryEmail?: string;
  sponsor?: string;
  startDate?: number;
  endDate?: number;
  location?: string;
  locationOtherAddressOne?: string;
  locationOtherAddressTwo?: string;
  locationOtherCity?: string;
  locationOtherState?: string;
  locationOtherZip?: string;
  createdDate: number;
  lastModified: number;
  status?: TENURE_REQUEST_STATUS;
  siteData?: IBlackboxData;
}
