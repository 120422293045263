import * as React from 'react';
import { default as ReactQuill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Command, CommandButtons } from './CommandButtons';

export interface RequirementDescriptionProps {
  initialText?: string;
  commands?: Command[];
  onChange?(text: string): void;
  readonly?: boolean;
  minHeight?: number|string;
}

export const RichTextEditor: React.FunctionComponent<RequirementDescriptionProps> =
({ initialText = '', commands, readonly = false, onChange, minHeight = 250, ...props }) => {

  const modules = {
    toolbar: !readonly,
  };

  return (
    <div>
      {commands && (<CommandButtons commands={commands} />)}
      <div style={{ minHeight }}>
          <ReactQuill
            theme="snow"
            value={initialText}
            readOnly={readonly}
            modules={modules}
            onChange={onChange}
          />
      </div>
    </div>
  );
};
