import { createToken } from '../helpers/tokenAuth';
import qs from 'qs';
import { SessionContext } from '../components/App';
import { useContext } from 'react';
import { ROUTES } from '../routes';
import { message } from 'antd';

export interface IRequestParams {
  [index: string]: any;
  page?: number;
  pageSize?: number;
  filters?: any;
  sort?: any;
  columns?: any;
}

export const useBRFetch = () => {

  const { session, dispatch } = useContext(SessionContext);

  const makeRequest = async (apiRoute: string, requestParams: IRequestParams = {}) => {
    const uri = `/api${apiRoute}${qs.stringify(requestParams, { addQueryPrefix: true })}`;
    const method = 'GET';

    const props = {
      uri,
      method,
      mac_key: localStorage.getItem('key'),
      access_token: localStorage.getItem('token'),
    };

    const requestOptions = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: createToken(props),
      },
    };

    const response = await fetch(uri, requestOptions);

    let data: any;
    const error: { text: string } = {
      text: '',
    };

    if (!response.ok) {
      if (response.status === 403) {
        session.router.go({ name: ROUTES.unauthorized }, undefined, { replace: true });
      } else if (response.status === 401) {
        dispatch({ type: 'reset' });
      } else if (response.status === 404) {
        message.error('The requested resource could not be found on the server.');
      } else if (response.status < 500) {
        error.text = `The server reported a problem with our request (error code ${response.status}).`;
        return Promise.reject(error);
      } else {
        dispatch({ type: 'siteDown' });
      }
    }

    try {
      data = await response.json();
    } catch (err) {
      error.text = 'Unexpected or No Response. Check your connection and try again.';
      return Promise.reject(error);
    }

    return data;
  };

  return [makeRequest];
};
