import React, { useState } from 'react';
import { Alert, Form } from 'antd';

import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';

import { IPerson, IPersonRequirement } from '../../types';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';

interface Props extends BRModalProps {
  person: IPerson;
  requirement?: string;
  personReqs?: IPersonRequirement[];
}

export const RemoveRequirementFromPersonModal: React.FunctionComponent<Props> =
({ person, requirement, personReqs, ...modalProps }) => {

  const [requirementUuid, setRequirementUuid] = useState(requirement ?? '');
  const [prError, setPrError] = useState(false);
  const [comments, setComments] = useState('');

  const onFinish = async () => {
    setPrError(false);
    if (!requirementUuid) {
      setPrError(true);
      return;
    }
    const formData = {
      comments,
      requirementUuid,
      personUuid: person.uuid,
    };

    await sendCommand({
      formData,
      command: COMMANDS.REMOVE_REQUIREMENT_FROM_PERSON,
    });
  };

  const formattedPr = personReqs && personReqs
    .filter((pr: IPersonRequirement) => (
        pr.manuallyAddedUntil ? new Date(pr.manuallyAddedUntil) > new Date() : false),
      )
    .map((pr: IPersonRequirement) => ({
      value: pr.uuid || '',
      label: pr.name,
      key: pr.uuid,
    }));

  const title = `Remove Requirement from ${person.firstName} ${person.lastName}`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Remove Requirement"
      formProps={{ onFinish }}
    >
      { !requirement && (
        <>
          <Form.Item
            label="Remove Requirement"
            help="Only manually added requirements are removeable"
          >
            <BRSelect
              setValue={setRequirementUuid}
              options={formattedPr}
            />
            {prError && <Alert message="Please select a requirement" type="warning" />}
          </Form.Item>

          <Form.Item label="From Person">
            <span className="ant-form-text">
              {`${person.firstName} ${person.lastName}`}
            </span>
          </Form.Item>
        </>
      )}

      <BRFormComments comments={comments} setComments={setComments}/>

    </BRModal>
  );
};
