import React, { useState } from 'react';
import { Select, Empty } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { BRSelectValue } from './BRSelect';

export interface BRLargeDataSelectProps extends SelectProps<BRSelectValue | BRSelectValue[]> {
  setValue: React.Dispatch<React.SetStateAction<any>>;
}

export const BRLargeDataSelect: React.FunctionComponent<BRLargeDataSelectProps> =
({ options, setValue, ...props }) => {

  const [data, setData] = useState<any[]>([]);
  const [errorText, setErrorText] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [timer, setTimer] = useState<number>();

  React.useEffect(
  () => {
    clearTimeout(timer);
    setTimer(setTimeout(filterProps, 500) as unknown as number);
  },
  [searchValue]);

  const filterProps = () => {
    if (searchValue) {
      const valueLower = searchValue.toLowerCase();
      const filterData = options as BRSelectValue[] | undefined || [];
      let nextData = filterData.filter((item) => {
        return item.label.toLowerCase().indexOf(valueLower) >= 0;
      });
      if (nextData.length === 0) {
        setErrorText('No entries found.');
      } else if (nextData.length > 1000) {
        setErrorText(`${nextData.length} results. Please narrow your search.`);
        nextData = [];
      }
      setData(nextData);
    } else {
      setErrorText('');
      setData([]);
    }
  };

  const handleSelect = (value: BRSelectValue | BRSelectValue[]) => {
    if (value instanceof Array) {
      if (!value) {
        setValue([]);
      } else {
      setValue(value.map(v => v.value));
      }
    } else {
      if (!value) {
        setValue('');
      } else {
        setValue(value.value);
      }
    }
  };

  const notFoundContent = errorText
    ? <Empty description={errorText} />
    : '';

  return (
    <Select
      placeholder={props.loading ? 'Loading...' : 'Search...'}
      {...props}
      onChange={handleSelect}
      onSearch={setSearchValue}
      notFoundContent={notFoundContent}
      showSearch={true}
      allowClear={true}
      filterOption={false}
      options={data}
      labelInValue={true}
      disabled={props.loading || props.disabled}
    />
  );
};
