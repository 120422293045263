import React, { useState, useEffect, ChangeEvent, useMemo } from 'react';
import { Form, Input } from 'antd';
import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments } from '../BRForm';
import { COMMANDS } from '../../constants';
import { sendCommand } from '../../services/commands.services';
import { useBRStore } from '../../hooks/useBRStore';
import { default as ReactQuill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ITenure } from '../../types';

interface Props extends BRModalProps {
  tenure: ITenure;
  notificationType: string;
}

export const SendTenureNotificationModal: React.FunctionComponent<Props> =
({ tenure, notificationType, ...modalProps }) => {
  const fetchDataParams = useMemo(() => ({ url: `/v0/tenures/${tenure.uuid}/templates/type/${notificationType}` }), [tenure, notificationType]);
  const templateStore = useBRStore<{ html: string, subject?: string }>({ fetchDataParams });
  const template = templateStore.data;

  useEffect(
    () => {
      templateStore.fetch();
    },
    [templateStore.fetch]);

  useEffect(
    () => {
      if (templateStore.loaded) {
        setBody(template.html);
        setSubject(template.subject || '');
      }
    },
    [templateStore.loaded],
  );

  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [comments, setComments] = useState('');

  const onFinish = async () => {
    const formData = {
      subject,
      body,
      comments,
      type: notificationType,
      tenure: tenure.uuid,
      recipient: tenure.person,
      resources: [tenure.uuid],
    };

    await sendCommand({
      formData,
      command: COMMANDS.SEND_TENURE_NOTIFICATION,
    });
  };

  const handleSubjectChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  };

  const title = `Send ${notificationType} Notification`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      formProps={{ onFinish }}
    >
      <Form.Item label="Subject">
        <Input
          type="text"
          value={subject}
          onChange={handleSubjectChange}
          required={true}
          readOnly={!templateStore.loaded}
        />
      </Form.Item>
      <Form.Item label="Message Body">
        <ReactQuill
          value={body}
          onChange={setBody}
          readOnly={!templateStore.loaded}
        />
      </Form.Item>
      <BRFormComments comments={comments} setComments={setComments} />
    </BRModal>
  );
};
