import React, { useState } from 'react';
import { DatePicker, Form, Switch } from 'antd';
import moment from 'moment';

import { BRModal, BRModalProps } from '../BRModal';
import { BRFormComments, BRSelect } from '../BRForm';

import { IPerson, IPersonRequirement, IRequirement } from '../../types';

import defaultExpirationDate from '../../helpers/defaultExpirationDate';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { useBRListData } from '../../hooks/useBRListData';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { API_ROUTES } from '../../constants/apiRoutes';
import { FEATURES } from '../../constants/features';
import { CategorizedCascader } from '../CategorizedCascader';

interface Props {
  personReqs: IPersonRequirement[];
  person: IPerson;
}

const fetchDataParams = { url: API_ROUTES.requirements, queryParams: { basic: true } };

export const AddRequirementToPersonModal: React.FunctionComponent<Props & BRModalProps> =
({ personReqs, person, ...modalProps }) => {

  const allRequirements = useBRListData<IRequirement>({ fetchDataParams });

  React.useEffect(
    () => {
      allRequirements.fetch();
    },
    []);

  const [requirementUuid, setRequirementUuid] = useState('');
  const [manuallyAddedUntil, setManullyAddedUntil] = useState(moment(defaultExpirationDate()));
  const [recommended, setRecommended] = useState(false);
  const [comments, setComments] = useState('');

  const requirementOptions: any[] = React.useMemo(
    () => {
      if (allRequirements.loaded) {
        const options = allRequirements.records.filter((req: IRequirement) => (
          req.active && (!personReqs || !personReqs.find(val => val.uuid === req.uuid && val.manuallyAddedUntil))
        ));
        if (FEATURES.CATEGORIZED_REQUIREMENT_SELECTOR) {
          return options;
        }
        return options.map((r: IRequirement) => ({
            key: r.uuid || '',
            label: r.name || '' ,
            value: r.uuid || '',
          }))
          .sort((a: any, b: any) => {
            if (a.label > b.label) {
              return 1;
            }
            if (a.label < b.label) {
              return -1;
            }
            return 0;
          });
      }
      return [];
    },
    [allRequirements.loaded]);

  const handleManuallyAddedUntil = (date: moment.Moment | null) => {
    date && setManullyAddedUntil(date);
  };

  const onFinish = async () => {
    await sendCommand({
      formData: {
        comments,
        person: person.uuid,
        requirement: requirementUuid,
        mandatory: !recommended,
        manuallyAddedUntil: FEATURES.MANUAL_REQUIREMENT_TIME_LIMIT ? manuallyAddedUntil : undefined,
      },
      command: COMMANDS.ADD_REQUIREMENT_TO_PERSON,
    });
  };

  const title = `Add Requirement to ${person.firstName} ${person.lastName}`;

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Add Requirement"
      formProps={{ onFinish }}
    >
      <Form.Item label="Add Requirement:">
        {FEATURES.CATEGORIZED_REQUIREMENT_SELECTOR ? (
          <CategorizedCascader
            disabled={requirementOptions.length === 0}
            resources={requirementOptions}
            setResourceUuid={setRequirementUuid}
          />
        ) : (
          <BRSelect
            disabled={requirementOptions.length === 0}
            options={requirementOptions}
            setValue={setRequirementUuid}
          />
        )}
      </Form.Item>

      { FEATURES.MANUAL_REQUIREMENT_TIME_LIMIT && <Form.Item label="Requirement Added Until:">
        <DatePicker
          value={manuallyAddedUntil}
          onChange={handleManuallyAddedUntil}
        />
      </Form.Item> }

      <Form.Item label="To Person:">
        <span className="ant-form-text">
          {`${person.firstName} ${person.lastName}`}
        </span>
      </Form.Item>

      <Form.Item label="Recommended">
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={recommended}
          onChange={setRecommended}
        />
      </Form.Item>

      <BRFormComments comments={comments} setComments={setComments} />

    </BRModal>
  );
};
