import React from 'react';
import { Typography } from 'antd';
import { BlockProps }  from 'antd/lib/typography/Base';
import { CLIENT_CONST } from '../constants';

const { Paragraph } = Typography;

interface Props extends BlockProps {
}

function Disclaimer(props: Props) {
  return (
    <Paragraph type="secondary" {...props}>
      You are authorized to use the system for approved
      purposes only, as described in the Acceptable Use of
      Technology policy. Use for any other purpose is
      prohibited. All transactional records, reports, email,
      software, and other information generated by, or
      residing upon the system are the property of {CLIENT_CONST.INSTITUTE_NAME}.
    </Paragraph>
  );
}

export default Disclaimer;
