import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, Form, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { BRModal, BRModalProps } from '../BRModal';
import { BRSelect } from '../BRForm';
import { IRequirement, IRequirementCategory } from '../../types';
import { sendCommand } from '../../services/commands.services';
import { COMMANDS } from '../../constants';
import { useBRListData } from '../../hooks/useBRListData';
import { FEATURES } from '../../constants/features';
import { Help } from '../Help';
import { SessionContext } from '../App';
import { ROUTES } from '../../routes';
import { API_ROUTES } from '../../constants/apiRoutes';
import { CategorizedTransfer } from '../CategorizedTransfer';

const requirementDataParams = { url: API_ROUTES.requirements, queryParams: { basic: true } };
const categoryDataParams = { url: API_ROUTES.requirementCategories, queryParams: { basic: true } };

export const RunRequirementReportModal: React.FunctionComponent<BRModalProps> =
({ ...modalProps }) => {

  const { session } = useContext(SessionContext);

  const allRequirements = useBRListData<IRequirement>({
    fetchDataParams: requirementDataParams,
  });

  const allReqCategories = useBRListData<IRequirementCategory>({
    fetchDataParams: categoryDataParams,
  });

  useEffect(
    () => {
      allRequirements.fetch();
      allReqCategories.fetch();
    },
    [
      allRequirements.fetch,
      allReqCategories.fetch,
    ]);

  const [categories, setCategories] = useState([]);
  const [requirements, setRequirements] = useState<string[]>([]);
  const [qtype, setQtype] = useState(FEATURES.REQUIREMENT_CATEGORIES && !FEATURES.CATEGORIZED_REQUIREMENT_SELECTOR ? 'categoryReport' : 'requirementReport');
  const [protocolData, setProtocolData] = useState(false);
  const [allPeople, setAllPeople] = useState(false);
  const [fellowshipData, setFellowshipData] = useState(false);
  const [complete, setComplete] = useState(false);
  const [expiring, setExpiring] = useState(false);
  const [expired, setExpired] = useState(true);
  const [actionRequired, setActionRequired] = useState(true);

  const onFinish = async () => {
    const requirementStatus = {
      complete,
      expiring,
      expired,
      actionRequired,
    };

    const formData = {
      allPeople,
      protocolData,
      fellowshipData,
      requirementStatus,
      type: qtype,
      requirements: qtype === 'requirementReport' ? requirements : undefined,
      categories: qtype === 'categoryReport' ? categories : undefined,
    };

    const response = await sendCommand({
      formData,
      command: COMMANDS.RUN_REPORT,
    });

    session.router.go({ name: ROUTES.report }, response);
  };

  const reqOptions = allRequirements.records
    .map(r => ({
      value: r.uuid,
      label: r.name || '',
    }))
    .sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    })
    .map(r => ({
      key: r.value,
      value: r.value || '',
      label: r.label,
    }));

  const catOptions = allReqCategories.records
    .map(rc => ({
      value: rc.uuid,
      label: rc.name || '',
    }))
    .sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    })
    .map(rc => ({
      key: rc.value,
      value: rc.value || '',
      label: rc.label,
    }));

  const onQtypeChange = (e: RadioChangeEvent) => {
    setQtype(e.target.value);
  };

  const handleDataCheckboxes = (checkedValues: CheckboxValueType[]) => {
    setAllPeople(checkedValues.includes('allPeople'));
    setProtocolData(checkedValues.includes('protocolData'));
    setFellowshipData(checkedValues.includes('fellowshipData'));
  };

  const handleStatusCheckboxes = (checkedValues: CheckboxValueType[]) => {
    setComplete(checkedValues.includes('complete'));
    setExpiring(checkedValues.includes('expiring'));
    setExpired(checkedValues.includes('expired'));
    setActionRequired(checkedValues.includes('actionRequired'));
  };

  const additionalFields = [
    { label: <span>Include All People <Help text="Calculated requirements status for all active People in the system" /></span>, value: 'allPeople' },
    { label: 'Related Protocols', value: 'protocolData' },
  ];

  if (FEATURES.AWARDS) {
    additionalFields.push({ label: 'Fellowship Data', value: 'fellowshipData' });
  }

  const requirementStatusOptions = [
    { label: 'Complete', value: 'complete' },
    { label: 'Expiring', value: 'expiring' },
    { label: 'Expired', value: 'expired' },
    { label: 'Action Required', value: 'actionRequired' },
  ];

  const title = 'Requirement Report';

  return (
    <BRModal
      {...modalProps}
      title={title}
      okText="Run Report"
      formProps={{ onFinish }}
    >
      { FEATURES.CATEGORIZED_REQUIREMENT_SELECTOR && (
        <CategorizedTransfer
          resources={allRequirements.records}
          setSelectedResources={setRequirements}
        />
      )}
      { FEATURES.REQUIREMENT_CATEGORIES && !FEATURES.CATEGORIZED_REQUIREMENT_SELECTOR && (
        <>
          <Form.Item label="Report by">
            <Radio.Group onChange={onQtypeChange} defaultValue="categoryReport">
              <Radio value="categoryReport" name="qtype" defaultChecked={true}>Category</Radio>
              <Radio value="requirementReport" name="qtype">Requirement</Radio>
            </Radio.Group>
          </Form.Item>
  
          { qtype === 'categoryReport' && (
            <Form.Item label="Select one or more">
              <BRSelect
                mode="multiple"
                options={catOptions}
                setValue={setCategories}
                placeholder="Requirement categories..."
              />
            </Form.Item>
          )}
        </>
      )}
      { qtype === 'requirementReport' && !FEATURES.CATEGORIZED_REQUIREMENT_SELECTOR && (
        <Form.Item label="Select one or more">
          <BRSelect
            mode="multiple"
            options={reqOptions}
            setValue={setRequirements}
            placeholder="Requirements..."
          />
        </Form.Item>
      )}    

      <Form.Item label="Additional data:">
        <Checkbox.Group onChange={handleDataCheckboxes} options={additionalFields}/>
      </Form.Item>

      <Form.Item label="Requirement Status:">
        <Checkbox.Group
          onChange={handleStatusCheckboxes}
          options={requirementStatusOptions}
          defaultValue={['expired', 'actionRequired']}
        />
      </Form.Item>

    </BRModal>
  );
};
