import * as React from 'react';
import { TABLE_IDS } from '../../constants';
import { BRLayout } from '../../components/BRLayout';
import BRTable from '../../components/BRTable';
import { useBRTableStore } from '../../hooks/useBRTableStore';
import { ROUTES } from '../../routes';
import { IRule } from '../../types/IRule';

interface Props {
}

export function RulesListPage(props: Props) {
  const rules = useBRTableStore<IRule>({
    fetchDataParams: { url: '/v0/rules' },
  });

  return (
    <BRLayout
      title="Access Rules"
    >
      <BRTable
        linkPath={ROUTES.rule}
        tableId={TABLE_IDS.RULES_TABLE}
        tableData={rules}
      />
    </BRLayout>
  );
}
