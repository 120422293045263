import moment, { Moment } from 'moment';
import { COMMANDS } from '../constants';
import { createToken } from '../helpers/tokenAuth';
import { Modal } from 'antd';
import { API_ROUTES } from '../constants/apiRoutes';

const COMMAND_TIMEOUT_SECONDS = 10;
const pendingCommands: {[index: string]: Moment} = {};

export async function sendCommand(params: {
  command: COMMANDS,
  formData: { [index: string]: any } | FormData,
  forceCompletion?: boolean,  // Do we want to execute this command if another instance of the same command is already in progress?
}) {
  const { command, formData } = params;

  if (!params.forceCompletion && pendingCommands[command]?.add(COMMAND_TIMEOUT_SECONDS, 'seconds').isAfter(moment())) {
    return;
  }

  pendingCommands[command] = moment();

  const uri = `/api${API_ROUTES.commands}`;
  const method = 'POST';

  const props = {
    uri,
    method,
    mac_key: localStorage.getItem('key'),
    access_token: localStorage.getItem('token'),
  };

  const requestOptions: RequestInit = {
    method,
    headers: {
      Authorization: createToken(props),
    },
    body: undefined,
  };

  if (formData instanceof FormData) {
    formData.append('command', command);
    requestOptions.body = formData;
  } else {
    if (requestOptions.headers) {
      requestOptions.headers['Content-Type'] = 'application/json';
    }
    requestOptions.body = JSON.stringify({ command, data: formData });
  }
  const response = await fetch(uri, requestOptions);
  let data: any;

  delete pendingCommands[command];

  if (response.headers.get('content-type')?.match(/^application\/json/)) {
    data = await response.json();
  }

  if (response.ok) {
    return data;
  }

  const error = data?.error || 'Unknown error.';

  Modal.error({
    title: 'There was an error processing your request.',
    content: error,
    style: { position: 'relative', top: '1em' },
  });

  if (console.error) {
    console.error(error);
  }

  throw new Error(error);
}
