import * as React from 'react';
import { PageHeader, Card } from 'antd';
import { NavBar } from './NavBar';
import { PageDescriptions } from './PageDescriptions';
import { CommandButtons, Command } from './CommandButtons';
import { SessionContext } from './App';
import { CLIENT_CONST } from '../constants';
import { TagType } from 'antd/lib/tag';
import { LoadingOutlined, HomeOutlined } from '@ant-design/icons';
import { Link } from '../hooks/useRoutes';
import { Route, BreadcrumbProps } from 'antd/lib/breadcrumb/Breadcrumb';
import { Session } from '../types/Session';
import { CoreRouteSpec, ROUTES } from '../routes';

interface Props {
  status?: {
    color: string,
    text: string,
  };
  commands?: Command[];
  loading?: boolean;
  children?: React.ReactElement | React.ReactElement[];
  title?: string;
  subTitle?: string;
  tags?: React.ReactElement<TagType> | React.ReactElement<TagType>[];
  descriptionItems?: { [ index: string ]: any };
  breadcrumbs?: Route[];
}

function itemRender(route: Route, params: BreadcrumbProps, routes: Route[], paths: string[]) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link href={route.path || '/'}>{route.breadcrumbName}</Link>
  );
}

function defaultCrumbs(session: Session<ROUTES, CoreRouteSpec>, title?: string) {
  const routes: Route[] = [];

  for (let i = 1; i < session.router.current.selected.length; i += 1) {
    const crumb = session.router.current.selected[i];
    routes.push({
      path: session.router.getUrl({ name: crumb.id }, session.router.current.params) || '',
      breadcrumbName: crumb.title || ((i === session.router.current.selected.length - 1 && title) ? title : ''),
    });
  }

  return routes;
}

export function BRLayout(props: Props) {
  const { session } = React.useContext(SessionContext);

  React.useEffect(
    () => {
      document.title = `${props.title} | ${session.router.current.spec.title || CLIENT_CONST.TITLE}`;
    },
    [props.title]);

  let extra;

  if (props.commands) {
    extra = <CommandButtons commands={props.commands} />;
  }

  const description = [];

  if (props.descriptionItems) {
    description.push(<PageDescriptions key="top-pd" items={props.descriptionItems} />);
  }

  const crumbs = props.breadcrumbs || defaultCrumbs(session, props.title);

  const routes: any = [
    {
      path: '/',
      breadcrumbName: <HomeOutlined />,
    },
    ...crumbs,
  ];

  return (
    <>
      <header>
        <NavBar />
      </header>
      <main>
        <PageHeader
          tags={props.tags}
          title={props.loading ? <LoadingOutlined /> : props.title}
          subTitle={props.subTitle}
          breadcrumb={crumbs.length > 1 ? { routes, itemRender } : undefined}
          extra={extra}
        >
          {description}
        </PageHeader>
        <Card bordered={false}>
          {props.children}
        </Card>
      </main>
    </>
  );
}
